import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { CgChevronRightO } from 'react-icons/cg';
import { useMemo } from 'react';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { Title } from '../pages/Slide3';
import Helmet from 'react-helmet';

// import comingSoonThumb from '../_img/coming-soon.webp'

<script src='https://player.vimeo.com/api/player.js'></script>;

const VideoContainer = () => {
    const { isMobile } = useMobileDetect();

    const videos = useMemo(() => {
        return [
					{
						link: 'https://player.vimeo.com/video/836335220?muted=0&h=68b7d59851&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
						legend: (
							<span>
												KRAZATI for <em>KRAS G12C</em>-Mutated Advanced NSCLC
										</span>
								),
								placeholder: '/assets/placeholder.webp',
								thumb: '/assets/thumb1.webp',
								title: 'MIRMIR_1650053_Main_05_15',
								id: 'video-2',
								disabled: false,
							},
							{
								link: 'https://player.vimeo.com/video/781866436?muted=0&h=11392a14bd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
								legend: (
									<span>
										Identifying Patients With Possible <em>KRAS G12C</em> Mutations
									</span>
								),
								placeholder: '/assets/placeholder.webp',
								thumb: '/assets/thumb2.webp',
								title: 'MIRMIR_1650053_Patient_ID_05_14',
								id: 'video-1',
								disabled: false,
							},
        ];
    }, []);

    const [currentVideoPlaying, setCurrentVideoPlaying] = useState(0);
    const [nextVideoLegend, setNextVideoLegend] = useState(videos[1].legend);

    const stopVideo = () => {
        const videosIframe = document.querySelectorAll('.iframe-video');

        videosIframe.forEach((video) => {
            // eslint-disable-next-line no-undef
            const player = new Vimeo.Player(video);
            if (video) {
                const videoSrc = video.src;
                video.src = videoSrc;
            }
        });
    };

    const unMute = () => {
        const videosIframe = document.querySelectorAll('.iframe-video');

        videosIframe.forEach((video) => {
            // eslint-disable-next-line no-undef
            const player = new Vimeo.Player(video);
            player.on('play', function () {
                console.log('Played the video');
            });
        });
    };

    const goToNextVideo = () => {
        stopVideo();
        if (currentVideoPlaying === videos.length - 1) {
            setCurrentVideoPlaying(0);
            // playVideo(0)

            return;
        }

        setCurrentVideoPlaying(currentVideoPlaying + 1);
        // playVideo(currentVideoPlaying + 1)
    };

    const goToVideo = (videoId) => {
        stopVideo();
        setCurrentVideoPlaying(videoId);
        // playVideo(videoId)
    };

    // const playVideo = (videoId) => {
    //   videos.forEach((video, index) => {
    //     const videoEl = document.getElementById(`video-${index}`)
    //     videoEl.pause()
    //     videoEl.currentTime = 0

    //     if (index === videoId) {
    //       videoEl.play()
    //     }
    //   })
    // }

    useEffect(() => {
        if (currentVideoPlaying === videos.length - 1) {
            setNextVideoLegend(videos[0].legend);
            return;
        }
        setNextVideoLegend(videos[currentVideoPlaying + 1].legend);
    }, [currentVideoPlaying, videos]);

    return (
        <>
            {!isMobile && <ContainerMark />}
            <Helmet>
                <script src='https://player.vimeo.com/api/player.js'></script>
            </Helmet>
            <Container>
                {isMobile && <Title>Hear from a thoracic malignancy specialist</Title>}
                <Wrapper>
                    <VideosContainer>
                        {videos.map((video, index) => (
                            // <VideoWrapper
                            //   id={`video-${index}`}
                            //   controls key={index}
                            //   className={ index === currentVideoPlaying ? 'active' : null}
                            //   poster={video.placeholder}
                            // >
                            //     <source src={video.link}/>
                            // </VideoWrapper>
                            <React.Fragment key={index}>
                                <VideoWrapper
                                    id={`video-${index}`}
                                    className={`${video.disabled ? 'disabled' : null} ${index === currentVideoPlaying ? 'active' : null}`}
                                    style={{ padding: '56.25% 0 0 0', position: 'relative', width: '100%' }}
                                >
                                    <iframe
                                        className={`iframe-video ${video.disabled ? 'disabled' : null}`}
                                        id={video.id}
                                        src={video.link}
                                        frameBorder='0'
                                        allow='autoplay; fullscreen; picture-in-picture'
                                        allowFullScreen
                                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                        title={video.title}
                                    ></iframe>
                                </VideoWrapper>
                            </React.Fragment>
                        ))}
                    </VideosContainer>

                    {!isMobile && (
                        <>
                            <VideoTitle>Now Playing: {videos[currentVideoPlaying].legend}</VideoTitle>
                            <Legend>
                                <span>Up next: {nextVideoLegend}</span>
                                <NextVideoBtn onClick={goToNextVideo}>
                                    <span>Play next</span>
                                    <CgChevronRightO />
                                </NextVideoBtn>
                            </Legend>
                        </>
                    )}
                </Wrapper>

                <NextVideoSelectorWrapper>
                    {videos.map((video, index) => (
                        <NextVideo
                            onClick={() => goToVideo(index)}
                            key={index * 10}
                            className={`
            ${index === currentVideoPlaying ? 'active' : null}
          `}
                        >
                            <img src={video.thumb} alt='' />
                        </NextVideo>
                    ))}
                </NextVideoSelectorWrapper>

                {isMobile && (
                    <>
                        <VideoTitle>Now Playing: {videos[currentVideoPlaying].legend}</VideoTitle>

                        <Legend>
                            <span>Up next: {nextVideoLegend}</span>
                            <NextVideoBtn onClick={goToNextVideo}>
                                <span>Play next</span>
                                <CgChevronRightO />
                            </NextVideoBtn>
                        </Legend>
                    </>
                )}
            </Container>
        </>
    );
};

export default VideoContainer;

const Container = styled.div`
    background: linear-gradient(#6f81a9, rgba(12, 22, 63, 0.6));
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    clip-path: polygon(0rem 0, 100% 0, 100% calc(100% - 2.4rem), calc(100% - 1.6rem) 100%, 0 100%, 0 2.4rem);
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding: 1.9rem 0.8rem 2.2rem;

    @media ${device.iphoneSe} {
        margin-top: 0;
    }

    @media ${device.tablet} {
        align-items: center;
        margin-top: 2.4rem;
        max-width: 80%;
    }

    @media ${device.laptop} {
        max-width: 70%;
        margin: 0;
    }

    @media ${device.desktop} {
        align-items: initial;
        width: 108rem;
        height: 61rem;
        max-width: 100%;
        flex-direction: row;
        margin-top: 0;
        padding: 3rem;
        clip-path: polygon(0rem 0, 100% 0, 100% calc(100% - 3.2rem), calc(100% - 2.4rem) 100%, 0 100%, 0 3.2rem);
        background: linear-gradient(172.29deg, rgba(80, 88, 125, 0.88) -10%, rgba(22, 29, 65, 0.6) 70%, rgba(22, 29, 65, 1) 100%);
    }
`;

const VideoTitle = styled.span`
    color: #ffffff;
    font-family: 'Barlow';
    font-size: 1.35rem;
    font-weight: bold;
    line-height: 1.6rem;
    margin-bottom: 1.6rem;
    margin-top: 0.4rem;
    @media ${device.desktop} {
        font-size: 1.65rem;
        line-height: 2rem;
        margin-top: 0.8rem;
        // width: 60rem;
        min-height: 2rem;
    }
`;

const ContainerMark = styled.div`
    position: relative;
    width: 100%;
    max-width: 107.8rem;
    &:after {
        content: '';
        position: absolute;
        top: -1.5rem;
        right: -1.5rem;
        width: 16rem;
        height: 16rem;
        border-right: 0.1rem solid white;
        border-top: 0.1rem solid white;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    @media ${device.tablet} {
        margin-top: 0;
        width: calc(100% - 153px);
    }
    @media (min-width: 1100px) {
        margin-top: 0;
        width: calc(100% - 153px);
        margin-right: 2.7rem;
    }
`;

const VideosContainer = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
    display: flex;

    position: relative;

    @media ${device.tablet} {
        justify-content: center;
    }

    @media ${device.desktop} {
        justify-content: initial;
        margin-top: 0;
        margin-bottom: 0;
    }
`;
const VideoWrapper = styled.div`
    width: min(100%, 50rem);
    margin: 0 auto;
    height: 100%;
    display: none;

    &.active {
        display: initial;
    }

    @media ${device.tablet} {
        max-width: 100%;
        width: 86rem;

        video {
            min-width: 86rem;
        }
    }
    // &.disabled {
    //   position: relative;
    //   &:before {
    //     content: '';
    //     display: block;
    //     background-image: url();
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-position: center center;
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: 1;
    //   }
    // }
    .disabled {
        pointer-events: none;
        display: none;
    }
`;

const Legend = styled.div`
    width: min(100%, 50rem);
    margin: 0.6rem auto 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    font-size: min(1.2rem, 1.6rem);
    min-height: 7.4rem;

    @media ${device.tablet} {
        font-size: min(1.6rem, 1.8rem);
        align-items: center;
    }

    @media ${device.desktop} {
        flex-direction: row;
        width: initial;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
        font-size: 1.4rem;
        min-height: initial;
        width: 100%;

        span {
            max-width: 75%;
        }
    }
`;

const NextVideoBtn = styled.button`
    border: none;
    background: #3246ff;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0.8rem 1.4rem;
    border-radius: 1.6rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1.6rem;
    max-height: 4rem;
    span {
        margin-right: 0.5rem;
        font-size: min(1.1rem, 1.4rem);
    }

    svg {
        font-size: min(1.2rem, 1.6rem);
    }

    @media ${device.desktop} {
        margin-left: 3rem;
        margin-top: 0;
        padding: 0.8rem 1.4rem;
        height: 3.2rem;
        span {
            font-size: 1.4rem;
        }

        svg {
            font-size: min(1.4rem, 1.6rem);
        }
    }
`;

const NextVideoSelectorWrapper = styled.div.attrs({
    className: 'swiper-no-swiping',
})`
    width: min(100%, 500px);
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media ${device.desktop} {
        flex-direction: column;
        justify-content: flex-start;
        width: 13.6rem;
    }
`;
const NextVideo = styled.div`
    margin-right: 1rem;
    width: 100%;
    &:last-of-type {
        margin-right: 0;
    }

    &.active {
        img {
            box-shadow: 0px 0px 0.1rem 0.1rem #fff;
        }
    }

    img {
        width: 100%;

        cursor: pointer;
        &:hover {
            box-shadow: 0px 0px 0.1rem 0.1rem #fff;
        }
    }

    @media ${device.desktop} {
        margin-right: 0;
        img {
            margin-bottom: 1.2rem;
            margin-left: 1.2rem;
            width: 13.6rem;
            height: 7.6rem;
            margin: 0 0 2.4rem;
        }
    }

    @media ${device.desktop600h} {
        width: 12.6rem;
    }
`;
