import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useForm } from "react-hook-form";
import { useSwiperSlide } from "swiper/react";
import {
  BaseLink,
  BaseText,
  Box,
  Container,
  Input,
  Label,
  StyledBothSideShape,
} from "../../_components/PageSwipper/utils/styles";
import useActiveLink from "../../_components/Header/hooks/useActiveLink";
import { device } from "../../_assets/styles/theme";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import ContainerScroll from "../../_components/PageSwipper/components/ContainerScroll";
import { ReactComponent as Check } from "../../_assets/img/icons/basic_done.svg";
import { Link } from "react-router-dom";
import Select from "../_components/Select";

const emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

const Slide2 = ({
  setShowHeaderSecondaryMenu,
  setCurrentSlide,
  isiconfig,
  setOverlay,
}) => {
  const swiperSlide = useSwiperSlide();
  const { isMobile } = useMobileDetect();
  const mobileBgWrapper = useRef();
  const [showPageContent, setShowPageContent] = useState(false);
  const mobileOverlay = "linear-gradient(180deg, rgba(36,40,62,0.74) 0%, rgba(7,13,35,0.8485) 100%)";
  const desktopOverlay = "transparent";
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
  const [finishedSubmission, setFinishedSubmission] = useState(false);
  const [errorOnSubmission, setErrorOnSubmission] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [providerFilled, setProviderFilled] = useState('')

  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitSuccessful, isDirty, isValid },
  } = useForm({ mode: "onChange" });
  useActiveLink(setShowHeaderSecondaryMenu, "contact-us", swiperSlide.isActive);

  useEffect(() => {
    const startSession = async () => {
      const api = await fetch('/api/session.php', {
        method: "POST",
      })
      const response = await api.json()

      setValue('session_key', response)
      // setValue('session_key', '32132132131312') - test to receive error
    }

    startSession()
  }, [])

  useEffect(() => {
    const slide = document.querySelector(".mySwiper");

    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setShowPageContent(true);
      setCurrentSlide(2);
      setOverlay(slideOverlay);
      slide.swiper.disable()
      slide?.swiper?.draggable?.disable();
      return;
    }
    setShowPageContent(false);
    slide?.swiper?.draggable?.enable();
  }, [swiperSlide.isActive]);

  useEffect(() => {
    // console.log('isDirty', isDirty);
    // console.log('isValid', isValid);
    // console.log('errors', errors);

  }, [isDirty, isValid, errors]);

  useEffect(() => {
    setTimeout(() => {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(el => {
        el.addEventListener('blur', () => {
          window.scroll(0, 0);
        });
      });
    }, 2000);
  }, []);


  const onSubmit = (data) => {
    const smsChecked = data["receive_message"];
    sendFormData(data)
  };

  function sendFormData(data) {
    // Push all form values into an array
    let urlEncodedArr = [];
    for (let key in data) {
      urlEncodedArr.push(key + "=" + encodeURIComponent(data[key]));
    }

    // Convert arr to string
    const urlEncodedStr = urlEncodedArr.join("&");

    // POST string to backend
    fetch("/api/api.php", {
      body: urlEncodedStr,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
    })
      .then((response) => {
        if (response.status !== 200) {
          setFinishedSubmission(true)
          setErrorOnSubmission(true)
        } else {
          setFinishedSubmission(true);
          // response.json()
        }
        const scrollContainer = document.querySelectorAll('.swiper-wrapper')[2];
        scrollContainer.style.transform = 'translate3d(0,0,0)';
        window.scroll(0, 0);
      })
  };

  function showPhone() {
    let checkboxes = document.querySelectorAll(".checkbox-container");

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];
      const container = checkbox.nextSibling;
      const phoneInput = container.querySelector("input");

      setTimeout(() => {
        if (checkbox.classList.contains("checked")) {
          // add phone input if checked
          phoneInput.classList.add("visible");
        } else {
          // remove phone input if not checked
          phoneInput.classList.remove("visible");
        }
      }, 100);
    }
  }

  const onError = (data) => { console.log(data) };

  if (isMobile) {
    return (
      <CustomContainer
        className={showPageContent ? "visible" : "hidden"}
        ref={isMobile ? mobileBgWrapper : null}
        isiconfig={isiconfig}
      >
        <ContainerScroll>
          <FormWrapper padding="1.6rem" direction="column">

            <BaseText size={2}>Stay in the know about KRAZATI</BaseText>
            <BaseText as="p" margin=".5rem 0" size={1.2} weight="300">
              You are now registering for information that is intended for US
              healthcare professionals.
            </BaseText>

            {finishedSubmission ? (
              errorOnSubmission ? (
                <ErrorMessage>
                  Oops!
                  <br />
                  Please try submitting again.
                </ErrorMessage>
              ) : (
                <ThankYouMessage>Thank you for registering!</ThankYouMessage>
              )
            ) : (
              <>
                <BaseText
                  className="required-field-message"
                  text-align="right"
                  margin=".5rem 0 0"
                  size={1}
                  weight="bold"
                >
                  *Indicates a required field
                </BaseText>

                <Box
                  as="form"
                  autoComplete="off"
                  direction="column"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input
                    type="hidden"
                    id="session_key"
                    {...register("session_key", { required: false })}
                  />
                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="fn_field">
                      First Name*
                    </Label>
                    <Input
                      margin="0.5rem 0 1.6rem"
                      id="fn_field"
                      {...register("fn_field", { required: true })}
                      className={errors?.fn_field ? "__has-error" : ""}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="last_name">
                      Last Name*
                    </Label>
                    <Input
                      margin="0.5rem 0 1.6rem"
                      id="last_name"
                      {...register("last_name", { required: true })}
                      className={errors?.last_name ? "__has-error" : ""}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="e_field">
                      Email*
                    </Label>
                    <Input
                      margin="0.5rem 0 1.6rem"
                      id="e_field"
                      className={errors?.e_field ? "__has-error" : ""}
                      {...register("e_field", {
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="zipcode">
                      ZIP Code*
                    </Label>
                    <Input
                      margin="0.5rem 0 1.6rem"
                      id="zipcode"
                      className={`cleave-zip ${errors?.zipcode ? "__has-error" : ""
                        }`}
                      maxLength={5}
                      {...register("zipcode", { required: true })}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="provider_type">
                      Provider Type*
                    </Label>
                    <div className="select-wrapper">
                      <Select
                        register={register}
                        className={errors?.provider_type ? "__has-error" : ""}
                        onChange={(e) => {
                          setProviderFilled(true);
                          trigger()
                        }}
                        setValue={setValue}
                        //cleanError={clearErrors}
                        options={[
                          'Medical Oncology',
                          'Radiation Oncology',
                          'Pathology',
                          'Advanced Practice Provider (NP/PA)',
                          'Pharmacy',
                          'Nurse',
                          'Nurse Navigator',
                          'Clinic Coordinator/Case Manager',
                          'Other'
                        ]}
                      />
                    </div>
                  </Box>

                  <Box direction="column" margin="6rem 0 0 0" justify="flex-start">
                    <Label weight="bold" size={1} htmlFor="npi_number">
                      NPI Number (This option saves you time!)
                    </Label>
                    <Input
                      margin="0.5rem 0 .5rem"
                      id="npi_number"
                      className="cleave-npi"
                      {...register("npi_number", { required: false })}
                    />
                  </Box>

                  <Box
                    justify="flex-start"
                    align="center"
                    margin="2rem 0.8rem 2.4rem 0"
                  >
                    <Box
                      as="label"
                      htmlFor="connected"
                      position="relative"
                      border="0.1rem solid white"
                      margin="0 0.8rem 0 0"
                      width="2.4rem"
                      height="2.4rem"
                      background="#303345"
                      className={watch("be_connected") ? "checked" : null}
                    >
                      {watch("be_connected") ? (
                        <CheckWrapper>
                          <Check />
                        </CheckWrapper>
                      ) : null}
                      <Input
                        className="invisible"
                        id="connected"
                        {...register("be_connected")}
                        type="checkbox"
                      />
                    </Box>
                    <Label width="90%" htmlFor="connected">
                      I would like to be connected to a representative from
                      Mirati.
                    </Label>
                  </Box>

                  <Box
                    justify="flex-start"
                    align="flex-start"
                    margin="0 0.8rem 2.4rem 0"
                  >
                    <Box
                      as="label"
                      htmlFor="receive_message"
                      position="relative"
                      border="0.1rem solid white"
                      margin="0 0.8rem 0 0"
                      width="2.4rem"
                      height="2.4rem"
                      background="#303345"
                      className={`checkbox-container ${watch("receive_message") ? "checked" : null
                        }`}
                      onClick={() => {
                        showPhone();
                      }}
                    >
                      {watch("receive_message") ? (
                        <CheckWrapper>
                          <Check />
                        </CheckWrapper>
                      ) : null}
                      <Input
                        className="invisible"
                        id="receive_message"
                        width="2.4rem"
                        height="2.4rem"
                        {...register("receive_message")}
                        type="checkbox"
                      />
                    </Box>
                    <Box direction="column" align="flex-start" width="90%">
                      <Label
                        width="90%"
                        htmlFor="receive_message"
                        margin="0 0 0 .2rem"
                        onClick={(e) => {
                          showPhone(e);
                        }}
                      >
                        I would like to receive text messages from Mirati.
                        Message and data rates may apply.{" "}
                        <BaseLink
                          margin="0 0  .2rem"
                          size={1.2}
                          weight="bold"
                          color="#17C94E"
                          decoration="underline"
                          target="_blank"
                          href="https://www.mirati.com/terms-of-use/"
                        >
                          SMS Terms and Conditions
                        </BaseLink>.
                      </Label>
                      <Input
                        border="0.1rem solid white"
                        width="50%"
                        height="2.8rem"
                        background="#303345"
                        padding="0.4rem"
                        text-align="center"
                        size={1.2}
                        id="mobile_phone"
                        placeholder="(XXX) XXX-XXXX"
                        className={`
                          ${errors?.mobile_phone ? "__has-error " : " "}
                          ${watch("receive_message") ? "visible " : " "}
                        `}
                        type="text"
                        {...register("mobile_phone", { required: watch("receive_message") })}
                      />
                    </Box>
                  </Box>

                  <Box
                    justify="flex-start"
                    align="flex-start"
                    margin="0 0.8rem 0.5rem 0"
                  >
                    <Box
                      as="label"
                      htmlFor="privacy_notice"
                      position="relative"
                      border="0.1rem solid white"
                      margin="0 0.8rem 0 0"
                      width="2.4rem"
                      height="2.4rem"
                      background="#303345"
                      className={watch("privacy_notice") ? "checked" : null}
                    >
                      {watch("privacy_notice") ? (
                        <CheckWrapper>
                          <Check />
                        </CheckWrapper>
                      ) : null}

                      <Input
                        className="invisible"
                        id="privacy_notice"
                        width="2.4rem"
                        height="2.4rem"
                        {...register("privacy_notice", {
                          required: {
                            value: true,
                            message:
                              "You must accept the Privacy Notice to continue.",
                          },
                        })}
                        type="checkbox"
                      />
                    </Box>

                    <PrivacyRequiredInfo>*</PrivacyRequiredInfo>
                    <Label width="90%" htmlFor="privacy_notice">
                      I have read and agree to the Mirati
                      <BaseLink
                        margin="0 0 0 .2rem"
                        size={1.2}
                        weight="bold"
                        color="#17C94E"
                        decoration="underline"
                        required="true"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            "https://www.bms.com/privacy-policy.html",
                            "_blank"
                          );
                        }}
                      >
                        Privacy Notice
                      </BaseLink>
                      .
                    </Label>
                  </Box>
                  <PrivacyErrorMessage>
                    {errors?.privacy_notice?.message}
                  </PrivacyErrorMessage>

                  <Button
                    as="button"
                    type="submit"
                    className={(Object.keys(errors).length || !providerFilled) ? "disabled" : ""}
                  >
                    Submit
                  </Button>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Box>
              </>
            )}
          </FormWrapper>
        </ContainerScroll>
      </CustomContainer>
    );
  }

  return (
    <CustomContainer
      className={showPageContent ? "visible" : "hidden"}
      isiconfig={isiconfig}
    >
      <ContainerScroll>
        <FormContainer
          background="rgba(46, 51, 68, .9)"
          border="transparent"
          borderSize={0.1}
          className="swiper-no-swiping swiper-pointer-events"
        >
          <Link className="close-btn" to="/n/stay-connected">
            <img src="/assets/close_big.svg" alt="" />
          </Link>

          <FormWrapper padding="0" direction="column">
            <CustomTitle>Stay in the know about KRAZATI</CustomTitle>
            <CustomSubTitle as="p" margin=".5rem 0 2rem" size={2} weight="300">
              You are now registering for information that is intended for US
              healthcare professionals.
            </CustomSubTitle>

            {finishedSubmission ? (
              errorOnSubmission ? (
                <ErrorMessage>
                  Oops!
                  <br />
                  Please try submitting again.
                </ErrorMessage>
              ) : (
                <ThankYouMessage>Thank you for registering!</ThankYouMessage>
              )
            ) : (
              <Box
                as="form"
                autoComplete="off"
                direction="column"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <input
                  type="hidden"
                  id="session_key"
                  {...register("session_key", { required: false })}
                />
                <Box>
                  <Box direction="column">
                    <Label weight="bold" size={1.3} htmlFor="fn_field">
                      First Name*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="fn_field"
                      {...register("fn_field", { required: true })}
                      className={errors?.fn_field ? "__has-error" : ""}
                    />
                  </Box>

                  <Box direction="column" margin="0 0 0 4rem">
                    <Label weight="bold" size={1.3} htmlFor="last_name">
                      Last Name*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="last_name"
                      className={errors?.last_name ? "__has-error" : ""}
                      {...register("last_name", { required: true })}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box direction="column">
                    <Label weight="bold" size={1.3} htmlFor="e_field">
                      Email*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="e_field"
                      className={errors?.e_field ? "__has-error" : ""}
                      {...register("e_field", {
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                  </Box>

                  <Box direction="column" margin="0 0 0 4rem">
                    <Label weight="bold" size={1.3} htmlFor="zipcode">
                      ZIP Code*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="zipcode"
                      maxLength={5}
                      className={`cleave-zip ${errors?.zipcode ? "__has-error" : ""
                        }`}
                      {...register("zipcode", { required: true })}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box direction="column" justify="flex-start">
                    <Label weight="bold" size={1.3} htmlFor="provider_type">
                      Provider Type*
                    </Label>
                    <div className="select-wrapper">
                      <Select
                        register={register}
                        className={errors?.provider_type ? "__has-error" : ""}
                        onChange={(e) => {
                          setProviderFilled(true);
                          trigger();
                        }}
                        setValue={setValue}
                        //cleanError={clearErrors}
                        options={[
                          'Medical Oncology',
                          'Radiation Oncology',
                          'Pathology',
                          'Advanced Practice Provider (NP/PA)',
                          'Pharmacy',
                          'Nurse',
                          'Nurse Navigator',
                          'Clinic Coordinator/Case Manager',
                          'Other'
                        ]}
                      />
                    </div>
                  </Box>
                  <Box direction="column" margin="0 0 0 4rem" justify="flex-start">
                    <Label weight="bold" size={1.3} htmlFor="npi_number">
                      NPI Number (This option saves you time!)
                    </Label>
                    <Input
                      margin="0.8rem 0 .5rem"
                      id="npi_number"
                      className="cleave-npi"
                      {...register("npi_number", { required: false })}
                    />
                  </Box>
                </Box>
                <BaseText
                  text-align="right"
                  margin="0 0 0"
                  size={1.3}
                  weight="bold"
                  className="required-field-message"
                >
                  *Indicates a required field
                </BaseText>

                <Box
                  justify="flex-start"
                  align="center"
                  margin="1.5rem 0.8rem 2.4rem 0"
                >
                  <Box
                    as="label"
                    style={{ cursor: "pointer" }}
                    htmlFor="connected"
                    position="relative"
                    border="0.1rem solid white"
                    margin="0.4rem 0.8rem 0 0"
                    width="2.4rem"
                    height="2.4rem"
                    background="#303345"
                    className={watch("be_connected") ? "checked" : null}
                  >
                    {watch("be_connected") ? (
                      <CheckWrapper>
                        <Check />
                      </CheckWrapper>
                    ) : null}
                    <Input
                      className="invisible"
                      id="connected"
                      {...register("be_connected")}
                      type="checkbox"
                    />
                  </Box>
                  <Label width="70%" htmlFor="connected">
                    I would like to be connected to a representative from
                    Mirati.
                  </Label>
                </Box>

                <Box
                  justify="flex-start"
                  align="center"
                  margin="0 0.8rem 2.4rem 0"
                >
                  <Box
                    as="label"
                    style={{ cursor: "pointer" }}
                    htmlFor="receive_message"
                    position="relative"
                    border="0.1rem solid white"
                    margin="0 0.8rem 0 0"
                    width="2.4rem"
                    height="2.4rem"
                    background="#303345"
                    className={`checkbox-container ${watch("receive_message") ? "checked" : null
                      }`}
                    onClick={() => {
                      //showPhone();
                    }}
                  >
                    {watch("receive_message") ? (
                      <CheckWrapper>
                        <Check />
                      </CheckWrapper>
                    ) : null}
                    <Input
                      className="invisible"
                      id="receive_message"
                      width="2.4rem"
                      height="2.4rem"
                      {...register("receive_message")}
                      type="checkbox"
                    />
                  </Box>
                  <Box justify="flex-start" align="center" width="90%">
                    <Label
                      width="70%"
                      htmlFor="receive_message"
                      margin="0 0 0 .2rem"
                    >
                      I would like to receive text messages from Mirati. Message
                      and data rates may apply.{" "}
                      <BaseLink
                        margin="0 0 .2rem"
                        size={1.6}
                        weight="bold"
                        color="#17C94E"
                        decoration="underline"
                        href="https://www.mirati.com/terms-of-use/"
                        target="_blank"
                      >
                        SMS Terms and Conditions
                      </BaseLink>.
                    </Label>

                    <Input
                      border="0.1rem solid white"
                      width="17rem"
                      height="2.8rem"
                      background="#303345"
                      padding="0.4rem"
                      text-align="center"
                      size={2}
                      id="mobile_phone"
                      placeholder="(XXX) XXX-XXXX"
                      className={`
                        ${errors?.mobile_phone ? "__has-error " : " "}
                        ${watch("receive_message") ? "visible " : " "}
                      `}
                      type="text"
                      {...register("mobile_phone", { required: watch("receive_message") })}
                    />
                  </Box>
                </Box>

                <Box justify="flex-start" align="center" margin="0 0.8rem 0 0">
                  <Box
                    as="label"
                    style={{ cursor: "pointer" }}
                    htmlFor="privacy_notice"
                    position="relative"
                    border="0.1rem solid white"
                    margin="0 0.8rem 0 0"
                    width="2.4rem"
                    height="2.4rem"
                    background="#303345"
                    className={watch("privacy_notice") ? "checked" : ""}
                  >
                    {watch("privacy_notice") ? (
                      <CheckWrapper>
                        <Check />
                      </CheckWrapper>
                    ) : null}
                    <Input
                      className="invisible"
                      id="privacy_notice"
                      width="2.4rem"
                      height="2.4rem"
                      {...register("privacy_notice", {
                        required: {
                          value: true,
                          message:
                            "You must accept the Privacy Notice to continue.",
                        },
                      })}
                      type="checkbox"
                    />
                  </Box>
                  <PrivacyRequiredInfo>*</PrivacyRequiredInfo>
                  <Label width="90%" htmlFor="privacy_notice">
                    I have read and agree to the Mirati
                    <BaseLink
                      margin="0 0 0 0.4rem"
                      size={1.6}
                      weight="bold"
                      color="#17C94E"
                      decoration="underline"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://www.bms.com/privacy-policy.html",
                          "_blank"
                        );
                      }}
                    >
                      Privacy Notice
                    </BaseLink>
                    .
                  </Label>
                </Box>
                <PrivacyErrorMessage>
                  {errors?.privacy_notice?.message}
                </PrivacyErrorMessage>

                <Button
                  as="button"
                  type="submit"
                  className={(Object.keys(errors).length || !providerFilled) ? "disabled" : ""}
                >
                  Submit
                </Button>
              </Box>
            )}
          </FormWrapper>
        </FormContainer>
      </ContainerScroll>
    </CustomContainer>
  );
};

export default Slide2;

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  max-height: ${(props) => `calc(100% - ${props.isiconfig.mobile}rem)`};

  .checked {
  }

  .__has-error {
    border: 2px solid #f56f6f;
  }

  @media ${device.tablet} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig.tablet}rem)`};
  }

  @media ${device.desktop} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig.desktop}rem)`};
  }

  @media ${device.desktop880h} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig.thinkpad}rem)`};
  }
`;

const FormWrapper = styled(Box)`
  margin-bottom: 1rem;
  .select-wrapper {
    position: relative;
  }

  .required-field-message {
    position: absolute;
    bottom: 12.5rem;
    right: 2.6rem;
    @media ${device.desktop} {
      bottom: 4rem;
      right: 6.4rem;
    }
  }
  select {

    background: #303345;
    border: .1rem solid white;
    color: white;
    padding-left: .8rem;
    appearance: none;
    width: 100%;
    font-weight: 500;
    letter-spacing: 0;
    font-family: "Barlow";
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 1.6rem;
    height: 3.35rem;
    @media ${device.desktop} {
      height: 4.2rem;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }


`;

const FormContainer = styled(StyledBothSideShape)`
  height: 100%;
  position: relative;
  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  @media ${device.desktop} {
    max-width: 89rem;
    margin: 3.2rem auto 0;
    padding: 3.2rem 6.4rem 4rem;
  }
`;
const Button = styled(BaseLink)`
  padding: 0.8rem 4rem;
  background: #3246FF;
  border: none;
  color: white;
  border-radius: 5rem;
  font-size: 1.1rem;
  width: fit-content;
  cursor: pointer;
  height: auto;
  border: 0.1rem solid #3246ff;
  transition: all 0.2s;
  margin-top: 2rem;
  margin-bottom: 5rem;
  line-height: 1;

  &.disabled {
    box-sizing: border-box;
    border: 1px solid rgba(255,255,255,0.5);
    color: rgba(255,255,255,0.8);
    background-color: #303345;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 1px 10px 0 rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.14);
  }

  @media ${device.desktop} {
    text-align: center;
    width: 20rem;
    font-size: 1.6rem;
    margin-bottom: 0;

  }
`;

const CheckWrapper = styled.div`
  position: absolute;
`;

const CustomTitle = styled(BaseText)`
  font-size: 3.6rem;
`;

const CustomSubTitle = styled(BaseText)`
  font-size: 2rem;
`;

const PrivacyErrorMessage = styled.p`
  font-size: min(1.2rem);
  color: #f56f6f;

  @media ${device.desktop} {
  }
`;

const ThankYouMessage = styled.div`
  margin-top: 3rem;
  font-size: 3rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  font-family: "Barlow";
`;

const ErrorMessage = styled.div`
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 300;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  font-family: "Barlow";
`;

const PrivacyRequiredInfo = styled.div`
  color: #ffffff;
  font-family: "Barlow";
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  transform: translate(2.5rem, -1.2rem);
`;
