import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  ReasonBox,
  ReasonNumber,
  ReasonTag,
  Scroll,
  Title,
  TitleText,
} from "../../_components/Tabs/Tabs";
import ReferenceWithLink from "../../_components/ReferenceWithLink";
import { device } from "../../_assets/styles/theme";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import ContainerScroll from "../../_components/PageSwipper/components/ContainerScroll";
import Reference from "../../_components/Reference";
import { FaArrowRight } from "react-icons/fa";
import {
  CustomLink,
  LinkBox,
} from "../../_components/PageSwipper/utils/styles";
import ReferenceModal from "../../_components/Modal/ReferenceModal";
import { Link } from "react-router-dom";

const TitleComponent = ({ handleShowModal }) => {
  return (
    <CustomTitleText>
      Metastases in NSCLC are common
      <sup onClick={() => handleShowModal([14, 15, 16, 17])}>14-17</sup>
    </CustomTitleText>
  );
};

const ListFirstItem = ({ handleShowModal }) => {
  return (
    <ListItem>
      <Bullet>•</Bullet>
      <p>
        Treatment of second-line NSCLC patients with a docetaxel-based regimen has historically led to suboptimal outcomes (median PFS of 2.8 months with docetaxel and 4.5 months with docetaxel+ramucirumab)
        <sup onClick={() => handleShowModal([13, 14])}>13,14</sup>
      </p>
    </ListItem>
  );
};

const ListSecondtItem = ({ handleShowModal }) => {
  return (
    <ListItem>
      <Bullet>•</Bullet>
      <p>
        <span>40% of patients </span>
        with <em>KRAS G12C</em>-mutated NSCLC developed brain metastases
        <sup onClick={() => handleShowModal([14])}>14</sup>*
      </p>
    </ListItem>
  );
};

const ListThirdtItem = ({ handleShowModal }) => {
  return (
    <ListItem>
      <Bullet>•</Bullet>
      <p>
        <span>~50% of patients </span>
        with NSCLC and brain metastases were asymptomatic
        <sup onClick={() => handleShowModal([15])}>15&dagger;</sup>
      </p>
    </ListItem>
  );
};

const ListFourthtItem = ({ handleShowModal }) => {
  return (
    <ListItem>
      <Bullet>•</Bullet>
      <p>
        <span>~30%-40% of patients </span>
        with lung cancer develop bone metastases during the course of their
        disease<sup onClick={() => handleShowModal([16, 17])}>16,17</sup>
      </p>
    </ListItem>
  );
};

const Tab2 = ({ ...props }) => {
  const { isMobile, isTabletAndLaptop } = useMobileDetect();
  const [referencesIndex, setReferencesIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs);
    }
  };

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true);
    }
  }, [referencesIndex]);

  if (isMobile && !isTabletAndLaptop) {
    return (
      <Scroll {...props}>
        {referencesIndex && (
          <ReferenceModal
            indexs={referencesIndex}
            isOpen={showModal}
            close={() => setShowModal(false)}
          />
        )}
        <ContainerScroll>
          <Body>
            <CustomTitle>
              <TitleComponent handleShowModal={handleShowModal} />
            </CustomTitle>

            <Container>
              <Wrapper>
                <ListWrapper>
                  <ListSecondtItem handleShowModal={handleShowModal} />
                  <ListThirdtItem handleShowModal={handleShowModal} />
                  <ListFourthtItem handleShowModal={handleShowModal} />
                </ListWrapper>
              </Wrapper>

              <HeadContainer>
                <img src={'/assets/body.webp'} alt="Metastases In Advanced Disease Are Common" />
              </HeadContainer>

              <BoxWrapper>
                <BoxBtn as={Link} to="/n/MOA">
                  Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                  <FaArrowRight />
                </BoxBtn>
              </BoxWrapper>

              <ReferenceWithLink url="/n/testing" linkText="Testing">
                <p className="footnote">
                  <span className="hang">*</span>Based on a retrospective analysis of patients with <em>KRAS G12C</em>-mutated advanced nonsquamous NSCLC (n=65) from a large tertiary referral center.<br />
                  <sup className="hang">†</sup>In a single-center prospective observational study of treatment-naïve patients with NSCLC (N=496); brain metastases were detected in 104 (21%); 53 (51%) were asymptomatic.<br />
                  NSCLC=non-small cell lung cancer.
                </p>
              </ReferenceWithLink>
            </Container>
          </Body>
        </ContainerScroll>
      </Scroll>
    );
  }

  return (
    <>
      <Tab {...props}>
        <Body>
          {referencesIndex && (
            <ReferenceModal
              indexs={referencesIndex}
              isOpen={showModal}
              close={() => setShowModal(false)}
            />
          )}
          <CustomTitle>
            <ReasonBox>
              <ReasonTag>Reason</ReasonTag>
              <ReasonNumber>2</ReasonNumber>
            </ReasonBox>

            <TitleComponent handleShowModal={handleShowModal} />
          </CustomTitle>

          <Container>
            <Wrapper>
              <ListWrapper>
                <ListSecondtItem handleShowModal={handleShowModal} />
                <ListThirdtItem handleShowModal={handleShowModal} />
                <ListFourthtItem handleShowModal={handleShowModal} />
              </ListWrapper>
              <Box>
                <BoxBtn as={Link} to="/n/MOA">
                  Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                  <FaArrowRight />
                </BoxBtn>
              </Box>
            </Wrapper>

            <HeadContainer>
              <img src='/assets/body.webp' alt="Metastases In Advanced Disease Are Common" />
            </HeadContainer>
          </Container>
        </Body>
      </Tab>

      <ReferenceContainer {...props}>
        <ReferenceWithLink url="/n/testing" linkText="Testing">
          <p>
            *Based on a retrospective analysis of patients with <em>KRAS G12C</em>-mutated advanced nonsquamous NSCLC (n=65) from a large tertiary referral center.<br />
            <sup>†</sup>In a single-center prospective observational study of treatment-naïve patients with NSCLC (N=496); brain metastases were detected in 104 (21%); 53 (51%) were asymptomatic.<br />
            NSCLC=non-small cell lung cancer.
          </p>
        </ReferenceWithLink>
      </ReferenceContainer>
    </>
  );
};

export default Tab2;

const Tab = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100%;

  @media ${device.desktop} {
    max-width: 110rem;
    margin: 0 auto;
    justify-content: flex-start;
  }
`;
const Body = styled.div`
  width: 100%;
  max-width: 110rem;
  margin: 0px auto 2rem;

  sup {
    cursor: pointer;
    pointer-events: all;
  }

  .footnote {
    margin-left: 0.55rem;
    .hang {
      margin: 0;
      padding: 0;
      margin-left: -0.55rem;
    }
  }
  @media ${device.tablet} {
    margin-bottom: 4rem;
  }

  @media ${device.desktop} {
    margin: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  @media ${device.tablet} {
    padding: 0 1.6rem;
  }

  @media ${device.laptop} {
    padding: 0 3.2rem;
  }

  @media ${device.desktop} {
    padding: 0;
  }
`;

const CustomTitle = styled(Title)`
  padding: 0 2rem;

  @media ${device.tablet} {
    padding: 0 1.6rem;
    margin-bottom: 3rem;
    justify-content: flex-start;
  }

  @media ${device.laptop} {
    padding: 0 3.2rem;
  }

  @media ${device.desktop} {
    margin: 0 auto 2rem;
    padding: 0 2rem;
  }
`;

const ListWrapper = styled.ul`
  color: white;
  list-style: none;
  margin: 0 auto;
  padding: 0 1rem 0 2.4rem;

  @media ${device.tablet} {
    padding: 0px 4rem;
    max-width: 74rem;
    margin-bottom: 1.6rem;
    margin: 0;
  }
`;

const ListItem = styled.li`
  font-family: "Barlow", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 0 1rem;
  display: flex;

  &:nth-of-type(2) {
    width: 19rem;
  }

  &:nth-of-type(3) {
    width: 19.5rem;
  }

  &:nth-of-type(4) {
    width: 21rem;
  }

  sup {
    font-size: 0.8rem;
    line-height: 0;
  }

  span {
    color: #17c94e;
    font-weight: bold;
  }

  @media ${device.tablet} {
    font-size: 1.8rem;
    margin: 0 0 2.6rem;
    sup {
      font-size: 55%; ;
    }

    &:nth-of-type(1) {
      max-width: 66rem;
    }

    &:nth-of-type(2) {
      width: initial;
      max-width: 56rem;
    }

    &:nth-of-type(3) {
      width: initial;
      max-width: 46rem;
    }
    &:nth-of-type(4) {
      width: initial;
      margin: 0 0 5rem 0;
      max-width: 48rem;
    }
  }

  @media ${device.desktop} {
    margin: 0 0 1.6rem 0;
    font-size: 1.8rem;

    &:nth-of-type(1) {
      max-width: 66rem;
    }

    &:nth-of-type(2) {
      max-width: 56rem;
    }

    &:nth-of-type(3) {
      max-width: 46rem;
    }

    &:nth-of-type(4) {
      max-width: 50rem;
    }
  }
`;

const Bullet = styled.strong`
  margin: 0;
  margin-right: 1.2rem;
  color: white;
`;

const Box = styled.div`
  position: relative;
  max-width: 47rem;
  padding: 0px 4rem;
`;
const BoxWrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  margin-top: 11.5rem;
  width: 100%;
  p {
    text-transform: uppercase;
    color: white;
    font-size: 1.8rem;
    font-size: 1.8rem;

    span {
      color: #17c94e;
      font-weight: bold;
    }
  }

  @media ${device.tablet} {
    width: auto;
    align-items: flex-start;
  }

  @media ${device.laptop} {
    padding: 1rem 3rem 1rem;
  }

  @media ${device.desktop} {
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 46rem;
    height: 20rem;
  }
`;

const BoxBtn = styled.button`
  font-family: "Barlow", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.6rem 1rem;
  border-radius: 2rem;
  color: white;
  border: 2px solid #17C94E;
  background: transparent;
  align-items: center;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);

  &:hover {
    background-color: rgba(9, 11, 25, 0.5);
    border: 0.1rem solid #17c94e;
  }

  svg {
    margin-left: 1rem;
    fill: ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
    font-size: 1.2rem;
  }

  @media ${device.tablet} {
    width: auto;
    svg {
      font-size: 1.4rem;
      transition: transform .6s;
    }

    &:hover {
      svg {
        transform: translateX(0.3rem);
      }
    }
  }

    @media ${device.tablet} {
        font-size: 1.6rem;
        padding: 0.9rem 1.6rem;
        width: 36.8rem;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    @media ${device.laptop} {
        top: 11rem;
        margin-top: 0;
    }
`;

const ChartTitle = styled.div`
    color: white;
    font-size: min(1.4rem, 1.8rem);
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-align: center;
    padding: 0 4.4rem;
    font-weight: bold;

    @media ${device.tablet} {
        font-size: min(1.8rem, 2rem);
        width: 100%;
        padding: 0;

        sup {
            line-height: 1;
        }
    }

    @media ${device.desktop} {
        font-size: 1.8rem;
        sup {
            font-size: 1rem;
        }
    }
`;

const ChartContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 0.8rem;
    svg {
        height: 100%;
        height: auto;
        width: 100%;
        position: relative;
        right: 0.5rem;
        #larger {
            transform: scale(0.828) translate(8.9%, 10.8%);
        }
        #break-off {
            transform: scale(1.035) translate(-1.5%, -6.25%);
        }
        #labels {
            opacity: 0;
        }

        @keyframes larger {
            from {
                transform: scale(0.828) translate(8.9%, 10.8%);
            }
            to {
                transform: none;
            }
        }

        @keyframes break {
            from {
                transform: scale(1.035) translate(-1.5%, -6.25%);
            }
            to {
                transform: none;
            }
        }

        @keyframes labels {
            from {
                opacity: 0;
            }
            to {
                opacity: 100%;
            }
        }

        @keyframes returnLarger {
            from {
                transform: none;
            }
            to {
                transform: scale(0.828) translate(8.9%, 10.8%);
            }
        }

        @keyframes returnBreak {
  @media ${device.tablet} {
    font-size: 1.6rem;
    padding: 0.9rem 1.6rem;
    max-width: 36rem;
  }

  @media ${device.desktop} {
    width: 38rem;
    max-width: 100%;
  }
`;

const HeadContainer = styled.div`
  position: relative;
  padding: 0 2rem;
  height: 100%;
  /* max-width: 28rem; */
  position: absolute;
  top: 7rem;
  right: -1.75rem;
  img {
    width: 13rem;
    height: 23.5rem;
  }

  svg {
    width: initial;
    max-width: 100%;
    height: 100%;
    #brain_molecules_1,
    #brain_molecules_2 {
      animation: pulse 0.6s linear infinite;
      transform-origin: 15% center;
    }

    #brain_molecules_2 {
    }
    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(0.98);
      }
      75% {
        transform: scale(0.95);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  @media ${device.tablet} {
    top: 7rem;
    right: -2rem;
    img {
      width: 24rem;
      height: 45rem;
    }
  }

  @media ${device.laptop} {
    top: -5rem;
    right: 0rem;

    img {
      width: 28rem;
      height: 49rem;
    }
  }

  @media ${device.desktop} {
    position: absolute;
    top: -4rem;
    right: -4rem;
    img {
      width: 48rem;
      height: 80rem;
    }
  }
`;
const CustomReference = styled(Reference)`
  margin-bottom: 4rem;
`;
const CustomTitleText = styled(TitleText)`
  @media ${device.tablet} {
    font-size: 3.2rem;
    line-height: 3.2rem;
    text-align: left;

    sup {
      font-size: 55%;
    }
  }

  @media ${device.laptop} {
    max-width: 100%;
  }

  @media ${device.desktop} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

const ReferenceContainer = styled.div`
  .footnote {
    margin-left: 0.55rem;
    .hang {
      margin: 0;
      padding: 0;
      margin-left: -0.55rem;
    }
  }
  @media ${device.tablet} {
    position: absolute !important;
    bottom: 1rem;
    width: 100%;

    > div {
      > div {
        max-width: 80%;
      }
    }
  }

  @media ${device.laptop} {
    position: absolute !important;
    bottom: 1rem;
    width: 100%;
  }

  @media ${device.desktop} {
    bottom: 0;
  }
`;
