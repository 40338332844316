import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { ReasonBox, ReasonNumber, ReasonTag, Scroll, Title, TitleText } from '../../_components/Tabs/Tabs'
import { device } from '../../_assets/styles/theme'
import Reference from '../../_components/Reference'
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll'
import useMobileDetect from '../../../_hooks/useMobileDetect'
import { FaArrowRight } from 'react-icons/fa'
import { CustomLink, LinkBox } from '../../_components/PageSwipper/utils/styles'
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink'
import ReferenceModal from '../../_components/Modal/ReferenceModal'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as LungsWithArrows } from '../assets/img/Lungs.svg';

const TitleComponent = ({ handleShowModal }) => {
  return (
    <CustomTitleText>Continuous regeneration may require continuous inhibition<sup
      onClick={() => handleShowModal([18, 19, 20])}>18-20</sup></CustomTitleText>
  )
}

const ListFirstItem = ({ handleShowModal }) => {
  return (
    <ListItem><Bullet>•</Bullet>
      <p><em>KRAS G12C</em> mutations produce abnormal KRAS proteins that lead to aberrant signaling and uncontrolled cellular proliferation<sup onClick={() => handleShowModal([21, 22, 23])}>21-23</sup></p>
    </ListItem>
  )
}

const ListSecondtItem = ({ handleShowModal }) => {
  return (
    <ListItem><Bullet>•</Bullet>
      <p>
        <em>KRAS G12C</em> may need to be continuously inhibited to suppress tumorigenesis<sup onClick={() => handleShowModal([18, 19, 20])}>18-20</sup>
      </p>
    </ListItem>
  )
}

const Tab3 = ({ ...props }) => {
  const { isMobile, isTabletAndLaptop } = useMobileDetect()
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])

  if (isMobile && !isTabletAndLaptop) {
    return (
      <Scroll {...props}>
        {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
        <ContainerScroll>
          <Body>
            <CustomTitle>
              <TitleComponent handleShowModal={handleShowModal} />
            </CustomTitle>

            <Container>
              <Wrapper>
                <ListWrapper>
                  <ListFirstItem handleShowModal={handleShowModal} />
                  <ListSecondtItem handleShowModal={handleShowModal} />
                </ListWrapper>
              </Wrapper>

              <LungsContainer>
                <LungsWithArrows />
                {/* <img src='/assets/Lungs.svg' alt="Lungs" /> */}
              </LungsContainer>

              <BoxWrapper>
                <p>KRAS proteins, including G12C, regenerate every</p>

                <TimeWrapper>
                  <Time>24-48</Time>
                  <TimeText>hours<sup onClick={() => handleShowModal([18, 19, 20])}>18-20</sup></TimeText>
                </TimeWrapper>

                <BoxBtn as={Link} to="/n/MOA">
                  Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                  <FaArrowRight />
                </BoxBtn>
              </BoxWrapper>

              <ReferecenceContainer {...props}>
                <ReferenceFooterWithLink linkText="Testing" url="/n/testing"></ReferenceFooterWithLink>
              </ReferecenceContainer>
            </Container>
          </Body>
        </ContainerScroll>
      </Scroll>
    )
  }

  return (
    <>
      <Tab {...props}>
        {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
        <Body>
          <Container>
            <Wrapper>
              <CustomTitle>
                <ReasonBox>
                  <ReasonTag>Reason</ReasonTag>
                  <ReasonNumber>3</ReasonNumber>
                </ReasonBox>

                <TitleComponent handleShowModal={handleShowModal} />
              </CustomTitle>
              <ListWrapper>
                <ListFirstItem handleShowModal={handleShowModal} />
                <ListSecondtItem handleShowModal={handleShowModal} />
              </ListWrapper>
              <Box>
                <BoxContainerShadow></BoxContainerShadow>
                <BoxContainer>
                  <BoxWrapper>

                    <p>KRAS proteins, including G12C, regenerate every</p>
                    <TimeWrapper>
                      <Time>24-48</Time>
                      <TimeText>hours<sup onClick={() => handleShowModal([18, 19, 20])}>18-20</sup></TimeText>
                    </TimeWrapper>

                    <BoxBtn as={Link} to="/n/MOA">
                      Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                      <FaArrowRight />
                    </BoxBtn>
                  </BoxWrapper>
                </BoxContainer>
              </Box>
            </Wrapper>

            <LungsContainer>
              <LungsWithArrows />
              {/* <img src='/assets/Lungs.svg' alt="Lungs" /> */}
            </LungsContainer>

          </Container>

        </Body>
      </Tab>

      <ReferecenceContainer {...props}>
        <ReferenceFooterWithLink linkText="Testing" url="/n/testing"></ReferenceFooterWithLink>
      </ReferecenceContainer>
    </>
  )
}

export default Tab3

const Tab = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.desktop} {
    max-width: 120rem;
    margin: 0 auto;
    justify-content: flex-start;
  }
`

const Body = styled.div`
  width: 100%;
  max-width: 110rem;
  margin: 0 auto;

  sup {
    cursor: pointer;
    pointer-events: all;
  }

  @media ${device.tablet} {
    padding-bottom: 4rem;
    margin-bottom: 0;
  }

  @media ${device.desktop} {
    padding: 0;
    margin-bottom: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;


  @media ${device.desktop} {
    flex-direction: row;
  }

`

const CustomTitle = styled(Title)`
  padding: 0 2.5rem;

  @media ${device.tablet} {
    padding: 0;
    margin-bottom: 3rem;
    justify-content: flex-start;
  }


  @media ${device.desktop} {
    max-width: 75rem;
    margin-bottom: 2rem;
    padding: 0 2rem;
    margin-left: 0;
    h1 {
      max-width: 70%;
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  &:first-of-type {
    width: 100%;
  }

  @media ${device.tablet} {
    padding: 0 1.6rem;
  }

  @media ${device.laptop} {
    padding: 0 3.2rem;
  }

  @media ${device.desktop} {
    padding: 0;

    &:last-of-type {
      left: 45%;
    }
  }
`

const ListWrapper = styled.ul`
  color: white;
  margin: 0 auto;
  list-style: none;
  padding: 0px 2.2rem;


  @media ${device.tablet} {
    max-width: 45%;
    margin: 0;
    margin-bottom: 1.6rem;
    padding: 0;
  }

  @media ${device.laptop} {
    max-width: 50%;
    padding: 0 3.2rem;
  }

  @media ${device.desktop} {
    padding: 0px 4rem;
    max-width: 100%;
    margin-bottom: 1.6rem;
  }



`

const ListItem = styled.li`
  font-family: 'Barlow', sans-serif;
  font-size: min(1.4rem, 1.8rem);
  font-weight: 500;
  margin: 0 0 0.8rem;
  display: flex;

  &:last-of-type {
    margin-bottom: 0;
  }

  sup {
    font-size: min(.9rem, 1.4rem);
  }

  span {
    color: #17C94E;
    font-weight: bold;
  }

  @media ${device.tablet} {
    font-size: min(1.8rem, 2.2rem);
    margin: 0 0 2.6rem;
    sup {
      font-size: calc(min(1.8rem, 2.2rem) * .65);
    }
  }

  @media ${device.desktop} {
    max-width: 45%;
    margin: 0 0 1.6rem 0;
    font-size: 1.8rem;
    &:last-of-type {
      margin: 0 0 3rem 0;
    }

    sup {
      font-size: 55%;
    }
  }
`

const Bullet = styled.strong`
  margin: 0;
  margin-right: 1.6rem;

  color: white;
`

const Box = styled.div`
  position: relative;
  display: none;

  @media ${device.tablet} {
    display: initial;
  }


`
const BoxContainer = styled.div`
  box-shadow: 0 .2rem 0.4rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 1rem 0 rgba(0,0,0,0.12), 0 0.4rem 0.5rem 0 rgba(0,0,0,0.14);
  background: #17C94E;;
  clip-path: polygon(3% 0,100% 0,100% 93%,97% 100%,0 100%,0 7%);
  padding: 2px;
  max-width: calc(450 * 0.11425vmin);

  @media ${device.tablet} {
    background: transparent;
    box-shadow: none;
  }

  @media ${device.laptop} {
    box-shadow: 0 .2rem 0.4rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 1rem 0 rgba(0,0,0,0.12), 0 0.4rem 0.5rem 0 rgba(0,0,0,0.14);
    background: #17C94E;;
    clip-path: polygon(3% 0,100% 0,100% 93%,97% 100%,0 100%,0 7%);
  }

`
const BoxContainerShadow = styled.div`
  position: absolute;
  z-index: -1;
  content: "";
  background: rgba(0, 0, 0, .1);
  width: calc(100% + 0.5rem);
  max-width: calc(450 * 0.11375vmin);
  height:  calc(100% + 0.5rem);
  left: 0px;
  top: 0px;
  clip-path: polygon(3% 0,100% 0,100% 93%,97% 100%,0 100%,0 7%);

  @media ${device.tablet} {
    background: transparent;
    box-shadow: none;
  }
  @media ${device.laptop} {
    background: rgba(0, 0, 0, .1);
  }

`
const BoxWrapper = styled.div`
  padding: 2rem 2rem 1.6rem;
  color: white;
  display: flex;
  flex-direction: column;
  clip-path: polygon(3% 0,100% 0,100% 93%,97% 100%,0 100%,0 7%);
  p {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
    color: white;
    font-size: 1.5rem;
    line-height: 1.05;
    text-transform: uppercase;
    max-width: 60%;
    span {
      color: #17C94E;
      font-weight: bold;
    }
  }


  @media ${device.tablet} {
    background: #1F2435;
    padding: 2rem 2rem 1.6rem;
    p {
      font-size: 2rem;
      max-width: 70%;
    }
  }

  @media ${device.laptop} {
  }

  @media ${device.desktop} {
    width: 100%;
    height: 26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 2.2rem;
      max-width: 36rem;
    }
  }
`
const BoxBtn = styled.button`
  display: flex;
  margin-top: 1rem;
  font-family: 'Barlow', sans-serif;
  font-size: min(1.2rem, 1.5rem);
  font-weight: 600;
  padding: calc(6 * 0.3125vmin) 1rem;
  max-height: calc(33 * 0.3125vmin);
  border-radius: 2rem;
  color: white;
  border: 2px solid #17C94E;
  background: transparent;
  cursor: pointer;
  justify-content: center;
  transition: all .2s;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 1px 10px 0 rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.14);
  text-align: center;
  align-items: center;

  &:hover {
    background-color: rgba(9,11,25,0.5);
    border: 0.1rem solid #17C94E;
  }

  svg {
    margin-left: 1rem;
    fill: ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
    font-size: 1.2rem;
    margin-top: 2px;
  }

  @media ${device.tablet} {

    svg {
      font-size: 1.4rem;
      transition: transform .6s;
    }

    &:hover {
      svg {
        transform: translateX(0.3rem);
      }
    }
  }

  @media ${device.tablet} {
    font-size: 1.6rem;
    padding: 0.9rem 1.6rem;
    max-width: 36rem;
  }

  @media ${device.desktop} {
    font-size: 1.6rem;
    padding: 0.9rem 1.6rem;
    max-width: 100%;
    width: 36rem;
  }

`
const TimeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`
const TimeText = styled.span`
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: bold;
  color: white;
  font-size: min(calc(22 * 0.3125vmin), 2.4rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
  sup {
    font-size: calc(min(1.2rem, 1.4rem) * .65);
  }

  @media ${device.tablet} {
    font-size: min(2.4rem, 2.6rem);
    sup {
      font-size: calc( min(1.4rem, 1.6rem) * .65);
    }
  }

  @media ${device.desktop} {
    font-size:  2.4rem;

    sup {
      font-size: 55%;
    }
  }
`
const Time = styled.span`
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  color: #17C94E;
  font-size: min(7rem,9rem);
  line-height: 1;

  @media ${device.tablet} {
    font-size: min(9rem, 12rem);
  }

  @media ${device.desktop} {
    font-size: 10.6rem;
  }
`
const LungsContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  svg {
    max-width: 100%;
    height: 100%;

    #noun-arrow {
      animation: rotate 4s linear infinite;
      transform-origin: 26.8% 50.8%;
    }
  }

  @keyframes rotate {
    from { transform: rotate(0deg) translate(66.28px, 195px) }
    to { transform: rotate(360deg) translate(66.28px, 195px) }
  }

  @media ${device.tablet} {
    position: absolute;
    right: 4rem;
    top: -3rem;
    padding: 0;
    max-width: 48%;
  }

  @media ${device.laptop} {
    right: -4rem;
    top: 0;
    max-width: 50%;
  }

  @media ${device.desktop} {
    right: -8rem;
    top: 1rem;
    width: 52rem;
    height: auto;
    max-width: 100%;
  }

  @media (min-width: 1100px) and (max-width: 1400px) {
    top: 5rem;
    width: 45rem;
  }

`
const CustomReference = styled(Reference)`
  margin-bottom: 4rem;
  align-self: flex-start;
`

const CustomTitleText = styled(TitleText)`

  @media ${device.tablet} {
    font-size: 3.2rem;
    line-height: 3.2rem;
    text-align: left;
    sup {
      font-size: 55%;
    }
  }

  @media ${device.laptop} {
    max-width: 60%;
  }

  @media ${device.desktop} {
    line-height: 4rem;
  }

`

const ReferecenceContainer = styled.div`
  margin-top: 1rem;
  position: relative !important;
  @media ${device.tablet} {
    position: absolute;
    bottom: 1rem;
    width: 100%;
  }
`

const ReferenceWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`
