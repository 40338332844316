import React from 'react'
import styled from 'styled-components/macro'
import { device } from '../../_assets/styles/theme'

const List = ({texts, bulletSpace = .8, bulletSpaceSide = 'right', weight, size, textColor = 'white', bulletColor = "white", ...props}) => {
  return (
    <ListWrapper textColor={textColor}>
      {texts.map((text) => (
        <ListItem weight={weight} size={size}  key={text} {...props}>
          <Bullet bulletColor={bulletColor} bulletSpaceSide={bulletSpaceSide} bulletSpace={bulletSpace}>•</Bullet>
          <span>{text}</span>
        </ListItem>
      ))}
      
    </ListWrapper>
  )
}

export default List

const ListWrapper = styled.ul`
  list-style: none;
  color: ${props => props.textColor};
  text-align: left;
  @media ${device.desktop} {
   
  }

  @media ${device.desktop800h} {
  }

`
const ListItem = styled.li`
  margin: 0 0 .5rem;
  font-family: ${props => props.fontfamily || `'Barlow', sans-serif`};
  font-weight: ${props=> props.weight || 500};
  font-size: ${props => props.size || 1.2}rem;
  display: ${props => props.display || 'initial'};
  .green {
    color: #17C94E;
    font-weight: bold;
  }

  span {
    font-size: ${props => props.size || 1.2}rem;
  }

  sup {
      font-size: 0.9rem;
    }

  @media ${device.desktop} {
    font-size: ${props => props.size || 1.6}rem;
    margin: 0 0 0.6rem;

    sup {
      font-size: 1.1rem;
    }

    span {
      font-size: ${props => props.size || 1.6}rem;
    }
  }

`
const Bullet = styled.strong`
  ${props => {
    if (props.bulletSpaceSide === 'both') {
      return (
        `margin: 0 ${props.bulletSpace}rem;`
      )
    }

    if (props.bulletSpaceSide === 'right') {
      return (
        `margin: 0; margin-right: ${props.bulletSpace}rem;`
      )
    }

    if (props.bulletSpaceSide === 'left') {
      return (
        `margin: 0; margin-left: ${props.bulletSpace}rem;`
      )
    }
  }}

  color: ${props => props.bulletColor} ;

  @media ${device.desktop} {
  }
`