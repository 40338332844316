import InteriorBanner from "./components/InteriorBanner"
import CRCLayout from "./layouts/CRCLayout"
import JumpMenu from "./components/JumpMenu"
import FooterCallout from "./components/FooterCallout"
import CardLink from "./components/CardLink"
import ContentContainer from "../_common/components/Layout/ContentContainer"
import MainContent from "../_common/components/Layout/MainContent"
import Poll from "./components/Poll"
import RefModal from "./components/RefModal"
import JumpLink from "./components/JumpLink"
import MetaTags from "../_common/components/MetaTags"

import styles from "./CRCTestingKRASG12C.module.scss"

export default function CRCTestingKRASG12C() {
  const links = [
    {
      url: '#trends-in-advanced-crc',
      text: 'Trends in advanced CRC'
    },
    {
      url: '#patient-id',
      text: 'Patient ID'
    },
    {
      url: '#fda-approved-tests',
      text: 'FDA-approved tests'
    }
  ]
  return (
    <CRCLayout>
      <MetaTags
        title='Learn About the Importance of Targeted Testing'
        description='Learn about NCCN recommendations for testing.'
        ogUrl='https://www.krazatihcp.com/c/testing'
      />
      <InteriorBanner title="Testing for <i>KRAS G12C</i>" imageUrl="/banners/banner-testing.jpg" desktopImageUrl="/banners/banner-testing-desktop.jpg" />
      <JumpMenu title="IMPORTANT CONSIDERATIONS" links={links} />

      <MainContent>
        <div className={styles.targetedTheraphy}>
          <JumpLink id="trends-in-advanced-crc" />
          <ContentContainer>
            <h2>Targeted therapy may help address unmet needs in previously treated advanced CRC</h2>
            <p><strong>Before targeted treatment was available, fewer patients with <i>KRAS G12C</i>-mutated mCRC were treated in later lines of therapy compared to first line<RefModal references={[7, 18, 19, 20]} display="7,18-20" /></strong></p>
            <p>Of patients who are diagnosed with <i>KRAS G12C</i>-mutated mCRC (n=2043)*:</p>
          </ContentContainer>
        </div>

        <div className={styles.testingImage}>
          <ContentContainer>
            <div>
              <img className={styles.imgMobile} src="/assets/testing/img-testing-01.png" alt="Percentage of Patients That Receive Therapy" />
              <img className={styles.imgDesktop} src="/assets/testing/img-testing-01-desktop.jpg" alt="Percentage of Patients That Receive Therapy" />
              <p><small>*Data are based on extrapolation of incidences from a real-world study (Fakih et al) that were applied to the SEER 2023 and Thompson et al 2011 data for newly diagnosed and recurrent mCRC, respectively.</small></p>
              <br />
            </div>
          </ContentContainer>
        </div>

        {/* <Poll /> */}

        <div className={styles.guidelines}>
          <JumpLink id="patient-id" />
          <ContentContainer>
            <h2>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) recommendations for testing for metastatic CRC<RefModal references={[2, 3]} /></h2>
            <ul style={{ paddingTop: '20px' }}>
              <li>All patients should be tested for <i>KRAS</i>, including <i>G12C</i>, as part of an NGS panel</li>
              <li>The testing can be performed on the primary tumor and/or the metastasis</li>
            </ul>
          </ContentContainer>
        </div>

        <div className={styles.callout}>
          <ContentContainer>
            <img src="/assets/icons/icon-double-arrow.svg" alt="" />
            <h3>Now you can identify <i>KRAS G12C</i> as an actionable driver mutation to give patients an alternative to chemotherapy in 2L+ advanced CRC</h3>
          </ContentContainer>
        </div>

        <div className={styles.identifyPatients}>
          <JumpLink id="fda-approved-tests" />
          <ContentContainer>
            <h2>Identify patients with <i>KRAS G12C</i>-mutated CRC with an <span className="no-wrap">FDA-approved</span> test</h2>
            <CardLink title="Tissue-based PCR" text="More information" link="https://www.qiagen.com/us/clp/kras" image="/assets/cards/card-link-time-based.jpg" desktopImage="/assets/cards/card-link-time-based-desktop.jpg" imageAlt="Hand Holding Test Tube" external="true"></CardLink>
            {/* <CardLink title="Liquid NGS" text="More information" link="https://www.agilent.com/en/product/ctdna-liquid-biopsies/ctdna-assays/resolution-ctdx-first-assay-2946236" image="/assets/cards/card-link-liquid-ngs.jpg" desktopImage="/assets/cards/card-link-liquid-ngs-desktop.jpg" imageAlt="Grouping of Test Tubes" external="true"></CardLink> */}
          </ContentContainer>
        </div>

        <div className={styles.references}>
          <ContentContainer>
            <p><small>2L=second line; CRC=colorectal cancer; mCRC=metastatic colorectal cancer; NCCN=National Comprehensive Cancer Network; NGS=next-generation sequencing; PCR=polymerase chain reaction; SEER=Surveillance, Epidemiology, and End Results Program.</small></p>
          </ContentContainer>
        </div>
      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="Explore the KRAZATI study design" link="/c/study-design" />
    </CRCLayout>
  )
}
