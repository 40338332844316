
import ContentContainer from "../../_common/components/Layout/ContentContainer"

import styles from './FooterCallout.module.scss'

export default function FooterCallout({ title, link }) {
  return (
    <div className={styles.footerCallout}>
      <a href={link}>
        <ContentContainer>
          <div>
            <h4>{title}</h4>
          </div>
          <div>
            <img src="/assets/icons/icon-footer-cta-arrow.svg" alt="" />
          </div>
        </ContentContainer>
      </a>
    </div>
  )
}
