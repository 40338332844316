/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseLink, BaseSubtitle, Container, StyledLeftSideShape, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import { FaChevronRight } from 'react-icons/fa';
import { device } from '../../_assets/styles/theme';
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import useMobileDetect from '../../../_hooks/useMobileDetect';
// import Reference from '../../_components/Reference';
import Leaving from '../../_components/Modal/Leaving';

const Slide3 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const slideOverlay = `linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`;

  useActiveLink(setShowHeaderSecondaryMenu, 'testing')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(3)
      setOverlay(slideOverlay)
      setBackground(`linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`);

      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])

  return (
    <>
      <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <CustomWrapper>
          <Title>
            <BaseSubtitle>Identify patients with <em>KRAS&nbsp;G12C</em>-mutated NSCLC with an FDA-approved test, such as:</BaseSubtitle>
          </Title>

          {isMobile
            ?
            <TestsContainer>
              <TestsShapeRight>
                <TestsImgWrapper className="qiagen-logo">
                  <img src='/assets/qiagen-logo.webp' alt="FDA Approved Test - Tissue-Based PCR" />
                </TestsImgWrapper>


                <Box >
                  <TestsText>Tissue-based PCR</TestsText>
                  <Leaving url="https://www.qiagen.com/us/clp/kras">
                    <TestsBtn target="_blank" rel="noreferrer" href="https://www.qiagen.com/us/clp/kras">More Information <FaChevronRight /></TestsBtn>
                  </Leaving>
                </Box>
              </TestsShapeRight>

              {/* <TestsShapeRight>
                <TestsImgWrapper className="bioscience-logo">
                  <img src='/assets/agilent-technologies-logo.webp' alt="FDA Approved Test - Liquid NGS" />
                </TestsImgWrapper>

                <Box >
                  <TestsText>Liquid NGS</TestsText>
                  <Leaving url="https://www.agilent.com/en/product/ctdna-liquid-biopsies/ctdna-assays/resolution-ctdx-first-assay-2946236">
                    <TestsBtn target="_blank" rel="noreferrer" href="https://www.agilent.com/en/product/ctdna-liquid-biopsies/ctdna-assays/resolution-ctdx-first-assay-2946236">More Information <FaChevronRight /></TestsBtn>
                  </Leaving>
                </Box>
              </TestsShapeRight> */}
            </TestsContainer>
            :
            <TestsContainer>
              <TestsShapeLeft>
                <TestsImgWrapper className="qiagen-logo">
                  <img src='/assets/qiagen-logo.webp' alt="FDA approved test - tissue-based PCR" />
                </TestsImgWrapper>

                <TestsText>Tissue-based PCR</TestsText>
                <Leaving url="https://www.qiagen.com/us/clp/kras">
                  <TestsBtn target="_blank" rel="noreferrer" href="https://www.qiagen.com/us/clp/kras">More Information <FaChevronRight /></TestsBtn>
                </Leaving>
              </TestsShapeLeft>

              {/* <TestsShapeRight>
                <TestsImgWrapper className="bioscience-logo">
                  <img src='/assets/agilent-technologies-logo.webp' alt="FDA approved test - liquid NGS" />
                </TestsImgWrapper>

                <TestsText>Liquid NGS</TestsText>
                <Leaving url="https://www.agilent.com/en/product/ctdna-liquid-biopsies/ctdna-assays/resolution-ctdx-first-assay-2946236">
                  <TestsBtn as="div" target="_blank" rel="noreferrer" href="https://www.agilent.com/en/product/ctdna-liquid-biopsies/ctdna-assays/resolution-ctdx-first-assay-2946236">More Information <FaChevronRight /></TestsBtn>
                </Leaving>
              </TestsShapeRight> */}
            </TestsContainer>
          }

        </CustomWrapper>
        {!isMobile &&
          <>
            <ReferenceFooterWithLink linkText="About KRAZATI" url="/n/MOA">NGS=next-generation sequencing; NSCLC=non-small cell lung cancer; PCR=polymerase chain reaction.</ReferenceFooterWithLink>
          </>
        }

        {isMobile && (
          <>
            <ReferenceFooterWithLink linkText="About KRAZATI" url="/n/MOA">NGS=next-generation sequencing; NSCLC=non-small cell lung cancer; PCR=polymerase chain reaction.</ReferenceFooterWithLink>
          </>
        )}

      </CustomContainer>
    </>
  )
}

export default Slide3

const CustomContainer = styled(Container)`
  display: flex;
  height: 100%;
  padding: 0;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};
`
const CustomWrapper = styled(Wrapper)`
  h2 {
    letter-spacing: 0.1rem;
  }
  @media ${device.desktop} {
    margin-top: 5.8rem;

    h2 {
      line-height: 4rem;
    }
  }
`
const Title = styled.div`
  display: flex;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0 3.2rem;
  margin-bottom: 2.5rem;

  @media ${device.desktop} {
    max-width: 78rem;
    margin-bottom: 5rem;
    padding: 0;
    justify-content: center;
  }
`

const TestsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem;

  @media ${device.tablet} {
    max-width: 60%;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    max-width: 80%;
    flex-direction: row;
    margin-top: 2rem;

    > div {
      &:first-of-type {
        margin-right: 2rem;
        clip-path: polygon(0 0,100% 0,100% 100%, 0 100%,1.6rem 100%,0 calc(100% - 2.4rem));
      }
    }
  }

  @media ${device.desktop} {
    padding-left: 7rem;
    > div {
      &:first-of-type {
        margin-right: 3.2rem;
        clip-path: polygon(0 0,100% 0,100% 100%, 0 100%,2.4rem 100%,0 calc(100% - 3.2rem));

      }
    }
  }

`

const TestsShapeLeft = styled(StyledLeftSideShape)`
  border: 0.1rem solid white;
  margin-bottom: 3rem;
  justify-content: space-between;
  align-items: center;
  // padding: 1.7rem 0.4rem;
  padding: 1.7rem 1.6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 33% 1fr;
  background: white;
  min-height: 9.1rem;
  &::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  clip-path: inherit;
  background: rgb(74, 78, 92);
  z-index: -1;
  }

  &:first-of-type {
    text-align: center;

    img {
      max-width: 6.5rem;
    }
  }

  &:last-of-type {
    text-align: center;

    img {
      max-width: 11rem;
    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.desktop} {
    display: flex;
    width: 26.4rem;
    height: 26.4rem;
    padding: 4rem 3.2rem;
    margin-bottom: 4rem;
    margin-right: 3.2rem;
    flex-direction: column;
    border: .2rem solid white;

    img {
      margin-bottom: 3.4rem;
    }
    &:first-of-type {
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    &:last-of-type {
      text-align: center;

      img {
        max-width: 100%;
      }
    }

  }

  @media ${device.desktop800h} {
    width: 28rem;
    height: 28rem;
  }
`

const Box = styled.div`
  margin-left: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items:center;
  p {
    margin-bottom: 0.4rem;
  }

  @media ${device.tablet} {
    margin-left: 0;
  }

  @media ${device.desktop} {
    margin-left: 1.6rem;
  }
`

const TestsShapeRight = styled(TestsShapeLeft)`
  clip-path: polygon(0 0,100% 0,100% calc(100% - 2.4rem), calc(100% - 1.6rem) 100%,0 100%,0 0);

  @media ${device.desktop} {
    clip-path: polygon(0 0,100% 0,100% calc(100% - 3.2rem), calc(100% - 2.4rem) 100%,0 100%,0 0);
    &:after {
      content: "";
      left: initial;
      right: -1rem;
      transform: rotate(-143deg);
      bottom: 5.4rem;
    }
  }

`

const TestsImgWrapper = styled.div`

  img {
    width: 100%;
  }

  @media ${device.desktop} {
    width: 100%;
    min-height: 11rem;

    &.qiagen-logo {
      img {
        width: 8.7rem;
      }
    }

    &.bioscience-logo {
      transform: translateY(1rem);

      img {
        width: 17.4rem;
      }
    }

    img {
      width: initial;
    }
  }

`
const TestsText = styled.p`
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 1.5rem;

  @media ${device.desktop} {
    font-size: 2rem;
    margin-bottom: 1.8rem;
  }

`

const TestsBtn = styled(BaseLink)`
  background: #3246FF;
  color: white;
  padding: .6rem 1.4rem;
  border-radius: 4rem;

  display: flex;
  align-items:center;
  justify-content: center;

  svg {
    margin-left: 1rem;
    fill: white;
  }

  @media ${device.tablet} {
    font-size: 1.4rem;
  }

  @media ${device.desktop} {
    position: relative;
    width: 16rem;
    max-width: 16rem;
    line-height: 0;
    padding: 1.4rem 1.4rem;
    font-size: 1.4rem;
    justify-content: flex-start;
    svg {
      position: absolute;
      right: 1.4rem;
      transition: all .2s linear;
    }

    &:hover {
      svg {
        right: 1rem;
      }
    }
    @media ${device.desktop800h} {
      padding: 1.4rem 1.6rem;
      font-size: 1.3rem;
      height: 3rem;
      min-height: 3rem;
      width: 18rem;
    }
  }

`

// const CustomReference = styled(ReferenceFooterWithLink)``

// const CustomReferenceMobile = styled(Reference)`
//   margin-bottom: .5rem;
// `
