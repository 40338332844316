import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, BaseText, Box, Container, FlexCentered, StyledBothSideShape, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import '../css/slide2.scss';

const Slide2 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;

    useActiveLink(setShowHeaderSecondaryMenu, 'safety');

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(2);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
			<ContainerScroll>

				<CustomWrapper>
					<Title>
						<Title1>
							KRAZATI ADVERSE REACTIONS (ARs)<sup onClick={() => handleShowModal([30])}>30</sup>
						</Title1>
					</Title>
					<Subtitle>
						<BaseText>
							ARs (≥20%) in Patients With <em>KRAS G12C</em>-Mutated NSCLC Who Received KRAZATI in KRYSTAL-1<sup onClick={() => handleShowModal([30, 32])}>30,32</sup>
						</BaseText>
					</Subtitle>
					<div className="content">
						<div className="table">
							<div className="table-inner">
								<table className="highlight">
									<thead>
										<tr>
											{!isMobile && <td></td>}
											<td></td>
											<th colSpan="4" className="border-bottom">KRAZATI (N=116)</th>
										</tr>
										<tr>
											<th colSpan={isMobile ? '1' : '2'} className="border-bottom-right">ADVERSE REACTION</th>
											<th className='border-right'><small>Grade 1 (%)</small></th>
											<th className='border-right'><small>Grade 2 (%)</small></th>
											<th className='border-right'><small>Grade 3 (%)</small></th>
											<th><small>Grade 4 (%)</small></th>
										</tr>
									</thead>
									<tbody>
										<tr></tr>
										{isMobile && <tr className="mobile-only"><span>GI Disorders</span></tr>}
										<tr>
											<th rowSpan="5">GI Disorders</th>
											<td>Diarrhea*</td>
											<td>54.3</td>
											<td>14.7</td>
											<td>0.9</td>
											<td>0</td>
										</tr>
										<tr>
											<td>Nausea</td>
											<td>40.5</td>
											<td>24.1</td>
											<td>4.3</td>
											<td>0</td>
										</tr>
										<tr>
											<td>Vomiting*</td>
											<td>39.7</td>
											<td>15.5</td>
											<td>0.9</td>
											<td>0</td>
										</tr>
										<tr>
											<td>Constipation</td>
											<td>18.1</td>
											<td>4.3</td>
											<td>0</td>
											<td>0</td>
										</tr>
										<tr>
											<td>Abdominal pain*</td>
											<td>12.1</td>
											<td>8.6</td>
											<td>0</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>General Disorders</span></tr>}
										<tr>
											<th rowSpan="2">General Disorders</th>
											<td>Fatigue*</td>
											<td>22.4</td>
											<td>29.3</td>
											<td>6.9</td>
											<td>0</td>
										</tr>
										<tr>
											<td>Edema*</td>
											<td>26.7</td>
											<td>5.2</td>
											<td>0</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Respiratory</span></tr>}
										<tr>
											<th rowSpan="2">Respiratory</th>
											<td>Dyspnea*</td>
											<td>9.5</td>
											<td>15.5</td>
											<td>8.6</td>
											<td>1.7</td>
										</tr>
										<tr>
											<td>Cough*</td>
											<td>15.5</td>
											<td>7.8</td>
											<td>0.9</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Musculoskeletal and Connective Tissue Disorders</span></tr>}
										<tr>
											<th>Musculoskeletal and Connective Tissue Disorders</th>
											<td>Musculoskeletal pain*</td>
											<td>19.8</td>
											<td>14.7</td>
											<td>6.9</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Hepatobiliary Disorders</span></tr>}
										<tr>
											<th>Hepatobiliary Disorders</th>
											<td>Hepatotoxicity*<sup>†</sup></td>
											<td>12.9</td>
											<td>13.8</td>
											<td>9.5</td>
											<td>0.9</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Renal and Urinary Disorders</span></tr>}
										<tr>
											<th>Renal and Urinary Disorders</th>
											<td>Renal impairment*<sup>‡</sup></td>
											<td>23.3</td>
											<td>6.9</td>
											<td>6.0</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Metabolism and Nutrition Disorders</span></tr>}
										<tr>
											<th>Metabolism and Nutrition Disorders</th>
											<td>Decreased appetite</td>
											<td>13.8</td>
											<td>12.1</td>
											<td>4.3</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Infections and Infestations</span></tr>}
										<tr>
											<th>Infections and Infestations</th>
											<td>Pneumonia*</td>
											<td>1.7</td>
											<td>3.4</td>
											<td>16.4</td>
											<td>0.9</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Nervous System Disorders</span></tr>}
										<tr>
											<th>Nervous System Disorders</th>
											<td>Dizziness*</td>
											<td>18.1</td>
											<td>4.3</td>
											<td>0.9</td>
											<td>0</td>
										</tr>
										{isMobile && <tr className="mobile-only"><span>Cardiac Disorders</span></tr>}
										<tr>
											<th>Cardiac Disorders</th>
											<td>Electrocardiogram QT prolonged</td>
											<td>9.5</td>
											<td>4.3</td>
											<td>6.0</td>
											<td>0</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="text">
							<BaseText>
								<p>
									KRAZATI was evaluated in a pooled patient population as a single agent at 600 mg orally twice daily in 366 patients with NSCLC and other solid tumors in KRYSTAL-1 and KRYSTAL-12.
								</p>
								<p>&nbsp;</p>
								<p>
									In KRYSTAL-1, 116 patients with <em>KRAS G12C</em>-mutated advanced NSCLC received KRAZATI 600 mg orally BID.
									<sup onClick={() => handleShowModal([30])}>30</sup>
								</p>
							</BaseText>
						</div>
					</div>
				</CustomWrapper>

				<OriginalReference>
					<p>*Grouped term.</p>
					<p>
						<sup>†</sup>Hepatotoxicity includes mixed liver injury, blood alkaline phosphatase increased, alanine aminotransferase increased, aspartate aminotransferase
						increased, liver function test increased, blood bilirubin increased, and bilirubin conjugated increased.
					</p>
					<p>
						<sup>‡</sup>Renal impairment includes acute kidney injury and increased blood creatinine.
					</p>
					<p>BID=twice-daily; GI=gastrointestinal; NSCLC=non-small cell lung cancer.</p>
				</OriginalReference>
			</ContainerScroll>
        </CustomContainer>
    );
};

export default Slide2;

const OriginalReference = styled(Reference)`
    @media ${device.desktop} {
      bottom: inherit;
    }
`;

const CustomContainer = styled(Container)`
    display: flex;
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig} - 1rem)`};
    @media ${device.desktop} {
        margin-left: 0;
        max-width: calc(100% - 100px);
        margin-right: 80px;
    }
`;
const CustomWrapper = styled(Wrapper)`
	height: auto;
  margin: 1.5rem 0 3rem;
`;
export const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 0;
    text-transform: uppercase;
    padding: 0 4rem;
`;
export const Title1 = styled(BaseSubtitle)`
    margin-bottom: 1rem;
    text-transform: initial;
    span {
        color: #17c94e;
        font-weight: bold;
    }

    @media ${device.desktop} {
        font-size: 3.2rem;
    }
`;

export const TableContainer = styled.div`
    color: white;
    padding: 0.8rem;

    img {
        width: 92%;
        max-width: 36rem;
        display: block;
        margin: 0 auto;

        &.tablet {
            display: none;
        }

        @media ${device.tablet} {
            &.mobile {
                display: none;
            }

            &.tablet {
                display: initial;
                width: 72rem;
            }
        }
    }
`;
export const Table = styled.div``;
export const TableText = styled.p`
    color: white;
    font-family: 'Barlow Semi Condensed';
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-bottom: 1rem;
    text-align: center;

    @media ${device.tablet} {
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    @media ${device.desktop} {
        letter-spacing: 0.08rem;
        line-height: 1.6rem;
        font-size: 1.35rem;
        margin-bottom: 2rem;
        text-align: center;
    }
`;

export const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 55% 1fr;
    margin-bottom: 1rem;

    @media ${device.tablet} {
        grid-template-columns: 19rem 1fr;
    }
`;
export const TableBodyWrapper = styled(TableHeader)`
    margin-bottom: 0.4rem;
`;
export const TableItemHeader = styled.div`
    display: flex;
    flex-direction: column;
`;
export const TableHeaderTitle = styled.h2`
    display: flex;
    align-items: flex-end;
    height: 100%;

    font-family: 'Oswald';
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;

    border-right: 0.1rem solid white;
    border-bottom: 0.1rem solid white;

    @media ${device.desktop} {
        color: white;
    }
`;
export const TableHeaderInfo = styled(FlexCentered)`
    border-bottom: 0.1rem solid white;
    padding-bottom: 0.2rem;

    @media ${device.desktop} {
        color: white;
    }
`;
export const TableValues = styled.span`
    font-family: 'Oswald';
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;

    @media ${device.tablet} {
        color: white;
        padding: 0.3rem;
        font-weight: 600;
        font-size: 1.2rem;
    }
`;
export const TableBodyValues = styled(TableValues)`
    background: white;
    color: black;
    margin-right: 0.4rem;
`;
export const TableSectionDescription = styled.span`
    font-family: 'Barlow Condensed';
    font-size: 1.2rem;
    font-weight: 500;

    @media ${device.tablet} {
        font-size: 1.35rem;
        font-weight: 600;
    }
`;
export const TableSectionTitle = styled.span`
    font-family: 'Barlow Condensed';
    font-size: 1.3rem;
    font-size: 1.3rem;
    font-weight: 600;

    @media ${device.desktop} {
        color: white;
        font-size: 1.45rem;
    }
`;
export const TableItemLeft = styled.div`
    margin-right: 0.4rem;
`;
export const TableBodyLeft = styled(TableItemLeft)`
    background: white;
    color: black;
    padding: 0.4rem 0.8rem;

    @media ${device.tablet} {
        padding: 0.4rem 1rem;
    }

    @media ${device.desktop} {
        font-size: 1.35rem;
        font-weight: 600;
    }
`;
export const TableBody = styled.div`
    margin-bottom: 1.2rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media ${device.desktop} {
        font-size: 1.3rem;
    }
`;
export const TableGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media ${device.tablet} {
        gap: 0.4rem;
    }
`;

const Subtitle = styled.div`
    display: flex;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto 2.8rem;
    color: white;

    > div {
        text-align: center;
        font-size: 1.6rem;
    }

    span {
        color: #17c94e;
        font-weight: bold;
    }

    sup {
        font-size: 55%;
    }

    @media ${device.desktop} {
        font-size: 2rem;
        > div {
            font-size: 2rem;
        }
        sup {
            font-size: 55%;
        }
    }
`;
