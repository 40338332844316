export const referenceData = [
  "KRAZATI®. Prescribing information. Princeton, NJ. Mirati Therapeutics, Inc., a Bristol Myers Squibb company; 2024.",
  "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Colon Cancer V.3.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed May 24, 2024. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.",
  "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Rectal Cancer V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed April 30, 2024. To view the most recent and complete version of the guideline, go online to NCCN.org.",
  "Ghimessy A, Radeczky P, Laszlo V, et al. Current therapy of KRAS-mutant lung cancer. <i>Cancer Metastasis Rev</i>. 2020;39(4):1159-1177. ",
  "Lu S, Jang H, Muratcioglu S, et al. Ras conformational ensembles, allostery, and signaling. <i>Chem Rev</i>. 2016;116(11):6607-6665.",
  "Zhu G, Pei L, Xia H, et al. Role of oncogenic KRAS in the prognosis, diagnosis and treatment of colorectal cancer. <i>Mol Cancer</i>. 2021;20(1):143.",
  "Fakih M, Tu H, Hsu H, et al. Real-world study of characteristics and treatment outcomes among patients with KRAS p.G12C-mutated or other KRAS mutated metastatic colorectal cancer. <i>Oncologist</i>. 2022;27(8):663-674.",
  "Stites EC, Shaw AS. Quantitative systems pharmacology analysis of KRAS G12C covalent inhibitors. <i>CPT Pharmacometrics Syst Pharmacol</i>. 2018;7(5):342-351.",
  "Shukla S, Allam US, Ahsan A, et al. KRAS protein stability is regulated through SMURF2: UBCH5 complex-mediated β-TrCP1 degradation. <i>Neoplasia</i>. 2014;16(2):115-128. ",
  "Bergo MO, Gavino BJ, Hong C, et al. Inactivation of Icmt inhibits transformation by oncogenic K-Ras and B-Raf. <i>J Clin Invest</i>. 2004;113(4):539-550. ",
  "Fernández-Medarde A, Santos E. Ras in cancer and developmental diseases. <i>Genes Cancer</i>. 2011;2(3):344-358. ",
  "Cox AD, Fesik SW, Kimmelman AC, Luo J, Der CJ. Drugging the undruggable RAS: mission possible? <i>Nat Rev Drug Discov</i>. 2014;13(11):828-851.",
  "Waters AM, Der CJ. KRAS: the critical driver and therapeutic target for pancreatic cancer. <i>Cold Spring Harb Perspect Med</i>. 2018;8(9):a031435.",
  "Sabari JK, Velcheti V, Shimizu K, et al. Activity of adagrasib (MRTX849) in brain metastases: preclinical models and clinical data from patients with KRAS G12C-mutant non-small cell lung cancer. <i>Clin Cancer Res</i>. 2022;28(15):3318-3328.",
  "Colclough N, Chen K, Johnström P, et al. Preclinical comparison of the blood-brain barrier permeability of osimertinib with other EGFR TKIs. <i>Clin Cancer Res</i>. 2021;27(1):189-201.",
  "Ballard P, Yates JW, Yang Z, et al. Preclinical comparison of osimertinib with other EGFR-TKIs in EGFR-mutant NSCLC brain metastases models, and early evidence of clinical brain metastases activity. <i>Clin Cancer Res</i>. 2016;22(20):5130-5140.",
  "Ou SI, Jänne PA, Leal TA, et al. First-in-human phase I/IB dose-finding study of adagrasib (MRTX849) in patients with advanced KRASG12C solid tumors (KRYSTAL-1). <i>J Clin Oncol</i>. 2022;40(23):2530-2538.",
  "Data on file. BMS-REF-ADAG-0021. Princeton, NJ: Mirati Therapeutics, Inc., a Bristol Myers Squibb company; 2024.",
  "Thompson TD, Pollack LA, Johnson CJ, et al. Breast and colorectal cancer recurrence and progression captured by five U.S. population-based registries: Findings from National Program of Cancer Registries patient-centered outcome research. <i>Cancer Epidemiol</i>. 2020;64:101653.",
  "National Cancer Institute. Cancer stat facts: colorectal cancer. Accessed April 18, 2024. https://seer.cancer.gov/statfacts/html/colorect.html",
  "Yaeger R, Uboha NV, Pelster MS, et al. Efficacy and safety of adagrasib plus cetuximab in patients with <i>KRAS</i><sup>G12C</sup>-mutated metastatic colorectal cancer. <i>Cancer Discov</i>. Published online April 8, 2024. doi:10.1158/2159-8290.CD-24-0217",
  "Hallin J, Calinisan A, Hargis L, et al. The anti-tumor activity of KRAS G12C inhibitor MRTX849 is augmented by cetuximab in CRC tumor models. <i>Cancer Res</i>. 2020;80(16 Suppl):Abstract nr LB-098.",
];
