import { baseHCPUrl } from "../../../config";

export const preMenuItems = [
  {
    label: "Important Safety Information",
    target: "_self",
    url: "#important-safety-information",
    button: false,
    hideMobile: false,
    hideDesktop: false,
  },
  {
    label: "Prescribing Information",
    external: true,
    url: "https://packageinserts.bms.com/pi/pi_krazati.pdf",
    button: false,
    hideMobile: false,
    hideDesktop: false,
  },
  { label: "References", url: "/n/references", pageLink: true, button: false, hideMobile: false, hideDesktop: false },
  { label: "Visit Patient Site", url: baseHCPUrl, button: false, hideMobile: false, hideDesktop: false },
];

export const menuItems = [
  {
    label: (
      <span>
        <em>KRAS G12C</em> in NSCLC
      </span>
    ),
    url: "",
    button: false,
    hideMobile: false,
    hideDesktop: false,
    submenu: [
      { label: "NCCN Recommendations", url: "/n/nccn-recommendations" },
      {
        label: (
          <span>
            About <em>KRAS G12C</em>
          </span>
        ),
        url: "/n/about-kras-g12c",
      },
      {
        label: (
          <span>
            Testing for <em>KRAS G12C</em>
          </span>
        ),
        url: "/n/testing",
      },
    ],
  },
  {
    label: "KRAZATI MOA",
    url: "/n/MOA",
    button: false,
    hideMobile: false,
    hideDesktop: false,
  },
  {
    label: "Clinical Data",
    submenu: [
      { label: "Study Design", url: "/n/study-design" },
      { label: "Efficacy", url: "/n/efficacy" },
      { label: "Safety", url: "/n/safety" },
    ],
    url: "",
    button: false,
    hideMobile: false,
    hideDesktop: false,
    redirect: "/n/study-design",
  },
  {
    label: "Dosage",
    url: "/n/dosing",
    button: false,
    hideMobile: false,
    hideDesktop: false,
  },
  {
    label: "Resources & Support",
    url: "",
    button: false,
    hideMobile: false,
    hideDesktop: false,
    submenu: [
      { label: "Videos", url: "/n/videos" },
      { label: "Resources", url: "/n/resources" },
      { label: "BMS Access Support", url: "/n/support" },
    ],
    redirect: "/n/resources",
  },
  {
    label: "Stay Connected",
    url: "/n/stay-connected",
    button: { desktop: { w: "15.6", h: "4" }, mobile: { w: "28.8", h: "4" } },
    hideMobile: false,
    hideDesktop: false,
  },
];
