import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro'
import { device } from '../../_assets/styles/theme';
import { BaseText } from '../PageSwipper/utils/styles';

const Reference = ({children, ...props}) => {

  return (
    <Container {...props}>
      { children }
    </Container>
  )
}

export default Reference

const Container = styled(BaseText)`
  font-family: 'Barlow', sans-serif;
  font-size: min(.9rem, 1.1rem);
  color: white;
  padding: 0 0.8rem;
  margin-bottom: 5px;

  p:not(:last-of-type) {
	margin-bottom: 3px;
  }

  span {
    &.pink {
      color: #FF00FF;
    }
  }

  @media ${device.tablet} {
    font-size: 1.1rem;
  }

  @media ${device.desktop} {
    padding-left: 5.6rem;
    font-size: 1.2rem;
    position: relative;
    bottom: 20px;
  }

  @media ${device.desktop600h} {
    font-size: 1.4rem;
  }

`
