import { useEffect, useState } from 'react'
import useWindowSize from '../../_hooks/useWindowSize'
import styles from './CardLink.module.scss'

export default function CardLink({ title, image, desktopImage, text, link, imageAlt, external = false }) {
  const winWidth = useWindowSize()
  const [img, setImg] = useState('')

  useEffect(() => {
    if (winWidth > 1024) {
      setImg(desktopImage)
    } else {
      setImg(image)
    }
  }, [desktopImage, image, winWidth])

  return (
    <div className={styles.cardLink} onClick={() => {
      if (external) {
        window.open(link, "_blank", "noreferrer")
      } else {
        window.open(link, "_self")
      }
    }}>
      <div className={styles.cardLinkImage} style={{ backgroundImage: `url(${img})` }}>
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
      </div>
      <div className={styles.cardLinkCTA}>
        <a href={link} {...(external ? { target: "_blank", rel: 'noreferrer' } : {})}>
          <span dangerouslySetInnerHTML={{ __html: text }}></span>
          <img src="/assets/icons/icon-card-link-arrow.svg" alt={imageAlt} />
        </a>
      </div>
    </div>
  )
}
