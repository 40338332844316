import { Link } from "react-router-dom";
import Dropdown from "./components/Dropdown";

import styles from "./CRCPrimaryMenu.module.scss";

export default function NSCLCPrimaryMenu() {
  return (
    <div className={styles.primaryMenu}>
      <div className={`${styles.primaryMenuWrapper} ${styles.nsclcMenuWrapper}`}>
        <Dropdown
          width="160px"
          title="<i>KRAS G12C</i> in NSCLC"
          links={[
            {
              url: "/n/nccn-recommendations",
              text: "NCCN Recommendations",
            },
            {
              url: "/n/challenging-mutation",
              text: "About <i>KRAS G12C</i>",
            },
            {
              url: "/n/testing",
              text: "Testing for <i>KRAS G12C</i>",
            },
          ]}
        />

        <Dropdown title="KRAZATI MOA" url="/n/MOA" />

        <Dropdown
          width="122px"
          title="Clinical Data"
          links={[
            {
              url: "/n/study-design",
              text: "Study Design",
            },
            {
              url: "/n/efficacy",
              text: "Efficacy",
            },
            {
              url: "/n/safety",
              text: "Safety",
            },
          ]}
        />

        <Dropdown title="Dosage" url="/n/dosing" />

        <Dropdown
          width="120px"
          title="Resources & Support"
          links={[
            {
              url: "/n/videos",
              text: "Videos",
            },
            {
              url: "/n/resources",
              text: "Resources",
            },
            {
              url: "/n/support",
              text: "BMS Access Support",
            },
          ]}
        />

        <Link className={styles.stayConnected} to="/n/stay-connected">
          STAY CONNECTED
        </Link>
      </div>
    </div>
  );
}
