import CRCLayout from "./layouts/CRCLayout"
import JumpMenu from './components/JumpMenu'
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import FooterCallout from "./components/FooterCallout"
import ContentContainer from "../_common/components/Layout/ContentContainer"
import JumpLink from "./components/JumpLink"
import RefModal from "./components/RefModal"
import MetaTags from "../_common/components/MetaTags"
import styles from "./CRCSafety.module.scss"

export default function CRCSafety() {
  const links = [
    {
      url: '#adverse-reactions',
      text: 'Adverse reactions'
    },
    {
      url: '#laboratory-abnormalities',
      text: 'Laboratory abnormalities'
    },
    {
      url: '#dosage-modifications',
      text: 'Dosage modification rates'
    },
    {
      url: '#warnings-precautions',
      text: 'Warnings and precautions'
    }
  ]
  return (
    <CRCLayout>
      <MetaTags
        title='Safety and Tolerability of Krazati®'
        description="Learn about the safety profile and tolerability of Krazati® in patients based on clinical data."
        ogUrl='https://www.krazatihcp.com/c/safety'
      />
      {/* Banner */}
      <InteriorBanner title="SAFETY AND TOLERABILITY" imageUrl="/banners/banner-about-kras.jpg" desktopImageUrl="/banners/banner-about-kras-desktop.jpg" />
      {/* Jump Menu */}
      <JumpMenu title="SAFETY HIGHLIGHTS" links={links} spacing="tight" />

      <MainContent>

        <div className={styles.adverse}>
          <JumpLink id="adverse-reactions" />
          <ContentContainer>
            <h2>Adverse reactions (ARs) with KRAZATI + cetuximab<RefModal references={[1]} /></h2>
            <p>Safety was evaluated in patients with <i>KRAS G12C</i>-mutated locally advanced or metastatic CRC. Patients initiated treatment with KRAZATI 600 mg BID in combination with cetuximab weekly (n=17) or every 2 weeks (n=77).</p>
            <p>Among patients who received KRAZATI in combination with cetuximab, <b>60% were exposed for greater than 6 months and 12% were exposed for greater than 12 months.</b></p>
            <br /><br />
            <p><strong>ARs (≥20%) in Patients With <i>KRAS G12C</i>-Mutated CRC Who Received KRAZATI in Combination With Cetuximab in KRYSTAL-1<RefModal references={[1, 18]} /></strong></p>
            <br />

            <img className={styles.imgDesktop} src="/assets/safety/img-chart-01.jpg" alt="Adverse Reactions" />
            <img className={styles.imgMobile} src="/assets/safety/img-chart-01-mobile.jpg" alt="Adverse Reactions" />

            <div className={styles.references}>
              <p><small>*Graded per CTCAE version 5.0.</small></p>
              <p><small><sup>†</sup>Grouped term; includes multiple related terms.</small></p>
            </div>

          </ContentContainer>
        </div>

        <div className={styles.lab}>
          <JumpLink id="laboratory-abnormalities" />
          <ContentContainer>
            <h2>Laboratory abnormalities<RefModal references={[1]} /></h2>
            <p><strong>Selected Laboratory Abnormalities (≥25%) in Patients Who Received KRAZATI in Combination With Cetuximab in KRYSTAL-1</strong></p>
            <img className={styles.imgDesktop} src="/assets/safety/img-chart-02.jpg" alt="Laboratory Abnormailities" />
            <img className={styles.imgMobile} src="/assets/safety/img-chart-02-mobile.jpg" alt="Laboratory Abnormailities" />
            <p><small>*Denominator used to calculate the rate varied from 82 to 92 based on the number of patients with a baseline value and at least one post-treatment value.</small></p>
          </ContentContainer>
        </div>

        <div className={styles.discontinuation}>
          <JumpLink id="dosage-modifications" />
          <ContentContainer>
            <div>
              <h2>KRAZATI discontinuation and dosage modification rates<RefModal references={[1]} /></h2>
              <p>(N=94)</p>
            </div>
            <div className={styles.cols}>
              <div className={styles.col}>
                <h4><span>62%</span> DOSE INTERRUPTIONS DUE TO AN AR</h4>
                <p>ARs requiring dosage interruption in ≥2.0% of patients who received KRAZATI included diarrhea, nausea, vomiting, abdominal pain, dizziness, headache, pneumonia, alanine aminotransferase increased, aspartate aminotransferase increased, dyspnea, fatigue, pleural effusion, rash, anemia, electrocardiogram QT prolongation, blood bilirubin increased, blood creatinine increased, decreased appetite, dehydration, hemorrhage, hypomagnesemia, lipase increased, muscular weakness, musculoskeletal pain, and pyrexia.</p>
              </div>
              <div className={styles.col}>
                <h4><span>35%</span> DOSE REDUCTIONS DUE TO AN AR</h4>
                <p>ARs requiring dose reductions in ≥2.0% of patients who received KRAZATI included fatigue, increased aspartate aminotransferase, increased alanine aminotransferase, nausea, decreased appetite, electrocardiogram QT prolongation, dizziness, acute kidney injury, diarrhea, dysarthria, and vomiting.</p>
              </div>
              <div className={styles.col}>
                <h4><span>2%</span> DISCONTINUED DUE TO AN AR<RefModal references={[1, 21]} /></h4>
                <p>Permanent discontinuation of KRAZATI due to an AR occurred in 2 patients due to abdominal pain and prolonged QT interval (1 patient each). TRAEs led to the discontinuation of cetuximab in 8 patients (8.5%).</p>
              </div>
            </div>
            <div>
              <p>Serious ARs were reported in 30% of patients who received KRAZATI in combination with cetuximab. The most common serious ARs (≥2%) were pneumonia (4.3%), pleural effusion, pyrexia, acute kidney injury, dehydration, and small intestinal obstruction (2.1% each). A fatal AR of pneumonia occurred in 1 patient who received KRAZATI in combination with cetuximab.</p>
            </div>
          </ContentContainer>
        </div>

        <div className={styles.warnings}>
          <JumpLink id="warnings-precautions" />
          <ContentContainer>
            <h2>KRAZATI WARNINGS AND PRECAUTIONS<RefModal references={[1]} /></h2>
            <p><b>GASTROINTESTINAL ADVERSE REACTIONS</b></p>
            <ul>
              <li>KRAZATI can cause severe gastrointestinal adverse reactions.</li>
              <li>In patients who received KRAZATI in combination with cetuximab, serious gastrointestinal adverse reactions included gastrointestinal bleeding in 8.5% including 1.1% Grade 3 or 4, gastrointestinal obstruction in 5.3% including 5.3% Grade 3 or 4, colitis in 1.1% including 1.1% Grade 3 and ileus in 1.1%. In addition, nausea, diarrhea, or vomiting occurred in 92% of 94 patients, including 6% Grade 3. Nausea, diarrhea, or vomiting led to KRAZATI dose interruption or dose reduction in 23% of patients.</li>
              <li>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity.</li>
            </ul>
            <p><b>QTc INTERVAL PROLONGATION</b></p>
            <ul>
              <li>KRAZATI can cause QTc interval prolongation, which can increase the risk for ventricular tachyarrhythmias (e.g., torsades de pointes) or sudden death.</li>
              <li>In patients who received KRAZATI in combination with cetuximab, 5% of 93 patients with at least one post-baseline electrocardiogram (ECG) assessment had an average QTc ≥ 501 msec and 16% of patients had an increase from baseline of QTc &gt; 60 msec.</li>
              <li>Avoid concomitant use of KRAZATI with other products with a known potential to prolong the QTc interval. Avoid use of KRAZATI in patients with congenital long QT syndrome and in patients with concurrent QTc prolongation.</li>
              <li>Monitor ECGs and electrolytes, particularly potassium and magnesium, prior to starting KRAZATI, during concomitant use, and as clinically indicated in patients with congestive heart failure, bradyarrhythmias, electrolyte abnormalities, and in patients who are unable to avoid concomitant medications that are known to prolong the QT interval. Correct electrolyte abnormalities. Withhold, reduce the dose, or permanently discontinue KRAZATI depending on severity.</li>
            </ul>
            <p><b>HEPATOTOXICITY</b></p>
            <ul>
              <li>KRAZATI can cause hepatotoxicity, which may lead to drug-induced liver injury and hepatitis.</li>
              <li>In patients who received KRAZATI in combination with cetuximab, 29% had increased alanine aminotransferase (ALT)/increased aspartate aminotransferase (AST); 5% were Grade 3 and 1.1% were Grade 4. The median time to first onset of increased ALT/AST was 4 weeks (range: 0.1 to 27). Overall hepatotoxicity occurred in 38%, and 10% were Grade 3 or 4. Hepatotoxicity leading to adagrasib dose interruption or reduction occurred in 12% of patients.</li>
              <li>Monitor liver laboratory tests (AST, ALT, alkaline phosphatase and total bilirubin) prior to the start of KRAZATI and monthly for 3 months or as clinically indicated, with more frequent testing in patients who develop transaminase elevations. Reduce the dose, withhold, or permanently discontinue KRAZATI based on severity.</li>
            </ul>
            <p><b>INTERSTITIAL LUNG DISEASE/PNEUMONITIS</b></p>
            <ul>
              <li>KRAZATI can cause interstitial lung disease (ILD)/pneumonitis, which can be fatal.</li>
              <li>In patients who received KRAZATI in combination with cetuximab, Grade 1 ILD/pneumonitis occurred in 1.1% of patients. The time to first onset for ILD/pneumonitis was 38 weeks.</li>
              <li>Monitor patients for new or worsening respiratory symptoms indicative of ILD/pneumonitis (e.g., dyspnea, cough, fever) during treatment with KRAZATI. Withhold KRAZATI in patients with suspected ILD/pneumonitis and permanently discontinue KRAZATI if no other potential causes of ILD/pneumonitis are identified.</li>
            </ul>
          </ContentContainer>
        </div>

        <div className={styles.moreInformation}>
          <ContentContainer>
            <p><strong>For more information about monitoring and management, refer to the <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">KRAZATI Prescribing Information</a> and <a href="/pdfs/therapy-management-guide.pdf" target="_blank">Therapy Management Guide</a>.</strong></p>
            <p><small>BID=twice-daily; CRC=colorectal cancer; CTCAE=Common Terminology Criteria for Adverse Events; TRAE=treatment-related adverse event.</small></p>
          </ContentContainer>
        </div>
      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="Detailed information on dosing" link="/c/dosage" />
    </CRCLayout>
  )
}
