import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { device } from '../../../_assets/styles/theme'

const DosingTabItem = ({title, item, setCurrentTab, ...props}) => {
  const ref = useRef(null)

  const handleSelect = () => {
    setCurrentTab(item)
  }
  
  return (
    <Container {...props} ref={ref} onClick={handleSelect}>
      <Wrapper>
        {title}
      </Wrapper>
    </Container>
  )
}

export default DosingTabItem

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  padding: 0 0.8rem;
  border: 0.1rem solid #FFF;
  transform: skewX(-35deg);
  color: #fff;
  max-width: 100%;
  height: 2.4rem;
  
  &:nth-of-type(even) {
    border-left: none;
  }

  @media ${device.desktop} {
    padding: 6;
    cursor: pointer;
    max-width: 100%;
    height: 4.2rem;
  }
`

const Wrapper = styled.div`
  font-family: 'Barlow', sans-serif;
  transform: skewX(35deg);
  font-weight: 500;
  font-size: .9rem;
  text-transform: uppercase;
  padding: 0;


  @media ${device.tablet} {
    font-size: 1.1rem;
  }
  
  @media ${device.desktop} {
    padding: 0.8rem 2rem;
  }

  @media ${device.desktop800h} {
  }

  @media ${device.desktop600h} {
    font-size: 1.4rem;
  }
`