import styled from "styled-components/macro";
export default function IndicationContent() {
  return (
    <IndicationContentWrapper>
      <p className="indication">
        KRAZATI, as a single agent, is indicated for the treatment of adult patients with KRAS G12C-mutated locally
        advanced or metastatic non-small cell lung cancer (NSCLC) as determined by an FDA-approved test, who have
        received at least one prior systemic therapy.
      </p>

      <p className="indication">
        This indication is approved under accelerated approval based on objective response rate (ORR) and duration of
        response (DOR). Continued approval for this indication may be contingent upon verification and description of a
        clinical benefit in a confirmatory trial.
      </p>
    </IndicationContentWrapper>
  );
}

const IndicationContentWrapper = styled.div`
  margin-bottom: 16px;
  p {
    color: #434343;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 500;
    position: relative;
  }
`;
