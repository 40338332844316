import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSwiper } from 'swiper/react';
import { device } from '../../../_assets/styles/theme';

const ResourcesTabItem = ({ title, item, currentTab, setCurrentTab, className, ...props }) => {
    const history = useNavigate();
    const swiper = useSwiper();
    const ref = useRef(null);

    const handleSelect = () => {
        // setCurrentTab(item)
        title === 'VIDEOS' ? history('#videos') : history('#downloads');
    };

    return (
        <Container className={'swiper-no-swiping ' + (className ? className : '')} {...props} ref={ref} onClick={handleSelect}>
            <Wrapper>{title}</Wrapper>
        </Container>
    );
};

export default ResourcesTabItem;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    padding: 0 0.8rem;
    border: 0.1rem solid #fff;
    transform: skewX(-35deg);
    color: #fff;
    max-width: 100%;
    height: 2.6rem;
    box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.4);

    &:nth-of-type(even) {
        border-left: none;
    }

    &:not(.active) {
        & > div[class*="Wrapper"] {
            font-family: "Barlow Condensed", sans-serif;
            font-size: 1.35rem;
            font-style: italic;
            font-weight: 600;

            @media ${device.tablet} {
                font-size: 1.95rem;
            }
        
            @media ${device.desktop} {
                font-size: 2.05rem;
            }
        
            @media ${device.desktop800h} {
                font-size: 1.65rem;
            }
        }
    }

    &.active {
        & > div[class*="Wrapper"] {
            font-weight: 600;
        }
    }

    @media ${device.tablet} {
        height: 2.4rem;
        padding: 0.6rem;
        max-width: 100%;
        height: 3.6rem;
    }

    @media ${device.desktop} {
        padding: 0.6rem;
        cursor: pointer;
        max-width: 100%;
        height: 4.2rem;
    }

    @media ${device.desktop800h} {
        padding: 0.6rem;
        cursor: pointer;
        max-width: 100%;
        height: 3.6rem;
    }
`;

const Wrapper = styled.div`
    font-family: "Barlow Condensed", sans-serif;
    transform: skewX(35deg);
    font-weight: 500;
    font-size: 1.4rem;
    text-transform: uppercase;
    padding: 0;

    @media ${device.tablet} {
        font-size: 2rem;
    }

    @media ${device.desktop} {
        padding: 0.8rem 2rem;
        font-size: 2.1rem;
    }

    @media ${device.desktop800h} {
        font-size: 1.7rem;
    }
`;
