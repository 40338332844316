import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { StyledBothSideShape } from '../PageSwipper/utils/styles'
import Modal from './Modal'
import { FaChevronRight } from 'react-icons/fa'
import { device } from '../../_assets/styles/theme'
import { useToggle } from '../../../_hooks/useToggle'

const Leaving = ({url, children}) => {
  const [open, toggle] = useToggle(false)

  const handleClick = (e) => {
      e.preventDefault()
      toggle()
  }


  return (
    <>
    {children.props ? <span onClick={handleClick}>{children}</span> : <a style={{cursor: 'pointer'}} href={url} onClick={handleClick}>{children}</a> }
    <Modal isOpen={open}>
      <Container>
        <StyledBothSideShape background="#20293A" border="white" borderSize={.2}>
          <CloseBtnWrapper onClick={toggle}>
              <img src="/assets/closebtn.svg" alt="" />
          </CloseBtnWrapper>

          <Wrapper>
            <div className="message">
            <p>You are now leaving the KRAZATI<sup className='no-cursor'>®</sup> site</p>
            </div>

            <a href={url} target="_blank" rel="noreferrer">Continue <FaChevronRight /></a>
          </Wrapper>
        </StyledBothSideShape>
      </Container>
    </Modal>
    </>
  )
}

export default Leaving

const Container = styled.div`
  height: 21.2rem;
  width: 49.6rem;
  font-family: 'Barlow';

  > div {
    box-shadow: 0 .2rem 0.4rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 1rem 0 rgba(0,0,0,0.12), 0 0.4rem .5rem 0 rgba(0,0,0,0.14);
    max-width: 95%;
    margin: 0 auto;

    @media ${device.desktop}{
      max-width: 100%;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2.4rem 1.6rem;
  align-items: center;
  p {
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6rem;
    text-align: center;
  }

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 4rem;
  }

  a {
    margin-bottom: 1rem;
    position: relative;
    height: 2.8rem;
    width: 13.6rem;
    border-radius: 1.6rem;
    border: none;
    background-color: #3246FF;
    box-shadow: 0 0.4rem .5rem 0 rgba(0,0,0,0.14);
    text-decoration: none;
    display: block;
    color: white;
    font-size: 12.2.5rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.75rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: all .3s;
      position: absolute;
      right: 1.6rem;
      font-size: 1.2rem;
    }

    &:hover {
      svg {
        right: 1rem;
      }
    }
  }

  a {
    color: white;
    font-size: 1.3rem;
    font-size: 1.3rem;
    line-height: 2.4rem;
    transition: all .3s;
    position: relative;

  }


  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 2.8rem 3.8rem;

    p {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.8rem;
      text-align: center;
      max-width: 100%;
    }

    a {
      height: 3.2rem;
      width: 16.8rem;
      border-radius: 1.6rem;

      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: all .3s;
        position: absolute;
        right: 1.6rem;
        font-size: 1.2rem;
      }
    }
  }

`

const CloseBtnWrapper = styled.div`
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;

    img {
      width: 1.6rem;
    }
`
