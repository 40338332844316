import React, { useEffect, useState } from 'react'
import { useSwiperSlide } from 'swiper/react';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';

import Tabs from '../../_components/Tabs/Tabs';
import Tab1 from '../components/Tab1';
// import Tab2 from '../components/Tab2';
// import Tab3 from '../components/Tab3';
// import Tab4 from '../components/Tab4';
// import Tab5 from '../components/Tab5';
// import Tab6 from '../components/Tab6';

import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import styled from 'styled-components/macro';
import { device } from '../../_assets/styles/theme';
import ReferenceModal from '../../_components/Modal/ReferenceModal'

const Slide2 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`
  const [isDatalayerUpdated, setIsDataLayerUpdated] = useState(false)
  useActiveLink(setShowHeaderSecondaryMenu, 'efficacy')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(2)
      setOverlay(slideOverlay)
      setCurrentTab(0)

      /**
      * Adds the datalayer in the first time the table appears in the screen.
      */
      if (!isDatalayerUpdated) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Efficacy Tabs',
          'efficacy_table_tabs': 'ORR'
        });

        setIsDataLayerUpdated(true)
      }

      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])


  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])

  const items = [
    {
      title: 'ORR',
      component: <Tab1 handleShowModal={handleShowModal} isiconfig={isiconfig} />,
      reference: <ReferenceFooterWithLink style={{ marginBottom: '0' }} url={'/n/testing'} linkText="Testing">
        <p>GTP=guanosine triphosphate; PFS=progression-free survival.</p>
      </ReferenceFooterWithLink>,
      legend: 'Primary endpoint'
    },
    // {
    //   title: 'DCR',
    //   component: <Tab2 handleShowModal={handleShowModal} isiconfig={isiconfig} />,
    //   reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing" />,
    //   legend: ''
    // },
    // {
    //   title: <IntracranialText><p>Intracranial ORR</p></IntracranialText>,
    //   component: <Tab3 handleShowModal={handleShowModal} isiconfig={isiconfig} />,
    //   reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing">
    //                 <p>*Median OS of ~5 months in untreated brain metastases.</p>
    //                 <p>NSCLC-non-small cell lung cancer; OS=overall survival; QOL=quality of life.</p>
    //               </ReferenceFooterWithLink>,
    //   legend: 'Post hoc analysis'
    // },
    // {
    //   title: 'OS',
    //   component: <Tab5 handleShowModal={handleShowModal} isiconfig={isiconfig} />,
    //   reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing">
    //                 <p>*Median OS of ~5 months in untreated brain metastases.</p>
    //                 <p>NSCLC-non-small cell lung cancer; OS=overall survival; QOL=quality of life.</p>
    //               </ReferenceFooterWithLink>,
    //   legend: 'Descriptive analysis'
    // },
    // {
    //   title: 'PFS',
    //   component: <Tab6 handleShowModal={handleShowModal} isiconfig={isiconfig} />,
    //   reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing">
    //                 <p>*Median OS of ~5 months in untreated brain metastases.</p>
    //                 <p>NSCLC-non-small cell lung cancer; OS=overall survival; QOL=quality of life.</p>
    //               </ReferenceFooterWithLink>,
    //   legend: 'Descriptive analysis'
    // },
    // {
    //   title: 'DOR',
    //   component: <Tab4 handleShowModal={handleShowModal} isiconfig={isiconfig} />,
    //   reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing">
    //                 <p>*Median OS of ~5 months in untreated brain metastases.</p>
    //                 <p>NSCLC-non-small cell lung cancer; OS=overall survival; QOL=quality of life.</p>
    //               </ReferenceFooterWithLink>,
    //   legend: 'Descriptive analysis'
    // }
  ]

  return (
    <>
      {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
      <Tabs
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        className={`efficacy efficacy-tabs ${showPageContent ? 'visible' : 'hidden'}`}
        isiconfig={isiconfig}
        items={items}
        horizontal={isMobile}
        padding='0 15px'
        hideScrollIcon={true}
      />

    </>
  )
}

export default Slide2

const IntracranialText = styled.div`
  pointer-events: none;

  p {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    pointer-events: none;
    &:first-of-type {
      text-transform: capitalize;
      line-height: 1;
    }
  }

  @media ${device.desktop} {
    display: flex;
    p {
      &:first-of-type {
        font-size: 1.6rem;
        margin-right: .2rem;
      }
    }
  }
`
