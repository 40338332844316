import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import {  useSwiperSlide } from 'swiper/react';
import { BaseText, BaseTitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import { device } from '../../_assets/styles/theme';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';
import useMobileDetect from '../../../_hooks/useMobileDetect';

const Slide1 = ({setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay}) => {
  const swiperSlide = useSwiperSlide();
  const { isMobile } = useMobileDetect()

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setCurrentSlide(1)
      setOverlay('linear-gradient(165deg, rgba(49, 78, 119, 0.2854) 4.22%, rgba(22, 38, 66, 0.2854) 90.76%)')
      setBackground(`url('/assets/moa-bg.png') no-repeat fixed center bottom / cover`)
      return
    }
  }, [swiperSlide.isActive])


  return (
    <>
    <CustomContainer isiconfig={isiconfig}>
        <CustomWrapper>
          <Title>
            <Title1>KRAZATI (<span className="lowercase">adagrasib</span>) {!isMobile && <br />} is intentionally designed to meet the challenge of KRAS G12C</Title1>
          </Title>

          <SubTitle>
            <p>Scroll to see how.</p>
          </SubTitle>
        </CustomWrapper>
        <ScrollIcon marginBottom="2rem" />
    </CustomContainer>
    </>
  )
}

export default Slide1

const CustomContainer = styled(Container)`
  display: flex;
  height: calc(100% - 11rem);
  padding: 0;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};
`

const CustomWrapper = styled(Wrapper)`
  padding: 2.1rem;

  @media ${device.desktop} {
    margin-top: 14.4rem;
  }

  @media ${device.desktop800h} {
    margin-top: 8rem;
  }

  @media ${device.desktop600h} {
    margin-top: 4rem;
  }
`
const Title = styled.div`
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  color: white;
  line-height: 1;
  width: 100%;
`
const Title1 = styled(BaseTitle)`
  margin-bottom: 1.6rem;
  text-transform: uppercase;

  span:not(.lowercase) {
    display: block;
    font-weight: 400;
  }
  .lowercase {
    text-transform: lowercase;
  }

  @media ${device.desktop} {
    margin-bottom: 2rem;
    max-width: 94.5rem;
  }

  @media ${device.desktop800h} {
    span:not(.lowercase) {
      font-size: 4.6rem;
    }
  }
`

const SubTitle = styled(BaseText)`
  color: white;
  font-size: 1.6rem;
  text-shadow: 0 .2rem 0.4rem rgba(0,0,0,0.5);

  @media ${device.desktop} {
    font-size: 2.4rem;
  }

  @media ${device.desktop800h} {
    font-size: 2.2rem;
  }
`
