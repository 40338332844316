import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useSwiperSlide } from "swiper/react";
import {
  BaseText,
  BaseTitle,
  Container,
  Wrapper,
} from "../../_components/PageSwipper/utils/styles";
import { device } from "../../_assets/styles/theme";
import ScrollIcon from "../../_components/PageSwipper/utils/ScrollIcon";
import useMobileDetect from "../../../_hooks/useMobileDetect";

const Slide1 = ({
  setShowHeaderSecondaryMenu,
  setCurrentSlide,
  isiconfig,
  setBackground,
  setOverlay,
  handleShowModal,
}) => {
  const { isMobile } = useMobileDetect();
  const swiperSlide = useSwiperSlide();
  const mobileOverlay = "linear-gradient(180deg, rgba(49, 78, 119, 0.1) 20.28%, rgba(22, 38, 66, 0.1) 100%);";
  const slideOverlay = mobileOverlay;
  const [showPageContent, setShowPageContent] = useState(false);
  const mobileBackground = `url("/assets/dosing-bg-ocean-waves-desktop.png") no-repeat fixed center top / cover`;
  const desktopBackground = `url("/assets/img_bkg-dosing.png") no-repeat fixed center top / cover`;
  const slideBackground = isMobile ? mobileBackground : desktopBackground;

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setCurrentSlide(1);
      setShowPageContent(true);
      setBackground(slideBackground);
      setOverlay(slideOverlay);
      return;
    }
    setShowPageContent(false);
  }, [swiperSlide.isActive]);

  return (
    <CustomContainer
      className={showPageContent ? "visible" : "hidden"}
      isiconfig={isiconfig}
    >
      <CustomWrapper>
        <Title>
          <Title1>KRAZATI Dosage</Title1>
        </Title>

        <SubTitle>
          <p>
            The recommended dosage of KRAZATI is 600 mg orally twice daily until
            disease progression or unacceptable toxicity.
            <sup onClick={() => handleShowModal([30])}>30</sup>
          </p>
          <p>Scroll for more dosing information.</p>
        </SubTitle>
      </CustomWrapper>

      <ScrollIcon marginBottom={isMobile ? "4.4rem" : "3.8rem"} />
    </CustomContainer>
  );
};

export default Slide1;

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0;
  max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomWrapper = styled(Wrapper)`
  margin-top: 0;
  justify-content: center;
  padding-top: 2.6rem;

  @media ${device.desktop} {
    padding-top: 0;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin-bottom: 1.6rem;
  padding: 0 3rem;

  @media ${device.desktop} {
    margin-bottom: 2.4rem;
  }
`;
const Title1 = styled(BaseTitle)`
  text-transform: uppercase;
`;
const SubTitle = styled(BaseText)`
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  font-size: 1.6rem;
  padding: 0 2.2rem;
  font-weight: 500;
  sup {
    font-size: 55%; ;
  }

  span {
    color: #17c94e;
  }

  @media ${device.desktop} {
    max-width: 55rem;
    font-size: 1.75rem;

    sup {
      font-size: 55%; ;
    }
  }
`;
