import { useLocation } from 'react-router-dom'
import styles from './NSCLCSiteSwitcher.module.scss'

export default function NSCLCSiteSwitcher() {
  let location = useLocation();
  const setSiteSwitchPath = () => {
    let loc = location.pathname.split('/')[2]
    if (!loc) {
      return '/c'
    }
    if (loc === 'testing') {
      return '/c/testing'
    }
    if (loc === 'dosing') {
      return '/c/dosage'
    }
    if (loc === 'MOA' || loc === 'moa' || loc === 'stay-connected' || loc === 'nccn-recommendations' || loc === 'videos' || loc === 'contact-us' || loc === 'contact') {
      return '/c'
    }
    return '/c/' + loc
  }
  return (
    <div className={styles.siteSwitcher}>
      <div className={styles.siteSwitcherWrapper}>
        <img className={styles.badge} src="/assets/icons/icon-nsclc-badge-desktop.svg" alt="Disease Indication Flag" />
        <div className={styles.siteSwitcherTitle}>
          Switch Indications
        </div>
        <div className={styles.siteSwitcherToggle}>
          <a href={setSiteSwitchPath()}>OTHER</a>
          <a href="#" className={styles.activeToggle}>NSCLC</a >
        </div>
      </div>
    </div>
  )
}
