import CRCLayout from "./layouts/CRCLayout"
import JumpMenu from './components/JumpMenu'
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import FooterCallout from "./components/FooterCallout"
import ContentContainer from "../_common/components/Layout/ContentContainer"
import Callout from "./components/Callout"
import JumpLink from "./components/JumpLink"
import RefModal from "./components/RefModal"
import MetaTags from "../_common/components/MetaTags"
import styles from "./CRCStudyDesign.module.scss"

export default function CRCStudyDesign() {
  const links = [
    {
      url: '#study-design',
      text: 'Study design'
    },
    {
      url: '#heavily-pretreated-patient-population',
      text: 'Heavily pretreated patient population'
    }
  ]
  return (
    <CRCLayout>
      <MetaTags
        title='KRYSTAL-1 | Study Design and Methodology'
        description='Explore the study design and methodology of the KRYSTAL-1 trial.'
        ogUrl='https://www.krazatihcp.com/c/study-design'
      />
      <InteriorBanner cn="studyDesign" title="KRYSTAL-1: REGISTRATIONAL STUDY" imageUrl="/banners/banner-about-kras.jpg" desktopImageUrl="/banners/banner-about-kras-desktop.jpg" />
      <JumpMenu title="HOW WAS KRAZATI TESTED?" links={links} />

      <MainContent>

        <div className={styles.studyDesign}>
          <JumpLink id="study-design" />
          <ContentContainer>
            <h2>Examining the safety and efficacy of KRAZATI + cetuximab</h2>
            <p><strong>Safety and efficacy were evaluated in patients with <i>KRAS G12C</i>-mutated previously treated advanced CRC in KRYSTAL-1, a phase 1/2 single-arm, open-label expansion cohort study  (N=94, who were evaluable by BICR according to RECIST v1.1).<RefModal references={[1, 21]} /></strong></p>
            <div className={styles.studyDesignCallout}>
              <div>
                <div>
                  <h4>Key inclusion criteria</h4>
                  <ul>
                    <li>Locally advanced or metastatic CRC</li>
                    <li>Confirmed <i>KRAS G12C</i> mutation determined in tumor tissue</li>
                    <li>Patients previously received treatment with fluoropyrimidine, oxaliplatin, irinotecan, and a VEGF pathway inhibitor, if eligible</li>
                  </ul>
                </div>
                <div>
                  <h4>KRAZATI 600 mg BID</h4>
                  <ul>
                    <li>Until disease progression or unacceptable toxicity</li>
                    <li>Cetuximab administered weekly (400 mg/m<sup>2</sup> loading dose, followed by 250 mg/m<sup>2</sup> weekly) or biweekly (500 mg/m<sup>2</sup>) in phase 1 (n=32), and biweekly only in phase 2 (n=62)<RefModal references={[1, 21]} /></li>
                  </ul>
                </div>
                <div>
                  <h4>Study objectives<RefModal references={[21]} /></h4>
                  <p><b>Primary endpoint: </b>safety for phase 1 and confirmed ORR by BICR for phase 2</p>
                  <p><b>Secondary endpoints:</b> safety and DOR</p>
                  <p>The median follow-up for primary analysis is 11.9 months.</p>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>

        <div className={styles.pretreated}>
          <JumpLink id="heavily-pretreated-patient-population" />
          <ContentContainer>
            <h3>KRAZATI was studied in a heavily pretreated population<RefModal references={[1, 21]} /></h3>
            <img className={styles.imgMobile} src="/assets/clinicaldata/img-chart-01-mobile.jpg" alt="" />
            <img className={styles.imgDesktop} src="/assets/clinicaldata/img-chart-01-desktop.jpg" alt="" />
          </ContentContainer>
        </div>

        <Callout title={["25% of patients in the KRYSTAL-1 trial had 4 or more prior lines of therapy", <RefModal references={[1]} />]} />

        <div className={styles.references}>
          <ContentContainer>
            <p><small>BICR=blinded independent central review; BID=twice-daily; CRC=colorectal cancer; DOR=duration of response; ECOG PS=Eastern Cooperative Oncology Group Performance Status; ORR=objective response rate; RECIST=Response Evaluation Criteria in Solid Tumors.</small></p>
          </ContentContainer>
        </div>

      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="See the clinical data for KRAZATI" link="/c/efficacy" />
    </CRCLayout>
  )
}
