import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import ISICollapsing from '../_components/ISI/Collapsing';
import useMobileDetect from '../../_hooks/useMobileDetect';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import StaticIsi from '../_components/ISI/StaticIsi';
import Footer from '../_components/Footer';
import { Page } from '../_components/PageSwipper/utils/styles';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import { PageOverlay, StaticContentWrapper } from '../Home';
import { isiConfig } from '../_components/ISI/config';
import { useLocation } from 'react-router-dom';
import { device } from '../_assets/styles/theme';
import { useInView } from 'react-intersection-observer';
import MetaTags from '../_components/MetaTags/MetaTags'

const Contact = ({ setPageBackground }) => {
  const { isMobile } = useMobileDetect()
  const [headerRef, setHeaderRef] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [renderPage, setRenderPage] = useState(false)
  const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(1)
  const [background, setBackground] = useState(`url(${isMobile ? '/assets/stay-connected-bg-m.png' : '/assets/stay-connected-bg.png'}) no-repeat fixed center top / cover`)
  const [overlay, setOverlay] = useState(null)
  const overlayRef = useRef()
  const mySwiper = document.querySelector('.mySwiper')
  const { ref, inView, entry } = useInView()

  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef?.current.clientHeight)
      setRenderPage(true)
    }
    setBackground(`url(${isMobile ? '/assets/form-page-bg-m.png' : '/assets/stay-connected-bg.png'}) no-repeat fixed center top / cover`);
    setOverlay(isMobile ? 'linear-gradient(180deg, rgba(34, 40, 62, 0.80) 0%, rgba(7, 13, 35, 0.77) 100%);' : 'linear-gradient(173deg, rgba(80, 82, 122, 0.2154) -2.67%, rgba(74, 78, 97, 0.2154) 22.8%, rgba(47, 49, 74, 0.2154) 96.29%);');
  }, [headerRef?.current])

  useEffect(() => {
    const isi = document.querySelector('.floatingWithIsi')

    if (!isi) return
    if (!inView) {
      isi?.classList.remove('hidden')
      return
    }
    isi?.classList.add('hidden')

  }, [inView, entry])



  useEffect(() => {
    overlayRef.current.classList.remove('visible')
    overlayRef.current.classList.add('hidden')

    const time = setTimeout(() => {
      overlayRef.current.classList.add('visible')
      overlayRef.current.classList.remove('hidden')
    }, 100)

    return () => {
      clearTimeout(time)
    }
  }, [currentSlide])

  return (
    <>
      <MetaTags
        title='Contact Us for KRAZATI® (adagrasib) Information'
        description="Connect with us for KRAZATI® (adagrasib) information. Request a representative's assistance or opt to receive text messages from Mirati. Learn how we can assist you."
        ogUrl='https://www.krazatihcp.com/n/contact'
      />
      <Page background={background}>
        <PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
        <PageScroll>
          <Container>

            <Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
            <div style={{ position: "relative" }}>
              {renderPage && (
                <PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
                  <Slide2
                    isiconfig={isiConfig.floating}
                    setCurrentSlide={setCurrentSlide}
                    setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                    setBackground={setBackground}
                    setOverlay={setOverlay}
                  />
                  <Slide1
                    isiconfig={isiConfig.collapsed}
                    setCurrentSlide={setCurrentSlide}
                    setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                    setBackground={setBackground}
                    setOverlay={setOverlay}
                  />
                </PageSwipper>
              )}

              {!isMobile && currentSlide === 1 && <ISICollapsing className="visible" />}
              {!isMobile && currentSlide === 2 && <ISICollapsing className="floatingWithIsi collapse" />}

              {isMobile && <FloatingIsi className={currentSlide === 2 ? 'collapse' : 'visible'} />}
            </div>

          </Container>
          {!isMobile && currentSlide === 2 &&
            <CustomStaticContentWrapper ref={ref}>
              <StaticIsi />
              <Footer translate={true}></Footer>
            </CustomStaticContentWrapper>
          }
        </PageScroll>
      </Page>
    </>
  )
}

export default Contact

const Container = styled.div`
  max-height: 100%;

  .swiper-pagination {
    display: none;
  }
`

const Content = styled.div`
   width: 100%;
    height: calc(100vh - 4.9rem);
    height: calc(var(--screen-height) - 4.9rem);


    @media ${device.desktop} {
      height: calc(100vh - 11.2rem);
      height: calc(var(--screen-height) - 11.2rem);
    }

`

const CustomStaticContentWrapper = styled(StaticContentWrapper)`
  transform: translateY(1px);
`
