import styled from 'styled-components/macro'
import { useToggle } from '../../../_hooks/useToggle'
import { motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import { device } from '../../_assets/styles/theme'
import { BaseText } from '../PageSwipper/utils/styles'
import { useSwiper } from 'swiper/react'

const AccordionNoArrow = ({title, icon, children, alt, startOpen = false, setRecalculate}) => {
  const [open, toggle] = useToggle(startOpen)
  const swiper = useSwiper()

  const control = useAnimation()
  const textControl = useAnimation()

  const show = {
    start: { overflow: 'hidden', height: '0', transition: {duration: .5,} },
    end: {overflow: 'initial',  height: 'auto', transition: {duration: .5}}
  }

  const showText = {
    start: {opacity: 0, transition: {delay: .1} },
    end: {opacity: 1, transition: {delay: .1}}
  }

  useEffect(() => {
    if (open) {
      control.start("end");
    }

    if (!open) {
      control.start("start");
    }

    setRecalculate(state => !state)

}, [open, control, textControl]);

  const handleClick = () => {
    toggle()
    setTimeout(() => {
      swiper.update()
    }, 500)
  }

  return (
    <AccordionContainer>
      <AccordionWrapper className="title" open={open}>
          <AccordionTitle onClick={handleClick}>
            <img src={icon} alt={alt}/> <AccordionText as="span">{title}</AccordionText>
            <img className={`custom-icon ${open ? '' : 'down'}`} src="/assets/ArrowGreen.svg" alt="Arrow Up Icon" />
          </AccordionTitle>
      </AccordionWrapper>

      <AccordionWrapper open={open}className="container" animate={control} variants={show} initial="start">
        <AccordionInfos animate={control} variants={showText}  initial="start">{children}</AccordionInfos>
      </AccordionWrapper>

    </AccordionContainer>
  )
}

export default AccordionNoArrow

const AccordionContainer = styled.div`
  text-align: left;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  overflow: hidden;
  background: #F5F6FC;

  &:last-of-type {
      margin-bottom: 1.8rem;
    }

    @media ${device.tablet} {
      width: 90%;
      margin: 0 auto 2rem;
      &:last-of-type {
        margin-bottom: 3rem;
      }
    }

  @media ${device.desktop} {
    max-width: 10.35rem;
    text-align: left;
    margin-bottom: 5rem;
    font-family: "Proxima Nova", sans-serif;
    position: relative;
    z-index: 2;

  }
`

const AccordionWrapper = styled(motion.div)`
    text-align: left;
    position: relative;
    z-index: 2;
`

const AccordionTitle = styled.div`
    min-height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-weight: 600;
    font-size: 1.6rem;
    color: ${({theme}) => theme.colors['dark-blue']};


    .custom-icon {
      width: 1.4rem;
      height: 1.4rem;
    }

    .list-none {
      list-style: none;
    }

    img {
      max-width: 3rem;
      width: 100%;
    }

    @media ${device.tablet} {
      font-size: 1.8rem;
	  font-size: 1.8rem;

      .custom-icon {
        width: 2rem;
        height: 2rem;
      }
    }

    @media ${device.desktop} {
      font-size: 2.1rem;
    }

`

const AccordionText = styled(BaseText) `
  color: #502D83;
  width: 100%;
  margin-left: 1.4rem;
  text-transform: uppercase;
  font-size: 1.3rem;
font-size: 1.3rem;
`

const AccordionInfos = styled(motion.div)`
    padding: .5rem 0.8rem 1.5rem;
    max-width: 92rem;
    font-size: 1.4rem;
    color: ${({theme}) => theme.colors.black};
    font-weight: 500;
    .list-none {
        list-style: none;

        li {
          margin: 2rem 0;
        }
    }

    a {
      &.__link {
        text-decoration: underline;
      }
    }

    @media ${device.tablet} {
      font-size: 1.8rem;
font-size: 1.8rem;
      padding: 1.8rem 3.7rem 1.8rem 1.8rem;

    }

    @media ${device.desktop} {
      padding: 0 0 3.4rem 2.2rem;
      font-size: 2rem;

      a {
        &.__link {
          text-decoration: underline;
        }
      }
    }
`
