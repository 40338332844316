import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import ISICollapsing from '../_components/ISI/Collapsing';
import { Page } from '../_components/PageSwipper/utils/styles';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import useMobileDetect from '../../_hooks/useMobileDetect';
import { PageOverlay, StaticContentWrapper } from '../Home';
import StaticIsi from '../_components/ISI/StaticIsi';
import Footer from '../_components/Footer';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import { isiConfig } from '../_components/ISI/config';
import { device } from '../_assets/styles/theme';
import MetaTags from '../_components/MetaTags/MetaTags';

const Efficacy = ({ setPageBackground, page = 1 }) => {
  const { isMobile } = useMobileDetect()
  const [headerRef, setHeaderRef] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [renderPage, setRenderPage] = useState(false)
  const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(1)
  const [background, setBackground] = useState(`url('/assets/bg-study.webp') no-repeat fixed center top / cover`)
  const [overlay, setOverlay] = useState(null)
  const overlayRef = useRef()

  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef?.current.clientHeight)
      setRenderPage(true)
    }
  }, [headerRef?.current])

  useEffect(() => {
    overlayRef.current.classList.remove('visible')
    overlayRef.current.classList.add('hidden')

    const time = setTimeout(() => {
      overlayRef.current.classList.add('visible')
      overlayRef.current.classList.remove('hidden')
    }, 100)

    return () => {
      clearTimeout(time)
    }
  }, [currentSlide])


  return (
    <Page background={background}>
      <PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
      <PageScroll>
        <Container>
          <MetaTags
            title='KRAZATI® (adagrasib) | KRYSTAL-1 Study Efficacy Data'
            description='Explore efficacy data analysis derived from the comprehensive KRYSTAL-1 study. Delve into various clinical endpoints and detailed analyses.'
            ogUrl='https://www.krazatihcp.com/n/efficacy'
          />

          <Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
          <div style={{ position: 'relative' }}>
            {renderPage && (
              <PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
                <Slide1
                  isiconfig={isiConfig.collapsed}
                  page={page}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                />
                <Slide2
                  isiconfig={isiConfig.floating}
                  page={page}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                />
              </PageSwipper>
            )}

            {!isMobile && currentSlide === 1 && <ISICollapsing className="visible" />}
            {!isMobile && currentSlide === 2 && <ISICollapsing className="floatingWithIsi collapse" />}

            {isMobile && <FloatingIsi className={currentSlide === 2 ? 'collapse' : 'visible'} />}

          </div>

          {!isMobile && currentSlide === 2 &&
            <StaticContentWrapper>
              <StaticIsi />
              <Footer translate={true}></Footer>
            </StaticContentWrapper>
          }
        </Container>
      </PageScroll>
    </Page>
  )
}

export default Efficacy

const Container = styled.div`
  max-height: 100%;

  .header-shadow {
    display: none;

    @media ${device.desktop} {
      display:initial;
    }
  }
`
