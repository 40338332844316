import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './DesktopStickyISI.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export default function DesktopStickyISI() {
  const navigate = useNavigate();
  let location = useLocation()
  const [isTop, setIsTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const isiVariants = {
    initial: {
      height: '204px',
      overflow: 'hidden',
      opacity: 1
    },
    hidden: {
      height: '204px',
      overflow: 'hidden',
      transition: { ease: 'easeOut' },
      opacity: 1,
    },
    visible: {
      height: '100vh',
      overflow: 'scroll',
      transition: { ease: 'easeOut' },
      opacity: 1
    },
    minimized: {
      height: '48px',
      overflow: 'hidden',
      opacity: 1,
      transition: { ease: 'easeOut' },
    }
  }

  const toggleISI = () => {
    if (isOpen && location.hash === '#important-safety-information') {
      navigate(location.pathname)
    }
    setIsOpen(!isOpen)
  }

  const setAnimationState = () => {
    if (!isOpen) {
      if (!isTop) {
        return 'minimized'
      }
      return 'hidden'
    }
    return 'visible'
  }

  const getTopOffset = () => {
    let st = window.scrollY;
    if (st > 100) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", getTopOffset)
    return () => window.removeEventListener("scroll", getTopOffset)
  }, [])

  useEffect(() => {
    if (location.hash === '#important-safety-information') {
      setIsOpen(true)
    }
  }, [location.hash])

  return (
    <AnimatePresence>

      <motion.div key="desktopisi" variants={isiVariants} initial="initial" animate={setAnimationState()} className={`${styles.isi} ${isOpen ? `${styles.expanded}` : ''}`} exit={{ opacity: 0 }}>
        <div className={styles.isiContent}>
          <div className={styles.isiContentContainer}>

            <div className={styles.isiArrow} onClick={() => {
              toggleISI();
            }}>
              <img src="/assets/icons/icon-isi-arrow.svg" alt="" />
            </div>

            <div className={``}>
              <div className={``}>

                <div className="">
                  <h2>IMPORTANT SAFETY INFORMATION</h2>
                  <p><strong>WARNINGS AND PRECAUTIONS</strong></p>
                  <p><strong>Gastrointestinal Adverse Reactions</strong></p>
                </div>

                <div className={``}>
                  <ul>
                    <li>KRAZATI can cause severe gastrointestinal adverse reactions</li>
                    <li>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity</li>
                  </ul>
                  <p><strong>QTc Interval Prolongation</strong></p>
                  <ul>
                    <li>KRAZATI can cause QTc interval prolongation, which can increase the risk for ventricular tachyarrhythmias (eg, torsades de pointes) or sudden death</li>
                    <li>Avoid concomitant use of KRAZATI with other products with a known potential to prolong the QTc interval. Avoid use of KRAZATI in patients with congenital long QT syndrome and in patients with concurrent QTc prolongation</li>
                    <li>Monitor ECGs and electrolytes, particularly potassium and magnesium, prior to starting KRAZATI, during concomitant use, and as clinically indicated in patients with congestive heart failure, bradyarrhythmias, electrolyte abnormalities, and in patients who are unable to avoid concomitant medications that are known to prolong the QT interval. Correct electrolyte abnormalities. Withhold, reduce the dose, or permanently discontinue KRAZATI, depending on severity</li>
                  </ul>
                  <p><strong>Hepatotoxicity</strong></p>
                  <ul>
                    <li>KRAZATI can cause hepatotoxicity, which may lead to drug-induced liver injury and hepatitis</li>
                    <li>Monitor liver laboratory tests (AST, ALT, alkaline phosphatase, and total bilirubin) prior to the start of KRAZATI, and monthly for 3 months or as clinically indicated, with more frequent testing in patients who develop transaminase elevations. Reduce the dose, withhold, or permanently discontinue KRAZATI based on severity</li>
                  </ul>
                  <p><strong>Interstitial Lung Disease/Pneumonitis</strong></p>
                  <ul>
                    <li>KRAZATI can cause interstitial lung disease (ILD)/pneumonitis, which can be fatal</li>
                    <li>Monitor patients for new or worsening respiratory symptoms indicative of ILD/pneumonitis (eg, dyspnea, cough, fever) during treatment with KRAZATI. Withhold KRAZATI in patients with suspected ILD/pneumonitis and permanently  discontinue KRAZATI if no other potential causes of ILD/pneumonitis are  identified</li>
                  </ul>
                  <p><strong>ADVERSE REACTIONS</strong></p>
                  <ul>
                    <li>Serious adverse reactions occurred in 30% of 94 patients who received adagrasib in combination with cetuximab. The most common adverse reactions in CRC patients (≥20%) were rash, nausea, diarrhea, vomiting, fatigue, musculoskeletal pain, hepatotoxicity, headache, dry skin, abdominal pain, decreased appetite, edema, anemia, dizziness, cough, constipation, and peripheral neuropathy</li>
                  </ul>
                  <p><strong>DRUG INTERACTIONS</strong></p>
                  <ul>
                    <li>Strong CYP3A4 Inducers: Avoid concomitant use.</li>
                    <li>Strong CYP3A4 Inhibitors: Avoid concomitant use until adagrasib concentrations have reached steady state.</li>
                    <li>Sensitive CYP3A4 Substrates: Avoid concomitant use with sensitive CYP3A4 substrates.</li>
                    <li>Sensitive CYP2C9 or CYP2D6 Substrates or P-gp Substrates: Avoid concomitant use with sensitive CYP2C9 or CYP2D6 substrates or P-gp substrates where minimal concentration changes may lead to serious adverse reactions.</li>
                    <li>Drugs That Prolong QT Interval: Avoid concomitant use with KRAZATI.</li>
                  </ul>
                  <p><i>Please see Drug Interactions Section of the Full Prescribing Information for additional information.</i></p>
                  <p><strong>USE IN SPECIFIC POPULATIONS</strong></p>
                  <p><strong>Females and Males of Reproductive Potential</strong></p>
                  <ul>
                    <li>Infertility: Based on findings from animal studies, KRAZATI may impair fertility in females and males of reproductive potential</li>
                  </ul>
                  <p><strong>Lactation</strong></p>
                  <ul>
                    <li>Advise not to breastfeed</li>
                  </ul>
                  <p><a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target='_blank' rel="noreferrer">Please see Full Prescribing Information</a>.</p>
                </div>

              </div>
            </div>

            <div className={``}>
              <div className={``}>

                <h2>INDICATION</h2>
                <div className={``}>
                  <p>KRAZATI in combination with cetuximab is indicated for the treatment of adult patients with KRAS G12C-mutated locally advanced or metastatic colorectal cancer (CRC), as determined by an FDA-approved test, who have received prior treatment with fluoropyrimidine-, oxaliplatin-, and irinotecan-based chemotherapy.</p>
                  <p>This indication is approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR). Continued approval for this indication may be contingent upon verification and description of a clinical benefit in a confirmatory trial.</p>
                </div>

              </div>
            </div>

          </div>
        </div>
      </motion.div>

    </AnimatePresence>
  )
}
