import React, { useEffect } from "react";
import { HeaderTitle, MotionImage } from "../Collapsing";
import IsiContent, { IsiHeader } from "./IsiContent";
import { rotate } from "../../../_assets/styles/animations";
import styled from "styled-components/macro";
import { device } from "../../../_assets/styles/theme";
import useMobileDetect from "../../../../_hooks/useMobileDetect";
import { useState } from "react";
import useAnimateOnScroll from "../../../../_hooks/useAnimateOnScroll";
import { motion, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";
import IndicationContent from "./IndicationContent";

const FloatingIndication = (props) => {
  const [showingFullIndication, setShowingFullIndication] = useState(false);
  const { inView } = useAnimateOnScroll();
  const control = useAnimation();
  const swiper = document.querySelector(".mySwiper")?.swiper;
  const location = useLocation();
  const isiEffects = {
    start: { height: "", overflowY: "initial", transition: { duration: 0.5 } },
    end: { height: "auto", overflowY: "scroll", transition: { duration: 0.5 } },
    fadeIn: { opacity: 1, height: "", transition: { duration: 0.3 } },
    fadeOut: { opacity: 0, height: 0, transition: { duration: 0.3 } },
  };
  const { isMobile } = useMobileDetect();

  useEffect(() => {
    if (showingFullIndication) {
      control.start("end");
      document.body.style.overflow = "hidden";
      document.body.classList.add("indication-open");
    }

    if (!showingFullIndication) {
      control.start("start");
      document.body.style.overflow = "initial";
      document.body.classList.remove("indication-open");
    }
  }, [showingFullIndication, control]);

  useEffect(() => {
    if (inView) {
      control.start("fadeOut");
    }

    if (!inView) {
      control.start("fadeIn");
    }
  }, [inView, control]);

  const handleClick = () => {
    if (inView) {
      return;
    }
    if (showingFullIndication) {
      setShowingFullIndication(false);
      return;
    }
    setShowingFullIndication(true);
  };

  useEffect(() => {
    if (!swiper) return;

    if (window.location.hash === "#indication") {
      setShowingFullIndication(true);
    } else {
      setShowingFullIndication(false);
    }
  }, [swiper, location.hash]);

  if (isMobile) {
    return (
      <ISIWrapper
        id="floating-indication"
        className={`swiper-no-swiping swiper-pointer-events ${props.className}`}
        animate={control}
        variants={isiEffects}
        initial="start">
        <HeaderWrapper onClick={handleClick}>
          <HeaderTitle>INDICATION</HeaderTitle>
          <MotionImage
            animate={control}
            variants={rotate}
            initial="start"
            className="custom-icon down"
            src="/assets/icons/arrow_chevron_up.svg"
            alt=""
          />
        </HeaderWrapper>
        <Content className={showingFullIndication ? "" : "collapse"}>
          <IndicationContent />
        </Content>
      </ISIWrapper>
    );
  } else {
    return (
      <ISIWrapper
        id="floating-indication"
        className={`I-dont-think-this-is-even-used swiper-no-swiping swiper-pointer-events ${props.className}`}
        animate={control}
        variants={isiEffects}
        initial="start"
        style={showingFullIndication ? { zIndex: 300, position: "fixed" } : null}>
        <HeaderWrapper onClick={handleClick}>
          <HeaderTitle>INDICATION AND IMPORTANT SAFETY INFORMATION</HeaderTitle>
          <MotionImage
            animate={control}
            variants={rotate}
            initial="start"
            className="custom-icon down"
            src="/assets/icons/arrow_chevron_up.svg"
            alt=""
          />
        </HeaderWrapper>
        <Content className={props.className}>
          {!showingFullIndication && <IsiHeader />}

          {showingFullIndication && <IsiContent />}
        </Content>
      </ISIWrapper>
    );
  }
};

export default FloatingIndication;

const ISIWrapper = styled(motion.div)`
  font-family: "Barlow", sans-serif;
  color: #434343;
  background: white;
  z-index: 999;
  width: calc(100% + 6vw);
  border-top: 2px solid blue;
  border-bottom: 2px solid blue;
  margin-left: -3vw;
  margin-right -3vw;
  padding: 0 3vw;
  &.floatingWithIsi {
    position: absolute;
  }

  &::-webkit-scrollbar {
      width: .5rem;
      background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
      background: #333;
  }

  p {
    margin: 0.5rem 0;
    font-size: .9rem;
    font-weight: 500;
    position: relative;
    padding-left: 0;
    &:nth-of-type(2) {
      margin-bottom: 2rem;
    }
  }
  section {
    margin: .5rem 5vw 2.4rem;
    max-width: 128rem;

    a {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.blue}
    }
  }

  @media ${device.tablet} {
      section {
          margin: 1.5rem 4rem 2rem;
      }
      p {
        font-size: 1.6rem;
      }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 2.5rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
  }

  @media ${device.tablet} {
    height: 3.5rem;
    margin-bottom: 0px;
    img {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const Content = styled.div`
  margin-bottom: 0.5rem;
  &.visible {
    max-height: 5rem;
    transition: max-height 0 ease;
  }

  &.collapse {
    max-height: 0;
    transition: max-height 0.1s ease;
    animation: fadeout 0.1s ease;
    margin-bottom: 0;
    overflow: hidden;
    p {
      display: none;
    }
  }

  @media ${device.tablet} {
    max-height: 11.1rem;

    &.visible {
      max-height: 11.1rem;
    }
  }
`;
