import { device } from '../_assets/styles/theme';
import { Helmet } from 'react-helmet-async';
import { isiConfig } from '../_components/ISI/config';
import { Page } from '../_components/PageSwipper/utils/styles';
import { PageOverlay, StaticContentWrapper } from '../Home';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import Footer from '../_components/Footer';
import Header from '../_components/Header';
import ISICollapsing from '../_components/ISI/Collapsing';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import PageSwipper from '../_components/PageSwipper';
import React, { useEffect, useRef } from 'react'
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import MetaTags from '../_components/MetaTags/MetaTags';
import StaticIsi from '../_components/ISI/StaticIsi';
import styled from 'styled-components/macro'
import useMobileDetect from '../../_hooks/useMobileDetect';

const StayConnected = ({ setPageBackground }) => {
	const { isMobile } = useMobileDetect()
	const [headerRef, setHeaderRef] = useState(null)
	const [headerHeight, setHeaderHeight] = useState(0)
	const [renderPage, setRenderPage] = useState(false)
	const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
	const [currentSlide, setCurrentSlide] = useState(1)
	const [background, setBackground] = useState(`url(${isMobile ? '' : '/assets/bg-home2.webp'}) no-repeat fixed center top / cover`)
	const [overlay, setOverlay] = useState(null)
	const overlayRef = useRef()
	const location = useLocation()

	useEffect(() => {
		if (headerRef?.current) {
			setHeaderHeight(headerRef?.current.clientHeight)
			setRenderPage(true)
		}
	}, [headerRef?.current])


	useEffect(() => {
		overlayRef.current.classList.remove('visible')
		overlayRef.current.classList.add('hidden')

		const time = setTimeout(() => {
			overlayRef.current.classList.add('visible')
			overlayRef.current.classList.remove('hidden')
		}, 100)

		return () => {
			clearTimeout(time)
		}
	}, [currentSlide])

	return (
		<>
			<MetaTags
				title='Stay Connected with KRAZATI® (adagrasib)'
				description='Stay connected with KRAZATI® (adagrasib). Report issues or call 1-844-647-2841 for assistance.'
				ogUrl='https://www.krazatihcp.com/n/stay-connected'
			/>
			<Page background={background}>
				<PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
				<PageScroll>
					<Container>
						<Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
						<div style={{ position: "relative" }}>
							{renderPage && (
								<PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
									<Slide1
										isiconfig={isiConfig.collapsed}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
									/>
									<Slide2
										isiconfig={isiConfig.collapsed}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
									/>
								</PageSwipper>
							)}

							{/* {!isMobile && currentSlide === 1 && <ISICollapsing className="visible"/>} */}
							{!isMobile && currentSlide === 1 && <ISICollapsing className="floatingWithIsi" />}
							{isMobile && <FloatingIsi className={currentSlide === 1 ? 'visible' : 'collapse'} />}
						</div>

					</Container>
					{!isMobile && currentSlide === 1 &&
						<StaticContentWrapper>
							<StaticIsi />
							<Footer translate={true}></Footer>
						</StaticContentWrapper>
					}
				</PageScroll>
			</Page>
		</>
	)
}

export default StayConnected

const Container = styled.div`
  max-height: 100%;

  .swiper-pagination {
    display: none;
  }
`
const Content = styled.div`
   width: 100%;
    height: calc(var(--screen-height) - 4.9rem);


    @media ${device.desktop} {
      height: calc(var(--screen-height) - 11.2rem);
    }

`
