import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useSwiperSlide, useSwiper } from 'swiper/react';
import { BaseLink, BaseText, Container, FlexCentered } from '../../_components/PageSwipper/utils/styles';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';
import Reference from '../../_components/Reference';
import LargeBlueArrow from '../../_components/Shapes/LargeBlueArrow';
import DoubleGreenArrow from '../../_components/Shapes/DoubleGreenArrow';
import '../css/style.scss';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';

const HomeLegends = () => {
  return (
    <NCCNReference>
      <p>NCCN=National Comprehensive Cancer Network.</p>
    </NCCNReference>
  );
};

const Slide1 = ({ handleShowModal, setPageBackground, setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const swiperSlide = useSwiperSlide();
  const swiper = useSwiper();
  const { hash } = useLocation();
  const { isMobile } = useMobileDetect();
  // const mobileBgWrapper = useRef();
  const [showPageContent, setShowPageContent] = useState(false);
  const mobileOverlay = 'linear-gradient( rgba(12, 22, 63, 0) 60%, rgba(1, 20, 96, 0.433)100%)';
  const desktopOverlay = 'linear-gradient(180deg, rgba(36,49,99,0) 70%,#070D23 100%)';
  const mobileBackground = 'url(/assets/form-page-bg-m.png) no-repeat center top / cover'
  const desktopBackground = 'url(/assets/hero-image.jpg) no-repeat fixed 0 center / cover'
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
  const slideBackground = isMobile ? mobileBackground : desktopBackground;

  useEffect(() => {
    if (hash === '#kolvideo1') {
      swiper.slideTo(2);
      return;
    }
    if (hash === '#nccn-recommendations') {
      swiper.slideTo(1);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setCurrentSlide(1);
      setShowPageContent(true);
      setBackground(slideBackground);
      setOverlay(slideOverlay);
      return;
    }
    setShowPageContent(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperSlide.isActive]);

  if (isMobile) {
    return (
      <MobileContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <ContainerScroll>
          <Indication>
            <IndicationTag>
              <span>INDICATION</span>
            </IndicationTag>
            <Indicationtext>
              <BaseText>
                KRAZATI is indicated for the treatment of adult patients with <em>KRAS G12C</em>-mutated locally advanced{' '}
                or metastatic non-small cell lung cancer (NSCLC), as determined by an <span className='no-wrap'>FDA-approved</span> test, who have received at least one prior systemic therapy.
              </BaseText>
              <BaseText>
                This indication is approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR). &nbsp;Continued approval for this indication may be contingent upon verification and description of a clinical benefit in a confirmatory trial(s).
              </BaseText>
            </Indicationtext>
          </Indication>
          {/* <Title className="home-text">
            <Title1>BUILT FOR THE</Title1>
            <Title2>CHALLENGE</Title2>
            <Title3><DoubleGreenArrow className="double-green-arrow" />OF KRAS G12C</Title3>
            <LargeBlueArrow className="large-blue-arrow" />
          </Title> */}
          <BigTitle>
            <span>BUILT FOR THE CHALLENGE OF KRAS G12C</span>
          </BigTitle>
          <div style={{ width: '100%', margin: '4rem 0 0 0' }}>
            <FlexCentered>
              <ScrollIcon marginBottom='.7rem' />
            </FlexCentered>
            <div className="home-buttons">
              <CardBtn as={Link} to='/n/study-design'>
                <span>Clinical Data</span>
              </CardBtn>
              <CardBtn as={Link} to='/n/nccn-recommendations'>
                <span>NCCN Guidelines® Recommendations</span>
              </CardBtn>
            </div>
            <HomeLegends />
          </div>
        </ContainerScroll>
      </MobileContainer>
    )
  }

  return (
    <DesktopContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
      {/* <Indication>
        <IndicationTag>
          <span>INDICATION</span>
        </IndicationTag>
        <Indicationtext>
          <BaseText>
            KRAZATI is indicated for the treatment of adult patients with <em>KRAS G12C</em>-mutated locally advanced{' '}
            or metastatic non-small cell lung cancer (NSCLC), as determined by an <span className='no-wrap'>FDA-approved</span> test, who have received at least one prior systemic therapy.
          </BaseText>
          <BaseText>
            This indication is approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR). &nbsp;Continued approval for this indication may be contingent upon verification and description of a clinical benefit in a confirmatory trial(s).
          </BaseText>
        </Indicationtext>
      </Indication> */}
      {/* <Title className="home-text">
        <Title1>BUILT FOR THE</Title1>
        <Title2>CHALLENGE</Title2>
        <Title3><DoubleGreenArrow className="double-green-arrow" />OF KRAS G12C</Title3>
        <LargeBlueArrow className="large-blue-arrow" />
      </Title> */}
      <BigTitle>
        <span>BUILT FOR THE</span>
        <span>CHALLENGE OF KRAS G12C</span>
      </BigTitle>
      <Slide1Actions>
        <ScrollIcon />
        <Slide1Buttons>
          <CardBtn as={Link} to='/n/study-design'>
            <span>Clinical Data</span>
          </CardBtn>
          <CardBtn as={Link} to='/n/nccn-recommendations'>
            <span>NCCN Guidelines® Recommendations</span>
          </CardBtn>
        </Slide1Buttons>
      </Slide1Actions>
      <HomeLegends />
    </DesktopContainer>
  );
};

export default Slide1;

const BigTitle = styled.div`
  font-family: "Univers";
  font-size: 145px;
  line-height: 120px;
  color: #fff;
  text-shadow: 0 2px 15px rgba(0,0,0,0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  height: 100%;
  font-size: 70px;
  line-height: 64px;
  span {
    flex: 1 0 auto;
  }
  span:first-child {
    display: flex;
    align-items: flex-end;
  }
  @media ${device.tablet} {
    font-size: 70px;
  }
  @media ${device.desktop} {
    font-size: 145px;
    line-height: 120px;
    padding: 0 14rem;
  }
`

const Slide1Actions = styled.div`
  position: absolute;
  width: 100%;
  bottom: 50px;
`;

const Slide1Buttons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  a:last-child {
    max-width: inherit;
    width: 22rem;
  }
  @media (min-width: 1099px) {
    gap: 20px;
    position: absolute;
    top: -15px;
    transform: translateY(50%);
    right: 3%;
    a:last-child {
      max-width: inherit;
      width: 30rem;
    }
  }
`;

const MobileContainer = styled(Container)`
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0;
  height: ${props => `calc(100% - ${props.isiconfig})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .swiper-wrapper {
	height: 100%!important;
	justify-content: space-between;
  }

  .swiper-slide {
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
  }
  [class*="Reference__Container"] {
    margin-top: 1.7rem;
  }
`

const DesktopContainer = styled(Container)`
	height: 100%;
	max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
	justify-content: space-between;

	@media ${device.tablet} {
        max-height: ${(props) => `calc(97% - ${props.isiconfig})`};
    }

	@media (min-width: 680px) {
		max-height: ${(props) => `calc(96.5% - ${props.isiconfig})`};
	}

	@media (min-width: 780px) {
		max-height: ${(props) => `calc(96% - ${props.isiconfig})`};
	}

	@media ${device.desktop} {
        max-height: ${(props) => `calc(96% - ${props.isiconfig})`};
    }

	@media (min-width: 1763px) {
		max-height: ${(props) => `calc(97.5% - ${props.isiconfig})`};
	}

	.swiper-wrapper {
		height: 100%!important;

		&>div,
		&>div>div {
			height: 100%!important;
			justify-content: space-between;
		}
	}
`;

const Indication = styled.div`
    font-family: 'Gilroy', sans-serif;
    display: flex;
    flex-direction: column;
    background: #fff;
    color: #222222;
    width: 100%;
    padding-bottom: 4px;

    @media ${device.tablet} {
      padding: 1rem 0;
    }

    @media ${device.desktop} {
        // margin-top: 2rem;
        flex-direction: row;
        align-items: center;
        padding: 0;
        width: 100%;
        position: relative;
        max-width: 75rem;
        background: rgba(255, 255, 255, 0.55);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -9.98rem;
            width: 10rem;
            height: 100%;
            background: rgba(255, 255, 255, 0.55);
            clip-path: polygon(0 0, 50% 50%, 0 100%);
        }
    }

    @media ${device.desktop800h} {
        max-width: 50%;

        margin-top: 1.6rem;
        padding: 0.5rem 0.5rem 0.5rem 0;
    }
`;

const IndicationTag = styled(BaseText)`
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: min(1.15rem, 1.6rem);
  padding: 0;
  letter-spacing: 0.01rem;
  width: 4.6rem;
  margin-right: .5rem;
  margin-top: 1rem;
  // color: white;
  color: #3246FF;
  padding-left: .6rem;
  font-size: 10px;

  span {
    bottom: 0;
  }

  @media ${device.tablet} {
    font-size: 1.5rem;
    letter-spacing: .02rem;
  }

  @media ${device.desktop} {
    justify-content: center;
    font-size: 1.5rem;
    letter-spacing: .02rem;
    font-weight: 600;
    letter-spacing: .1rem;
    transform: rotate(-90deg) translateY(.5rem);
    left: 3rem;
    position: absolute;
    color: #3A3A3A;
    padding-left: 0;

    span {
      transform: none;
      position: initial;
    }
  }
    @media ${device.tablet} {
        font-size: 1.5rem;
        letter-spacing: 0.02rem;
    }

    @media ${device.desktop} {
        font-size: 1.5rem;
        letter-spacing: 0.02rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        transform: rotate(-90deg) translateY(0.5rem);

        span {
            transform: none;
            position: initial;
        }
    }
`;

const Indicationtext = styled.div`
    padding: 0.7rem 0.8rem 0.2rem .6rem;
    @media ${device.tablet} {
      padding: 0.7rem 1rem 0.7rem 2.5rem;
    }
    @media ${device.desktop} {
      padding: 0.7rem 1rem 0.9rem 8.5rem;
    }

    ${BaseText} {
        font-family: 'Barlow', sans-serif;
        font-size: min(1.05rem, 1.15rem);
        line-height: min(1.2rem, 1.25rem);
        margin-bottom: 0.5rem;
        font-weight: 400;
        color: white;
        color: #222;
        font-size: 9px;
        line-height: 12px;

        @media ${device.tablet} {
            font-size: 1.6rem;
            line-height: 1.8rem;
            max-width: 90%;
        }

        @media ${device.desktop} {
            padding: 0 1rem 0.7rem 0;
            font-size: 1.75rem;
            line-height: 2rem;
            max-width: 100%;
            margin-bottom: 0.5rem;
            color: #3A3A3A;
            &:last-of-type {
                margin-bottom: 0;
                padding: 0 1rem 0 0;
            }
        }
    }
`;

const Title = styled.div`
    font-family: 'Barlow Condensed', sans-serif;
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 50px;
    margin-left: 50px;
    line-height: 1;
    text-align: left;
    width: fit-content;

    @media ${device.tablet} {
      width: 100%;
    }

    @media ${device.desktop} {
        padding: 3rem 0 3rem 12.2rem;
        margin-top: 1.5rem;
        width: fit-content;
        justify-content: flex-start;
    }

    @media ${device.desktop800h} {
        margin-top: 3rem;
    }

    @media ${device.desktop600h} {
        margin-top: 1rem;
    }
`;
const Title1 = styled.p`
  font-family: 'Univers', sans-serif;
  // font-size: 3.7rem;
  font-size: 36px;
  letter-spacing: normal;
  line-height: 0.9;
  text-shadow: 0 .2rem 1.7rem rgba(0, 0, 0, 0.48);

  @media ${device.tablet} {
    font-size: 8rem;
    // letter-spacing: 2.8rem;
    // padding-left: 2rem;
    text-shadow: 0 0.2rem 1.7rem rgba(0, 0, 0, 0.48);
  }

  @media ${device.desktop} {
    font-size: 10rem;
    letter-spacing: 0;
    line-height: 0.8;
  }
`;
const Title2 = styled.p`
    font-family: 'Univers', sans-serif;
    font-weight: 400;
    // font-size: 5.6rem;
    font-size: 68px;
    line-height: 0.9;
    letter-spacing: normal;
    text-shadow: 0 0.2rem 1.7rem rgba(0, 0, 0, 0.48);

    @media ${device.tablet} {
        font-size: 10rem;
    }

    @media ${device.desktop} {
        font-size: 15rem;
        letter-spacing: 0.5rem;
        line-height: 0.8;
    }
`;
const Title3 = styled.p`
    font-family: 'Univers', sans-serif;
    // font-size: 3.8rem;
    font-size: 36px;
    letter-spacing: normal;
    line-height: 0.9;
    text-shadow: 0 0.2rem 1.7rem rgba(0, 0, 0, 0.48);

    @media ${device.tablet} {
        font-size: 8rem;
    }

    @media ${device.desktop} {
        line-height: 0.8;
        font-size: 6.3rem;
        letter-spacing: 0.275rem;
    }
`;

const CardBtn = styled(BaseLink)`
    font-family: 'Barlow';
    font-size: min(1.225rem, 1.4rem);
    position: relative;
    background: #3246ff;
    color: white;
    padding: min(0.8rem, 1rem);
    border-radius: 4rem;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    svg {
        right: 1.6rem;
        fill: white;
        position: absolute;
        transition: all 0.3s;
        font-size: min(1.2rem, 1.4rem);
    }

    &:hover {
        svg {
            right: 1rem;
        }
    }

    @media ${device.tablet} {
        max-width: min(20.8rem, 32rem);
        font-size: min(1.225rem, 1.4rem);
    }

    @media ${device.desktop} {
        max-width: min(20.8rem, 32rem);
        font-size: 1.4rem;
        width: 20.8rem;
        max-height: 3rem;

        svg {
            font-size: 1.6rem;
        }
    }

    @media ${device.desktop600h} {
        max-height: 3.4rem;
    }
`;

const NCCNReference = styled(Reference)`
    @media ${device.desktop} {
        padding-left: 14.5rem;
        bottom: 1.5rem;
    }
`
