import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Mousewheel, FreeMode } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import styled from 'styled-components/macro';
import useMobileDetect from '../../../../_hooks/useMobileDetect';


const PageScroll = ({children, recalculate, headerHeight}) => {
  const [translatePosition, setTranslatePosition] = useState(0)
  const { isMobile } = useMobileDetect()

  // Fix bug in the last page: When user is not in the top of the page and change the slide after scroll
  useEffect(()=>{
    const slide = document.querySelector('.mySwiper')
    const floatingWithIsi = document.querySelector('.floatingWithIsi')
    if(!slide) return

    if(floatingWithIsi) {
      if (translatePosition < -15) {
        floatingWithIsi.classList.add('hidden')
      } else {
        floatingWithIsi.classList.remove('hidden')
      }
    }

    if(translatePosition < 0 ) {
      slide.swiper?.mousewheel?.disable()
      return
    }

    setTimeout(()=> {
      slide.swiper?.mousewheel?.enable()
    }, 200)

  }, [translatePosition])
  
  return (
    <Container>
        <SwipperStyled 
          direction={"vertical"}
          slidesPerView={"auto"}
          freeMode={true}
          scrollbar={{
              enabled: true,
              draggable: true,
              snapOnRelease: false
          }}
          mousewheel={{
            enabled: true,
            sensitivity: .6
          }}
          nested={false}
          modules={[FreeMode, Scrollbar, Mousewheel]}
          className="mySwiper3"
          autoHeight={true}
          onSetTranslate={(swiper)=> setTranslatePosition(swiper.translate)}
          simulateTouch={isMobile}
        >
          <SwiperSlide>
            {children}
          </SwiperSlide>
        </SwipperStyled>
    </Container>
  )
}

export default PageScroll

const Container = styled.div`
  height: 100%;
`

const SwipperStyled = styled(Swiper)`
    height: 100%;
    
    &.swiper {
      width: 100%;
    }
    .swiper-container {
      max-height: 100%;
    }

    .swiper-slide {
      height: fit-content;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .swiper-scrollbar {
      z-index: 9999;
    }
    
    .swiper-pagination {
      pointer-events: all;
    }

`
