import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide, } from 'swiper/react';
import { Pagination, Mousewheel } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import styled from 'styled-components';
import { device } from '../../_assets/styles/theme';

const PageSwipper = ({ children, headerHeight, isiheight, nested = true, className = "mySwiper" }) => {
  const swiper = useSwiper()
  const swiperSlide = useSwiperSlide()
  const [updated, setUpdate] = useState(false)
  const pageSlider = document.querySelector('.mySwiper')?.swiper
  const scrollSlide = document.querySelector('.mySwiper3')?.swiper

  useEffect(() => {
    swiper?.update()

  }, [swiperSlide, swiper])

  useEffect(() => {
    if (!pageSlider) return

    if (pageSlider.isEnd) {
      scrollSlide.disable()

      setTimeout(() => {
        scrollSlide.enable()
      }, 500)
    }
  }, [pageSlider?.activeIndex])

  if (!children || children.length === 0 || children.length == 1) return null

  return (
    <>
      <SwipperStyled
        isiheight={isiheight}
        headerheight={headerHeight}
        direction={"vertical"}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        nested={nested}
        mousewheel={true}
        modules={[Pagination, Mousewheel]}
        className={className ? className : ""}
        longSwipes={false}
        longSwipesMs={300}
        autoHeight={true}
        observer={true}
        observeParents={true}
        onSlideChange={() => {
          // if (!'Cookiebot' in window || !'CookiebotDialog' in window) return;
          // if (!window.Cookiebot.hasResponse && window.CookiebotDialog && window.CookiebotDialog.consentLevel=='implied') {
          //   window.CookiebotDialog.submitConsent(true, document.location.href);
          // }
        }}
        onObserverUpdate={(x) => {
          // this function update the height in the last "slide" which contains the footer in desktop.
          // In the first load, sometimes the swiper calculates the wrong container height's
          if (x.isEnd && !updated) {
            setTimeout(() => {
              swiper?.update()
              setUpdate(true)
            }, 200)
          }
        }}
      >
        {children.map((element, index) => (
          <SwiperSlide key={index}>{element}</SwiperSlide>
        ))}

      </SwipperStyled>
    </>

  )
}

export default PageSwipper

const SwipperStyled = styled(Swiper)`
  &.swiper {
    width: 100%;
    height: calc(100vh - 4.9rem);
    height: calc(var(--screen-height) - 4.9rem);
    height: calc(var(--screen-height) - 3.6rem);


    @media ${device.desktop} {
      height: calc(100vh - 11.2rem);
      height: calc(var(--screen-height) - 11.2rem);

    }

    }
    .swiper-container {
      max-height: 100%;
    }
    .swiper-slide {
      background: transparent;
    }

    .swiper-pagination-bullet {
      height: 0.8rem;
      width: 0.8rem;
      border: 0.1rem solid #fff;
      opacity: 1;
      background: transparent;

      @media ${device.desktop} {
        height: 1.2rem;
        width: 1.2rem;
        border: 0.1rem solid #fff;

      }
    }

    .swiper-pagination-bullet-active {
      background: #fff;
    }

    .swiper-pagination {
      right: 0.4rem;



      @media ${device.desktop} {
        right: 4rem;
        top: calc(50% - 9.5rem);
        .swiper-pagination-bullet {
          margin: 1.6rem 0;
        }
      }
    }

`



