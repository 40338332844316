import { useState } from 'react'
import { motion } from 'framer-motion'

import styles from './Dropdown.module.scss'

export default function Dropdown({ title, links, width, url = "#" }) {
  const [isOpen, setIsOpen] = useState(false);
  const variants = {
    initial: {
      height: 0,
      overflow: 'hidden',
    },
    visible: {
      height: 'auto',
      transition: { ease: 'easeOut' }
    },
    hidden: {
      height: 0,
      overflow: 'hidden',
      transition: { ease: 'easeOut' }
    }
  }
  return (
    <div className={styles.menuItem} onMouseLeave={() => {
      setIsOpen(false)
    }}>
      <a className={styles.menuItemLink} href={url} onMouseEnter={() => {
        setIsOpen(true)
      }}>
        <span dangerouslySetInnerHTML={{ __html: title }}>
        </span>
        {links && (
          <img src="/assets/icons/icon-menu-dropdown-arrow.svg" alt="" />
        )}
      </a>

      {links && (
        <motion.div className={styles.subMenu} variants={variants} initial="initial" animate={isOpen ? 'visible' : 'hidden'} style={{ width: width }}>
          <div className={styles.subMenuWrapper}>
            {links.map((link, index) => (
              <a key={index} href={link.url} dangerouslySetInnerHTML={{ __html: link.text }}></a>
            ))}
          </div>
        </motion.div>
      )
      }
    </div >
  )
}
