import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// NSCLC (HCP folder)
import Home from "./HCP/Home";
import NCCN from "./HCP/NCCN";
import About from "./HCP/About";
import Testing from "./HCP/Testing";
import MOA from "./HCP/MOA";
import Study from "./HCP/Study";
import Efficacy from "./HCP/Efficacy";
import Safety from "./HCP/Safety";
import Dosing from "./HCP/Dosing";
// import Support from "./HCP/Support"
import Contact from "./HCP/Contact";
import Resources from "./HCP/Resources";
import Videos from "./HCP/Videos";
import Support from "./HCP/Support";
import References from "./HCP/References";
import StayConnected from "./HCP/StayConnected";
import ErrorPage from "./HCP/ErrorPage/ErrorPage";

// Landing page
import LandingPage from "./LandingPage";

// CRC
import CRCHome from "./CRC/CRCHome";
import CRCReferences from "./CRC/CRCReferences";
import CRCAboutKRASG12C from "./CRC/CRCAboutKRASG12C";
import CRCTestingKRASG12C from "./CRC/CRCTestingKRASG12C";
import CRCStudyDesign from "./CRC/CRCStudyDesign";
import CRCEfficacy from "./CRC/CRCEfficacy";
import CRCSafety from "./CRC/CRCSafety";
import CRCDosing from "./CRC/CRCDosing";
import CRCResources from "./CRC/CRCResources";
import CRCSupport from "./CRC/CRCSupport";

const AppRoutes = ({ setPageBackground }) => {
  return (
    <Routes>
      {/* Redirects */}
      <Route path="learnmore" element={<Navigate replace to="/" />} />
      <Route path="/about-kras-g12c" element={<Navigate replace to="/n/challenging-mutation" />} />
      <Route path="/testing" element={<Navigate replace to="/n/testing" />} />
      <Route path="/MOA" element={<Navigate replace to="/n/MOA" />} />
      <Route path="/study-design" element={<Navigate replace to="/n/study-design" />} />
      <Route path="/efficacy" element={<Navigate replace to="/n/efficacy" />} />
      <Route path="/safety" element={<Navigate replace to="/n/safety" />} />
      <Route path="/dosing" element={<Navigate replace to="/n/dosing" />} />
      <Route path="/resources" element={<Navigate replace to="/n/resources" />} />
      <Route path="/support" element={<Navigate replace to="/n/support" />} />
      <Route path="/stay-connected" element={<Navigate replace to="/n/stay-connected" />} />
      <Route path="/contact-us" element={<Navigate replace to="/n/contact" />} />
      <Route path="/references" element={<Navigate replace to="/n/references" />} />
      <Route path="/nccn-recommendations" element={<Navigate replace to="/n/nccn-recommendations" />} />
      <Route path="/videos" element={<Navigate replace to="/n/videos" />} />

      <Route path="/n/about-kras-g12c" element={<Navigate replace to="/n/challenging-mutation" />} />
      <Route path="/n/contact-us" element={<Navigate replace to="/n/contact" />} />

      {/* Landing Page */}
      <Route path="/" element={<LandingPage />} />

      {/* Routes NSCLC */}
      <Route path="/n/" element={<Home setPageBackground={setPageBackground} />} />
      <Route path="/n/nccn-recommendations" element={<NCCN setPageBackground={setPageBackground} />} />
      <Route path="/n/challenging-mutation" element={<About setPageBackground={setPageBackground} />} />
      <Route path="/n/testing" element={<Testing setPageBackground={setPageBackground} />} />
      <Route path="/n/MOA" element={<MOA setPageBackground={setPageBackground} />} />
      <Route path="/n/study-design" element={<Study setPageBackground={setPageBackground} />} />
      <Route path="/n/efficacy" element={<Efficacy setPageBackground={setPageBackground} />} />
      <Route path="/n/safety" element={<Safety setPageBackground={setPageBackground} />} />
      <Route path="/n/dosing" element={<Dosing setPageBackground={setPageBackground} />} />
      <Route path="/n/support" element={<Support setPageBackground={setPageBackground} />} />
      <Route path="/n/resources" element={<Resources setPageBackground={setPageBackground} />} />
      <Route path="/n/videos" element={<Videos setPageBackground={setPageBackground} />} />
      <Route path="/n/stay-connected" element={<StayConnected setPageBackground={setPageBackground} />} />
      <Route path="/n/contact" element={<Contact setPageBackground={setPageBackground} />} />
      <Route path="/n/references" element={<References />} />

      {/* Routes CLC */}
      <Route path="/c" element={<CRCHome />} />
      <Route path="/c/references" element={<CRCReferences />} />
      <Route path="/c/challenging-mutation" element={<CRCAboutKRASG12C />} />
      <Route path="/c/testing" element={<CRCTestingKRASG12C />} />
      <Route path="/c/study-design" element={<CRCStudyDesign />} />
      <Route path="/c/efficacy" element={<CRCEfficacy />} />
      <Route path="/c/safety" element={<CRCSafety />} />
      <Route path="/c/dosage" element={<CRCDosing />} />
      <Route path="/c/resources" element={<CRCResources />} />
      <Route path="/c/support" element={<CRCSupport />} />

      {/* 404 */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AppRoutes;
