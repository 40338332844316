import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import {  useSwiperSlide, useSwiper } from 'swiper/react';
import { BaseText, Box, Container } from '../../_components/PageSwipper/utils/styles';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { useNavigate } from 'react-router-dom';
import { allReferences } from '../../_components/Modal/ReferenceModal';

const Slide1 = ({setPageBackground, setShowHeaderSecondaryMenu,setCurrentSlide, isiconfig, setBackground, setOverlay}) => {
  const navigate = useNavigate()
  const swiperSlide = useSwiperSlide()
  const swiper = useSwiper()
  const { isMobile } = useMobileDetect()

  const [showPageContent, setShowPageContent] = useState(false)
  const mobileOverlay = 'transparent'
  const desktopOverlay = 'transparent'
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay

  const slideBackground = `linear-gradient(173.26deg, rgba(173, 192, 214, 0.7) 0%, rgba(148, 142, 160, 0.1) 35.48%, rgba(115, 115, 138, 0.1) 68.95%, rgba(102, 105, 134,0.1) 100%) no-repeat`
  // useSwipeBackground(swiperSlide.isActive, slideBackground, setPageBackground)

  useEffect(()=> {
    if(window.location.hash === '#kolvideo1') {
      swiper.slideTo(1)
    }
  }, [])

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setCurrentSlide(1)
      setShowPageContent(true)
      setBackground(slideBackground)
      setOverlay(slideOverlay)
      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])


  return (
    <>
    {!isMobile && <Overlay />}
    <Container className={showPageContent ? 'visible' : 'hidden'}>
      <Start isiconfig={isiconfig}>
        <RefereceTitleWrapper>
          <BaseText color="#3A3A3A" size={isMobile ? 2 : 3.3} weight="bold">References:</BaseText>
          <button onClick={()=> navigate(-1)}><img src="/assets/icons/menu _ close_small.svg" alt="" /></button>
        </RefereceTitleWrapper>

        <ReferencesList>
          {allReferences.map((reference, index) =>
            <ReferenceList key={reference + index}>{reference}</ReferenceList>
          )}
        </ReferencesList>
      </Start>
    </Container>

    </>
  )
}

export default Slide1

const Start = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: ${props => `calc(100% - ${props.isiconfig.mobile}rem)`};

  @media ${device.tablet} {
    align-items: initial;
    max-height: ${props => `calc(100% - ${props.isiconfig.tablet}rem)`};
  }

  @media ${device.desktop} {
    align-items: initial;
    max-height: ${props => `calc(100% - ${props.isiconfig.desktop}rem)`};
    max-width: 110rem;
    margin: 0 auto;
  }

  @media ${device.desktop800h} {
    align-items: initial;
    max-height: ${props => `calc(100% - ${props.isiconfig.thinkpad}rem)`};
  }

`

const RefereceTitleWrapper = styled(Box)`
  padding: 3.2rem 0.8rem 0.8rem;
  justify-content: space-between;
  align-items: flex-end;
  button {
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    top: -1.5rem;
    img {
      width: 4rem;
      height: 4rem;
    }
  }

  @media ${device.tablet}{
    padding: 3.2rem 4rem 0.8rem;
  }


  @media ${device.desktop}{
    padding: 5.6rem 0 4rem;
  }

`

const ReferencesList = styled.ol`
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
  padding: 0 0.8rem;

  @media ${device.tablet}{
    padding: 0 4rem;
  }

  @media ${device.desktop}{
    padding: 0 0 3rem;
  }
`

const ReferenceList = styled.li`
  color: #3A3A3A;
  font-family: 'Barlow', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4rem;
  margin-bottom: .5rem;
  padding: 0 0 0 2em;
  list-style-type: none;
  counter-increment: item;
  position: relative;

  &::before {
    position: absolute;
    display: inline-block;
    left: 0;
    font-weight: bold;
    text-align: left;
    content: counter(item) ".";
  }

  @media ${device.desktop} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  right: 0;
  top: -11.2rem;
  z-index: -1;
  height: 24rem;
  background: linear-gradient(180deg,rgb(29,51,139,.6) 0%,rgba(145,145,145,0) 100%);
`
