import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import ISICollapsing from '../_components/ISI/Collapsing';
import Slide3 from './pages/Slide3';
import useMobileDetect from '../../_hooks/useMobileDetect';
import { isiConfig } from '../_components/ISI/config';
import { Page } from '../_components/PageSwipper/utils/styles';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import { PageOverlay, StaticContentWrapper } from '../Home';
import StaticIsi from '../_components/ISI/StaticIsi';
import Footer from '../_components/Footer';
import MetaTags from '../_components/MetaTags/MetaTags';

const Study = ({ setPageBackground }) => {
	const { isMobile } = useMobileDetect()
	const [headerRef, setHeaderRef] = useState(null)
	const [headerHeight, setHeaderHeight] = useState(0)
	const [renderPage, setRenderPage] = useState(false)
	const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
	const [currentSlide, setCurrentSlide] = useState(1)
	const [background, setBackground] = useState(`url('/assets/bg-study.webp') no-repeat fixed center top / cover`)
	const [overlay, setOverlay] = useState(null)
	const overlayRef = useRef()

	useEffect(() => {
		if (headerRef?.current) {
			setHeaderHeight(headerRef?.current.clientHeight)
			setRenderPage(true)
		}
	}, [headerRef?.current])

	useEffect(() => {
		overlayRef.current.classList.remove('visible')
		overlayRef.current.classList.add('hidden')

		const time = setTimeout(() => {
			overlayRef.current.classList.add('visible')
			overlayRef.current.classList.remove('hidden')
		}, 100)

		return () => {
			clearTimeout(time)
		}
	}, [currentSlide])


	return (
		<>
			<MetaTags
				title='KRAZATI® (adagrasib) | KRYSTAL-1 Study Design'
				description='Examine findings from a single-arm, open-label study regarding the safety and efficacy of a treatment.'
				ogUrl='https://www.krazatihcp.com/n/study-design'
			/>
			<Page background={background}>
				<PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
				<PageScroll>
					<Container>
						<Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
						<div style={{ position: 'relative' }}>
							{renderPage && (
								<PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
									<Slide1
										isiconfig={isiConfig.collapsed}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
									/>
									<Slide2
										isiconfig={isiConfig.floating}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
									/>
									<Slide3
										isiconfig={isiConfig.floating}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
									/>
								</PageSwipper>
							)}

							{!isMobile && currentSlide !== 3 && <ISICollapsing className={currentSlide === 2 ? 'collapse' : 'visible'} />}
							{!isMobile && currentSlide === 3 && <ISICollapsing className="floatingWithIsi collapse" />}

							{isMobile && <FloatingIsi className={currentSlide === 2 || currentSlide === 3 ? 'collapse' : 'visible'} />}
						</div>

						{!isMobile && currentSlide === 3 &&
							<StaticContentWrapper id="static-content">
								<StaticIsi />
								<Footer translate={true}></Footer>
							</StaticContentWrapper>
						}
					</Container>
				</PageScroll>
			</Page>
		</>
	)
}

export default Study

const Container = styled.div`
  max-height: 100%;
  height: 100%;
`
