import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import {  useSwiperSlide } from 'swiper/react';
import { BaseTitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';

const Slide1 = ({setShowHeaderSecondaryMenu, page, setCurrentSlide, isiconfig,setBackground, setOverlay}) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const mobileOverlay = ''
  const [showPageContent, setShowPageContent] = useState(false)
  const desktopOverlay = 'linear-gradient(165deg, rgba(49, 78, 119, 0.2854) 4.22%, rgba(22, 38, 66, 0.2854) 90.76%)'
  const slideOverlay = desktopOverlay
  const slideBackground = isMobile ? `url('/assets/study-design-bg.png') no-repeat fixed 15% center / cover` : `url('/assets/study-design-bg.png') no-repeat fixed 20% top / cover`

  useActiveLink(setShowHeaderSecondaryMenu, 'efficacy')

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setCurrentSlide(1)
      setShowPageContent(true)
      setBackground(slideBackground)
      setOverlay(slideOverlay)
      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])


  return (
    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <CustomWrapper>
          <Title>
            <Title1>KRYSTAL-1 Efficacy</Title1>
            <Title2>Scroll to see the efficacy of KRAZATI.</Title2>
          </Title>
        </CustomWrapper>
      <ScrollIcon marginBottom={isMobile ? "2rem" : "3.6rem"} />
    </CustomContainer>
  )
}

export default Slide1

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-height: ${props => `calc(100% - ${props.isiconfig} - 10rem)`};
`
const CustomWrapper = styled(Wrapper)`
  padding: 0 2.2rem;
  margin-top: 0;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
  @media ${device.desktop} {
    margin-bottom: 1rem;
  }
`
const Title1 = styled(BaseTitle)`
  margin-bottom: 1.6rem;
  padding: 0 3rem;

  @media ${device.desktop} {
    margin-bottom: 2rem;
  }
`
const Title2 = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: 1.7rem;
  span {
    color: #17C94E;
    font-weight: bold;
  }

  @media ${device.desktop} {
    font-size: 2.4rem;
  }
`
