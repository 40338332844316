import CRCLayout from "./layouts/CRCLayout";
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import Container from "../_common/components/Layout/Container"
import MetaTags from "../_common/components/MetaTags";
import styles from './CRCSupport.module.scss'

export default function CRCSupport() {
  return (
    <CRCLayout>
      <MetaTags
        title='Patient Support Resources'
        description="Explore support programs and resources available to patients prescribed Krazati®."
        ogUrl='https://www.krazatihcp.com/c/support'
      />
      {/* Banner */}
      <InteriorBanner title="BMS Access Support" />

      <MainContent>

        <div className={styles.support}>
          <Container>
            <h2 className={styles.pageHeading}>At BMS, we provide support with purpose. </h2>
            <p>BMS Access Support is dedicated to helping patients access their prescribed BMS medication. We may offer benefit investigations, prior authorizations assistance, appeals process support, and information on financial support options. The BMS Access Support Co-Pay Assistance Program assists with out-of-pocket co-payment or co-insurance requirements for eligible, commercially-insured patients who have been prescribed certain BMS products, including KRAZATI®.</p>

            <div className={styles.calloutWrapper}>
              <h2 className={styles.calloutWrapper.h2}>For more information, visit <a href="https://www.bmsaccesssupport.com/" target="_blank" rel="noreferrer">www.BMSAccessSupport.com</a>, or call BMS Access Support at <a href="tel:8008610048">1-800-861-0048</a>, 8 AM to 8 PM ET, Monday - Friday.</h2>
            </div>
          </Container>
        </div>

      </MainContent >
    </CRCLayout >
  )
}
