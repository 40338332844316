import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro'
import { BaseText } from '../PageSwipper/utils/styles';
import { FaArrowRight } from 'react-icons/fa'
import { device } from '../../_assets/styles/theme';

const ReferenceFooterWithLink = ({margin, align,children, url, linkText, variant = '', ...props}) => {

  const italics = linkText == 'About KRAS G12C';

  return (
    <SliderFooter className="footer-with-link-container" margin={margin} align={align}>
        <Reference variant={variant} {...props} >
          {children}
        </Reference>

        <CustomLink variant={variant} to={url}>
          { italics ? <>About&nbsp; <i>KRAS G12C</i></> : linkText }
          <FaArrowRight />
        </CustomLink>
    </SliderFooter>
  )
}

export default ReferenceFooterWithLink

const SliderFooter = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: ${props => props.align || 'flex-end'};
  margin: ${props => props.margin || 0};
  width: 100%;
  pointer-events: all;

  @media (max-width: 1024px) {
	flex-direction: column-reverse;
	justify-content: flex-end;
	align-items: center;
  }
`
const Reference = styled(BaseText)`
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  color: ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
  padding: 15px 0.8rem;


  @media ${device.tablet} {
    font-size: 1.2rem;
  }

  @media ${device.desktop} {
    padding-left: 5.6rem;
    font-size: 1.2rem;
  }

  @media ${device.desktop600h} {
    font-size: 1.4rem;
  }

`

const CustomLink = styled(Link).attrs({
  className: 'swiper-no-swiping'
})`
  display: flex;
  align-items: center;
  border: 2px solid ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
  font-family: 'Barlow',sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
  padding: 0.3rem 1rem;
  border-color: #17C94E;
  border-radius: 30px;
  text-transform: uppercase;
  svg {
    margin-left: 1rem;
    fill: ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
    font-size: 1.2rem;
		margin-top: 2px;
  }

  @media ${device.tablet} {

    svg {
      font-size: 1.4rem;
      transition: transform .6s;
    }

    &:hover {
      svg {
        transform: translateX(0.3rem);
      }
    }
  }

  @media ${device.desktop} {
	margin-right: 5.6rem;
    transform: translateY(-1rem);
    svg {
      font-size: 1.4rem;
    }
  }

  @media ${device.desktop800h} {
    font-size: 1.5rem;
  }
`
