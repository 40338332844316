import { useState } from 'react'
import { motion } from 'framer-motion'
import Container from "../../_common/components/Layout/Container";

import styles from './JumpMenu.module.scss'
import useWindowSize from '../../_hooks/useWindowSize';
import ContentContainer from '../../_common/components/Layout/ContentContainer';

export default function JumpMenu({ title, links, desktopLinks, spacing = 'flex-start' }) {
  const winWidth = useWindowSize();
  const [menuOpen, setMenuOpen] = useState(false)
  const variants = {
    initial: {
      height: 0,
      overflow: 'hidden'
    },
    visible: {
      height: 'auto',
      transition: { ease: 'easeOut' }
    },
    hidden: {
      height: 0,
      overflow: 'hidden',
      transition: { ease: 'easeOut' }
    }
  }

  if (winWidth <= 1024) {
    return (
      <div className={styles.jumpMenu}>
        <div className={styles.jumpMenuToggle} onClick={() => {
          setMenuOpen(!menuOpen)
        }}>
          <Container>
            <span>JUMP TO</span>
            <img className={!menuOpen ? `${styles.jumpMenuToggleClosed}` : ''} src="/assets/icons/icon-jump-menu-arrow-up.svg" alt="" />
          </Container>
        </div>
        <motion.div variants={variants} initial="initial" animate={menuOpen ? 'visible' : 'hidden'}>
          <div className={`${styles.jumpMenuContent}`}>
            <Container>
              <div className={styles.jumpMenuTitle}>
                <span dangerouslySetInnerHTML={{ __html: title }}></span>
                <img src="/assets/icons/icon-jump-menu-arrow.svg" alt="" />
              </div>
              <div className={styles.jumpMenuLinks}>
                <ul>
                  {links && links.map((link, index) => (
                    <li key={index}>
                      <a href={link.url}>
                        {/* {link.text} */}
                        <span dangerouslySetInnerHTML={{ __html: link.text }}></span>
                        <img src="/assets/icons/icon-jump-menu-link-down-arrow.svg" alt="" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Container>
          </div>
        </motion.div>
      </div >
    )
  }
  return (
    <div className={`${styles.jumpMenu} ${styles.jumpMenuDesktop}`}>
      <div className={`${styles.jumpMenuContent}`}>
        <ContentContainer>
          <div className={styles.jumpMenuTitle}>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
            <img src="/assets/icons/icon-jump-menu-arrow.svg" alt="" />
          </div>
          <div className={styles.jumpMenuLinks}>
            <span>JUMP TO:</span>
            <ul className={`${spacing}`}>
              {desktopLinks ?
                desktopLinks && desktopLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.url}>
                      <span dangerouslySetInnerHTML={{ __html: link.text }}></span>
                      <img src="/assets/icons/icon-jump-menu-link-down-arrow.svg" alt="" />
                    </a>
                  </li>
                ))
                :
                links && links.map((link, index) => (
                  <li key={index}>
                    <a href={link.url}>
                      <span dangerouslySetInnerHTML={{ __html: link.text }}></span>
                      <img src="/assets/icons/icon-jump-menu-link-down-arrow.svg" alt="" />
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        </ContentContainer>
      </div>
    </div>
  )
}
