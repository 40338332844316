import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Mousewheel, FreeMode } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import styled from 'styled-components/macro';


const ContainerScroll = ({ children }) => {

  return (
    <Container>
      <SwipperStyled
        direction={"vertical"}
        slidesPerView={"auto"}
        freeMode={true}
        scrollbar={{
          enabled: true,
          draggable: true
        }}
        mousewheel={true}
        nested={true}
        modules={[FreeMode, Scrollbar, Mousewheel]}
        className="mySwiper2 container-scroll"
        autoHeight={true}
      >
        <SwiperSlide>
          {children}
        </SwiperSlide>
      </SwipperStyled>
    </Container>
  )
}

export default ContainerScroll

const Container = styled.div`
  height: 100%;
`

const SwipperStyled = styled(Swiper)`
    &.container-scroll {
      .swiper-scrollbar {
        display: none;
      }
    }

    height: 100%;

    &.swiper {
      width: 100%;
    }
    .swiper-container {
      max-height: 100%;
    }

    .swiper-slide {
      height: fit-content;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

`
