import { useState } from 'react'
import { motion } from 'framer-motion'
import useWindowSize from "../../_hooks/useWindowSize"
import Container from "../../_common/components/Layout/Container"

import styles from "./Navigation.module.scss"
import { useLocation } from 'react-router-dom'

export default function Navigation() {
  let location = useLocation()
  const winWidth = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)

  const mobileVariants = {
    initial: {
      height: '0px',
      overflow: 'hidden'
    },
    visible: {
      height: 'auto',
      transition: { ease: 'easeOut' }
    },
    hidden: {
      height: 0,
      transition: { ease: 'easeOut' }
    }
  }

  const toggleMobileMenu = () => {
    setIsOpen(!isOpen)
  }

  if (winWidth <= 1024) {
    return (
      <div className={`${styles.mobileNavigation} ${isOpen ? `${styles.mobileOpen}` : ''}`}>
        <header>
          <div className={styles.mobileLogo}>
            <img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/logo/logo-landing-page.png" alt="KRAZATI - adagrasib 200 mg Tablets Logo" />
            <img className={!isOpen ? `${styles.hideLogo}` : ''} src="/assets/logo/logo-landing-page-invert.png" alt="KRAZATI - adagrasib 200 mg Tablets Logo" />
          </div>
          <div className={`${styles.menuToggle} ${isOpen ? `${styles.mobileToggleOpen}` : ''}`} onClick={() => {
            toggleMobileMenu();
          }}>
            <img className={`${!isOpen ? `${styles.hideLogo}` : ''}`} src="/assets/icons/icon-menu-toggle.svg" alt="Open mobile menu" />
            <img className={`${isOpen ? `${styles.hideLogo}` : ''}`} src="/assets/icons/icon-menu-toggle-blue.svg" alt="Open mobile menu" />
          </div>
          <motion.div className={styles.mobileLinks} variants={mobileVariants} initial="initial" animate={isOpen ? "visible" : "hidden"}>
            <a href={`${location.pathname}#important-safety-information`} target="_self">Important Safety Information</a>
            <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
            <a href="https://www.krazati.com/c" target="_blank" rel="noreferrer">Visit Patient Site</a>
          </motion.div>
        </header>
      </div>
    )
  }
  return (
    <div className={styles.navigation}>
      <Container>
        <header>
          <div className={styles.logo}>
            <img src="/assets/logo/logo-landing-page.svg" alt="KRAZATI - adagrasib 200 mg Tablets Logo" />
          </div>
          <div className={styles.links}>
            <a href={`${location.pathname}#important-safety-information`} target="_self">Important Safety Information</a>
            <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
            <a href="https://www.krazati.com/c" target="_blank" rel="noreferrer">Visit Patient Site</a>
          </div>
        </header>
      </Container>
    </div>
  )
}
