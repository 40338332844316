import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from './_common/assets/styles/theme'
import styled from 'styled-components'
import AppRoutes from './routes';
import { useState } from 'react';
import BMSModal from './HCP/_components/Modal/BMSModal';
import { useLocation } from 'react-router-dom';
import './App.css'
// import HealthCare from './HCP/_components/Modal/HealthCare';

function App() {
  const location = useLocation();
  const [pageBackground, setPageBackground] = useState('#FFF');

  // override overflow on html that is required for the NSCLC
  // side of the website. remove once we rebuild NSCLC side.
  useEffect(() => {
    let spath = window.location.pathname.split('/');
    if (spath[1] !== 'n') {
      document.querySelector('html').style.overflow = 'visible';
    } else {
      document.querySelector('html').style.overflow = 'hidden';
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <AppContainer style={{ background: `${pageBackground}` }}>
        <AppRoutes setPageBackground={setPageBackground} />
        {/* below is the attestation popup. Disabled on 4/5/23. */}
        {/* <HealthCare /> */}
        <BMSModal />
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
