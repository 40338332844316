import { useEffect, useState } from 'react';

const useMobileDetect = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isThinkpad, setIsThinkpad] = useState(false);
    const [isTabletAndLaptop, setIsTabletAndLaptop] = useState(false);

    useEffect(() => {
        const matchMobile = window.matchMedia('(max-width: 1099px)');
        const matchThinkpad = window.matchMedia('(min-width: 1100px) and (max-height: 800px)');
        const matchTabletAndLaptop = window.matchMedia('(min-width: 700px) and (max-width: 1100px)');

        setIsMobile(matchMobile && matchMobile.matches);
        setIsThinkpad(matchThinkpad && matchThinkpad.matches);
        setIsTabletAndLaptop(matchTabletAndLaptop && matchTabletAndLaptop.matches);

        window.addEventListener('resize', () => {
            document.documentElement.style.setProperty('--screen-height', `${window.innerHeight - 1}px`);

            setIsMobile(matchMobile && matchMobile.matches);
            setIsThinkpad(matchThinkpad && matchThinkpad.matches);
            setIsTabletAndLaptop(matchTabletAndLaptop && matchTabletAndLaptop.matches);
        });

        return () => {
            window.removeEventListener('resize', () => {
                document.documentElement.style.setProperty('--screen-height', `${window.innerHeight - 1}px`);

                setIsMobile(matchMobile && matchMobile.matches);
                setIsThinkpad(matchThinkpad && matchThinkpad.matches);
                setIsTabletAndLaptop(matchTabletAndLaptop && matchTabletAndLaptop.matches);
            });
        };
    }, []);

    return { isMobile, isTabletAndLaptop, isThinkpad };
};

export default useMobileDetect;
