import React from 'react';
import { Swiper, SwiperSlide,  } from 'swiper/react';
import { Scrollbar, Mousewheel, FreeMode } from "swiper";

// Import Swiper styles
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import styled from 'styled-components';


const HorizontalScroll = ({children, className}) => {
  return (
    <>
        <SwipperStyled
          className={`${className} swiper-h` } 
          direction={'horizontal'}
          modules={[FreeMode, Scrollbar, Mousewheel]}
          slidesPerView={'auto'}
          scrollbar={{
              enabled: true,
              draggable: true
          }}
          mousewheel={true}
          freeMode={true}
          nested={true}
        >
            {children.map((child, index) => (
                <SwiperSlide key={index}>{child}</SwiperSlide>
            ))}
        </SwipperStyled>
    </>
  )
}

export default HorizontalScroll

const SwipperStyled = styled(Swiper)`
  &.swiper-h {
    .swiper-slide {
      pointer-events: all !important;
    }
  }
  &.swiper {
    width: 100%;
    }
 
    .swiper-slide {
      background: transparent;
      flex-basis: fit-content;
      height: fit-content;
      width: fit-content;
    }


    .swiper-pagination-bullet {
      height: 1.2rem;
      width: 1.2rem;
      border: 0.1rem solid #fff;
      opacity: 1;
      background: transparent;
    }

    .swiper-pagination-bullet-active {
      background: #fff;
    }

    .swiper-pagination {
      right: 4rem;
    }
`