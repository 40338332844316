import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { FaChevronRight } from 'react-icons/fa';

import { BaseLink, BaseSubtitle, BaseText, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { Link } from 'react-router-dom';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import '../css/style.scss';

const Slide2 = ({ handleShowModal, setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setPageGradient, setBackground, setOverlay }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const mobileOverlay = 'linear-gradient(180deg, rgba(49, 78, 119, .2382) 4.22%, rgba(22, 38, 66, .2382) 90.76%)';
    const desktopOverlay = 'linear-gradient(180deg, rgba(49, 78, 119, .2382) 4.22%, rgba(22, 38, 66, .2382) 90.76%)';
    const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
    const slideBackground = `url(${isMobile ? '/assets/nccn-bg-ocean-m-slide2.png' : '/assets/nccn-bg-ocean.png'}) no-repeat fixed 40% center / cover`;

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setPageGradient('');
            setCurrentSlide(2);
            setShowPageContent(true);
            setBackground(slideBackground);
            setOverlay(slideOverlay);
            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
          <Scroll>
            <ContainerScroll>
              <CustomWrapper>
                <div className='nccn-slide2-content'>
                    <div className='rec-card'>
                        <div className='text'>
                            <div className="card-title">Adagrasib (KRAZATI) is included as a treatment option for NSCLC in the NCCN Guidelines<sup onClick={() => handleShowModal([1])}>1</sup></div>
                            <div className="other">
                              <div className="card-rec">CATEGORY 2A NCCN RECOMMENDED</div>
                              <div className="white-bar">Advanced NSCLC</div>
                              <div className="card-footer">Consider adagrasib (KRAZATI) as a subsequent therapy option for patients with <em>KRAS G12C</em>-mutated advanced or metastatic NSCLC after progression (category 2A).<sup onClick={() => handleShowModal([1])}>1</sup></div>
                              <div className="card-bottom">Recommendation received December 2022.<sup onClick={() => handleShowModal([3])}>3</sup></div>
                            </div>
                        </div>
                    </div>
                    <div className='rec-card'>
                        <div className='text'>
                            <div className="card-title">Adagrasib (KRAZATI) is included as a treatment option for NSCLC CNS metastases in the NCCN Guidelines for CNS Cancers<sup onClick={() => handleShowModal([2])}>2</sup></div>
                            <div className="other">
                              <div className="card-rec">CATEGORY 2A NCCN RECOMMENDED</div>
                              <div className="white-bar">CNS Cancers</div>
                              <div className="card-footer">Consider adagrasib (KRAZATI) as a systemic therapy option for patients with <em>KRAS G12C</em>-mutated advanced NSCLC with brain metastases (category 2A).<sup onClick={() => handleShowModal([2])}>2</sup></div>
                              <div className="card-bottom">Recommendation received March 2023.<sup onClick={() => handleShowModal([2])}>2</sup></div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/about-kras-g12c'} linkText="About KRAS G12C">
                    <p>CNS=central nervous system; NCCN=National Comprehensive Cancer Network; NSCLC=non-small cell lung cancer.</p>
                </ReferenceFooterWithLink>
              </CustomWrapper>
            </ContainerScroll>
          </Scroll>
        </CustomContainer>
    );
};

export default Slide2;

const Scroll = styled.div`
  height: 100%
`;

const CustomWrapper = styled(Wrapper)`
  height: calc(100% - 17.5rem);
  margin-top: 1.6rem;

  @media ${device.desktop} {
    height: 100%;
    margin-top: 1.6rem;
    gap: 100px;
  }
`;

const CustomContainer = styled(Container)`
    display: flex;
    height: ${props => `calc(100% - ${props.isiconfig})`};
    .nccn-slide2-content {
      height: 100%;
      flex-direction: column;
      flex: 1 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 28px;
      margin-bottom: 1.5rem;
      .rec-card {
        width: 95%;
        position: relative;
        color: white;
        background: rgba(0,0,0,0.5536);
        font-size: 2rem;
        font-family: 'Oswald', sans-serif;
        mix-blend-mode: multiply;
        height: 100%;
        &:first-of-type {
          margin-top: 28px;
        }
        .card-title {
          margin: 24px 31px 0;
          padding-bottom: 1rem;
          border-bottom: 2px solid #17C94E;
          font-weight: 500;
          sup {
            font-size: 1rem;
          }
        }
        .card-rec {
          font-size: 1.75rem;
          font-family: 'Barlow', sans-serif;
          font-weight: 800;
          margin: 1rem 31px;
        }
        .white-bar {
          font-family: 'Barlow', sans-serif;
          background: white;
          width: 100%;
          color: #3246FF;
          font-weight: 800;
          position: relative;
          margin-bottom: 1rem;
          padding: 0 31px;
        }
        .card-footer,
        .card-bottom {
          margin: 0 31px;
          padding-bottom: 4rem;
          font-family: 'Barlow', sans-serif;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: normal;
        }
        .card-footer {
          min-height: 10rem;
        }
      }
    }
    @media ${device.desktop} {
      padding: 0 5rem;
      overflow-y: visible;

      max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
      .nccn-slide2-content {
        flex-direction: row;
        max-width: 70%;
        margin: auto;
        .card-title {
          height: 40%;
        }
        .other {
          height: 60%;
        }
        .rec-card {
          flex: 1;
          display: flex;
          &:first-of-type {
            margin-top: 0;
          }
          .text {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .swiper-wrapper {
        height: 100% !important;
        justify-content: center;
      }
    }
    [class*="ReferenceWithLink__SliderFooter"] {
      flex-direction: column;
      text-shadow: #000000 0px 0px 12px, #000000 0px 0px 2px;
      margin-bottom: 1.5rem;
      @media ${device.desktop} {
        flex-direction: row;
      }
    }
`;
