import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseLink, BaseText, Box, StyledLeftSideShape, StyledRightSideShape, Container } from '../../_components/PageSwipper/utils/styles';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import { Title1 } from './Slide2';
import { CustomLink, Wrapper } from '../../Efficacy/components/Tab1';
import { FaArrowRight } from 'react-icons/fa';
import DosingTabs from '../components/Tabs/DosingTabs';
import { Banner, BannerContainer } from '../../Efficacy/components/Tab2';

import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import Leaving from '../../_components/Modal/Leaving';
import style from '../css/slide-3.module.scss';
import ArrowCallout from '../../_components/Shapes/ArrowCallout';

const Tab1 = () => {
    return (
        <StyledRightSideShape className='dosing-tab' background='linear-gradient(167deg, #5A566A, #3E3E56) ' border='white' borderSize={0.1}>
            <Box direction='column' padding='1.4rem 5.2rem'>
                <TabHeader>400 mg Twice Daily</TabHeader>

                <TabBox>
                    <TabWrapper>
                        <div className='top'></div>
                        <img src='/assets/sun-capsules.webp' alt='Amount of capsules in first dose - Morning' />
                        <BaseText size={1.2} style={{ marginTop: '0.8rem' }}>
                            (2) 200-mg tablets*
                        </BaseText>
                    </TabWrapper>
                    <TabWrapper>
                        <img src='/assets/moon-capsules.webp' alt='Amount of capsules in second dose - Night' />
                        <BaseText size={1.2} style={{ marginTop: '0.8rem' }}>
                            (2) 200-mg tablets*
                        </BaseText>
                    </TabWrapper>
                </TabBox>
            </Box>
        </StyledRightSideShape>
    );
};

const Tab2 = () => {
    return (
        <StyledRightSideShape className='dosing-tab' background='linear-gradient(167deg, #5A566A, #3E3E56) ' border='white' borderSize={0.1}>
            <Box direction='column' padding='1.4rem 5.2rem'>
                <TabHeader>600-mg ONCE Daily</TabHeader>

                <TabBox className='center'>
                    <TabWrapper>
                        <img src='/assets/once-daily.svg' alt='Amount of capsules in first dose - Morning' />
                        <BaseText text-align='center' size={1.2} style={{ marginTop: '1.4rem' }}>
                            (3) 200 mg tablets*
                        </BaseText>
                    </TabWrapper>
                </TabBox>
            </Box>
        </StyledRightSideShape>
    );
};

const Slide3 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = ` linear-gradient(173deg, #50527A -2.67%, #4A4E61 22.8%, #2F314A 96.29%)`;

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(3);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    const items = [
        {
            title: 'First dosage reduction',
            component: <Tab1 />,
        },
        {
            title: 'Second dosage reduction',
            component: <Tab2 />,
        },
    ];

    return (
        <Scroll isiconfig={isiconfig}>
            <ContainerScroll>
                <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
                    <CustomWrapper>
                        <Title>
                            <Title1>
                                Krazati Dosage Modifications<sup onClick={() => handleShowModal([30])}>30</sup>
                            </Title1>
                        </Title>

                        <Infos>
                            <BaseText size={isMobile ? 1.3 : 1.6} fontFamily='Barlow' weight={isMobile ? 500 : 600}>
                                <span>
                                    If adverse reactions (ARs) occur, a maximum of 2 dose reductions are permitted. Permanently discontinue KRAZATI in patients who are unable to
                                    tolerate 600 mg once daily.
                                </span>
                            </BaseText>
                        </Infos>
                        <Infos>
                            <BaseText className="rec-dosage">
                                Recommended dosage: 600 mg orally | 2x daily
                            </BaseText>
                        </Infos>

                        <DoseReductionContainer>
                            <StyledLeftSideShape background='#2F2F3C' border='white' borderSize={0.1}>
                                <Box direction='column' padding={isMobile ? '20px' : '30px'} align='center'>
                                    <Box direction={!isMobile ? 'row' : 'column'} gap='20px' align={!isMobile ? 'center' : 'start'} justify='space-between'>
                                        <div className={style['dose-item']}>
                                            <div className={style.image}>
                                                <img src={require('../img/1.svg').default} alt='First Dose Reduction' />
                                            </div>
                                            <div className={style['dose-text']}>
                                                <span className={style['heading']}>FIRST DOSE REDUCTION</span>
                                                <span className={style['amount']}>400 MG TWICE DAILY</span>
                                                (2) 200-mg tablets
                                            </div>
                                        </div>
                                        <div className={style['dose-item']}>
                                            <div className={style.image}>
                                                <img src={require('../img/2.svg').default} alt='Second Dose Reduction' />
                                            </div>
                                            <div className={style['dose-text']}>
                                                <span className={style['heading']}>SECOND DOSE REDUCTION</span>
                                                <span className={style['amount']}>600 MG ONCE DAILY</span>
                                                (3) 200-mg tablets
                                            </div>
                                        </div>
                                    </Box>
                                    <hr style={{ width: '100%', margin: '15px 0' }} />
                                    <Box width={isMobile ? '100%' : '80%'}>
                                        <BaseText text-align={isMobile ? 'left' : 'center'} size={isMobile ? 1.4 : 1.6}>
                                            Refer to the{' '}
                                            <Leaving url='https://packageinserts.bms.com/pi/pi_krazati.pdf'>
                                                <BaseLink
                                                    weight='bold'
                                                    target='_blank'
                                                    href='https://packageinserts.bms.com/pi/pi_krazati.pdf'
                                                    size={isMobile ? 1.4 : 1.6}
                                                    color='#17C94E'
                                                    decoration='underline'
                                                >
                                                    KRAZATI Prescribing Information
                                                </BaseLink>
                                            </Leaving>{' '}
                                            and{' '}
                                            <BaseLink
                                                weight='bold'
                                                target='_blank'
                                                href='/files/therapy-management-guide.pdf'
                                                size={isMobile ? 1.4 : 1.6}
                                                color='#17C94E'
                                                decoration='underline'
                                            >
                                                Therapy Management Guide
                                            </BaseLink>{' '}
                                            for information about dosage modifications for specific ARs.
                                        </BaseText>
                                    </Box>
                                </Box>
                            </StyledLeftSideShape>
                        </DoseReductionContainer>

                        <Box width={isMobile ? '100%' : '80rem'} height='fit-content' margin='1.5rem auto'>
                            <StyledRightSideShape
                                style={{ lineHeight: '1.5', display: 'flex', justifyContent: 'center' }}
                                background='#2F2F3C'
                                border='white'
                                borderSize={0.1}
                                className='second-shape'
                            >
                                <BaseText weight='600' fontfamily='Barlow' text-align={isMobile ? 'center' : 'left'} size={isMobile ? 1.5 : 2.1}>
                                    If you determine that a dosage reduction is necessary, your patient can continue therapy at the next lower dose without immediately requiring a
                                    new prescription
                                </BaseText>
                            </StyledRightSideShape>
                        </Box>
                        <ArrowCallout className='arrow-callout' margin={isMobile ? '0' : '2rem auto'}>
                            <BaseText weight='bold' transform='uppercase' text-align={isMobile ? 'center' : 'left'} fontfamily='Oswald' size={isMobile ? 1.6 : 1.8}>
                                Dosage modifications can help support keeping patients on therapy
                            </BaseText>
                        </ArrowCallout>
                    </CustomWrapper>
                    <ReferenceFooterWithLink linkText='Videos' url='/n/videos' />
                </CustomContainer>
            </ContainerScroll>
        </Scroll>
    );
};

export default Slide3;

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomContainer = styled(Container)`
    display: flex;
    padding: 0 0 3rem;
    align-items: stretch;
    height: 100%;

    @media ${device.desktop} {
		padding: 0;
        justify-content: space-between;
		min-height: ${(props) => `calc(100vh - 12rem - ${props.isiconfig})`};
    }

    .second-shape {
        padding: 15px 20px;

        @media ${device.laptop} {
            padding: 32px;
        }
    }

    .arrow-callout {
        [class*="ArrowCallout"] {
            padding: 10px 16px;

            @media ${device.desktop} {
                padding: 29px 45px 32px 30px;
            }
        }
    }
`;
const CustomWrapper = styled(Wrapper)`
    padding: 1.2rem 16px 3rem;

    @media ${device.desktop} {
        padding: 1.2rem 0 2rem;
        justify-content: space-between;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 0;
    text-transform: uppercase;
    padding: 0 2.6rem;

    @media ${device.desktop} {
        margin-bottom: 0;
    }
`;
const Infos = styled.div`
    margin: 0 auto 1.4rem;

    > div {
        text-align: center;
    }
    .rec-dosage {
      font-size: 1.3rem;
    }
    @media ${device.desktop} {
        max-width: 76rem;
        align-items: center;
        margin: 0 auto 1.8rem;

        > div {
            font-size: 1.8rem;
            line-height: 2rem;
        }
        .rec-dosage {
          margin-bottom: 3.8rem;
          font-size: 1.8rem;
        }
    }
`;

const DoseReductionContainer = styled(Box)`
    @media ${device.tablet} {
        max-width: 60%;
        margin: 0 auto;
    }

    @media ${device.desktop} {
        max-width: 80rem;
        > div {
            width: 90.2rem;

            h2 {
                font-size: 2.1rem;
                margin-bottom: 1rem;

                + div {
                }
            }
        }
    }
`;

const TabHeader = styled(BaseText)`
    display: flex;
    font-size: 1.2rem;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;

    &:after,
    &:before {
        content: '';
        position: absolute;
        width: 30%;
        height: 0.1rem;
        background: #d8d8d8;
    }

    &:after {
        top: 50%;
        left: 0;
    }

    &:before {
        top: 50%;
        right: 0;
    }

    @media ${device.desktop} {
        font-size: 1.6rem;

        &.once-daily {
            &:after,
            &:before {
                width: 23%;
            }
        }
    }
`;
const TabBox = styled(Box)`
    width: 100%;
    justify-content: space-between;
    position: relative;

    &.center {
        justify-content: center;
        text-align: center;
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        width: 0.1rem;
        height: 0.8rem;
        background: #d8d8d8;
    }

    &:after {
        left: 0;
        top: -0.8rem;
    }

    &:before {
        right: 0;
        top: -0.8rem;
    }

    @media ${device.desktop} {
        font-size: 1.6rem;
    }
`;
const TabWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.2rem;
    position: relative;
    img {
        width: 100%;
    }

    &.center {
        justify-content: center;
        text-align: center;
        padding-left: 2rem;
    }

    @media ${device.desktop} {
        padding-top: 0.5rem;
    }
`;

const LinkBox = styled(Box)`
    > div {
        line-height: 1.6rem;
    }
`;
