import React from 'react';
import './css/nccn-card.scss';

function NccnCard({ title, subtitle, text }) {
    return (
        <div className='card'>
            <div className='title'>{title}</div>
            <div className='copy'>
                <div className='copy-title'>{subtitle}</div>
                {text}
            </div>
        </div>
    );
}

export default NccnCard;
