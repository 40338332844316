import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import useMobileDetect from '../../../_hooks/useMobileDetect'
import { device } from '../../_assets/styles/theme'

const TabItem = ({title, legend, item, setCurrentTab, className, ...props }) => {
  const {isMobile} = useMobileDetect()
  const ref = useRef(null)

  const handleSelect = () => {
    setCurrentTab(item)
  }
  return (
    <Container id="tab-item" className={(className ? className : '') + (isMobile ? '' : ' swiper-no-swiping') } {...props} ref={ref} onClick={handleSelect}>
      <Wrapper className={(className ? className : '')}>
        {title}
        <span>{legend}</span>
      </Wrapper>
    </Container>
  )
}

export default TabItem

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  border: 2px solid #FFF;
  transform: skewX(-35deg);
  color: #fff;
  width: 160px;
  height: 2.7rem;
  pointer-events: all;
  border-right: none;
  @media ${device.tablet} {
    width: 100%;
    cursor: pointer;
    max-width: 18rem;
    height: 3.2rem;
  }

  @media ${device.desktop} {
    width: 100%;
    cursor: pointer;
    max-width: 100%;
    height: 4.2rem;
  }

  &.efficacy {
    font-family: "Barlow", sans-serif;
    padding: 0.8rem 0;
    height: 3rem;

    @media ${device.desktop} {
      padding: 0.8rem 0;
      height: 4.2rem;

      span {
        font-size: 1.3rem;
      }
    }

  }

  &.about-kras-g12c:not(.active) {
    font-style: italic;
  }
  &.active {
    border-color: #17C94E;
    border-right: 2px solid #17C94E;
  }
  &.active + div {
    border-left: none;
  }
`

const Wrapper = styled.div`
  font-family: 'Oswald', sans-serif;
  transform: skewX(35deg);
  font-weight: 300;
  font-size: min(1.24rem, 1.4rem);
  padding: 0 25px;
  padding: 0;
  min-width: 8.4rem;
  text-transform: uppercase;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.active {
    font-weight: 600;
  }
  span {
    font-size: 10px;
  }

  @media ${device.tablet} {
    font-size: min(1.24rem, 1.6rem);
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    padding: 0.8rem 2rem;

    span {
      font-size: 1.3rem;
    }
  }



  &.efficacy {
    font-family: "Barlow", sans-serif;

    &.active {
      font-weight: 500;
    }
    @media ${device.desktop} {
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.6rem;
      line-height: 1;
      padding: 0.8rem 0;
      text-transform: none;

      span {
        font-size: 1.3rem;
        font-weight: 500;
      }
    }

  }
`
