import ContentContainer from '../../_common/components/Layout/ContentContainer'
import styles from './Callout.module.scss'
export default function Callout({ title, grayBg = true }) {
  if (grayBg) {
    grayBg = { 'background-color': '#f2f2f2', 'padding': '30px 20px' }
  } else {
    grayBg = {}
  }
  return (
    <div className={styles.callout}>
      <ContentContainer>
        <div style={grayBg}>
          <img src="/assets/icons/icon-double-arrow.svg" alt="" />
          <h3>{title}</h3>
        </div>
      </ContentContainer>
    </div>
  )
}
