import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { Container, Wrapper, BaseText, BaseLink } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import ArrowCallout from '../../_components/Shapes/ArrowCallout';
import '../css/slide4.scss';

const Slide3 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;

    useActiveLink(setShowHeaderSecondaryMenu, 'safety');

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(3);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
            <ContainerScroll>
                <CustomWrapper id='new-slide'>
                    <div className='title'>
                        USE OF COMMON GI CONCOMITANT MEDICATIONS IN KRYSTAL-1<sup onClick={() => handleShowModal([35])}>35</sup>
                    </div>
                    <div className='subtitle'>
                        Nausea, diarrhea, and vomiting were manageable with dose reductions, interruptions, and/or use of supportive concomitant medications
                    </div>
                    <div>
                        <div className='inner'>
                            <div className='column'>
                                <div className='percents'>
                                    48<span>%</span>
                                </div>
                                <div className='text'>OF PATIENTS USED ANTIDIARRHEALS</div>
                            </div>
                            <div className='column'>
                                <div className='percents'>
                                    87<span>%</span>
                                </div>
                                <div className='text'>OF PATIENTS USED ANTIEMETICS/ANTINAUSEANTS</div>
                            </div>
                            <div className='column'>
                                <div className='percents green'>
                                    53<span>%</span>
                                </div>
                                <div className='text'>
                                  OF PATIENTS USED PPIs
                                  <span className="desktop-only">There were no clinically significant differences in the <br></br>PK of adagrasib when used concomitantly with a PPI.<sup onClick={()=> handleShowModal([30])}>30</sup></span>
                                </div>
                            </div>
                            <div className='column'>
                              <div className="text">
                                <span className="mobile-only">There were no clinically significant differences in the PK of adagrasib when used concomitantly with a PPI.<sup onClick={()=> handleShowModal([30])}>30</sup></span>
                              </div>
                            </div>
                            <div className='column'>
                                <div className='percents'>
                                    20<span>%</span>
                                </div>
                                <div className='text'>OF PATIENTS USED H<sub>2</sub>-RECEPTOR ANTAGONISTS</div>
                            </div>
                        </div>
                    </div>
                    <div>
                      <BaseText fontfamily='Oswald' weight='600' transform='uppercase' size={isMobile ? 1.2 : 1.6} text-align={isMobile ? 'center' : 'left'}>
                          FOR MORE INFORMATION ABOUT MONITORING AND MANAGEMENT, REFER TO THE{' '}
                          <BaseLink
                              weight='bold'
                              target='_blank'
                              href='https://packageinserts.bms.com/pi/pi_krazati.pdf'
                              size={isMobile ? 1.2 : 1.6}
                              color='#17C94E'
                              decoration='underline'
            fontfamily='Oswald'
                          >
                              KRAZATI PRESCRIBING INFORMATION
                          </BaseLink>{' '}
                          AND{' '}
                          <BaseLink
                              weight='bold'
                              target='_blank'
                              href='/files/therapy-management-guide.pdf'
                              size={isMobile ? 1.2 : 1.6}
                              color='#17C94E'
                              decoration='underline'
            fontfamily='Oswald'
                          >
                              THERAPY MANAGEMENT GUIDE
                          </BaseLink>
                          .
                      </BaseText>
                    </div>
                </CustomWrapper>
            </ContainerScroll>
            <Reference>
                <p>GI=gastrointestinal; PK=pharmacokinetics; PPI=proton pump inhibitor.</p>
            </Reference>
        </CustomContainer>
    );
};

export default Slide3;

const CustomContainer = styled(Container)`
    display: flex;
    height: 100%;
    padding: 0;
    max-height: ${(props) => `calc(100% - ${props.isiconfig} - 1rem)`};
    [class*="Reference__Container"] {
      bottom: 0;
    }
`;
const CustomWrapper = styled(Wrapper)`
    max-width: 1200px;
    .desktop-only {
      display: none !important;
    }
    @media ${device.desktop} {
        margin: 3rem auto 0;
        .desktop-only {
          display: block !important;
        }
        .mobile-only {
          display: none !important;
        }
    }
    .inner .column .text {
      span {
        font-family: 'Barlow', sans-serif;
        display: block;
        font-weight: 500;
      }
    }
`;
