import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { device } from "../../../_assets/styles/theme";

// 'swiper-no-swiping' is used to allow user to copy text (disable the drag in the component)

export const Page = styled.div`
  height: var(--screen-height);
  background: ${(props) => props.background};
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
  margin: ${(props) => props.margin || "0 auto"};

  // &.visible {
  //   transition: opacity .6s ease-in-out;
  //   transition-delay: .2s;
  //   opacity: 1;
  // }

  // &.hidden {
  //   opacity: 0;
  // }
`;

export const Wrapper = styled.div`
  margin-top: 5vmin;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    margin-top: 6.4rem;
  }
`;
export const BaseTitle = styled.h1`
  font-family: ${(props) => props.fontfamily || `'Oswald', sans-serif`};
  font-size: ${(props) => (props.size ? `${props.size}rem` : "4rem")};
  line-height: ${(props) => props.size + 0.4 || 4.8}rem;
  color: ${(props) => props.color || "white"};
  font-weight: ${(props) => props.fontfamily || `400`};
  text-transform: uppercase;
  color: ${(props) => props.color || "white"};

  @media ${device.desktop} {
    font-size: ${(props) => props.size || 6.4}rem;
    line-height: ${(props) => props.size + 0.4 || 6.8}rem;
  }
`;

export const BaseSubtitle = styled.h2`
  font-family: ${(props) => props.fontfamily || `'Oswald', sans-serif`};
  font-size: ${(props) =>
    props.size ? `${props.size}rem` : "min(1.7rem, 2.4rem)"};
  color: ${(props) => props.color || "white"};
  font-weight: ${(props) => props.weight || "bold"};

  sup {
    font-size: ${(props) =>
      props.size
        ? `calc(${props.size}rem * .5)`
        : "calc(min(1.7rem, 2.4rem) * .5)"};
  }

  @media ${device.tablet} {
    font-size: min(2.4rem, 3.2rem);

    sup {
      font-size: calc(min(2.4rem, 3.2rem) * 0.5);
    }
  }

  @media ${device.desktop} {
    font-size: ${(props) => (props.size ? `${props.size}rem` : "3.2rem")};

    sup {
      font-size: ${(props) =>
        props.size ? `calc(${props.size}rem * .5)` : "calc(3.2rem * .5)"};
    }
  }
`;

export const BaseText = styled.div`
  font-family: ${(props) => props.fontfamily || `'Barlow', sans-serif`};
  font-weight: ${(props) => props.weight || 500};
  font-size: ${(props) => (props.size ? `${props.size}rem` : "1.6rem")};
  color: ${(props) => props.color || "white"};
  text-transform: ${(props) => props.transform || "initial"};
  margin: ${(props) => props.margin || "initial"};
  padding: ${(props) => props.padding || "initial"};
  text-align: ${(props) => props["text-align"] || "initial"};
  -webkit-text-size-adjust: none;

  @media ${device.tablet} {
    font-size: ${(props) => (props.size ? `${props.size}rem` : "1.8rem")};
  }

  @media ${device.desktop} {
    font-size: ${(props) => (props.size ? `${props.size}rem` : "1.8rem")};
  }
`;

export const BaseLink = styled.a`
  width: ${(props) => props.width || "initial"};
  height: ${(props) => props.height || "100%"};
  padding: ${(props) => props.padding || 0};
  font-family: ${(props) => props.fontfamily || `'Barlow', sans-serif`};
  font-weight: ${(props) => props.weight || 500};
  font-size: ${(props) =>
    props.size ? `${props.size}rem` : "min(1.3rem, 1.6rem)"};
  color: ${(props) => props.color || "white"};
  text-decoration: ${(props) => props.decoration || "none"};
  text-transform: ${(props) => props.transform || "initial"};
  margin: ${(props) => props.margin || "initial"};
  text-align: ${(props) => props["text-align"] || "initial"};

  @media ${device.tablet} {
    font-size: ${(props) => (props.size ? `${props.size}rem` : "1.8rem")};
  }

  @media ${device.desktop} {
    font-size: ${(props) => (props.size ? `${props.size}rem` : "1.8rem")};
  }
`;
export const LinkBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid white;
  font-family: "Barlow", sans-serif;
  font-size: min(1.3rem, 1.6rem);
  color: white;
  padding-bottom: 0.3rem;
  transform: translateY(-1rem);
  margin: 2rem 0 3rem;

  svg {
    margin-left: 1rem;
    fill: white;
  }

  @media ${device.tablet} {
    font-size: 1.4rem;
  }

  @media ${device.desktop} {
    font-size: 1.6rem;
  }
`;

export const FlexCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Box = styled.div`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  position: ${(props) => props.position || "initial"};
  display: flex;
  padding: ${(props) => props.padding || 0};
  justify-content: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.align || "initial"};
  flex-direction: ${(props) => props.direction || "row"};
  margin: ${(props) => props.margin || "initial"};
  text-align: ${(props) => props["text-align"] || "initial"};
  background: ${(props) => props.background || "initial"};
  border: ${(props) => props.border || "none"};
  gap: ${(props) => props.gap || "0"};
  ${(props) => props.css || props.css}
`;

export const Input = styled.input`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  display: flex;
  padding: ${(props) => props.padding || "0.8rem"};
  justify-content: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.align || "initial"};
  flex-direction: ${(props) => props.direction || "row"};
  margin: ${(props) => props.margin || "0.8rem 0"};
  text-align: ${(props) => props["text-align"] || "initial"};
  color: ${(props) => props.color || "white"};
  background: ${(props) => props.background || "#303345"};
  border: ${(props) => props.border || "1px solid white"};
  font-size: ${(props) => (props.size ? `${props.size}rem` : "1.3rem")};
  height: 3.4rem;

  max-height: 4.2rem;

  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  &.invisible {
    position: absolute;
    visibility: hidden;
  }

  &:checked {
    label {
      background: red;
    }
  }

  &::placeholder {
    font-size: 95%;
    font-weight: inherit;
    color: inherit;
  }

  &#mobile_phone {
    opacity: 0;
    pointer-events: none;
    padding: 0;
    margin: 0;
    max-height: 0;

    &.visible {
      opacity: 1;
      pointer-events: initial;
      // transition: opacity .25s linear .25s;
      padding: 0.8rem;
      max-height: 4.2rem;
      margin: 0.8rem 0;
    }

    @media ${device.desktop} {
      &.visible {
        margin: 0;
      }
    }
  }

  @media ${device.desktop} {
    height: auto;
    font-size: ${(props) => (props.size ? `${props.size}rem` : "1.6rem")};
  }
`;
export const Label = styled.label`
  width: ${(props) => props.width || "100%"};
  font-family: ${(props) => props.fontfamily || `'Barlow', sans-serif`};
  font-weight: ${(props) => props.weight || 300};
  font-size: ${(props) =>
    props.size ? `${props.size}rem` : "min(1.2rem, 1.6rem)"};
  color: ${(props) => props.color || "white"};
  text-transform: ${(props) => props.transform || "initial"};
  margin: ${(props) => props.margin || "initial"};
  text-align: ${(props) => props["text-align"] || "initial"};
  cursor: pointer;

  @media ${device.desktop} {
    font-size: ${(props) => (props.size ? `${props.size}rem` : "1.6rem")};
  }
`;

export const StyledLeftSideShape = styled.div`
  position: relative;
  background: ${(props) => props.border || ""};
  clip-path: polygon(1.6rem 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0 2.4rem);
  width: 100%;
  height: 100%;
  ${(props) => {
    if (props.border && props.background && props.borderSize) {
      return `&::before {
          content: "";
          display: block;
          position: absolute;
          top: ${props.borderSize}rem;
          left: ${props.borderSize}rem;
          height: calc(100% - ${props.borderSize * 2}rem);
          width: calc(100% - ${props.borderSize * 2}rem);
          clip-path: inherit;
          background: ${props.background};
          z-index: -1;
        }
      `;
    }
  }}

  @media ${device.desktop} {
    clip-path: polygon(2.4rem 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0 3.2rem);
  }
`;

export const StyledRightSideShape = styled.div`
  position: relative;
  background: ${(props) => props.border || ""};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  width: 100%;
  height: 100%;
  ${(props) => {
    if (props.border && props.background && props.borderSize) {
      return `&::before {
        content: "";
        display: block;
        position: absolute;
        top: ${props.borderSize}rem;
        left: ${props.borderSize}rem;
        height: calc(100% - ${props.borderSize * 2}rem);
        width: calc(100% - ${props.borderSize * 2}rem);
        clip-path: inherit;
        background: ${props.background};
        z-index: -1;
        }
      `;
    }
  }}

  @media ${device.desktop} {
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 3.2rem),
      calc(100% - 2.4rem) 100%,
      0 100%,
      0 0
    );
  }
`;

export const StyledBothSideShape = styled.div`
  clip-path: polygon(
    1.6rem 0,
    100% 0,
    100% calc(100% - 2.4rem),
    calc(100% - 1.6rem) 100%,
    0 100%,
    0 2.4rem
  );
  position: relative;
  background: ${(props) => props.border || ""};
  width: 100%;
  height: 100%;
  ${(props) => {
    if (props.border && props.background && props.borderSize) {
      return `&::before {
        content: "";
        display: block;
        position: absolute;
        top: ${props.borderSize}rem;
        left: ${props.borderSize}rem;
        height: calc(100% - ${props.borderSize * 2}rem);
        width: calc(100% - ${props.borderSize * 2}rem);
        clip-path: inherit;
        background: ${props.background};
        z-index: -1;
        }
      `;
    }
  }}

  @media ${device.desktop} {
    clip-path: polygon(
      2.4rem 0,
      100% 0,
      100% calc(100% - 3.2rem),
      calc(100% - 2.4rem) 100%,
      0 100%,
      0 3.2rem
    );
  }
`;
