import { useState } from 'react'
import { motion } from 'framer-motion'
import useWindowSize from '../../../_hooks/useWindowSize'

import { Link, useLocation } from 'react-router-dom'
import CRCSubMenu from './CRCSubMenu'
// import CRCPrimaryMenu from './CRCPrimaryMenu'

import styles from "./NSCLCNavigation.module.scss"
import NSCLCPrimaryMenu from './NSCLCPrimaryMenu'

export default function NSCLCNavigation({ layoutRef }) {
  const winWidth = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  // const navigate = useNavigate();
  let location = useLocation();

  const mobileVariants = {
    initial: {
      height: '0px',
      overflow: 'hidden'
    },
    visible: {
      height: '100vh',
      transition: { ease: 'easeOut' }
    },
    hidden: {
      height: 0,
      transition: { ease: 'easeOut' }
    }
  }

  const toggleMobileMenu = () => {
    if (isOpen) {
      document.querySelector('html').style.overflow = 'visible';
    } else {
      document.querySelector('html').style.overflow = 'hidden';
    }
    setIsOpen(!isOpen)
  }

  // const switchIndications = () => {
  //   navigate('/n/' + location.pathname)
  // }
  const setSiteSwitchPath = () => {
    let loc = location.pathname.split('/')[2]
    if (!loc) {
      return '/n'
    }
    if (loc === "testing-for-kras-g12c") {
      return '/n/testing'
    }
    if (loc === 'dosage') {
      return '/n/dosing'
    }
    return '/n/' + loc
  }

  if (winWidth <= 1024) {
    return (
      <div className={`${styles.mobileNavigation} ${isOpen ? `${styles.mobileOpen}` : ''}`}>
        <header>
          <div className={styles.mobileLogo}>
            <Link to="/n"><img className={!isOpen ? `${styles.hideLogo}` : ''} src="/assets/logo/logo-landing-page.svg" alt="KRAZATI - adagrasib 200 mg Tablets Logo" /></Link>
            <Link to="/n"><img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/logo/logo-landing-page-invert.svg" alt="KRAZATI - adagrasib 200 mg Tablets Logo" /></Link>
          </div>

          <div className={!isOpen ? `${styles.hideLogo}` : `${styles.mobileSwitchIndication}`}>
            <span>Switch Indications</span>
            <div className={styles.mobileSwitchToggle}>
              <a className={styles.mobileSwitchToggleActive} href="/#">OTHER</a>
              <a href={setSiteSwitchPath()}>NSCLC</a>
            </div>
          </div>

          <div className={styles.mobileCRCBadge}>
            <img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/icons/icon-crc-badge-nav.svg" alt="" />
            <img className={!isOpen ? `${styles.hideLogo}` : ''} src="/assets/icons/icon-crc-badge-nav-open.svg" alt="" />
          </div>

          <div className={`${styles.menuToggle} ${styles.mobileToggleOpen}`} onClick={() => {
            toggleMobileMenu();
          }}>
            <img className={isOpen ? `${styles.hideLogo}` : ''} src="/assets/icons/icon-menu-toggle.svg" alt="Open mobile menu" />
            <img className={!isOpen ? `${styles.hideLogo}` : `${styles.mobileCloseIcon}`} src="/assets/icons/icon-mobile-close.svg" alt="Close mobile menu" />
          </div>

          <motion.div className={styles.mobileLinks} variants={mobileVariants} initial="initial" animate={isOpen ? "visible" : "hidden"}>
            <div className={styles.mobilePrimaryLinks}>
              <CRCSubMenu title="KRAS G12C in CRC" links={[
                {
                  url: '/n/nccn-recommendations',
                  text: 'NCCN Recommendations'
                },
                {
                  url: '/n/challenging-mutation',
                  text: 'About KRAS G12C'
                },
                {
                  url: '/n/testing',
                  text: 'Testing for KRAS G12C'
                }
              ]} />
              <CRCSubMenu title="Clinical Data" links={[
                {
                  url: '/c/study-design',
                  text: 'Study Design'
                },
                {
                  url: '/c/efficacy',
                  text: 'Efficacy'
                },
                {
                  url: '/c/safety',
                  text: 'Safety'
                }
              ]} />
              <Link to="/c/dosage">Dosing</Link>
              <CRCSubMenu title="Resources & Support" links={[
                {
                  url: '/c/resources',
                  text: 'Resources'
                },
                {
                  url: '/c/support',
                  text: 'Mirati & Me'
                }
              ]} />
            </div>
            <div className={styles.mobileSecondaryLinks}>
              <a href={`${location.pathname}#important-safety-information`} target="_self">Important Safety Information</a>
              <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
              <Link to="/n/references">References</Link>
              <a href="https://www.krazati.com/n" target="_blank" rel="noreferrer">Visit Patient Site</a>
            </div>
          </motion.div>
        </header>
      </div>
    )
  }
  return (
    <div className={styles.navigation}>
      <div className={styles.navigationWrapper}>
        <header>
          <div className={styles.logo}>
            <Link to="/n"><img src="/assets/logo/logo-landing-page-invert.svg" alt="KRAZATI - adagrasib 200 mg Tablets Logo" /></Link>
          </div>
          <div className={styles.mainLinks}>
            {/* <CRCPrimaryMenu /> */}
            <NSCLCPrimaryMenu />
          </div>
          <div className={styles.links}>
            <a href={`${location.pathname}#important-safety-information`} target="_self">Important Safety Information</a>
            <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>
            <Link to="/n/references">References</Link>
            <a href="https://www.krazati.com/n" target="_blank" rel="noreferrer">Visit Patient Site</a>
          </div>
        </header>
      </div>
    </div>
  )
}
