import React from 'react'
import styled from 'styled-components/macro'
import { device } from '../../_assets/styles/theme'
import ModalPortal from './utils/portal'

const Modal = ({ isOpen, children, ...props }) => {

  isOpen ? document.body.style.overflow = "hidden" : document.body.style.overflow = "initial";


  if (isOpen) {
    return (
      <ModalPortal>
        <ModalContainer {...props}>
          {children}
        </ModalContainer>
      </ModalPortal>
    )
  }

  return null
}

export default Modal

const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

