import Container from "../_common/components/Layout/Container";
import MainContent from "../_common/components/Layout/MainContent";
import InteriorBanner from "./components/InteriorBanner";
import CRCLayout from "./layouts/CRCLayout";
import { referenceData } from "./data/referenceData";
import MetaTags from "../_common/components/MetaTags";

import styles from './CRCReferences.module.scss'

export default function CRCReferences() {
  return (
    <CRCLayout>
      <MetaTags
        title='References & Important Safety Information'
        description='This page gives healthcare providers and patients access to authoritative references and important safety information regarding KRAZATI® (adagrasib).'
        ogUrl='https://www.krazatihcp.com/c/references'
      />
      {/* Banner */}
      <InteriorBanner title="References" imageUrl="/banners/banner-references.jpg" desktopImageUrl="/banners/banner-references-desktop.jpg" />

      <MainContent>
        <div className={styles.references}>
          <Container>
            <ol>
              {referenceData && referenceData.map((reference, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: reference }}></li>
              ))}
            </ol>
          </Container>
        </div>
      </MainContent>
    </CRCLayout>
  )
}
