import Dropdown from './components/Dropdown';

import styles from './CRCPrimaryMenu.module.scss'

export default function CRCPrimaryMenu() {
  return (
    <div className={styles.primaryMenu}>
      <div className={styles.primaryMenuWrapper}>
        <Dropdown width="148px" title="<i>KRAS G12C</i> in CRC" links={[
          {
            url: '/c/challenging-mutation',
            text: 'About <i>KRAS G12C</i>'
          },
          {
            url: '/c/testing',
            text: 'Testing for <i>KRAS G12C</i>'
          }
        ]} />

        <Dropdown width="122px" title="Clinical Data" links={[
          {
            url: '/c/study-design',
            text: 'Study Design'
          },
          {
            url: '/c/efficacy',
            text: 'Efficacy'
          },
          {
            url: '/c/safety',
            text: 'Safety'
          }
        ]} />

        <Dropdown title="Dosing" url="/c/dosage" />

        <Dropdown width="120px" title="Resources & Support" links={[
          {
            url: '/c/resources',
            text: 'Resources'
          },
          {
            url: '/c/support',
            text: 'BMS Access Support'
          }
        ]} />

      </div>
    </div>
  )
}
