import { useState } from 'react'
import Container from '../../_common/components/Layout/Container'

import styles from './Indication.module.scss'

export default function Indication() {
  const [isOpen, setIsOpen] = useState(true)
  const [openMore, setOpenMore] = useState(false)

  if (!isOpen) {
    return null
  }

  return (
    <div className={`${styles.indication} ${openMore ? `${styles.active}` : ''}`}>
      <div className={styles.closeButton}>
        <button onClick={() => {
          setIsOpen(false)
        }}>
          <img src="/assets/icons/icon-indication-close-button.svg" alt="Close indication" />
        </button>
      </div>
      <div className={styles.moreButton}>
        <button onClick={() => {
          setOpenMore(!openMore)
        }}>
          {!openMore ? `More` : `Less`}
          <img className={`${openMore ? `${styles.flip}` : ''}`} src="/assets/icons/icon-mobile-indication-arrow.svg" alt="Show more indication" />
        </button>
      </div>
      <Container>
        <div className={styles.content}>
          <p><b>INDICATION</b></p>
          <p>KRAZATI, as a single-agent, is indicated for the treatment of adult patients with KRAS G12C-mutated locally advanced or metastatic non-small cell lung cancer (NSCLC) as determined by an FDA-approved test, who have received at least one prior systemic therapy.</p>
          <p>KRAZATI in combination with cetuximab is indicated for the treatment of adult patients with KRAS G12C-mutated locally advanced or metastatic colorectal cancer (CRC), as determined by an FDA-approved test, who have received prior treatment with fluoropyrimidine-, oxaliplatin-, and irinotecan-based chemotherapy.</p>
          <p>These indications are approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR). Continued approval for these indications may be contingent upon verification and description of a clinical benefit in confirmatory trials.</p>
        </div>
      </Container>
    </div>
  )
}
