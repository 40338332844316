import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, BaseTitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import { device } from '../../_assets/styles/theme';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import Reference from '../../_components/Reference';
import ReferenceModal from '../../_components/Modal/ReferenceModal'

const Slide1 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const mobileOverlay = ''
  const [showPageContent, setShowPageContent] = useState(false)
  const desktopOverlay = 'linear-gradient(165deg, rgba(49, 78, 119, 0.2854) 4.22%, rgba(22, 38, 66, 0.2854) 90.76%)'
  const slideOverlay = desktopOverlay
  const slideBackground = isMobile ? `url('/assets/study-design-bg.png') no-repeat fixed 15% center / cover` : `url('/assets/study-design-bg.png') no-repeat fixed 20% top / cover`
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)
  useActiveLink(setShowHeaderSecondaryMenu, 'study')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setCurrentSlide(1)
      setShowPageContent(true)
      setBackground(slideBackground)
      setOverlay(slideOverlay)
      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])


  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])


  return (
    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
      {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
      <CustomWrapper>
        <Title>
          <Title1>KRYSTAL-1: REGISTRATIONAL study of safety and efficacy of KRAZATI</Title1>
        </Title>
        <SubTitle>
          <p>
            The safety and efficacy of KRAZATI were evaluated in patients with <em style={{ textWrap: 'nowrap' }}>KRAS G12C</em>-mutated, locally advanced or metastatic NSCLC who received at least one prior systemic therapy in KRYSTAL-1, a multicenter, single-arm, open-label expansion cohort study. 116 patients were enrolled; 112 were evaluable.<sup onClick={() => handleShowModal([30])}>30</sup>
          </p>
        </SubTitle>
      </CustomWrapper>
      <ScrollIcon marginBottom="1.5rem" />
      <Reference>NSCLC=non-small cell lung cancer.</Reference>
    </CustomContainer>
  )
}

export default Slide1

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0;
  max-height: ${props => `calc(90% - ${props.isiconfig})`};
  @media ${device.desktop} {
    [class*="Reference__Container"] {
      bottom: 40px;
    }
  }
`

const CustomWrapper = styled(Wrapper)`
  margin-top: 4.4rem;

  @media ${device.desktop} {
    margin-top: 0;
    margin-bottom: 3rem;
    justify-content: center;
  }

  @media ${device.desktop800h} {
    margin-top: 8rem;
  }

  @media ${device.desktop600h} {
    margin-top: 4rem;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  color: white;
  margin-bottom: 1.6rem;
  padding: 0 1.5rem;

  @media ${device.desktop} {
    margin-bottom: 3rem;
    max-width: 96rem;
    padding: 0;
  }

`
const Title1 = styled(BaseTitle)`
  text-transform: uppercase;

  @media ${device.desktop} {

  }
`
const SubTitle = styled(BaseSubtitle)`
  font-family: "Barlow";
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0 2.2rem;

  p {
    margin-bottom: .5rem;
  }

  @media ${device.desktop} {
    max-width: 83rem;
    font-size: 2.4rem;
  }
`
