import React from 'react'
import styled from 'styled-components/macro';

import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { useRef } from 'react';

const DoughnutChart = ({ actualData }) => {
  const chartRef = useRef()
  let dataInfo;

  if (actualData.length > 0) {
    dataInfo = actualData.map(item => {
      let r = (100 - item?.data) / 100 * 360;
      return {
        datasets: [{
          percent: item?.data,
          backgroundColor: [item?.background, 'transparent'],
          cutout: '80%',
          hoverOffset: 4,
          rotation: r,
        }]
      }
    })
  } else {
    let r = (100 - actualData?.data) / 100 * 360;
    dataInfo = {
      datasets: [{
        percent: actualData?.data,
        backgroundColor: [actualData?.background, 'transparent'],
        cutout: '80%',
        hoverOffset: 4,
        rotation: r,
      }]
    }
  }

  if(!dataInfo) return null
  return (
    <>
      {actualData.length > 0 ?
      (
       <MultipleChartsWrapper>
        {dataInfo.map((info, index) => (
        <Container key={actualData[index]?.data + index} className="multiple">
          <Doughnut ref={chartRef}
            redraw={true}
            data={info}
            options={{
              spacing: 0,
              responsive: true,
              animations: {
                startAngle: {
                  from: Math.PI * 4
                },
                endAngle: {
                  from: Math.PI / 2
                }
              },
              layout: {
                autoPadding: false,
                padding: 5,
              },
              borderColor: 'transparent',
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false,
                }
              }
            }}

            plugins={
              [{
                beforeInit: (chart) => {
                  const dataset = chart.data.datasets[0];
                  chart.data.labels = [dataset.label];
                  dataset.data = [dataset.percent, 100 - dataset.percent];
                }
              }
            ]
            }
          />
          <TextContainer className="multiple">
            <p>{actualData[index]?.data}<sup>%</sup></p>
            <p>{actualData[index]?.text}</p>
          </TextContainer>
        </Container>
       ))}
       </MultipleChartsWrapper>
      )
      :
      (
        <Container>
          <Doughnut ref={chartRef}
            redraw={true}
            data={dataInfo}
            options={{
              spacing: 0,
              responsive: true,
              layout: {
                autoPadding: false,
                padding: 5,
              },
              animations: {
                startAngle: {
                  from: Math.PI * 4
                },
                endAngle: {
                  from: Math.PI / 2
                }
              },
              borderColor: 'transparent',
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false,
                }
              }
            }}

            plugins={
              [{
                beforeInit: (chart) => {
                  const dataset = chart.data.datasets[0];
                  chart.data.labels = [dataset.label];
                  dataset.data = [dataset.percent, 100 - dataset.percent];
                }
              }
            ]
            }
          />
          <TextContainer>
            <p {...(actualData.data === 64 ? {style: {paddingLeft: 0}} : {})}>{actualData?.data}<sup>%</sup></p>
            <p {...(actualData.data === 64 ? {style: {paddingLeft: 0}} : {})}>{actualData?.text}</p>
          </TextContainer>
        </Container>
      )}
    </>
  )
}

export default DoughnutChart

const Container = styled.div`
  position: relative;
  background: transparent;
  width: 35.4rem;
  canvas {
    max-width: 35.4rem;
    max-height: 35.4rem;
  }

  &.multiple {
    width: 28rem;
    canvas {
      max-width: 28rem;
      max-height: 28rem;
    }
  }
`

const TextContainer = styled.div`
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  background: transparent;
  box-shadow: -.2rem 0.3rem .6rem rgba(0,0,0,0.2),  0 .2rem 1.4rem rgba(0,0,0,0.12),  0 .6rem 0.7rem rgba(0,0,0,0.14);
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: -1;
  border: 0.4rem solid #CECECE;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  p {
    &:first-of-type {
      font-size: 10.2rem;
      line-height: 11.3rem;
      line-height: 1;
      font-family: 'Oswald';
      font-weight: 600;
      text-shadow: 0 0.3rem .6rem rgba(0,0,0,0.5);
      padding-left: 1.7rem;

      sup {
        font-size: 5.1rem;
        line-height: 5.6rem;
        top: 1rem;
        left: .5rem;
        position: relative;
        text-shadow: 0 0.3rem .6rem rgba(0,0,0,0.5);
      }

    }

    &:last-of-type {
      font-family: 'Barlow';
      font-size: 2.3rem;
      letter-spacing: .129rem;
      line-height: 2.7rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;
      text-shadow: 0 0.3rem .6rem 0 rgba(0,0,0,0.5);
      font-weight: 500;
      padding-left: 1.5rem;

      small {
        font-size: 1.8rem;
        line-height: 2.1rem;
        display: block;
      }

      .green {
        color: #17C94E;
        font-weight: bold;
      }
    }
  }

  &.multiple {
    width: 25rem;
    height: 25rem;
    top: 1.5rem;
    left: 1.5rem;

    p {
      &:first-of-type {
        font-size: 8rem;
        line-height: 9.3rem;

        sup {
          font-size: 4rem;
          line-height: 5.4rem;
          top: 0.5rem;
          left: 0;
        }

      }

      &:last-of-type {
        font-family: 'Barlow';
        font-size: 2.4rem;
        letter-spacing: .129rem;
        line-height: 2.9rem;

        small {
          font-size: 1.6rem;
          line-height: 1.8rem;
          display: block;
        }
      }
    }
  }
`

const MultipleChartsWrapper = styled.div `
display: flex;
flex-direction: column;
gap: 2rem;
width: 35.435rem;
transform: translateY(1.5rem);
`
