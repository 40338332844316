import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import ReferenceModal from '../../_components/Modal/ReferenceModal'
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';

const Slide2 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const slideOverlay = `linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`;

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])

  useActiveLink(setShowHeaderSecondaryMenu, 'testing')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(2)
      setOverlay(slideOverlay)
      setBackground(`linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`);

      return
    }
    setShowPageContent(false)

  }, [swiperSlide.isActive])

  return (
    <Scroll isiconfig={isiconfig}>
      <ContainerScroll>
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'}>
          {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
          <Wrapper>
            <Title>
              <BaseSubtitle>Identifying AN ACTIONABLE driver mutation like <em>KRAS&nbsp;G12C</em> can inform treatment decision-making<sup onClick={() => handleShowModal([24, 25])}>24,25</sup></BaseSubtitle>
            </Title>

            <Characteristics>
              <SlantedShape className='slanted-shape-1'>
                <div className="text">
                  <div><span>3</span> <span>characteristics to look for in patients</span></div>
                </div>
                <div className='shape'></div>
              </SlantedShape>

              <Wrap>
                <CharacteristicsItem>
                  <CharacteristicImg>
                    <img src='/assets/icn_past-smoker.png' alt="Past or current smoker" />
                  </CharacteristicImg>
                  <span>Past or current smoker</span>
                </CharacteristicsItem>
                <CharacteristicsItem>
                  <CharacteristicImg>
                    <img src='/assets/icn_nonsquamous-nsclc.png' alt="Disease Icon" />
                  </CharacteristicImg>
                  <span>Nonsquamous NSCLC/adenocarcinoma</span>
                </CharacteristicsItem>
                <CharacteristicsItem>
                  <CharacteristicImg>
                    <img src='/assets/icn_unknown-kras.png' alt="Unknown Mutation Status" />
                  </CharacteristicImg>
                  <span>Unknown <em>KRAS</em> mutation status</span>
                </CharacteristicsItem>
              </Wrap>
              <SlantedShape className='slanted-shape-2'>
                <div className="text">Waiting for BIOMARKER testing results before initiating treatment MAY improve patient outcomes<sup onClick={() => handleShowModal([26])}>26</sup></div>
                <div className="text white-text">Based on retrospective analysis of a cohort of patients newly diagnosed with stage IV NSCLC with mutations of <em>EGFR, ALK, ROS1, BRAF, MET, RET, HER2,</em> or <em>NTRK1/2/3</em> (N=510). Patients with <em>KRAS&nbsp;G12C</em>-mutated NSCLC were not included in this analysis.</div>
                <div className='shape'></div>
              </SlantedShape>
            </Characteristics>
          </Wrapper>
          {isMobile && <Footnote>NSCLC=non-small cell lung cancer.</Footnote>}
        </CustomContainer>
      </ContainerScroll>
      {!isMobile && <Footnote>NSCLC=non-small cell lung cancer.</Footnote>}
    </Scroll>
  )
}

export default Slide2;

const Footnote = styled(Reference)`
	margin: 4rem 0 10px;

	@media ${device.desktop} {
		margin: 0;
		transform: translateY(calc(-100% - 5px));
	}
`

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;
const CustomContainer = styled(Container)`
  display: flex;
  height:  100%;
  padding: 0;
  [class^="styles__BaseText"] {
    margin: 5px 0 10px;
  }
`

const Title = styled.div`
  display: flex;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0 1.2rem;

  @media ${device.desktop} {
    max-width: 90rem;
    margin-bottom: 0;
  }
`


const Characteristics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.4rem;

  @media ${device.desktop} {
    margin-top: 1.5rem;
    padding: 0;
    max-width: 55%;
  }
`
const Wrap = styled.div`
  margin: 10px auto 10px;
  width: 100%;
  max-width: 700px;

  @media (${device.desktop}) {
	padding-left: 4%;
  }
`
const SlantedShape = styled.div`
  position: relative;
  color: #17C94E;
  padding: 1.2rem;
  width: 100%;
  max-width: 800px;

  .text {
    position: relative;
    z-index: 2;
    font: 600 1.25rem/1.9rem "Oswald", sans-serif;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.75px;

    &.white-text {
      font-size: 1.25rem;
      color: white;
      line-height: normal !Important;
      text-transform: none;
      letter-spacing: 0;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;

      span:first-child {
        font-size: 1.5em;
        margin-right: 4px;
      }

      span:last-child {
        font-size: .9em;
      }
    }

    sup {
      font-size: 0.5em;
    }
  }

  .shape {
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: #000;
    opacity: 0.5536;
    mix-blend-mode: multiply;
  }

  @media ${device.mobile} {
    padding: 1.2rem;

    .text {
	  span {
        letter-spacing: 0;
      }
    }

    &.slanted-shape-2 {
      padding-left: 0.8rem;
      padding-right: 0.8rem;

    }
  }

  @media ${device.desktop} {
    min-width: 900px;
    padding: 1.4rem 4rem 1.4rem 6rem;

    .text {
      font-size: 1.8rem;
      &.white-text {
        font-size: 1.25rem;
        color: white;
        line-height: normal !Important;
        text-transform: none;
      }
      div {
        font-size: 2rem;

        span:last-child {
          font-size: 1.7rem;
		  letter-spacing: 2px;
        }
      }
    }

    .shape {
    }

    &.slanted-shape-2 {
      min-width: 60rem;
      padding-left: 4rem;
      padding-right: 4rem;

      .text {
        line-height: 2.8rem;
      }

      .shape {
      }
    }
  }
`
const CharacteristicsItem = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  width: 100%;
  color: white;
  font-family: 'Oswald', sans-serif;
  height: 6rem;
  margin-bottom: 2.5rem;
  img {
    width: 5.6rem;
  }
  span {
    font-size: 1.5rem;
    letter-spacing: .06rem;
  }

  @media ${device.desktop} {
    grid-template-columns: 7.5rem 1fr;
    gap: 4rem;

    span {
      font-size: 2.9rem;
      text-align: left;
    }
  }

  @media ${device.desktop800h} {
    grid-template-columns: 6rem 1fr;
    gap: 3rem;

    span {
      font-size: 2.1rem;
    }
  }
`

const CharacteristicImg = styled.div`
  text-align: center;
  img {
    width: 5rem;
    text-align: center;
    margin-bottom: .5rem;
  }

  @media ${device.desktop800h} {
    img {
      max-width: 95%;
    }
  }
`
