import { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import styles from './CRCSubMenu.module.scss'

export default function CRCSubMenu({ title, links }) {
  const [isOpen, setIsOpen] = useState(false);
  const variants = {
    initial: {
      height: 0,
      overflow: 'hidden'
    },
    visible: {
      height: 'auto',
      transition: { ease: 'easeOut' }
    },
    hidden: {
      height: 0,
      overflow: 'hidden',
      transition: { ease: 'easeOut' }
    }
  }
  return (
    <div className={styles.crcSubMenu}>
      <button href="" onClick={() => {
        setIsOpen(!isOpen)
      }}>
        <span dangerouslySetInnerHTML={{ __html: title }}></span>
        <img className={isOpen ? `${styles.expanded}` : ''} src="/assets/icons/icon-mobile-dropdown-arrow.svg" alt="" />
      </button>
      <motion.div variants={variants} initial="initial" animate={isOpen ? 'visible' : 'hidden'}>
        <div className={styles.crcSubMenuLinks}>
          {links.map((link, index) => (
            <Link key={index} to={link.url} dangerouslySetInnerHTML={{ __html: link.text }}></Link>
          ))}
        </div>
      </motion.div>
    </div>
  )
}
