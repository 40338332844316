import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { Container, LinkBox, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import { Title1 } from './Slide2';
import { CustomLink } from '../../Efficacy/components/Tab1';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import Leaving from '../../_components/Modal/Leaving';
const Slide6 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;

    useActiveLink(setShowHeaderSecondaryMenu, 'safety');

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(6);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    if (isMobile) {
        return (
            <Scroll isiconfig={isiconfig}>
                <ContainerScroll>
                    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
                        <CustomWrapper>
                            <Title>
                                <Title1>
                                    KRAZATI WARNINGS AND PRECAUTIONS<sup onClick={() => handleShowModal([30])}>30</sup>
                                </Title1>
                            </Title>

                            <Content>
                                <h3>GASTROINTESTINAL ADVERSE REACTIONS</h3>
                                <p>
                                    <span className='bullet'>•</span>KRAZATI can cause severe gastrointestinal adverse reactions.
                                </p>

                                <p>
                                    <span className='bullet'>•</span>In the pooled safety population, serious gastrointestinal adverse reactions observed were gastrointestinal
                                    bleeding in 3.8% including 0.8% Grade 3 or 4, gastrointestinal obstruction in 1.6% including 1.4% Grade 3 or 4, colitis in 0.5% including 0.3%
                                    Grade 3, ileus in 0.5%, and stenosis in 0.3%. In addition, nausea, diarrhea, or vomiting occurred in 89% of 366 patients, including 9% Grade 3.
                                    Nausea, diarrhea, or vomiting led to dosage interruption or dose reduction in 29% of patients and permanent discontinuation of KRAZATI in 0.3%.
                                </p>

                                <p>
                                    <span className='bullet'>•</span>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement,
                                    as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity.
                                </p>

                                <h3>
                                    QT<span className='text-initial'>c</span> Interval Prolongation
                                </h3>
                                <p>
                                    <span className='bullet'>•</span>KRAZATI can cause QTc interval prolongation, which can increase the risk for ventricular tachyarrhythmias (e.g., torsades de pointes) or sudden death.
                                </p>
                                <p>
									<span className='bullet'>•</span>In the pooled safety population, 6% of 366 patients with at least one post-baseline electrocardiogram (ECG) assessment had an average QTc ≥ 501
                                    ms and 11% of patients had an increase from baseline of QTc &gt; 60 msec. KRAZATI causes concentration-dependent increases in the QTc interval.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>Avoid concomitant use of KRAZATI with other products with a known potential to prolong the QTc interval. Avoid
                                    use of KRAZATI in patients with congenital long QT syndrome and in patients with concurrent QTc prolongation.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>Monitor ECGs and electrolytes prior to starting KRAZATI, during concomitant use, and as clinically indicated in
                                    patients with congestive heart failure, bradyarrhythmias, electrolyte abnormalities, and in patients who are unable to avoid concomitant
                                    medications that are known to prolong the QT interval. Withhold, reduce the dose, or permanently discontinue KRAZATI depending on severity.
                                </p>

                                <h3>HEPATOTOXICITY</h3>
                                <p>
                                    <span className="bullet">•</span>KRAZATI can cause hepatotoxicity, which may lead to drug-induced liver injury and hepatitis.
                                </p>
                                <p>
                                    <span className="bullet">•</span>In the pooled safety population of 366 patients, drug-induced liver injury was reported in 0.3% of patients, including 0.3% Grade 3. A total of 32% of patients who received KRAZATI had increased alanine aminotransferase (ALT)/increased aspartate aminotransferase (AST); 5% were Grade 3 and 0.5% were Grade 4. The median time to first onset of increased ALT/AST was 3 weeks (range: 0.1 to 48). Overall hepatotoxicity occurred in 37%, and 7% were Grade 3 or 4. Hepatotoxicity leading to dose interruption or reduction occurred in 12% of patients. KRAZATI was discontinued due to hepatotoxicity in 0.5% of patients.
                                </p>
                                <p>
                                    <span className="bullet">•</span>Monitor liver laboratory tests (AST, ALT, alkaline phosphatase and total bilirubin) prior to the start of KRAZATI and monthly for 3 months or as clinically indicated, with more frequent testing in patients who develop transaminase elevations. Reduce the dose, withhold, or permanently discontinue KRAZATI based on severity.
                                </p>

                                <h3>INTERSTITIAL LUNG DISEASE/PNEUMONITIS</h3>
                                <p>
                                    <span className="bullet">•</span>KRAZATI can cause interstitial lung disease (ILD)/pneumonitis, which can be fatal.
                                </p>
                                <p>
                                    <span className="bullet">•</span>In the pooled safety population, ILD/pneumonitis occurred in 4.1% of patients, 1.4% were Grade 3 or 4, and one case was fatal. The median time to first onset for ILD/pneumonitis was 12 weeks (range: 5 to 31 weeks). KRAZATI was discontinued due to ILD/pneumonitis in 0.8% of patients.
                                </p>
                                <p>
                                    <span className="bullet">•</span>Monitor patients for new or worsening respiratory symptoms indicative of ILD/pneumonitis (e.g., dyspnea, cough, fever) during treatment with KRAZATI.
                                </p>
                                <p>
                                    <span className="bullet">•</span>Withhold KRAZATI in patients with suspected ILD/pneumonitis and permanently discontinue KRAZATI if no other potential causes of ILD/pneumonitis are identified.
                                </p>
                            </Content>

                            <Arrow>
                                {/* <ArrowContainerShadow></ArrowContainerShadow> */}
                                <ArrowContainer>
                                    <ArrowWrapper>
                                        <p>
                                            FOR MORE INFORMATION ABOUT MONITORING AND MANAGEMENT, REFER TO THE{' '}
                                            <Leaving url='https://packageinserts.bms.com/pi/pi_krazati.pdf'>
                                                <a target='_blank' href='https://packageinserts.bms.com/pi/pi_krazati.pdf' rel='noreferrer'>
                                                    KRAZATI&nbsp;PRESCRIBING&nbsp;INFORMATION
                                                </a>
                                            </Leaving>{' '}
                                            AND{' '}
                                            <a target='_blank' href='/files/therapy-management-guide.pdf'>
                                                THERAPY MANAGEMENT GUIDE
                                            </a>
                                            .
                                        </p>
                                    </ArrowWrapper>
                                </ArrowContainer>
                            </Arrow>
                        </CustomWrapper>

                        <>
                          <ReferenceFooterWithLink linkText="Dosage" url="/dosing">
                          </ReferenceFooterWithLink>
                        </>
                    </CustomContainer>
                </ContainerScroll>
            </Scroll>
        );
    }

    return (
        <Scroll isiconfig={isiconfig}>
            <ContainerScroll>
                <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
                    <CustomWrapper>
                        <Title>
                            <Title1>
                                KRAZATI WARNINGS AND PRECAUTIONS<sup onClick={() => handleShowModal([30])}>30</sup>
                            </Title1>
                        </Title>

                        <Content>
                            <div>
                                <h3>GASTROINTESTINAL ADVERSE REACTIONS</h3>
                                <p>
                                    <span className='bullet'>•</span>KRAZATI can cause severe gastrointestinal adverse reactions.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>In the pooled safety population, serious gastrointestinal adverse reactions observed were gastrointestinal
                                    bleeding in 3.8% including 0.8% Grade 3 or 4, gastrointestinal obstruction in 1.6% including 1.4% Grade 3 or 4, colitis in 0.5% including 0.3%
                                    Grade 3, ileus in 0.5%, and stenosis in 0.3%. In addition, nausea, diarrhea, or vomiting occurred in 89% of 366 patients, including 9% Grade 3.
                                    Nausea, diarrhea, or vomiting led to dosage interruption or dose reduction in 29% of patients and permanent discontinuation of KRAZATI in 0.3%.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>Monitor and manage patients using supportive care, including antidiarrheals, antiemetics, or fluid replacement,
                                    as indicated. Withhold, reduce the dose, or permanently discontinue KRAZATI based on severity.
                                </p>
                            </div>
                            <div>
                                <h3>
                                    QT<span className='text-initial'>c</span> Interval Prolongation
                                </h3>
                                <p>
                                    <span className='bullet'>•</span>KRAZATI can cause QTc interval prolongation, which can increase the risk for ventricular tachyarrhythmias
                                    (e.g., torsades de pointes) or sudden death.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>In the pooled safety population, 6% of 366 patients with at least one post-baseline electrocardiogram (ECG) assessment had an average QTc ≥ 501 ms and 11% of patients had an increase from baseline of QTc &gt; 60 msec. KRAZATI causes concentration-dependent increases in the QTc interval.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>Avoid concomitant use of KRAZATI with other products with a known potential to prolong the QTc interval. Avoid
                                    use of KRAZATI in patients with congenital long QT syndrome and in patients with concurrent QTc prolongation.
                                </p>
                                <p>
                                    <span className='bullet'>•</span>Monitor ECGs and electrolytes prior to starting KRAZATI, during concomitant use, and as clinically indicated in
                                    patients with congestive heart failure, bradyarrhythmias, electrolyte abnormalities, and in patients who are unable to avoid concomitant
                                    medications that are known to prolong the QT interval. Withhold, reduce the dose, or permanently discontinue KRAZATI depending on severity.
                                </p>
                            </div>
                        </Content>

                        <Arrow>
                            {/* <ArrowContainerShadow></ArrowContainerShadow> */}
                            <ArrowContainer>
                                <ArrowWrapper>
                                    <p>
                                        FOR MORE INFORMATION ABOUT MONITORING AND MANAGEMENT, REFER TO THE{' '}
                                        <Leaving url='https://packageinserts.bms.com/pi/pi_krazati.pdf'>
                                            <a target='_blank' href='https://packageinserts.bms.com/pi/pi_krazati.pdf' rel='noreferrer'>
                                                KRAZATI&nbsp;PRESCRIBING&nbsp;INFORMATION
                                            </a>
                                        </Leaving>{' '}
                                        AND{' '}
                                        <a target='_blank' href='/files/therapy-management-guide.pdf'>
                                            THERAPY MANAGEMENT GUIDE
                                        </a>
                                        .
                                    </p>
                                </ArrowWrapper>
                            </ArrowContainer>
                        </Arrow>
                    </CustomWrapper>

                    <Reference>
                    </Reference>
                </CustomContainer>
            </ContainerScroll>
        </Scroll>
    );
};

export default Slide6;

const Scroll = styled.div`
    height: 100%;

    max-height: ${(props) => `calc(100% - ${props.isiconfig} - 1rem)`};
`;

const CustomContainer = styled(Container)`
    display: flex;
    padding: 0;
    justify-content: space-between;
    padding-bottom: 4rem;

    @media ${device.desktop} {
        padding-bottom: 0;
    }
`;
const CustomWrapper = styled(Wrapper)`
    height: auto;
    margin-top: 2.8rem;
    @media ${device.desktop} {
        margin-top: 1.6rem;
        margin-bottom: 5rem;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 2rem;
    text-transform: uppercase;
    padding: 0 1.6rem;

    @media ${device.desktop} {
        margin-bottom: 2rem;
    }
`;

const Content = styled.div`
    color: #ffffff;
    padding: 0 1.8rem;
    h3 {
        font-family: 'Oswald';
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: uppercase;
        margin: 2rem 0;
    }

    p {
        font-family: 'Barlow';
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.03rem;
        line-height: 1.6rem;
        margin-bottom: 1.5rem;
        position: relative;
        padding-left: 1.5rem;

        .bullet {
            position: absolute;
            left: 0.5rem;
        }
        &:last-of-type {
            margin-bottom: 4rem;
        }
    }

    @media ${device.tablet} {
        padding: 0 4rem;
    }
    @media ${device.desktop} {
        display: flex;
        max-width: 120rem;
        > div {
            width: 48.8rem;
            margin-right: 6.3rem;

            &:last-of-type {
                margin-right: 0;
                width: 57rem;
            }
        }
        h3 {
            font-size: 2.4rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 2.6rem;
        }

        p {
            font-size: 1.7rem;
            line-height: 1.8rem;
            margin-bottom: 1.5rem;
            padding-left: 2.5rem;
        }
    }
`;

const Arrow = styled.div`
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0px 3px 3px);
    position: relative;
    display: flex;
    justify-content: center;
    @media ${device.desktop} {
        max-width: 120rem;
        padding: 0 4rem;
        > div {
            max-width: 65.8rem;
        }
    }
`;
const ArrowContainer = styled.div`
    box-shadow: 0.3rem 0.2rem 1rem -0.5rem #000;
    background: linear-gradient(108.19deg, rgba(31, 36, 53, 0.8) 0%, rgba(47, 54, 75, 0.7) 100%, rgba(66, 74, 101, 0.7) 100%);
    margin-bottom: 2rem;
    @media ${device.desktop} {
        clip-path: polygon(calc(100% - 4.2rem) 0%, 100% 50%, calc(100% - 4.2rem) 100%, 0% 100%, 0 50%, 0% 0%);
    }
`;
const ArrowContainerShadow = styled.div`
    display: none;
    position: absolute;
    z-index: -1;
    content: '';
    background: linear-gradient(108.19deg, rgba(31, 36, 53, 0.5) 0%, rgba(47, 54, 75, 0.5) 100%, rgba(66, 74, 101, 0.5) 100%);

    width: calc(100% + 0.1rem);
    height: calc(100% + 0.1rem);
    left: 4rem;
    top: 0.2rem;

    @media ${device.desktop} {
        display: initial;
        clip-path: polygon(calc(100% - 4.2rem) 0%, 100% 50%, calc(100% - 4.2rem) 100%, 0% 100%, 0 50%, 0% 0%);
    }
`;
const ArrowWrapper = styled.div`
    color: white;
    padding: 1.2rem 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        font-size: 1.4rem;

        a {
            color: #17c94e;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    @media ${device.tablet} {
        p {
            font-size: 1.6rem;
            max-width: 92%;
        }
    }

    @media ${device.desktop} {
        padding: 1.6rem 2.2rem;
        align-items: flex-start;
        p {
            font-size: 1.8rem;
            font-size: 1.8rem;
            text-align: initial;
            padding: 0;
            max-width: 95%;
            sup {
                font-size: 1.6rem;
            }
        }
    }
`;

const CustomReference = styled(Reference)``;
