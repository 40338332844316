import React, { useEffect, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { device } from '../_assets/styles/theme'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import Footer from '../_components/Footer';
import { Box, Page } from '../_components/PageSwipper/utils/styles';
import { useSwiper } from 'swiper/react';
import StaticIsi from '../_components/ISI/StaticIsi';
import useMobileDetect from '../../_hooks/useMobileDetect';
import { isiConfig } from '../_components/ISI/config';
import ISICollapsing from '../_components/ISI/Collapsing';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import { useInView } from "react-intersection-observer";
import MetaTags from '../_components/MetaTags/MetaTags';

const References = () => {
  const { isMobile } = useMobileDetect()
  const pageWrapper = useRef()
  const [headerRef, setHeaderRef] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [renderPage, setRenderPage] = useState(false)
  const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(1)
  const isiHeight = currentSlide === 3 ? 136 : 56
  const [pageGradient, setPageGradient] = useState(null)
  const delay = 1000
  const [showOverlay, setShowOverlay] = useState(false)
  const [background, setBackground] = useState(`url(${isMobile ? '#FFF' : "/assets/.webp"}) no-repeat fixed center top / cover`)
  const [overlay, setOverlay] = useState(null)
  const overlayRef = useRef()
  const { ref, inView, entry } = useInView()
  const isiWrapper = useRef(null)

  useEffect(() => {
    if (!inView) {
      isiWrapper?.current.classList.toggle('visible')
      return
    }
    isiWrapper?.current.classList.remove('visible')

  }, [inView, entry])

  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef?.current.clientHeight)
      setRenderPage(true)
    }
  }, [headerRef?.current])

  const changeOverlay = (newOverlay) => {
    overlayRef.current.classList.remove('visible')
    overlayRef.current.classList.add('hidden')
    overlayRef.current.background = ''
    setOverlay(newOverlay)
  }

  useEffect(() => {
    overlayRef.current.classList.remove('visible')
    overlayRef.current.classList.add('hidden')

    const time = setTimeout(() => {
      // overlayRef.current.style.opacity = 1
      overlayRef.current.classList.add('visible')
      overlayRef.current.classList.remove('hidden')
    }, 100)

    return () => {
      clearTimeout(time)
    }
  }, [currentSlide])


  return (
    <Page background={background} >
      <PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
      <PageScroll>
        <Container ref={pageWrapper}>
          <MetaTags
            title='KRAZATI® (adagrasib) | References'
            description='Access authoritative references and diverse resources, offering updated clinical guidelines for informed decision-making.'
            ogUrl='https://www.krazatihcp.com/n/references'
          />

          <Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
          <PageWrapper pageGradient={pageGradient}>
            <Slide1
              isiconfig={isiConfig.collapsed}
              setCurrentSlide={setCurrentSlide}
              setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
              setPageGradient={setPageGradient}
              setBackground={setBackground}
              setOverlay={setOverlay}
            />
          </PageWrapper>
          <StaticContentWrapper>
            <StaticIsi hiddenRef={ref} noButton={true} />
            {!isMobile && <Footer translate={false}></Footer>}
          </StaticContentWrapper>
        </Container>
      </PageScroll>
      <IsiWrapper ref={isiWrapper} className="visible">
        {isMobile ?
          <FloatingIsi className={'visible'} /> :
          <ISICollapsing className={'visible'} />}
      </IsiWrapper>
    </Page>
  )
}

export default References

const Container = styled.div`
  height: 100%;
  max-height: 100%;

`
const PageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${props => props.overlay};
  opacity: 0;

  &.visible {
    transition: opacity .3s linear;
    opacity: 1;
  }

`
const PageWrapper = styled.div`
  position: relative;

  @media ${device.desktop} {
    &:after {
      content: none;
    }
  }


`
export const StaticContentWrapper = styled.div`
  position: relative;
  height: auto;
  z-index: 999;

  @media ${device.desktop} {
    > div {
      &:after {
        content: none;
      }
    }
  }
`
const IsiWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index:9999;
  transition: opacity .1s linear;
  opacity: 0;

  &.visible {
    opacity: 1
  }

`
