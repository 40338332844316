import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { Container } from '../../_components/PageSwipper/utils/styles';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { device } from '../../_assets/styles/theme';
import ReferenceWithLink from "../../_components/ReferenceWithLink";

const Slide2 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, loading, setLoading, isiconfig, setBackground }) => {
  const mobileBgWrapper = useRef()
  const { isMobile, isTabletAndLaptop } = useMobileDetect()
  const [showMoa, setShowMoa] = useState(false)
  const swiperSlide = useSwiperSlide();

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setCurrentSlide(2)
      setBackground(`url('/assets/moa-bg.webp') no-repeat fixed center center / cover`)
    }
  }, [swiperSlide.isActive])


  return (
    <>
      <CustomContainer ref={mobileBgWrapper} isiconfig={isiconfig}>

        {isMobile && !isTabletAndLaptop ?
          <VideoContainer>
            <video controls muted>
              <source src='/assets/moa.mp4' type="video/mp4"></source>
            </video>
          </VideoContainer>
          :
          <>
            <button
              className={`moa-toggle ${showMoa ? 'started' : 'stopped'}`}
              onClick={() => setShowMoa(!showMoa)}>
              {showMoa ? 'Close' : 'Start'} experience
            </button>
            {showMoa &&
              <iframe
                title="MOA animation"
                src="/moa-threejs/"
                frameBorder="0"
              >
              </iframe>
            }
            <div className={`moa-image ${showMoa ? 'hidden' : ''}`}>
              <img src='/assets/moa-image.webp' alt="Mechanism of Action Animation for Krazati - adagrasib" />
            </div>
          </>
        }
        {!showMoa &&
          <div className="reference">
            <ReferenceWithLink url="/n/study-design" linkText="Study Design" />
          </div>
        }
      </CustomContainer>
    </>
  )
}

export default Slide2

const CustomContainer = styled(Container)`
  display: flex;
  height: 100%;
  padding: 0;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};
  position: relative;
  z-index: 1;

  iframe {
    margin: auto;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);


    // @media (min-width: 376px) {
    //   width: 90%;
    //   margin-top: 0;
    //   height: 89%;
    // }
  }

  .moa-image {
    display: flex;
    justify-content: center;

    img {
      transition: .3s;
      width: 100rem;
    }


    &.hidden {
      img {
        transition: .3s;
        height: 0;
        opacity: 0;

      }
    }

  }

  .moa-toggle {
    position: absolute;
    transition: left .3s, right .3s, bottom .6s .3s;
    &.stopped {
      left: 50%;
      transform: translateX(-50%);
    }

    &.started {
      left: initial;
      bottom: 2.4rem;
      right: 8.4rem;
    }
    bottom: 9rem;
    cursor: pointer;
    z-index: 10;
    width: 24rem;
    height: 5.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    background: rgba(22, 38, 66, .5);
    border: 1px solid white;

    font-size: 1.7rem;
    font-family: "Barlow";
    text-transform: uppercase;
    color: white;
    font-weight: 500;
  }

  .reference {
	width: 100%;
	text-align: center;
	margin-bottom: 1rem;

	a {
		margin-right: 0;
	}

	  @media ${device.desktop} {
		position: absolute;
		bottom: 2rem;
		right: 5.6rem;
		margin-top: 8rem;
	  }
  }
`

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
  }
`
