import { useLocation } from 'react-router-dom'
import styles from './SiteSwitcher.module.scss'

export default function SiteSwitcher() {
  let location = useLocation();
  const setSiteSwitchPath = () => {
    let loc = location.pathname.split('/')[2]
    if (!loc) {
      return '/n'
    }
    if (loc === "testing-for-kras-g12c") {
      return '/n/testing'
    }
    if (loc === 'dosage') {
      return '/n/dosing'
    }
    if (loc === 'support') {
      return '/n'
    }
    return '/n/' + loc
  }
  return (
    <div className={styles.siteSwitcher}>
      <div className={styles.siteSwitcherWrapper}>
        <img src="/assets/icons/icon-crc-badge-nav-desktop.svg" alt="Disease Indication Flag" />
        <div className={styles.siteSwitcherTitle}>
          Switch Indications
        </div>
        <div className={styles.siteSwitcherToggle}>
          <a className={styles.activeToggle} href="/#">CRC</a>
          <a href={setSiteSwitchPath()}>NSCLC</a >
        </div>
      </div>
    </div>
  )
}
