import React, { useState, useRef } from 'react'
import styled from 'styled-components/macro'
import { device } from '../../_assets/styles/theme'
import Leaving from '../Modal/Leaving'
import { BaseLink, BaseText, Box } from '../PageSwipper/utils/styles'

const HorizontalAccordion = ({children}) => {
  const [selected, setSelected] = useState(0)
  const ref = useRef()

  return (
    <Container>
      <Wrapper>
      {children.map((item, index) => (
        <div key={index} onClick={() => setSelected(index)}>
          {React.cloneElement(item, {className: index === selected ? 'selected desktop' : 'desktop'})}
        </div>
      ))}
      </Wrapper>
      <Wrapper>
        {children.map((item, index) => {
          if(index === selected) {
            return (
              React.cloneElement(item.props.info, {ref, key: index})
            )
          }
         
        })}
      </Wrapper>
    </Container>
  )
}

export default HorizontalAccordion

const Container = styled.div`
  display: grid;
  max-width: 110rem;
  padding: 3.2rem 1.6rem 3.2rem 4rem;
  grid-template-columns: 50rem 1fr;


  .restriction-text {
    color: #3A3A3A;
    font-family: "Barlow";
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.3rem;
    font-weight: 500;
  }
`
const Wrapper = styled.div`
  position: relative;
  &:first-of-type {
    display: flex;
    flex-direction: column;
	justify-content: space-between;
    gap: 1.5rem;
    width: 100%;
  }

  &:last-of-type {
    padding-left: 4rem;
  }
`

const Button = styled(BaseLink)`
  padding: 0.8rem 1.6rem;
  background: #3246FF;
  border: none;
  outline: none;
  color: white;
  border-radius: 1.4rem;
  font-size: 1.1rem;
  width: fit-content;
  margin-top: 1.2rem;

  @media ${device.desktop} {
    margin-top: 0;
    width: 22rem;
    height: 4.8rem;
    padding: 1.3rem;
    font-size: 1.7rem;
    border-radius: 2.8rem;
    text-align: center;
  }
`