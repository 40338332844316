import CRCLayout from "./layouts/CRCLayout";
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import Container from "../_common/components/Layout/Container"
import FooterCallout from "./components/FooterCallout";
import MetaTags from "../_common/components/MetaTags";
import styles from './CRCResources.module.scss'
import ExternalDownloadLink from "../_common/components/ExternalDownloadLink";

export default function CRCResources() {
  return (
    <CRCLayout>
      <MetaTags
        title='Treatment & Educational Materials'
        description="Access educational materials, guidelines, and support resources for healthcare providers."
        ogUrl='https://www.krazatihcp.com/c/resources'
      />
      {/* Banner */}
      <InteriorBanner title="Resources" imageUrl="/banners/banner-resources.jpg" desktopImageUrl="/banners/banner-resources-desktop.jpg" />

      <MainContent>
        <div className={styles.resources}>
          <Container>
            <div>
              <h2 className={styles.pageHeading}>Resources for you and your patients</h2>
              <h3>Easily access downloadable guides and important information to use during treatment with KRAZATI.</h3>
            </div>
            <div className={styles.resourcesCards}>
              <div>
                <h4>Healthcare provider resources</h4>
                <img src="/assets/resources/resource-1.jpg" alt="Therapy Management Guide" />
                <h5>Therapy Management Guide</h5>
                <p>Digital resource designed to help guide your conversations with patients while managing their treatment. Topics include safety information, dosage modifications, counseling patients on drug-drug interactions, and more.</p>
                <div><a href="/pdfs/therapy-management-guide.pdf" className={styles.downloadButton} target="_blank"><img src="/assets/resources/img-download-white.svg" alt="" />Download</a></div>
              </div>
              <div>
                <h4 className={styles.hide}>&nbsp;</h4>
                <img src="/assets/resources/resource-2.jpg" alt="Dosing Flashcard" />
                <h5>Dosing Flashcard</h5>
                <p>Quick reference guide for dosing KRAZATI.</p>
                <div><a href="/pdfs/dosing-flashcard-crc.pdf" className={styles.downloadButton} target="_blank"><img src="/assets/resources/img-download-white.svg" alt="" />Download</a></div>
              </div>
              <div>
                <h4>Patient resources</h4>
                <img src="/assets/resources/resource-3.jpg" alt="Patient Brochure" />
                <h5>Patient Brochure</h5>
                <p>Brochure with information for patients and care partners about KRAZATI.</p>
                <div><a href="/pdfs/patient-brochure-crc.pdf" className={styles.downloadButton} target="_blank"><img src="/assets/resources/img-download-white.svg" alt="" />Download</a></div>
              </div>
            </div>
          </Container>
        </div>
      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="Learn about BMS Access Support" link="/c/support" />
    </CRCLayout>
  )
}
