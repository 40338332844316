import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { Box, Container, StyledBothSideShape, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import { Title1 } from './Slide2';
import '../css/slide6.scss';

const Slide5 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;

    useActiveLink(setShowHeaderSecondaryMenu, 'safety');

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(5);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    if (isMobile) {
        return (
            <Scroll isiconfig={isiconfig}>
                <ContainerScroll>
                    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
                        <CustomWrapper>
                            <Title>
                                <Title1>
                                    KRAZATI discontinuation and dosage modification rates<sup onClick={() => handleShowModal([30])}>30</sup>
                                </Title1>
                            </Title>

                            <SubTitle>N=116</SubTitle>

                            <MobileContent>
                                <InfoContainer>
                                    <div>
                                        <InfoHeader>
                                            <span>
                                                77<sup className='no-cursor'>%</sup>
                                            </span>
                                            <p>Dose interruptions due to an AR</p>
                                        </InfoHeader>

                                        <InfoBody>
                                            <p>
                                                Adverse reactions (ARs) requiring dosage interruption in ≥2% of patients who received KRAZATI included nausea, hepatotoxicity,
                                                fatigue, vomiting, pneumonia, renal impairment, diarrhea, QTc interval prolongation, anemia, dyspnea, increased lipase, decreased
                                                appetite, dizziness, hyponatremia, muscular weakness, increased amylase, pneumonitis, sepsis, and decreased weight.
                                            </p>
                                        </InfoBody>
                                    </div>
                                </InfoContainer>

                                <InfoContainer>
                                    <div>
                                        <InfoHeader className='reductions'>
                                            <span>
                                                28<sup className='no-cursor'>%</sup>
                                            </span>
                                            <p>Dose reductions due to an AR</p>
                                        </InfoHeader>

                                        <InfoBody>
                                            <p>
                                                ARs which required dose reductions in ≥2% of patients who received KRAZATI included hepatotoxicity, fatigue, nausea, diarrhea,
                                                vomiting, and renal impairment.
                                            </p>
                                        </InfoBody>
                                    </div>
                                </InfoContainer>

                                <InfoContainer>
                                    <div>
                                        <InfoHeader className='reductions discontinued'>
                                            <span>
                                                13<sup className='no-cursor'>%</sup>
                                            </span>
                                            <p>Discontinued due to an AR</p>
                                        </InfoHeader>

                                        <InfoBody>
                                            <p>
                                                ARs which resulted in permanent discontinuation of KRAZATI occurring in 2 patients each (1.7%) were pneumonia and pneumonitis, and
                                                occurring in 1 patient each (0.9%), were cerebrovascular accident, dyspnea, decreased ejection fraction, encephalitis,
                                                gastrointestinal obstruction, hemorrhage, hepatotoxicity, hypotension, muscular weakness, pulmonary embolism, pyrexia, respiratory
                                                failure, and sepsis.
                                            </p>
                                            <br />
                                            <p>Serious ARs were reported in 57% of patients receiving KRAZATI. Fatal ARs occurred in 11% of patients.</p>
                                        </InfoBody>
                                    </div>
                                </InfoContainer>
                            </MobileContent>
                        </CustomWrapper>

                        {/* <CustomReference>
              <p>AR=adverse reaction;</p>
              </CustomReference>  */}
                    </CustomContainer>
                </ContainerScroll>
            </Scroll>
        );
    }

    return (
        <Scroll isiconfig={isiconfig}>
            <ContainerScroll>
                <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
                    <CustomWrapper>
                        <Title>
                            <Title1>
                                KRAZATI discontinuation and dosage modification rates<sup onClick={() => handleShowModal([30])}>30</sup>
                            </Title1>
                        </Title>
                        <SubTitle>N=116</SubTitle>
                        <DesktopTable>
                            <div className='wrapper'>
															<span className="connection top" />
															<InfoContainer>
                                <div>
                                  <InfoHeader>
                                      <span>
                                          77<sup className='no-cursor'>%</sup>
                                      </span>
                                      <p>Dose interruptions due to an AR</p>
                                  </InfoHeader>

                                  <InfoBody>
                                      <p>
                                          Adverse reactions (ARs) requiring dosage interruption in ≥2% of patients who received KRAZATI included nausea, hepatotoxicity,
                                          fatigue, vomiting, pneumonia, renal impairment, diarrhea, QTc interval prolongation, anemia, dyspnea, increased lipase, decreased
                                          appetite, dizziness, hyponatremia, muscular weakness, increased amylase, pneumonitis, sepsis, and decreased weight.
                                      </p>
                                  </InfoBody>
                                </div>
															</InfoContainer>

															<InfoContainer>
																	<div>
																			<InfoHeader className='reductions'>
																					<span>
																							28<sup className='no-cursor'>%</sup>
																					</span>
																					<p className='extra-padding-left'>Dose reductions due to an AR</p>
																			</InfoHeader>

																			<InfoBody>
																					<p>
																							ARs which required dose reductions in ≥2% of patients who received KRAZATI included hepatotoxicity, fatigue, nausea, diarrhea,
																							vomiting, and renal impairment.
																					</p>
																			</InfoBody>
																	</div>
															</InfoContainer>
															<span className="connection bottom" />
                            </div>
                            {/* <DiscontinuedWrapper> */}
                            <InfoContainer>
                                <div>
                                    <InfoHeader className='reductions discontinued'>
                                        <span>
                                            13<sup className='no-cursor'>%</sup>
                                        </span>
                                        <p>Discontinued due to an AR</p>
                                    </InfoHeader>

                                    <InfoBody>
                                        <p>
                                            ARs which resulted in permanent discontinuation of KRAZATI occurring in 2 patients each (1.7%) were pneumonia and pneumonitis, and
                                            occurring in 1 patient each (0.9%), were cerebrovascular accident, dyspnea, decreased ejection fraction, encephalitis, gastrointestinal
                                            obstruction, hemorrhage, hepatotoxicity, hypotension, muscular weakness, pulmonary embolism, pyrexia, respiratory failure, and sepsis.
                                        </p>
                                        <br />
                                        <p>Serious ARs were reported in 57% of patients receiving KRAZATI. Fatal ARs occurred in 11% of patients.</p>
                                    </InfoBody>
                                </div>
                                {/* </DiscontinuedWrapper> */}
                            </InfoContainer>
                        </DesktopTable>
                    </CustomWrapper>

                    {/* <Reference>
              <p>AR=adverse reaction;</p>
            </Reference>  */}
                </CustomContainer>
            </ContainerScroll>
        </Scroll>
    );
};

export default Slide5;

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomContainer = styled(Container)`
    display: flex;
    padding: 0;
    max-width: 100%;
    justify-content: space-between;
    padding-bottom: 2rem;

    height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;
const CustomWrapper = styled(Wrapper)`
    height: auto;
    margin-bottom: 2rem;
    margin-top: 2.7rem;
    @media ${device.desktop} {
        margin-top: 1rem;
        margin-bottom: 0rem;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 2.1rem;
    text-transform: uppercase;
    padding: 0 1.6rem;

    h2 {
        text-transform: uppercase;
    }

    @media ${device.desktop} {
        margin-bottom: 0.6rem;
    }
`;
const MobileContent = styled(Box)`
    height: auto;
    flex-direction: column;
    align-items: center;
`;

const DesktopTable = styled(Box)`
    height: auto;
    flex-direction: column;
    align-items: center;
    max-width: 110rem;
    position: relative;

    .wrapper {
        display: flex;
        > div {
            margin-top: 0;
            margin-right: 4rem;

            &:after {
                content: none;
            }
            &:last-of-type {
                margin-right: 0;
            }
            > div {
                height: 100%;
                width: 52.8rem;
                padding: 1.8rem 1.6rem;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
            }
        }
    }
`;

const SubTitle = styled.h3`
    color: #17c94e;
    font-family: 'Oswald';
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 3.6rem;

    @media ${device.desktop} {
        margin-bottom: 4.5rem;
        position: relative;
        padding-right: 1rem;
    }
`;
const InfoContainer = styled.div`
    margin-top: 0;
    position: relative;
    margin-top: 2.6rem;
    max-width: 95%;

    &:last-of-type {
        margin-top: 3.2rem;
    }

    > div {
        padding: 1.6rem;
        height: auto;

        &.discontinued {
            width: 70rem;
            padding: 0.8rem 1.6rem;
        }
    }
    &:after {
        content: '';
        width: 11.3rem;
        height: 6.1rem;
        border-top: 0.1rem solid white;
        border-right: 0.1rem solid white;
        position: absolute;
        top: -0.8rem;
        right: 0.8rem;
        z-index: 20;
    }

    @media ${device.tablet} {
        max-width: 70%;
    }

    @media ${device.laptop} {
        max-width: 50%;
    }

    @media ${device.desktop} {
        &:last-of-type {
            margin-top: 3.5rem;
            max-width: 100%;
        }

        &:after {
            content: none;
            width: 14.4rem;
            height: 9.6rem;
            right: 1.2rem;
            top: -0.8rem;
        }
    }
`;

const InfoHeader = styled.div`
    font-family: 'Oswald';

	span {
        color: #17c94e;
        font-size: 4.1rem;
        font-weight: bold;
        letter-spacing: 0;
		line-height: 1;

        sup {
            font-size: 2rem;
        }
    }

    p {
        color: #ffffff;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 500;
		display: inline-block;
		margin-left: 5px;
    }

    @media ${device.desktop} {
        padding-left: 2rem;

        span {
            font-size: 8rem;
			position: relative;

            sup {
              font-size: 3.6rem;
              top: 48px;
              right: 0;
              margin: 0;
              padding: 0;
              transform: translateX(100%);
            }
        }

        p {
            font-size: 2.2rem;
        }
    }
`;

const InfoBody = styled.div`
    color: #ffffff;
    font-family: 'Barlow';
    font-size: 1.3rem;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;

    @media ${device.desktop} {
        font-size: 1.7rem;
        line-height: 2rem;
		margin-top: 5px;
    }
`;

const DiscontinuedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 2rem;
    position: relative;
    > div {
        &:first-child {
            font-family: 'Oswald';
            font-weight: 600;
            line-height: 1;
            margin-right: 0.9rem;
            display: flex;
            span {
                color: #17c94e;
                font-size: 8rem;
                letter-spacing: 0;
                sup {
                    color: #17c94e;
                    position: relative;
                    top: -1rem;
                    left: 0.3rem;
                    font-size: 3.2rem;
                }
            }
        }

        &:last-child {
            color: #ffffff;

            p {
                position: relative;
                &:first-child {
                    font-family: 'Oswald';
                    font-size: 1.6rem;
                    font-weight: bold;
                    margin-bottom: 0.5rem;
                    text-transform: uppercase;
                    position: absolute;
                    top: 5rem;
                    left: 11rem;
                }

                &:last-child {
                    font-family: 'Barlow';
                    font-size: 1.3rem;
                    font-weight: 500;
                }
            }
        }
    }

    @media ${device.tablet} {
        max-width: 70%;
    }

    @media ${device.laptop} {
        max-width: 50%;
    }

    @media ${device.desktop} {
        max-width: 77rem;
        margin-top: 5rem;
        position: relative;
        padding-left: 8.5rem;
        &:after {
            content: '';
            background: white;
            width: 0.25rem;
            height: 1.6rem;
            position: absolute;
            top: -2.6rem;
            left: 50%;
        }

        > div {
            &:first-child {
                margin-right: 1.8rem;
                span {
                    font-size: 8rem;
                    sup {
                        font-size: 3.6rem;
                    }
                }
            }

            &:last-child {
                p {
                    &:first-child {
                        font-size: 2.2rem;
                        margin-bottom: 0;
                        left: 17.5rem;
                        top: 4.5rem;
                    }

                    &:last-child {
                        font-size: 1.7rem;
                        padding-left: 1.5rem;
                    }
                }
            }
        }
    }
`;

const CustomReference = styled(Reference)`
    margin-bottom: 10rem;
`;
