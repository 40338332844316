import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import React from "react";
import styled from "styled-components/macro";
import { device } from "../../../_assets/styles/theme";
import Leaving from "../../Modal/Leaving";
import Footer from "../../Footer";
import IndicationContent from "./IndicationContent";

/* Contains ISI body text */

export const IsiHeader = ({ hiddenRef }) => {
  return (
    <ContentWrapper ref={hiddenRef}>
      <ISIWrap>
        <ISIHalf>
          <ContentCustomSpace>WARNINGS AND PRECAUTIONS</ContentCustomSpace>

          <ContentCustomSpace>Gastrointestinal Adverse Reactions</ContentCustomSpace>
          <p>
            <span className="bullet">•</span>KRAZATI can cause severe gastrointestinal adverse reactions
            <br />
            <span className="bullet">•</span>Monitor and manage patients using supportive care, including
            antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently
            discontinue KRAZATI based on severity
          </p>
        </ISIHalf>
        <ISIHalf className="indication-container">
          <IndicationContent />
        </ISIHalf>
      </ISIWrap>
    </ContentWrapper>
  );
};

const IsiContent = ({ hiddenRef }) => {
  const [currentLocation, setCurrentLocation] = useState("");
  let location = useLocation();
  useEffect(() => {
    setCurrentLocation(location.pathname.split("/")[2]);
  }, [location]);
  return (
    <ContentWrapper>
      <ISIWrap>
        <ISIHalf>
          <strong className="warning p-top">WARNINGS AND PRECAUTIONS</strong>
          <strong>Gastrointestinal Adverse Reactions</strong>
          <p className="close-bullet">
            <span className="bullet">•</span>KRAZATI can cause severe gastrointestinal adverse reactions
            <br />
            <span className="bullet">•</span>Monitor and manage patients using supportive care, including
            antidiarrheals, antiemetics, or fluid replacement, as indicated. Withhold, reduce the dose, or permanently
            discontinue KRAZATI based on severity
          </p>
          <strong>QTc Interval Prolongation</strong>
          <p>
            <span className="bullet">•</span>KRAZATI can cause QTc interval prolongation, which can increase the risk
            for ventricular tachyarrhythmias (eg, torsades de pointes) or sudden death
          </p>
          <p>
            <span className="bullet">•</span>Avoid concomitant use of KRAZATI with other products with a known potential
            to prolong the QTc interval. Avoid use of KRAZATI in patients with congenital long QT syndrome and in
            patients with concurrent QTc prolongation
          </p>

          <p>
            <span className="bullet">•</span>Monitor ECGs and electrolytes, particularly potassium and magnesium, prior
            to starting KRAZATI, during concomitant use, and as clinically indicated in patients with congestive heart
            failure, bradyarrhythmias, electrolyte abnormalities, and in patients who are unable to avoid concomitant
            medications that are known to prolong the QT interval. Correct electrolyte abnormalities. Withhold, reduce
            the dose, or permanently discontinue KRAZATI, depending on severity
          </p>

          <strong>Hepatotoxicity</strong>
          <p>
            <span className="bullet">•</span>KRAZATI can cause hepatotoxicity, which may lead to drug-induced liver
            injury and hepatitis
          </p>
          <p>
            <span className="bullet">•</span>Monitor liver laboratory tests (AST, ALT, alkaline phosphatase, and total
            bilirubin) prior to the start of KRAZATI, and monthly for 3 months or as clinically indicated, with more
            frequent testing in patients who develop transaminase elevations. Reduce the dose, withhold, or permanently
            discontinue KRAZATI based on severity
          </p>

          <strong>Interstitial Lung Disease/Pneumonitis</strong>
          <p>
            <span className="bullet">•</span>KRAZATI can cause interstitial lung disease (ILD)/pneumonitis, which can be
            fatal
          </p>

          <p>
            <span className="bullet">•</span>Monitor patients for new or worsening respiratory symptoms indicative of
            ILD/pneumonitis (eg, dyspnea, cough, fever) during treatment with KRAZATI. Withhold KRAZATI in patients with
            suspected ILD/pneumonitis and permanently discontinue KRAZATI if no other potential causes of
            ILD/pneumonitis are identified
          </p>

          <strong>ADVERSE REACTIONS</strong>
          <p>
            <span className="bullet">•</span>Serious adverse reactions occurred in 57% of 116 patients who received
            adagrasib in NSCLC patients. The most common adverse reactions in NSCLC patients (≥20%) were diarrhea,
            nausea, fatigue, vomiting, musculoskeletal pain, hepatotoxicity, renal impairment, dyspnea, edema, decreased
            appetite, cough, pneumonia, dizziness, constipation, abdominal pain, and QTc interval prolongation
          </p>

          <strong>DRUG INTERACTIONS</strong>
          <p>
            <span className="bullet">•</span>Strong CYP3A4 Inducers: Avoid concomitant use.
          </p>
          <p>
            <span className="bullet">•</span>Strong CYP3A4 Inhibitors: Avoid concomitant use until adagrasib
            concentrations have reached steady state (after ~8 days).
          </p>
          <p>
            <span className="bullet">•</span>Sensitive CYP3A4 Substrates: Avoid concomitant use with sensitive CYP3A4
            substrates.
          </p>
          <p>
            <span className="bullet">•</span>Sensitive CYP2C9 or CYP2D6 Substrates or P-gp Substrates: Avoid concomitant
            use with sensitive CYP2C9 or CYP2D6 substrates or P-gp substrates where minimal concentration changes may
            lead to serious adverse reactions.
          </p>
          <p>
            <span className="bullet">•</span>Drugs That Prolong QT Interval: Avoid concomitant use with KRAZATI.
          </p>
          <p className="no-indent">
            <em>
              Please see Drug Interactions Section of the Full Prescribing Information for additional information.
            </em>
          </p>
          <strong>USE IN SPECIFIC POPULATIONS</strong>
          <strong>Females and Males of Reproductive Potential</strong>
          <p>
            <span className="bullet">•</span>Infertility: Based on findings from animal studies, KRAZATI may impair
            fertility in females and males of reproductive potential
          </p>

          <strong>Lactation</strong>
          <p>
            <span className="bullet">•</span>Advise not to breastfeed
          </p>
          <strong>
            Please see{" "}
            <Leaving url="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank">
              Full Prescribing Information
            </Leaving>
            .
          </strong>
          <MobileFooterWrapper className={`${currentLocation}`}>
            <Footer />
          </MobileFooterWrapper>
        </ISIHalf>
        <ISIHalf className="indication-container">
          <IndicationContent />
        </ISIHalf>
      </ISIWrap>
    </ContentWrapper>
  );
};

export default IsiContent;

const MobileFooterWrapper = styled.div`
  display: block;
  margin-top: 30px;
  margin-left: -3vw;
  margin-right: -3vw;
  &.references {
    margin-left: -5vw;
    margin-right: -5vw;
  }
  @media ${device.desktop} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 20px;
  font-family: "Barlow", sans-serif;
  color: #434343;

  .bullet {
    position: absolute;
    left: 7px;
  }

  p {
    margin: 0.5rem 0;
    font-size: 9px;
    font-weight: 400;
    position: relative;
    padding-left: 35px;

    &.indication {
      padding-left: 0;

      &:nth-of-type(2) {
        margin-bottom: 2rem;
      }
    }
    &:last-of-type {
      padding-bottom: 1rem;
    }

    &.close-bullet {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &.no-indent {
      padding-left: 0;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  @media ${device.tablet} {
    p {
      font-size: 16px;
      margin: 0.5rem 0;
      padding-right: 1.6rem;
    }
  }

  @media ${device.desktop} {
    p {
      padding-left: 35px;
    }
  }
  strong {
    color: #434343;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 9px;
    font-weight: 500;
    display: block;
    &.warning {
      margin: 0;
      + strong {
        margin-top: 5px;
      }
    }
    &.p-top {
      margin-top: 6px;
      font-weight: 600;
    }
    @media ${device.tablet} {
      margin-top: 1rem;
      margin-bottom: 0.8rem;
      font-size: 16px;
    }
  }
`;
const ISIWrap = styled.div`
  display: flex;
  gap: 0 5%;
`;
const ISIHalf = styled.div`
  flex: 1 0 45%;
  &.indication-container {
    display: none;
  }
  @media ${device.desktop} {
    &.indication-container {
      display: inherit;
      flex-direction: column;
    }
  }
`;

const ContentWrapperMobile = styled(ContentWrapper)`
  padding-right: 0;

  p {
    margin: 0;
    font-size: 1rem;
  }
`;

const ContentCustomSpace = styled.h3`
  color: #434343;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;

  @media ${device.tablet} {
    margin-bottom: 0.8rem;
    font-size: 16px;
  }

  &.prescribing {
    padding-bottom: 2rem;
  }
`;
