import CRCLayout from "./layouts/CRCLayout"
import JumpMenu from './components/JumpMenu'
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import FooterCallout from "./components/FooterCallout"
import ContentContainer from "../_common/components/Layout/ContentContainer"
import JumpLink from "./components/JumpLink"
import RefModal from "./components/RefModal"
import MetaTags from "../_common/components/MetaTags"
import styles from './CRCDosing.module.scss'

export default function CRCDosage() {
  const links = [
    {
      url: '#recommended-dosage',
      text: 'Recommended dosage'
    },
    {
      url: '#dosage-modifications',
      text: 'Dosage modifications'
    }
  ]
  return (
    <CRCLayout>
      <MetaTags
        title='Recommended Dosage for Krazati®'
        description="Understand the recommended dosage regimen for Krazati®. Twice daily oral dosing."
        ogUrl='https://www.krazatihcp.com/c/dosage'
      />
      {/* Banner */}
      <InteriorBanner title="DOSING" imageUrl="/banners/banner-about-kras.jpg" desktopImageUrl="/banners/banner-about-kras-desktop.jpg" />
      {/* Jump Menu */}
      <JumpMenu title="DOSING DETAILS" links={links} />

      <MainContent>
        <div className={styles.infusionTheraphy}>
          <JumpLink id="recommended-dosage" />
          <ContentContainer>
            <div>
              <h2>KRAZATI + cetuximab infusion therapy</h2>
            </div>
            <div>
              <p><strong>KRAZATI recommended dosage: 600 mg BID<RefModal references={[1]} /></strong></p>
              <p><b>Until disease progression or unacceptable toxicity.</b></p>
              <img className={styles.imgDesktop} src="/assets/dosing/img-bottle.jpg" alt="Dosing and Bottle of Tablets Used for Treatment" />
              <img className={styles.imgMobile} src="/assets/dosing/img-bottle-mobile.png" alt="Dosing and Bottle of Tablets Used for Treatment" />
            </div>
            <div>
              <h3>How large is one tablet of KRAZATI?</h3>
              <img className={styles.imgDesktop} src="/assets/dosing/img-pills.jpg" alt="Size Comparison of Tablet" />
              <img className={styles.imgMobile} src="/assets/dosing/img-pills-mobile.jpg" alt="Size Comparison of Tablet" />
              <h3>KRAZATI is available as immediate-release tablets</h3>
              <ul>
                <li>Advise patients to take KRAZATI at the same time every day with or without food</li>
                <li>KRAZATI tablets should be swallowed whole (not chewed, crushed, or split)</li>
                <li><b>If a patient misses a dose</b> by more than 4 hours or if vomiting occurs after taking KRAZATI, advise patients not to take an additional dose. Resume dosing at the next scheduled time</li>
                <li>There are no restrictions for use of PPIs or H<sub>2</sub>-receptor antagonists in the KRAZATI Prescribing Information</li>
              </ul>
            </div>
          </ContentContainer>
        </div>

        <div className={styles.callout1}>
          <ContentContainer>
            <img src="/assets/icons/icon-double-arrow.svg" alt="" />
            <div>
              <h3>Refer to the cetuximab Prescribing Information for cetuximab dosage information.</h3>
              {/* <p>If permanent discontinuation of cetuximab is recommended, treatment with KRAZATI as a <b>single agent</b> can be continued.</p>
              <p>For combination treatment, permanently discontinue cetuximab when permanently discontinuing treatment with KRAZATI.</p> */}
            </div>
          </ContentContainer>
        </div>

        <div className={styles.dosageModifications}>
          <JumpLink id="dosage-modifications" />
          <ContentContainer>
            <h2>KRAZATI dosage modifications for adverse reactions (ARs)<RefModal references={[1]} /></h2>
            <p><strong>If ARs occur, a maximum of 2 dose reductions is permitted. Permanently discontinue KRAZATI in patients who are unable to tolerate 600 mg once daily.</strong></p>

            <img className={styles.imgDosageDesktop} src="/assets/dosing/img-dosage.png" alt="Dosage Modifications for Adverse Reactions" />
            <img className={styles.imgDosageMobile} src="/assets/dosing/img-dosage-mobile.png" alt="Dosage Modifications for Adverse Reactions" />
            <p>Refer to the cetuximab Prescribing Information for dose modifications for ARs associated  with cetuximab.</p>
            <p>Treatment with KRAZATI as a <b>single agent</b> may be continued if cetuximab is permanently discontinued.</p>
            <p>For combination treatment, withhold or permanently discontinue cetuximab when  KRAZATI is withheld or permanently discontinued.</p>
            <br />
            <p><strong>Refer to the <a href="https://packageinserts.bms.com/pi/pi_krazati.pdf" target="_blank" rel="noreferrer">KRAZATI Prescribing Information</a> and <a href="/pdfs/therapy-management-guide.pdf" target="_blank">Therapy Management Guide</a> for information about dosage modifications for specific ARs.</strong></p>

          </ContentContainer>
        </div>

        <div className={`${styles.callout} ${styles.callout2}`}>
          <ContentContainer>
            <img src="/assets/icons/icon-double-arrow.svg" alt="" />
            <div>
              <h3>A dosage reduction doesn't immediately require a new prescription</h3>
              <p>If you determine that a dosage reduction is necessary, your patient can continue KRAZATI therapy at the next lower dose without immediately requiring a new prescription.</p>
            </div>
          </ContentContainer>
        </div>

        <div className={styles.references}>
          <ContentContainer>
            <p><small>BID=twice-daily; PPI=proton pump inhibitor, QD=once-daily.</small></p>
          </ContentContainer>
        </div>
      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="Resources for you and your patients" link="/c/resources" />
    </CRCLayout>
  )
}
