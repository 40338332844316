import React, { useState } from 'react'
import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { device } from '../../../_assets/styles/theme'
import DosingTabItem from './DosingTabItem'
import useMobileDetect from '../../../../_hooks/useMobileDetect'
import HorizontalScroll from '../../../_components/PageSwipper/components/HorizontalScroll'
import { BaseText } from '../../../_components/PageSwipper/utils/styles'

const DosingTabs = ({items, horizontal}) => {
  const [currentTab, setCurrentTab] = useState(0)
  const { isMobile } = useMobileDetect()

  return (
    <Tab>
     
      <Container>

          {horizontal ? (
              <HeaderSlider>
                <HorizontalScroll>
                  {items.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        { currentTab === index && item.legend ? <Legend key={`l-${index}`}>{item.legend}</Legend> : <Legend key={`l-${index}`} >&nbsp;</Legend> }
                        
                        <DosingTabItem 
                          style={ currentTab === index ? {background: "linear-gradient(108.88deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.53) 100%)"}   : null} 
                          key={index} 
                          item={index} 
                          setCurrentTab={setCurrentTab} 
                          title={item.title}
                        />
                      </React.Fragment>
                    )
                  })}
                </HorizontalScroll>
              </HeaderSlider>
            )
            : (
              <Header>
                {items.map((item, index) => <DosingTabItem style={ currentTab === index ? {background: "linear-gradient(108.88deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.53) 100%)"}  : null} key={index} item={index} setCurrentTab={setCurrentTab} title={item.title}/> )}
              </Header>
            )
          }
         

          
        {items.map((item, index) => currentTab === index && React.cloneElement(item.component, {key: index}))}
      </Container>

      {!isMobile && (
        items.map((item, index) => currentTab === index && <div key={index}>{item.reference}</div>)
      )}

    </Tab>
  )
}

export default DosingTabs

export const Scroll = styled.div`
  height: 80vh;
`

const Tab = styled.div`
  padding: 0 0.8rem;
  display: flex;
  flex-direction: column;
  max-width: 180rem;
  width: 100%;
  height: calc(100% - 2.4rem);

  @media ${device.desktop} {
    height: calc(100% - 5.6rem);
  }
  
  @media ${device.desktop800h} {
    height: calc(100% - 4rem);
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.desktop} {
    margin-top: 4.8rem;
  }

  @media ${device.desktop800h} {
    margin-top: 2rem;
  }

  @media ${device.desktop600h} {
    margin-top: 1rem;
  }

  .dosing-tab {
    max-height: 12.5rem;

    > div {
      padding: 1.4rem 3rem;
    }
  }
`
const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 93rem;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 0.8rem 0.8rem;
  
  @media ${device.desktop} {
    margin: 0 auto 3.2rem;
  }

  @media ${device.desktop800h} {
    margin: 0 auto 2rem;
  }

  @media ${device.desktop600h} {
    max-width: 80rem;
  }
`

const HeaderSlider = styled(Header)`
  padding: 2rem 0 2.4rem;

  .swiper {
    padding: 0 3.2rem;
  }
  .swiper-scrollbar {
    display: none;
  }

`
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.2rem;
  margin-bottom: 1.2rem;

  @media ${device.desktop} {
    margin: 0 auto 3.2rem;
    padding: 0;
  }
`
export const TitleText = styled.h1`
  font-family: 'Oswald', sans-serif;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.6rem;
  text-align: center;

  sup {
    font-size: 1.1rem;
  }
  span {
    color: #17C94E;
    font-weight: bold;
  }

  @media ${device.desktop} {
    font-size: 2.9rem;
    text-align: initial;
  }
 
  @media ${device.desktop800h} {
    font-size: 1.8rem;
font-size: 1.8rem;
  }

  @media ${device.desktop600h} {
    font-size: 1.6rem;
  }
`

export const ReasonBox = styled.div`
  width: 9.6rem;
  max-height: 9.6rem;
  box-shadow: 0.3rem .2rem 1rem -.5rem #000;
  background: #223758;
  padding: 0.8rem;
  margin: 0 2rem 0 0;
  align-items: flex-start;
  justify-content: space-between;
  display: none;

  @media ${device.tablet} {
    display: flex;
  }

  @media ${device.desktop800h} {
    width: 8rem;
    max-height: 8rem;
  }

  @media ${device.desktop600h} {
    width: 7rem;
    max-height: 7rem;
  }
`

export const ReasonTag = styled(BaseText)`
  font-family: 'Proxima Nova', sans-serif;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-size: 2rem;
  margin-right: 1rem;
  color: white;
  text-transform: uppercase;

  @media ${device.desktop800h} {
    font-size: 1.6rem;
  }

  @media ${device.desktop600h} {
    font-size: 1.4rem;
  }
`

export const ReasonNumber = styled(BaseText)`
  font-family: 'Proxima Nova', sans-serif;
  font-size: 6.6rem;
  color: white;
  text-transform: uppercase;
  text-align: initial;
  padding-right: 1rem;

  @media ${device.desktop800h} {
    font-size: 5.6rem;
  }

  @media ${device.desktop600h} {
    font-size: 5rem;
  }
`

const Legend = styled.p`
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 0.8rem;
  color: white;
  padding-left: 1rem;
  padding-bottom: 0.4rem;
  text-align: center;
`