import styles from './RefModal.module.scss'
import * as Dialog from '@radix-ui/react-dialog';
import { referenceData } from '../data/referenceData';
export default function RefModal({ references = [], append, display }) {
  const _refs = references.length && references.map((reference, index) => {
    if (references.length !== index + 1) return reference + ',';
    return reference;
  })
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <sup className={styles.dialogTrigger}>
          {display || _refs}
          {append}
        </sup>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content className={styles.dialogContent}>
          <Dialog.Close className={styles.dialogClose}>
            <img src="/assets/icons/icon-indication-close-button.svg" alt="Close references modal" />
          </Dialog.Close>
          <ul className={styles.referenceList}>
            {references.length && references.map((reference, index) => {
              return <li key={index} dangerouslySetInnerHTML={{ __html: `<span>${reference}.</span> <span>${referenceData[reference - 1]}</span>` }}></li>;
            })}
          </ul>
        </Dialog.Content>
      </Dialog.Portal >
    </Dialog.Root >
  )
}
