import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import ReferenceModal from '../../_components/Modal/ReferenceModal'
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';

const Slide3 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const slideOverlay = `linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`;

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])

  useActiveLink(setShowHeaderSecondaryMenu, 'testing')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(2)
      setOverlay(slideOverlay)
      setBackground(`linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`);

      return
    }
    setShowPageContent(false)

  }, [swiperSlide.isActive])


  return (
    <Scroll isiconfig={isiconfig}>
      <ContainerScroll>
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'}>
          {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
          <Wrapper>
            <Title>
              <BaseSubtitle>mutational analysis in community setting</BaseSubtitle>
            </Title>

            <Characteristics>
              <Wrap>
                <CharacteristicsItem>
                  <CharacteristicImg>
                    <img src='/assets/icn_mutational-analysis.png' alt="Mutational Analysis in Community Setting" />
                  </CharacteristicImg>
                  <div>
                    <div className="percent-wrap">
                      <p className="percent">52%</p>
                      <p className="percent-text">Tested for &gt;1 driver mutation prior to 1L therapy<sup onClick={() => handleShowModal([27])}>27*</sup></p>
                    </div>
                    <div className="percent-wrap">
                      <p className="percent">45%</p>
                      <p className="percent-text">Patients with advanced NSCLC tested with NGS<SupOffset onClick={() => handleShowModal([28])}>28<span className="dagger">†</span></SupOffset></p>
                    </div>
                  </div>
                </CharacteristicsItem>
              </Wrap>
            </Characteristics>
          </Wrapper>
          <CustomReference>
            <p>
              *Based on a retrospective study assessing real-world biomarker testing patterns in the community setting in patients with de novo metastatic NSCLC treated with systemic therapy between 2017 and 2019 (N=2257). Of the 2257 patients, 1724 had results available for at least 1 driver mutation or PD-L1 during the study period.
            </p>
            <p>
              <sup>†</sup>Based on retrospective observational chart review study of patients with metastatic NSCLC initiating first-line systemic therapy between 10/01/2019 and 03/31/2020 (N=3474).
            </p>
            <p>
              1L=first-line; NGS=next-generation sequencing; NSCLC=non-small cell lung cancer; PD-L1=programmed death-ligand 1.
            </p>
          </CustomReference>
        </CustomContainer>
      </ContainerScroll>
    </Scroll>
  )
}

export default Slide3;

const SupOffset = styled.sup`
	position: relative;
  top: 0;
  @media ${device.desktop} {
    top: 3px;
  }
`;
const Footnote = styled(Reference)`
	margin: 4rem 0 10px;

	@media ${device.desktop} {
		margin: 0;
		transform: translateY(calc(-100% - 5px));
	}
`

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
  .swiper-wrapper {
    height: 100% !important;
    justify-content: center;
  }
`;
const CustomContainer = styled(Container)`
  display: flex;
  height:  100%;
  padding: 0;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.div`
  display: flex;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0 1.2rem;
  margin-bottom: 2.8rem;

  @media ${device.desktop} {
    max-width: 90rem;
    margin-bottom: 0;
  }
`


const Characteristics = styled.div`
  padding: 0 2rem;
  width: 100%;

  @media ${device.desktop} {
    width: auto;
    margin-top: 1.5rem;
    padding: 0;
  }
`
const Wrap = styled.div`
  margin: 2rem auto;
  width: 100%;
`
const CharacteristicsItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  width: 100%;
  color: white;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 2.5rem;
  justify-content: space-around;

  span {
    font-size: 1.5rem;
    letter-spacing: .06rem;
  }
  .percent-wrap {
    font-size: 1rem;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    .percent {
      font-size: 3rem;
      margin-left: 10px;
    }
    .percent-text {
      max-width: 12rem;
      margin-left: 1rem;
    }
  }

  @media ${device.tablet} {
    display: flex;
    gap: 4rem;

    span {
      font-size: 2.9rem;
      text-align: left;
    }

    &:first-of-type {
      img {
        max-width: 16rem;
      }
    }
    .percent-wrap {
      font-size: 1.75rem;
      .percent {
        font-family: 'Oswald', sans-serif;
        font-size: 5rem;
      }
      .percent-text {
        max-width: 21rem;
      }
    }
  }
`

const CharacteristicImg = styled.div`
  text-align: center;
  img {
    width: 9.25rem;
    text-align: center;
    margin-bottom: .5rem;
  }

  @media ${device.tablet} {
    img {
      width: 16rem;
    }
  }
  @media ${device.desktop} {
    img {
      width: initial;
      text-align: center;
    }
  }

  @media ${device.desktop800h} {
    img {
      max-width: 95%;
    }
  }
`
export const CustomReference = styled(Reference)`
    margin-bottom: 6rem;
    padding-right: 5.6rem;
    @media ${device.desktop} {
      margin-top: 6rem;
    }
`;
