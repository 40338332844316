import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { StyledBothSideShape } from '../PageSwipper/utils/styles'
import Modal from './Modal'
import { device } from '../../_assets/styles/theme'

export default function BMSModal(props) {

  const [open, setOpen] = useState(true);

  useState(()=> {
    const cookies = document.cookie
    if(cookies.includes('bmsModal=true')) {
      setOpen(false)
      return
    }
  }, [open])

  const handleContinue = () => {
    document.cookie = "bmsModal=true"
    setOpen(false)
  }
  return (
    <Modal isOpen={open}>
      <Container>
        <Wrapper>
          <img
            className="x show"
            src="/assets/closebtn.svg"
            alt="x"
            onClick={(e) => {
              e.preventDefault();
              handleContinue();
            }}
          />
          <h2>Mirati is now a<br/> Bristol Myers Squibb company</h2>
          <p>
            Select <a class="close-modal" href="" onClick={(e) => {e.preventDefault(); handleContinue();}}>Accept</a> for Information about Mirati.<br/>
            To learn more about Bristol Myers Squibb <a href="//www.bms.com/" target="_blank" rel="noopener" data-cta-type="cta" onClick={(e) => {
              handleContinue();
            }}>Click Here</a>.
          </p>
        </Wrapper>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  height: 21.2rem;
  width: 100%;
  max-width: 660px;
  font-family: 'Barlow';

  > div {
    box-shadow: 0 .2rem 0.4rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 1rem 0 rgba(0,0,0,0.12), 0 0.4rem .5rem 0 rgba(0,0,0,0.14);
    max-width: 95%;
    margin: 0 auto;

    @media ${device.desktop}{
      max-width: 100%;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background: #fff;
  text-align: center;
  position: relative;
  padding: 110px 20px;
  transform: translateY(-25%);

  img.x {
    align-self: flex-end;
    cursor: pointer;
    height: 19px;
    position: absolute;
    right: 13px;
    top: 14px;
    width: 19px;
    z-index: 1;
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    background: transparent;
    transform: none;
    padding: 0;
    margin: 10px auto 30px;
    left: 0;
    right: 0;
    color: #3246ff;
    font-family: "Barlow Condensed";
  }

  p {
    font-size: 20px;
    color: #4c4c4c;
    font-weight: 400;
    text-transform: initial;
    letter-spacing: 0;
    line-height: 1.5;
  }

  a {
    color: #3246ff;
    font-weight: 600;
    transition: all .3s;
    position: relative;
    text-decoration: underline;
    &:visited {
      color: #3246ff;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0.1rem;
      background: white;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  @media (max-width: 799px) {
    padding: 80px 20px 40px;
    h2 {
      font-size: 28px;
    }
    p {
      font-size: 19px;
    }
  }
  @media (max-width: 499px) {
    padding: 60px 20px 30px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
`
