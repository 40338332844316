import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useSwiperSlide } from "swiper/react";
import {
  BaseLink,
  BaseText,
  Box,
  Container,
  FlexCentered,
  StyledBothSideShape,
  Wrapper,
} from "../../_components/PageSwipper/utils/styles";
import useActiveLink from "../../_components/Header/hooks/useActiveLink";
import { device } from "../../_assets/styles/theme";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import ContainerScroll from "../../_components/PageSwipper/components/ContainerScroll";
import List from "../../_components/List";
import AccordionNoArrow from "../../_components/Accordion/AccordionNoArrow";
import HorizontalAccordion from "../../_components/Accordion/HorizontalAccordion";
import { FaChevronRight } from "react-icons/fa";
// import { Link } from 'react-router-dom';
import Leaving from "../../_components/Modal/Leaving";

const Slide1 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect();
  const swiperSlide = useSwiperSlide();
  const swiper = document.querySelector(".mySwiper");
  const [recalculate, setRecalculate] = useState(false);
  const [showPageContent, setShowPageContent] = useState(false);
  const slideOverlay = `#F0FFF9`;

  useActiveLink(setShowHeaderSecondaryMenu, "support");

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setShowPageContent(true);
      setCurrentSlide(1);
      setOverlay(slideOverlay);
      swiper.swiper.disable();
      return;
    }
    setShowPageContent(false);
  }, [swiperSlide.isActive]);

  return (
    <Scroll isiconfig={isiconfig}>
      <ContainerScroll>
        <Hero>
          <img src="../assets/bms-access-support-cta.png" alt="Logo" />
          <h2>At Bristol Myers Squibb, we provide support with purpose</h2>
        </Hero>

        <ContentWrapper>
          <Content>
            <p>
              <strong>
                The BMS Access Support program is dedicated to helping patients access their prescribed BMS medications.
              </strong>{" "}
              We may offer benefit investigations, prior authorizations assistance, appeals process support, and
              information on financial support options. The BMS Access Support Co-Pay Assistance Program assists with
              out-of-pocket co-payment or co-insurance requirements for eligible, commercially-insured patients who have
              been prescribed certain BMS products, including KRAZATI.
            </p>
            <p className="border-top">
              For more information, visit{" "}
              <a href="https://www.bmsaccesssupport.com/" target="_blank">
                BMS Access Support
              </a>{" "}
              or call BMS Access Support at{" "}
              <a href="tel:18008610048" className="no-style">
                1-800-861-0048
              </a>
              , 8 am to 8 pm ET, Monday – Friday.
            </p>
          </Content>
        </ContentWrapper>
      </ContainerScroll>
    </Scroll>
  );
};

export default Slide1;

const Refs = styled.div`
  font: 12px "Barlow", sans-serif;
  color: #3a3a3a;
  padding-left: 5px;
  margin-top: 15px;

  @media ${device.desktop} {
    margin-top: 0;
  }

  p {
    margin-bottom: 2px;
  }

  sup:first-of-type,
  span:first-of-type {
    margin: 0;
    padding: 0;
    width: 5px;
    position: relative;
    display: inline-block;
    margin-left: -5px;
    text-align: right;
  }
`;

const CustomBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
const Scroll = styled.div`
  height: calc(100% - 4.6rem);

  @media ${device.desktop} {
    height: 100%;
  }
`;

const Hero = styled.div`
  padding: 5vh 20px 20px;
  text-align: center;
  img {
    max-width: 315px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #be2bbb;
  }
  @media (max-width: 768px) {
    img {
      max-width: 225px;
    }
    h2 {
      font-size: 17px;
    }
  }
`;
const ContentWrapper = styled.div`
  margin: 10px;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.14));
`;

const Content = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto 60px;
  padding: 50px 75px;
  background: #fff;
  clip-path: polygon(20px 0, 100% 0, 100% calc(100% - 25px), calc(100% - 20px) 100%, 0 100%, 0 25px);

  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    strong {
      font-weight: 600;
    }
    &.border-top {
      margin-bottom: 0;
      padding-top: 30px;
      border-top: 2px solid #be2bbb;
    }
  }
  a {
    color: #be2bbb;
    text-decoration: underline;
  }
  a.no-style {
    color: inherit;
    text-decoration: none;
  }
`;
