import React from 'react';
import styled from 'styled-components/macro';
import { Scroll, Title, TitleText } from '../../_components/Tabs/Tabs';
import { Link } from 'react-router-dom';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import { FaArrowRight } from 'react-icons/fa';
import Reference from '../../_components/Reference';
import TwoSides from '../../_components/Shapes/TwoSides';
import NccnCard from './NccnCard';
import './css/style.scss';

const ListTextComponent = ({ handleShowModal }) => {
    return (
        <>
            <ListTitle>
                <span>58%</span> had a response duration of ≥6 months<sup onClick={() => handleShowModal([30])}>30</sup>
            </ListTitle>
            <ListWrapper>
                <li>
                    Median time to response was 1.4 months (range:&nbsp;0.9-7.2)<sup onClick={() => handleShowModal([31])}>31</sup>
                </li>
                <li>
                    <p>
                        At data cutoff, treatment was ongoing in 50% (24/48) of patients who experienced a response<sup onClick={() => handleShowModal([33])}>33</sup>
                    </p>
                </li>
                <li>
                    Median DOR: 8.5 months (95% CI: 6.2-13.8)<sup onClick={() => handleShowModal([30])}>30</sup>
                </li>
                {/* <li>
                    Median OS: 12.6 months (95% CI: 9.2-19.2)<sup onClick={() => handleShowModal([31])}>31</sup>
                </li>
                <li>
                    Median PFS: 6.5 months (95% CI: 4.7-8.4)<sup onClick={() => handleShowModal([31])}>31</sup>
                </li> */}
            </ListWrapper>
        </>
    );
};

const Tab1 = ({ isiconfig, handleShowModal, ...props }) => {
    const { isMobile } = useMobileDetect();

    if (isMobile) {
        return (
            <CustomScroll {...props} isiconfig={isiconfig}>
                <ContainerScroll>
                    <Container>
                        <Wrapper>
                            <CustomTitle>
                                <p>REGISTRATIONAL PHASE 2 COHORT</p>
                                <TitleText>
                                    43% ORR: A ROBUST RESPONSE TO A RELENTLESS DISEASE<sup onClick={() => handleShowModal([30])}>30</sup>
                                </TitleText>
                            </CustomTitle>
                            <TabShapeContainer className='tab-1'>
                                <TwoSides>
                                    <InfoTitle>
                                        <span className='green'>
                                            43<sup className='no-cursor'>%</sup>&nbsp;ORR<sup className='special-sup'>*<span className="dagger">†</span></sup>
                                        </span>
                                    </InfoTitle>
                                    <InfoSubTitle>(n=112; 95% CI: 34-53)</InfoSubTitle>
                                </TwoSides>
                            </TabShapeContainer>

                            <ListContainer>
                                <ListTextComponent handleShowModal={handleShowModal} />
                                {/* <ListText>
                                    Single-arm trials do not adequately characterize time-to-event endpoints such as OS and PFS. Thus, these data from KRYSTAL-1 cannot be
                                    interpreted as having OS and PFS benefit.
                                </ListText> */}
                            </ListContainer>
                            <NccnCard
                                title={
                                    <>
                                        <div>CATEGORY&nbsp;2A</div> <span>NCCN</span>Recommended
                                    </>
                                }
                                subtitle='NSCLC'
                                text={
                                    <>
                                        NCCN Guidelines recommend adagrasib (KRAZATI) as a subsequent therapy option{' '}
                                        <b>
                                            for patients with <em>KRAS G12C</em>-mutated advanced or metastatic NSCLC after progression (Category&nbsp;2A).
                                            <sup onClick={() => handleShowModal([1])}>1</sup>
                                        </b>
                                    </>
                                }
                            />
                        </Wrapper>
                        <ReferenceFooterWithLink linkText='Safety' url='/n/safety' style={{ margin: '0 0 10rem' }}>
                            <p>
                                *Tumor response was assessed by BICR. Phase 2 data cutoff: October 15, 2021 (median follow-up: 12.9 months). Additional follow-up planned.
                            </p>
                            <p style={{ margin: '4px 0' }}>
                                <sup>†</sup>Included in this analysis were patients lost to follow-up (15%) who were not evaluated.<sup onClick={() => handleShowModal([31])}>31</sup>
                            </p>
                            <p>
                                BICR=blinded independent central review; CI=confidence interval; DOR=duration of response;
                                NCCN=National Comprehensive Cancer Network; NSCLC=non-small cell lung cancer; ORR=objective response rate.
                            </p>
                        </ReferenceFooterWithLink>
                    </Container>
                </ContainerScroll>
            </CustomScroll>
        );
    }

    return (
        <CustomScroll {...props} isiconfig={isiconfig}>
            <Container>
                <Wrapper>
                    <CustomTitle>
                        <p>REGISTRATIONAL PHASE 2 COHORT</p>
                        <TitleText>
                            43% ORR: A ROBUST RESPONSE TO A RELENTLESS DISEASE<sup onClick={() => handleShowModal([30])}>30</sup>
                        </TitleText>
                    </CustomTitle>
                    <div className='main-content'>
                        <Box>
                            <TabShapeContainer className='tab-1'>
                                <TwoSides>
                                    <InfoTitle>
                                        <span className='green'>
                                            43<sup className='no-cursor'>%</sup>&nbsp;ORR<sup className='special-sup'>*<span className="dagger">†</span></sup>
                                        </span>
                                    </InfoTitle>
                                    <InfoSubTitle>(n=112; 95% CI: 34-53)</InfoSubTitle>
                                </TwoSides>
                            </TabShapeContainer>
                            <ListContainer className='tab-1'>
                                <ListTextComponent handleShowModal={handleShowModal} />
                            </ListContainer>
                        </Box>
                        <NccnCard
                            title={
                                <>
                                    <div>CATEGORY&nbsp;2A</div> <span>NCCN</span>Recommended
                                </>
                            }
                            subtitle='NSCLC'
                            text={
                                <>
                                    NCCN Guidelines recommend adagrasib (KRAZATI) as a subsequent therapy option{' '}
                                    <b>
                                        for patients with <em>KRAS G12C</em>-mutated advanced or metastatic NSCLC after progression (Category&nbsp;2A).
                                        <sup onClick={() => handleShowModal([1])}>1</sup>
                                    </b>
                                </>
                            }
                        />
                    </div>
                    {/* <ListText>
                        Single-arm trials do not adequately characterize time-to-event endpoints such as OS and PFS. Thus, these data from KRYSTAL-1 cannot be interpreted as having
                        OS and PFS benefit.
                    </ListText> */}
                </Wrapper>
            </Container>
            <ReferenceFooterWithLink linkText='Safety' url='/n/safety'>
                <p>
                    *Tumor response was assessed by BICR. Phase 2 data cutoff: October 15, 2021 (median follow-up: 12.9 months). Additional follow-up planned.
                </p>
                <p style={{ margin: '4px 0' }}>
                    <sup>†</sup>Included in this analysis were patients lost to follow-up (15%) who were not evaluated.<sup onClick={() => handleShowModal([31])}>31</sup>
                </p>
                <p>
                    BICR=blinded independent central review; CI=confidence interval; DOR=duration of response;
                    NCCN=National Comprehensive Cancer Network; NSCLC=non-small cell lung cancer; ORR=objective response rate.
                </p>
            </ReferenceFooterWithLink>
        </CustomScroll>
    );
};

export default Tab1;

export const CustomScroll = styled(Scroll)`
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: auto;

    @media ${device.desktop} {
        max-height: ${(props) => `calc(100% - ${props.isiconfig}rem)`};
        padding-top: 4rem;
    }
`;

const CustomTitle = styled(Title)`
    padding: 0 30px;
    margin-bottom: 1.8rem;
    flex-direction: column;
    align-items: center;

    p {
        color: #ffffff;
        font-family: 'Oswald';
        font-size: 1.2rem;
        letter-spacing: 0;
        line-height: 1.6rem;
    }

    @media ${device.desktop} {
        padding: 0;
        margin: 0 0 4rem;
        align-items: flex-start;
        h1 {
            font-size: 3.2rem;
        }

        p {
            color: #ffffff;
            font-family: 'Oswald';
            font-size: 1.7rem;
            letter-spacing: 0;
            line-height: 1.8rem;
        }
    }
`;
export const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    margin-bottom: 2rem;

    @media ${device.desktop} {
        margin-top: 1rem;
        max-width: 110rem;
        width: 90%;
        align-items: center;
        margin: 0 auto;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
	max-width: 475px;
	margin: auto auto 2rem;

	@media ${device.desktop} {
		max-width: 100%;
		align-items: flex-start;
	}
`;

export const TabShapeContainer = styled.div`
    margin: 0 auto 1.8rem;
    position: relative;

    &:after {
        content: '';
        width: 6.4rem;
        height: 4rem;
        border-top: 2px solid white;
        border-right: 2px solid white;
        position: absolute;
        top: -0.8rem;
        right: 1rem;
        z-index: 20;
    }
`;

export const CustomTwoSides = styled(TwoSides)``;

export const InfoTitle = styled.h3`
    color: white;
    font-family: 'Oswald';
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 14px;
    sup {
        font-size: 2.4rem;
        position: relative;
        top: -0.5rem;
        span {
         font-size: 75%;
         position: relative;
         top: -.3rem;
        }
    }

    .green {
        color: #17c94e;
    }

    @media ${device.desktop} {
        font-size: 5.5rem;
        margin-bottom: 2rem;
        sup {
            font-size: 3.6rem;

            &:last-of-type {
                font-weight: 600;
                font-size: 1.5rem;
                top: -1.5rem;
                left: -.2rem;
                .dagger {
                  font-size: 70%;
                  position: relative;
                  top: -0.45em;
                }
            }
        }
    }
`;

export const InfoSubTitle = styled.h4`
    line-height: 1;
    color: white;
    font-family: 'Oswald';
    font-size: 1.5rem;
    font-weight: 500;
	text-align: center;
    @media ${device.desktop} {
        font-size: 2rem;
    }
`;

export const ListContainer = styled.div`
    color: white;
    max-width: 95%;
    margin: auto;
    padding: 0 15px;

    @media ${device.tablet} {
        max-width: 100%;
        padding: 0 4rem;
    }

    @media ${device.desktop} {
        width: 60%;
        padding: 0 0 0 4rem;
    }
`;
export const ListTitle = styled.h5`
    font-family: 'Oswald';
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    sup {
        font-size: 55%;
    }

    span {
        color: #17c94e;
        font-weight: bold;
    }

    @media ${device.desktop} {
        font-size: 2.2rem;

        sup {
            font-size: 55%;
        }
    }
`;
export const ListWrapper = styled.ul`
  margin-bottom: 2rem;
	margin-top: 1rem;
	padding-left: 4.5rem;

	li {
		font: 500 1.2rem 'Barlow Semi Condensed', sans-serif;
		margin-bottom: 10px;

		span {
			color: #17C94E;
			font-weight: bold;
		}

		sup {
			font-size: 0.55em;
			margin: -18px;
		}

		@media ${device.desktop} {
			font-size: 1.4rem;
			font-family: 'Barlow', sans-serif;
		}
	}
`;
export const ListItem = styled.li`
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0.5rem;
    display: flex;

    &.bullet {
        &::before {
            content: '';
            width: 0.3rem;
            height: 0.3rem;
            background: white;
            border-radius: 100%;
        }
    }

    span {
        color: #17c94e;
        font-weight: bold;
    }

    sup {
        font-size: 0.9rem;
    }

    @media ${device.desktop} {
        font-size: 2rem;
        margin: 0 0 1.6rem 1rem;
        font-family: 'Barlow';
        sup {
            font-size: 55%;
        }
    }
`;

export const ListText = styled.p`
    font-family: 'Barlow', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: white;

    @media ${device.desktop} {
        font-size: 1.7rem;
        line-height: 2.4rem;
        max-width: 880px;
    }
`;

export const ListBtn = styled.button`
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.8rem 1.2rem;
    border-radius: 2rem;
    color: white;
    border: 0.1rem solid white;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 3.5rem;

    span {
        max-width: 80%;
        margin-left: 2.8rem;
        font-weight: 300;

        em {
            font-weight: 500;
        }
    }
    svg {
        font-size: 1.2rem;
        fill: white;
        margin-left: 1rem;
    }

    @media ${device.tablet} {
        margin: 0 auto 3.5rem;
    }

    @media ${device.desktop} {
        font-size: 1.6rem;
    }

    &.tab-1 {
        @media ${device.desktop} {
            margin-top: 8rem;
            font-size: 1.7rem;
            letter-spacing: 0.01rem;
            width: 68rem;

            span {
                max-width: 90%;
                margin-left: 2.8rem;
            }
        }
    }
`;

export const Bullet = styled.strong`
    margin: 0;
    margin-right: 0.8rem;
    color: white;

    @media ${device.desktop} {
        margin: 0 0.5rem;
    }
`;

const Box = styled.div`
    width: ${(props) => props.width || '100%'};
    display: flex;
    padding: ${(props) => props.padding || 0};
    justify-content: ${(props) => props.justify || 'center'};
    align-items: ${(props) => props.align || 'initial'};
    flex-direction: ${(props) => props.dir || 'row'};
`;
export const CustomLink = styled(Link)`
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid white;
    font-family: 'Barlow', sans-serif;
    font-size: 1.3rem;
    color: white;
    padding-bottom: 0.3rem;
    margin: 3.3rem 0;

    svg {
        margin-left: 1rem;
        fill: white;
    }

    @media ${device.desktop} {
        transform: translateY(-1rem);
    }
`;

export const CustomReference = styled(Reference)`
    margin-bottom: 6rem;
`;
