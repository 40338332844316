import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { device } from "../../_assets/styles/theme";
import { BaseText } from "../PageSwipper/utils/styles";
import TabItem from "./TabItem";
import HorizontalScroll from "../PageSwipper/components/HorizontalScroll";

const Tabs = ({ items, horizontal, isiconfig, className, isChangingSlide = false, setIsChangingSlid, hideScrollIcon = false }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const containerRef = useRef(null);

  return (
    <Tab ref={containerRef} isiconfig={isiconfig}>
      <Container className={className} isiconfig={isiconfig}>
        {/* Header */}
        {horizontal ? (
          <HeaderSlider className={className}>
            <HorizontalScroll>
              {items.map((item, index) => {
                return (
                  <div className="tab-item-wrapper" key={index}>
                    <TabItem
                      legend={currentTab === index ? item.legend : null}
                      className={
                        currentTab === index ? "active " + className : className
                      }
                      style={
                        currentTab === index
                          ? {
                            background:
                              "linear-gradient(108.88deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.53) 100%)",
                          }
                          : null
                      }
                      key={index}
                      item={index}
                      setCurrentTab={setCurrentTab}
                      title={item.title}
                    />
                  </div>
                );
              })}
            </HorizontalScroll>
          </HeaderSlider>
        ) : (
          <Header className={className}>
            <div className="tab-header-wrapper">
              {items.map((item, index) => (
                <TabItem
                  className={
                    currentTab === index ? "active " + className : className
                  }
                  legend={currentTab === index ? item.legend : null}
                  style={
                    currentTab === index
                      ? {
                        background:
                          "linear-gradient(108.88deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.53) 100%)",
                      }
                      : null
                  }
                  key={index}
                  item={index}
                  setCurrentTab={setCurrentTab}
                  title={item.title}
                />
              ))}
            </div>
          </Header>
        )}
        {/*End Header */}

        {/* Body content */}
        {/* {items.map((item, index) => currentTab === index &&
          React.cloneElement(item.component, {key: index, className: 'tab-content active'}))
        } */}

        {items.map((item, index) => {
          return React.cloneElement(item.component, {
            key: index,
            className: `tab-content ${currentTab === index ? "active" : "inactive"
              }`,
          });
        })}
        {/* End Body content */}
      </Container>
      {!hideScrollIcon &&
        <div className="scroll-container">
          <img src="/assets/scroll-icon-sideways.svg" alt="scroll-icon" />
        </div>
      }
    </Tab>
  );
};

export default Tabs;

export const Scroll = styled.div`
  height: 100%;
  max-height: calc(100% - 1rem);
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;

  height: ${(props) => `calc(100% - ${props.isiconfig})`};
  position: relative;
  .tab-content {
    opacity: 0;
    transform: translateX(-100vw);
    position: absolute;
    &.active {
      position: initial;
      transform: translateX(0);
      animation: showTab 0.4s ease-in 0.2s forwards;
      overflow: hidden;

      @media ${device.desktop} {
        overflow: initial;
      }
    }

    // Show the chart effect after change the tab (about - reason 1)
    &.inactive {
      #larger {
        animation: returnLarger 1.5s ease 0.5s forwards;
      }
      #break-off {
        animation: returnBreak 1.5s ease 0.5s forwards;
      }
      #labels {
        animation: returnLabels 1.5s ease 1.5s forwards;
      }
    }

    @keyframes showTab {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
  .scroll-container {
    position: absolute;
    top: 4.5rem;
    left: 25px;
  }
  @media ${device.tablet} {
    .scroll-container {
      display: none;
    }
  }
  @media (max-height: 900px) {
    height: 600px;
    padding-top: 120px;
  }
  @media (max-height: 800px) {
    height: 600px;
    padding-top: 60px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .swiper-slide {
    &:last-child {
      #tab-item {
        border-right: 2px solid #fff;
        &.active {
          border-right: 2px solid #17C94E;
        }
        &.active + div {
          border-left: none;
        }
      }
    }
    &:has(#tab-item.active) + div {
      #tab-item {
        border-left: none;
      }
    }
  }
  &.visible {
    transition: opacity 0.6s ease-in-out;
    transition-delay: 0.2s;
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }


  @media ${device.desktop} {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.efficacy {
    .swiper {
      overflow: initial;
    }
    @media ${device.desktop} {
      justify-content: flex-start;
      margin-top: 2.6rem;
      > div {
        &:first-of-type {
          padding-top: 2rem;
          max-width: 94rem;
        }

        &:last-of-type {
          padding-top: 4rem;
        }
      }
    }
  }
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2.4rem 2rem;
  .tab-header-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      transform: skew(-35deg);
      position: absolute;
      box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 3px 14px 2px rgba(0,0,0,0.12), 0 8px 10px 1px rgba(0,0,0,0.14);
      pointer-events: none;
      max-width: 27rem;
      @media ${device.tablet} {
        max-width: 54rem;
      }
      @media ${device.desktop} {
        max-width: 100%;
      }
    }
  }

  .swiper-wrapper {
    #tab-item {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 3px 14px 2px rgba(0,0,0,0.12), 0 8px 10px 1px rgba(0,0,0,0.14);
        max-height: 3rem;
        bottom: 0;
      }
    }
  }

  &:not(.efficacy){
    #tab-item {
      &:last-child {
        border-right: 2px solid white;
        &.active {
          border-right: 2px solid #17C94E;
        }
      }
    }
  }

  @media ${device.tablet} {
    padding: 4rem 2rem;
  }

  @media ${device.desktop} {
    max-width: 100rem;
    margin: 4rem auto;
    padding: 0;
    #tab-item {
      &:last-child {
        border-right: 2px solid white;
        &.active {
          border-right: 2px solid #17C94E;
        }
      }
    }
  }

  &.efficacy {
    @media ${device.tablet} {
      padding: 4rem 2rem;
    }

    @media ${device.desktop} {
      max-width: min(calc(900 * 0.11375vmin), 900rem);
      margin: 0 auto;
      padding: 2rem 0 0;
    }
  }
`;
const HeaderSlider = styled(Header)`
  padding: 2rem 0 2.4rem;


  .swiper {
    padding: 0 3.2rem;
  }
  .swiper-scrollbar {
    display: none;
  }

  &.efficacy {
    padding: 1rem 0 1.6rem;

    .swiper {
      padding: 0 2.6rem;
    }

    @media ${device.tablet} {
      .swiper {
        width: auto;
      }
    }
  }
`;
const BodyWrapper = styled.div``;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.2rem;
  margin-bottom: 1.2rem;

  @media ${device.desktop} {
	  margin: 0 auto 3.2rem;
	  padding: 0;
	  justify-content: flex-start;
	}
	`;
export const TitleText = styled.h1`
  font-family: "Oswald", sans-serif;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: min(1.7rem, 2.2rem);
  line-height: min(2rem, 2.4rem);
  text-align: center;

  sup {
    font-size: calc(min(1.7rem, 2.2rem) * .5);
	top: -4px;
	position: relative;
  }
  span {
    color: #17c94e;
    font-weight: bold;
  }

  @media ${device.tablet} {
    font-size: min(1.7rem, 2.9rem);

    sup {
      font-size: calc(min(1.7rem, 2.9rem) * .5);
    }
  }



  @media ${device.desktop} {
    font-size: 2.9rem;
    line-height: initial;
    text-align: initial;

    sup {
      font-size: 55%;
    }
  }
`;

export const ReasonBox = styled.div`
  width: 9.6rem;
  max-height: 9.6rem;
  padding: 0.8rem;
  box-shadow: rgb(34, 55, 88) 1rem -0.5rem #000;
  background: #1F2435;
  margin: 0 2rem 0 0;
  align-items: center;
  justify-content: center;
  display: none;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 1px 10px 0 rgba(0,0,0,0.12), 0 4px 5px 0 rgba(0,0,0,0.14);

  @media ${device.tablet} {
    display: flex;
    width: calc(96 * 0.13125vmin);
    max-height: calc(96 * 0.13125vmin);
    padding: calc(8 * 0.13125vmin);
  }

  @media ${device.desktop} {
    width: calc(96 * 0.11375vmin);
    max-height: calc(96 * 0.11375vmin);
    padding: 0.8rem;
  }
`;

export const ReasonTag = styled(BaseText)`
  font-family: "Oswald", sans-serif;
  font-style: italic;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-weight: 300;
  color: white;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 2rem;
  }

  @media ${device.desktop} {
    font-size: calc(29 * 0.11375vmin);
  }
`;

export const ReasonNumber = styled(BaseText)`
  font-family: "Oswald", sans-serif;
  color: white;
  text-transform: uppercase;
  text-align: initial;
  font-weight: 200;
  line-height: 1;

  @media ${device.tablet} {
    font-size: 6.6rem;
  }

  @media ${device.desktop} {
    font-size: 9.3rem;
    margin-bottom: 0.5rem;
  }
`;

const Legend = styled.p`
  font-family: "Barlow";
  font-weight: 500;
  font-size: 0.8rem;
  color: white;
  padding-left: 1rem;
  padding-bottom: 0.4rem;
  text-align: center;
`;
