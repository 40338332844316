import React from 'react'
import styled from 'styled-components/macro'
import useMobileDetect from '../../../../_hooks/useMobileDetect'

const ScrollIcon = ({ variant, ...props }) => {
  const { isMobile } = useMobileDetect()

  return (
    <ScrollIconWrapper {...props}>
      <img className={isMobile ? 'mobile' : 'desktop'} src={isMobile ? (variant === 'black' ? "/assets/icons/touch-icon-black.webp" : "/assets/icons/touch-icon.webp") : (variant === 'black' ? "/assets/icons/mouse-icon-black.webp" : "/assets/icons/mouse-icon.webp")} alt="Scroll Navigation Icon" />
    </ScrollIconWrapper>
  )
}

export default ScrollIcon

const ScrollIconWrapper = styled.div`
  margin-bottom: ${props => props.marginBottom || '0'};
  margin-top: ${props => props.marginTop || '0'};
  text-align: center;
  img {
    width: 100%;
    max-width: 3rem;

    &.mobile {
      max-width: 3.2rem;
    }

    &.desktop {
      max-width: 2rem;
    }
  }
`
