import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../_assets/styles/theme'

const Select = ({options, register, setValue, onChange, ...props}) => {
  const [selectedOption, setSelectedOption] = useState()
  const [isActive, setIsActive] = useState(false)

  const handleShowOptions = (e) => {
    e.preventDefault()
    setIsActive(prev => !prev)
  }

  const handleSelectOption = ( option ) => {
    setSelectedOption(option)
    onChange()
    setValue('provider_type', option)
  }

  return (
    <Container>
      <Wrapper onClick={handleShowOptions} className={isActive ? 'active' : 'disabled'}>
        <Input
          {...register("provider_type", { required: true })}
          id="provider_type"
          type="text"
          {...props}
        >
         {selectedOption || '(please select one)'}
         <img src="/assets/caret-down.svg" alt="Down navigation arrow" />
        </Input>

        <OptionsContainer className={!isActive ? 'disabled' : 'active'}>
          {options.map((option, index) => (
            <Option
              className={option === selectedOption ? 'selected' : ''}
              key={option + index}
              onClick={()=> handleSelectOption(option)}
            >
              {option === selectedOption ? <img src="/assets/basic_done.svg" alt="" /> : null}
              {option}
            </Option>
          ))}
        </OptionsContainer>
      </Wrapper>
    </Container>
  )
}

export default Select

const Container = styled.div`
  cursor: pointer;
  position: relative;
  margin: 0.5rem 0 0.5rem;

  @media ${device.desktop} {
    margin: 0.8rem 0 0.5rem;
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  background: #303345;
  z-index: 99;

  &.disabled {
    max-height: 3.6rem;
  }

`

const Input = styled.div`

  cursor: pointer;
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  height: 3.64rem;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  color: #FFFFFF;
  font-family: 'Barlow';
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1.3rem;
  border: .1rem solid white;

  img {
    position: absolute;
    right: 1rem;
    width: 3.2rem;
    height: 3.2rem;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }

  @media ${device.desktop} {
    font-size: 1.6rem;
  }

`
const OptionsContainer = styled.div`
  display: none;
  position: absolute;
  &.active {
    display: initial;

  }
  top: -29rem;
  background: #303345;
  z-index: 99;
  width: calc(100% + .1rem);
  left: -.1rem;
  border: .1rem solid white;
  @media ${device.tablet} {
    position: initial;
    z-index: initial;
    width: 100%;
    border: none;
  }
`

const Option = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  padding-left: 2.8rem;
  color: #FFFFFF;
  font-family: 'Barlow';
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;

  img {
    width: 2.4rem;
    position: absolute;
    left: 0;
  }

  &.selected {
    background: rgba(0,0,0,.5);
    width: calc(100% - .2rem);
    position: relative;
    left: .2rem;
    position: relative;
    &:before {
      left: 0;
      top: 0;
      display: block;
      content: '';
      background: url(../assets/green-check.svg);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      height: 40px;
      width: 40px
    }
  }

  @media ${device.desktop} {
    font-size: 1.8rem;
  }
`
