import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { StyledBothSideShape } from '../PageSwipper/utils/styles';
import Modal from './Modal';
import { device } from '../../_assets/styles/theme';
import CloseBtn from '../../_assets/img/close.svg';
import { Link } from 'react-router-dom';

export const allReferences = [
    //1
    <span>
      Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.5.2023. ©&nbsp;National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed December 7, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.
    </span>,
    //2
    <span>
      Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Central Nervous System Cancers V.1.2023. ©&nbsp;National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed April 11, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or  application and disclaims any responsibility for their application or use in any way.
    </span>,
    //3
    <span>
      Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.1.2023. ©&nbsp;National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed September 5, 2023. To view the most recent and complete version of the guideline, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.
    </span>,
    //4
    <span>
        Ghimessy A, Radeczky P, Laszlo V, et al. Current therapy of KRAS-mutant lung cancer. <em>Cancer Metastasis Rev.</em> 2020;39(4):1159-1177.
    </span>,
    //5
    <span>
        Lu S, Jang H, Muratcioglu S, et al. Ras conformational ensembles, allostery, and signaling. <em>Chem Rev.</em> 2016;116(11):6607-6665.
    </span>,
    //6
    <span>
        Pakkala S, Ramalingam SS. Personalized therapy for lung cancer: striking a moving target. <em>JCI Insight.</em> 2018;3(15):e120858.
    </span>,
    //7
    <span>
        Nassar AH, Adib E, Kwiatkowski DJ. Distribution of <em>KRASG12C</em> somatic mutations across race, sex, and cancer type. <em>N Engl J Med.</em> 2021;384(2):185-187.
    </span>,
    //8
    <span>
        Acker F, Stratmann J, Aspacher L, et al. KRAS mutations in squamous cell carcinomas of the lung. <em>Front Oncol.</em> 2021;11:788084.{' '}
    </span>,
    //9
    <span>
      Svaton M, Fiala O, Pesek M, et al. The prognostic role of KRAS mutation in patients with advanced NSCLC treated with second- or third-line chemotherapy. <em>Anticancer Res</em>. 2016;36(3):1077-1082.
    </span>,
    //10
    <span>
      Hames ML, Chen H, Iams W, et al. Correlation between KRAS mutation status and response to chemotherapy in patients with advanced non-small cell lung cancer. <em>Lung Cancer</em>. 2016;92:29-34.
    </span>,
    //11
    <span>
      Iams WT, Balbach ML, Phillips S, et al. A multicenter retrospective chart review of clinical outcomes among patients with KRAS G12C mutant non-small cell lung cancer. <em>Clin Lung Cancer.</em> 2023;24(3):228-234.
    </span>,
    //12
    <span>
      Gray JE, Hsu H, Younan D, et al. Real-world outcomes in patients with KRAS G12C-mutated advanced non-small cell lung cancer treated with docetaxel in second-line or beyond. <em>Lung Cancer.</em> 2023;181:107260.
    </span>,
    //13
    <span>
      de Langen AJ, Johnson ML, Mazieres J, et al. Sotorasib versus docetaxel for previously treated non-small-cell lung cancer with KRASG12C mutation: a randomized, open-label, phase 3 trial. <em>Lancet.</em> 2023;401(10378):733-746.
    </span>,
    //14
    <span>
        Cui W, Franchini F, Alexander M, et al. Real world outcomes in KRAS G12C mutation positive non-small cell lung cancer. <em>Lung Cancer.</em> 2020;146:310-317.
    </span>,
    //15
    <span>
        Naresh G, Malik PS, Khurana S, et al. Assessment of brain metastasis at diagnosis in non–small cell lung cancer: a prospective observational study from north India.{' '}
        <em>JCO Glob Oncol.</em> 2021;7:593-601.
    </span>,
    //16
    <span>
        Al Husaini H, Wheatly-Price P, Clemons M, Shepherd FA. Prevention and management of bone metastases in lung cancer: a review. <em>J Thorac Oncol.</em> 2009;4(2):251-259.
    </span>,
    //17
    <span>
        D’Antonio C, Passaro A, Gori B, et al. Bone and brain metastases in lung cancer: recent advances in therapeutic strategies. <em>Ther Adv Med Oncol.</em> 2014;6(3):101-114.
    </span>,
    //18
    <span>
      Stites EC, Shaw AS. Quantitative systems pharmacology analysis of KRAS G12C covalent inhibitors. <em>CPT Pharmacometrics Syst Pharmacol.</em> 2018;7(5):342-351.
    </span>,
    //19
    <span>
      Shukla S, Allam US, Ahsan A, et al. KRAS protein stability is regulated through SMURF2: UBCH5 complex-mediated β-TrCP1 degradation. <em>Neoplasia.</em> 2014;16(2):115-128.
    </span>,
    //20
    <span>
      Bergo MO, Gavino BJ, Hong C, et al. Inactivation of Icmt inhibits transformation by oncogenic K-Ras and B-Raf. <em>J Clin Invest.</em> 2004;113(4):539-550.
    </span>,
    //21
    <span>
      Fernández-Medarde A, Santos E. Ras in cancer and developmental diseases. <em>Genes Cancer.</em> 2011;2(3):344-358.
    </span>,
    //22
    <span>
        Cox AD, Fesik SW, Kimmelman AC, Luo J, Der CJ. Drugging the undruggable RAS: mission possible? <em>Nat Rev Drug Discov.</em> 2014;13(11):828-851.{' '}
    </span>,
    //23
    <span>
        Waters AM, Der CJ. KRAS: the critical driver and therapeutic target for pancreatic cancer. <em>Cold Spring Harb Perspect Med.</em> 2018;8(9):a031435.{' '}
    </span>,
    //24
    <span>
        Kilgoz HO, Bender G, Scandura JM, Viale A, Taneri B. KRAS and the reality of personalized medicine in non-small cell lung cancer. <em>Mol Med.</em> 2016;22:380-387.
    </span>,
    //25
    <span>
        Eisenstein M. New lung-cancer drugs extend survival times. <em>Nature.</em> 2020;587(7834):S10-S12.
    </span>,
    //26
    <span>
      Scott JA, Lennerz J, Johnson ML, et al. Compromised outcomes in stage IV non-small-cell lung cancer with actionable mutations initially treated without tyrosine kinase inhibitors: a retrospective analysis of real-world data. <em>JCO Onco Pract.</em> 2023;OP2200611.
    </span>,
    //27
    <span>
      Nadler E, Vasudevan A, Wang Y, Ogale S. Real-world patterns of biomarker testing and targeted therapy in de novo metastatic non-small cell lung cancer patients in the US oncology network. <em>Cancer Treat Res Commun.</em> 2022;31:100522.
    </span>,
    //28
    <span>
      Robert NJ, Espirito JL, Chen L, et al. Biomarker testing and tissue journey among patients with metastatic non-small cell lung cancer receiving first-line therapy in The US Oncology Network. <em>Lung Cancer.</em> 2022;166:197-204.
    </span>,
    //29
    <span>
      Aggarwal C, Marmarelis ME, Hwang W, et al. Association of comprehensive molecular genotyping and overall survival in patients with advanced non-squamous NSCLC. Poster presented at: ASCO Annual Meeting; June 3-7, 2022; Chicago, IL, https://meetings.asco.org/abstracts-presentations/214569
    </span>,
    //30
    <span>KRAZATI. Prescribing information. Mirati Therapeutics, Inc.; 2022. </span>,
    //31
    <span>
        Jänne PA, Riely GJ, Gadgeel SM, et al. Adagrasib in non-small-cell lung cancer harboring a KRASG12C mutation. <em>N Engl J Med.</em> 2022;387(2):120-131.
    </span>,
    //32
    <span>Data on file, Mirati Therapeutics. 2022.</span>,
    //33
    <span>
        Spira AI, Riely GJ, Gadgeel SM, et al. KRYSTAL-1: activity and safety of adagrasib (MRTX849) in patients with advanced/metastatic non-small cell lung cancer (NSCLC)
        harboring KRAS<sup className='no-cursor'>G12C</sup> mutation. <em>J Clin Oncol.</em> 2022;40(16 Suppl):9002.
    </span>,
    //34
    <span>
      Gadgeel SM, Jänne PA, Spira AI, et al. KRYSTAL-1: two-year follow-up of adagrasib (MRTX849) monotherapy in patients with advanced/metastatic KRASG12C-mutated NSCLC. Presented at: International Association for the Study of Lung Cancer 2023 World Conference on Lung Cancer; September 9-12, 2023; Singapore.
    </span>,
    //35
    <span>
      Zhang J, Johnson M, Barve M, et al. Practical guidance for the management of adverse events in patients with KRASG12C-mutated non-small cell lung cancer receiving adagrasib. <em>Oncologist.</em> 2023;28(4):287-296.
    </span>,
];

const RerefenceModal = ({ isOpen, close, indexs }) => {
    const [open, setOpen] = useState(false);

    const parsedData = allReferences
        ?.map((reference, index) => {
            if (indexs.includes(index + 1)) {
                return {
                    data: reference,
                    index: index + 1,
                };
            }
            return null;
        })
        .filter((item) => item !== null);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <CustomModal isOpen={isOpen || open}>
            <Container>
                <StyledBothSideShape background='#20293A' border='white' borderSize={0.2}>
                    <CloseBtnWrapper onClick={close || handleClose}>
                        <img src='/assets/close.svg' alt='' />
                    </CloseBtnWrapper>

                    <TitleWrapper>
                        <Title>
                            <Link onClick={close || handleClose} to='/n/references'>
                                References
                            </Link>
                        </Title>
                    </TitleWrapper>

                    <References>
                        <ol>
                            {parsedData?.map((info, index) => (
                                <li key={index} className='reference'>
                                    <span className='number'>{info.index}.</span>
                                    {info.data}
                                </li>
                            ))}
                        </ol>
                    </References>
                </StyledBothSideShape>
            </Container>
        </CustomModal>
    );
};

export default RerefenceModal;

const CustomModal = styled(Modal)`
    > div {
        max-width: 100%;
        // padding: 7rem 0.1rem 0;
        align-items: center;
        max-height: 90vh;
        @media ${device.tablet} {
            padding: 7rem 3rem 0;
            max-width: 95%;
            margin: 0 auto;
        }
    }
`;

const Container = styled.div`
    max-width: 93.6rem;
    font-family: 'Barlow';

    > div {
        padding: 2.4rem 1rem 2.4rem 1rem;
        box-shadow: 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12), 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14);
        margin: 0 auto;

        @media ${device.desktop} {
            max-width: 100%;
            padding: 3.6rem 6rem;
        }
    }
`;

const TitleWrapper = styled.div`
    margin-bottom: 2.5rem;
`;
const Title = styled.h2`
    color: #ffffff;
    font-family: 'Oswald';
    font-size: 1.7rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.8rem;
    text-align: center;
    text-transform: uppercase;

    a {
        color: inherit;
    }

    @media ${device.desktop} {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 3.5rem;
    }
`;

const CloseBtnWrapper = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;

    svg {
        fill: white;
        max-width: 2rem;
    }

    @media ${device.desktop} {
        right: 2rem;
        top: 2rem;

        svg {
            max-width: initial;
        }
    }
`;

const References = styled.div`
    overflow-y: auto;
    max-height: calc(90vh - 100px);
    li {
        color: #ffffff;
        font-family: 'Barlow';
        font-size: 1.35rem;
        letter-spacing: 0;
        line-height: 1.6rem;
        margin-bottom: 2rem;
        list-style: none;
        display: flex;
        .number {
            margin-right: 2rem;
        }
    }

    @media ${device.desktop} {
        li {
            color: #ffffff;
            font-family: 'Barlow';
            font-size: 2rem;
            letter-spacing: 0;
            line-height: 2.2rem;
            margin-bottom: 1.5rem;
            list-style: none;
        }
    }
`;
