import { Helmet } from 'react-helmet-async';
import { isiConfig } from '../_components/ISI/config';
import { Page } from '../_components/PageSwipper/utils/styles';
import { PageOverlay, StaticContentWrapper } from '../Home';

import { useState } from 'react';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import Footer from '../_components/Footer';
import Header from '../_components/Header';
import ISICollapsing from '../_components/ISI/Collapsing';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import PageSwipper from '../_components/PageSwipper';
import React, { useEffect, useRef } from 'react';
import ReferenceModal from '../_components/Modal/ReferenceModal';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import Slide3 from './pages/Slide3';
import Slide4 from './pages/Slide4';
import Slide5 from './pages/Slide5';
import Slide6 from './pages/Slide6';
import Slide7 from './pages/Slide7';
import Slide8 from './pages/Slide8';
import StaticIsi from '../_components/ISI/StaticIsi';
import styled from 'styled-components/macro';
import useMobileDetect from '../../_hooks/useMobileDetect';
import MetaTags from '../_components/MetaTags/MetaTags';

const Safety = ({ setPageBackground }) => {
    const { isMobile } = useMobileDetect();
    const [headerRef, setHeaderRef] = useState(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [renderPage, setRenderPage] = useState(false);
    const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [background, setBackground] = useState(`url('/assets/bg-study.webp') no-repeat fixed center top / cover`);
    const [overlay, setOverlay] = useState(null);
    const overlayRef = useRef();
    const [referencesIndex, setReferencesIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (indexs) => {
        if (indexs) {
            setReferencesIndex(indexs);
        }
    };

    useEffect(() => {
        if (referencesIndex) {
            setShowModal(true);
        }
    }, [referencesIndex]);

    useEffect(() => {
        if (headerRef?.current) {
            setHeaderHeight(headerRef?.current.clientHeight);
            setRenderPage(true);
        }
    }, [headerRef?.current]);

    useEffect(() => {
        overlayRef.current.classList.remove('visible');
        overlayRef.current.classList.add('hidden');

        const time = setTimeout(() => {
            // overlayRef.current.style.opacity = 1
            overlayRef.current.classList.add('visible');
            overlayRef.current.classList.remove('hidden');
        }, 100);

        return () => {
            clearTimeout(time);
        };
    }, [currentSlide]);

    return (
        <>
            <MetaTags
                title='KRAZATI® (adagrasib) | Safety and Tolerability'
                description='Explore laboratory abnormalities and adverse reactions in the KRYSTAL-1 study. Learn about warnings and precautions for safety and tolerability.'
                ogUrl='https://www.krazatihcp.com/n/safety'
            />
            <Page background={background}>
                {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
                <PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
                <PageScroll>
                    <Container>
                        <Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
                        <div style={{ position: 'relative' }}>
                            {renderPage && (
                                isMobile ? (
                                    <PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
                                        <Slide1
                                            isiconfig={isiConfig.collapsed}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide2
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide3
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide4
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide5
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide6
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide7
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                    </PageSwipper>
                                ) : (
                                    <PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
                                        <Slide1
                                            isiconfig={isiConfig.collapsed}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide2
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide3
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide4
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide5
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide6
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide7
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                        <Slide8
                                            isiconfig={isiConfig.floating}
                                            setCurrentSlide={setCurrentSlide}
                                            setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                                            setBackground={setBackground}
                                            setOverlay={setOverlay}
                                            handleShowModal={handleShowModal}
                                        />
                                    </PageSwipper>
                                )
                            )}

                            {!isMobile && currentSlide !== 7 && <ISICollapsing className={currentSlide !== 1 || currentSlide === 7 ? 'collapse' : 'visible'} />}
                            {!isMobile && currentSlide === 7 && <ISICollapsing className='floatingWithIsi collapse' />}

                            {isMobile && <FloatingIsi className={currentSlide !== 1 || currentSlide === 7 ? 'collapse' : 'visible'} />}
                        </div>

                        {!isMobile && currentSlide === 7 && (
                            <StaticContentWrapper>
                                <StaticIsi />
                                <Footer translate={true}></Footer>
                            </StaticContentWrapper>
                        )}
                    </Container>
                </PageScroll>
            </Page>
        </>
    );
};

export default Safety;

const Container = styled.div`
    height: 100%;
`;
