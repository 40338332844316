import Header from '../_components/Header';
import MetaTags from '../_components/MetaTags/MetaTags';
import './ErrorPage.scss';

function ErrorPage() {
	return (
		<>
			<MetaTags title='Page Not Found' description='This page could not be found.' />
			<div className="page-not-found">
				<Header />
				<div className="not-found-wrapper">
					<div className="left">404</div>
					<div className="right">This page could not be found.</div>
				</div>
			</div>
		</>
	)
}

export default ErrorPage;