
// const makeUrl = (environment) => {
//   if(environment === 'dev' || environment === 'test') {
//     return `https://${environment}-branddtc.pantheonsite.io/`
//   }
//   return 'https://www.krazati.com'
// }

// const getHcpUrl = () => {
//   const url = document.location.href

//   if (url.includes('dev')) {
//     return makeUrl('dev')
//   }

//   if (url.includes('test')) {
//     return makeUrl('test')
//   }

//   return makeUrl('live')
// }

// export const baseHCPUrl = getHcpUrl();
export const baseHCPUrl = 'https://www.krazati.com';


