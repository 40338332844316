import { useState } from 'react'
import { motion } from 'framer-motion'
// import Container from '../Layout/Container'

import styles from './CRCIndication.module.scss'
import ContentContainer from '../Layout/ContentContainer'

export default function CRCIndication() {
  const [isOpen, setIsOpen] = useState(true)

  const variants = {
    initial: {
      height: 'auto'
    },
    visible: {
      height: 'auto'
    },
    hidden: {
      height: '0',
      overflow: 'hidden'
    }
  }

  return (
    <motion.div variants={variants} initial="initial" animate={isOpen ? "visible" : "hidden"}>
      <div className={styles.indication}>
        <div className={styles.closeButton}>
          <button onClick={() => {
            setIsOpen(false)
          }}>
            <img src="/assets/icons/icon-indication-close-button.svg" alt="Close indication" />
          </button>
        </div>
        <ContentContainer>
          <div className={styles.content}>
            <div>
              <p><b>INDICATION</b></p>
            </div>
            <div>
              <p>KRAZATI in combination with cetuximab is indicated for the treatment of adult patients with KRAS G12C-mutated locally advanced or metastatic colorectal cancer (CRC), as determined by an FDA-approved test, who have received prior treatment with fluoropyrimidine-, oxaliplatin-, and irinotecan-based chemotherapy.</p>
              <p>This indication is approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR). Continued approval for this indication may be contingent upon verification and description of a clinical benefit in a confirmatory trial.</p>
            </div>
          </div>
        </ContentContainer>
      </div>
    </motion.div>
  )
}
