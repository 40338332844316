import CRCLayout from "./layouts/CRCLayout";
import JumpMenu from './components/JumpMenu'
import InteriorBanner from "./components/InteriorBanner"
import MainContent from "../_common/components/Layout/MainContent"
import FooterCallout from "./components/FooterCallout"
import StyledHeading from "./components/StyledHeading"
import ContentContainer from "../_common/components/Layout/ContentContainer"
import Callout from "./components/Callout"
import JumpLink from "./components/JumpLink"
import RefModal from "./components/RefModal"
import MetaTags from "../_common/components/MetaTags";
import styles from "./CRCEfficacy.module.scss"

export default function CRCEfficacy() {
  const links = [
    {
      url: '#orr',
      text: 'ORR (primary endpoint)'
    },
    {
      url: '#dcr',
      text: 'DCR'
    },
    {
      url: '#os',
      text: 'OS'
    },
    {
      url: '#pfs',
      text: 'PFS'
    }
  ]
  return (
    <CRCLayout>
      <MetaTags
        title='KRYSTAL-1 Trial | Study Efficacy Data'
        description="Delve into the efficacy data from the KRYSTAL-1 trial to understand the treatment's impact."
        ogUrl='https://www.krazatihcp.com/c/efficacy'
      />
      <InteriorBanner title="KRYSTAL-1 EFFICACY FOR PATIENTS TAKING KRAZATI + <span>cetuximab</span>" imageUrl="/banners/banner-about-kras.jpg" desktopImageUrl="/banners/banner-about-kras-desktop.jpg" />
      {/* <JumpMenu title="REVIEW THE RESULTS" links={links} /> */}

      <MainContent>

        <div className={styles.response}>
          <JumpLink id="orr" />
          <ContentContainer>
            <h2>Response seen in heavily pretreated patients<RefModal references={[1]} /></h2>
            <div className={styles.col1}>
              <img className={styles.imgEfficacy} src="/assets/efficacy/img-efficacy-desktop.jpg" alt="Percentage of ORR" />
            </div>
            <div className={styles.col2}>
              <div>
                <h3>Median DOR: <span className={styles.greenHighlight}>5.8 months</span><br className={styles.imgMobile} /> (95% CI: 4.2-7.6)</h3>
                <ul>
                  <li>31% had a response duration of ≥6 months<sup>†</sup></li>
                </ul>
              </div>
              <StyledHeading center={true} title="Category 2A NCCN recommended" />
              <div>
                <h4>CRC</h4>
                <p>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for colon and rectal cancers recommend adagrasib (KRAZATI) + cetuximab as a systemic therapy option for patients with <i>KRAS G12C</i>-mutated advanced CRC.<RefModal references={[2, 3]} /></p>
              </div>
            </div>
            <div>
              <p><small>*Tumor response was assessed by BICR. Data cutoff: June 30, 2023 (median follow-up: 11.9 months).<br /><sup>†</sup>Observed proportion of patients with duration of response beyond landmark time.</small></p>
            </div>

          </ContentContainer>
        </div>

        {/* <div className={styles.diseaseControl}>
          <JumpLink id="dcr" />
          <ContentContainer>
            <div>
              <h2>Disease control rate of patients taking KRAZATI + cetuximab<RefModal references={[21]} /></h2>
              <img style={{ marginTop: '20px', marginBottom: '30px' }} className={styles.imgMath} src="/assets/efficacy/img-math-desktop.svg" alt="Percentage of Patients That Receive Therapy" />
              <p>Disease control rate is defined as the percentage of patients who achieved a complete response, partial response, or stable disease; the complete response rate in the trial was 0.</p>
              <p><strong>Best change in tumor volume from baseline in patients taking KRAZATI + cetuximab<sup>§</sup></strong></p>
              <img className={styles.imgMobile} src="/assets/efficacy/img-graph-01.png" alt="Tumor Volume for Patients on Treatment" />
              <img className={`${styles.imgMobile} ${styles.imgResponse}`} src="/assets/efficacy/img-graph-01-responses.png" alt="" />
            </div>
            <div className={styles.graphs}>
              <div className={styles.col1}>
                <img src="/assets/efficacy/img-graph-01.png" alt="Tumor Volume for Patients on Treatment" />
              </div>
              <div className={styles.col2}>
                <img className={`${styles.imgDesktop} ${styles.imgLegend}`} src="/assets/efficacy/img-graph-01-legend.jpg" alt="" />
                <ul>
                  <li>Median follow-up time was 11.9 months<sup>¶</sup></li>
                </ul>
              </div>
            </div>
            <div className={styles.imgMobile}>
              <ul>
                <li>Median follow-up time was 11.9 months<sup>¶</sup></li>
              </ul>
            </div>

            <Callout title={["KRAZATI offers patients a chance for response in previously treated advanced CRC", <RefModal references={[1]} />]} />

            <p><small><sup>‡</sup>Stable disease is defined as neither sufficient tumor shrinkage to qualify for partial response (≥30%) nor sufficient increase to qualify for progressive disease (≥20%).</small><br />
              <small><sup>§</sup>Excludes 8 patients without post-baseline measurement in target lesions.</small><br />
              <small><sup>¶</sup>Tumor response was assessed by BICR. Data as of June 30, 2023.</small></p>
          </ContentContainer>
        </div> */}

        <Callout grayBg={true} title={["KRAZATI offers patients a chance for response in previously treated advanced CRC", <RefModal references={[1]} />]} />

        {/* <div className={styles.medianOS}>
          <JumpLink id="os" />
          <ContentContainer>
            <div>
              <h2>Median OS in patients taking KRAZATI + cetuximab: 15.9 months<RefModal references={[21]} /></h2>
            </div>
            <div className={styles.cols}>
              <div className={styles.col1}>
                <img src="/assets/efficacy/img-graph-02.png" alt="Median OS in Patients Taking Treatment" />
                <img className={`${styles.imgMobile} ${styles.imgData}`} src="/assets/efficacy/img-graph-02-data.svg" alt="" />
              </div>
              <div className={styles.col2}>
                <img className={styles.imgDesktop} src="/assets/study-design/img-graph-02-percent.svg" alt="" />
                <p className={styles.imgDesktop}>(median, N=94; 95% CI: 11.8-18.8)</p>
                <ul>
                  <li>Median follow-up time was 11.9 months. Data cutoff: June 30, 2023</li>
                </ul>
                <p><b>Single-arm trials do not adequately characterize time-to-event endpoints such as OS. Thus, these data from KRYSTAL-1 cannot be interpreted as having OS benefit.</b></p>
              </div>
            </div>
            <Callout title={["87.8% of patients were still alive at 6 months", <RefModal references={[21]} />]} />
          </ContentContainer>
        </div> */}

        {/* <div className={styles.medianPFS}>
          <JumpLink id="pfs" />
          <ContentContainer>
            <div>
              <h2>Median PFS in patients taking KRAZATI + cetuximab: 6.9 months<RefModal references={[21]} /></h2>
            </div>
            <div className={styles.cols}>
              <div className={styles.col1}>
                <img src="/assets/efficacy/img-graph-03.jpg" alt="Median PFS In Patients Taking Treatment" />
                <img className={`${styles.imgMobile} ${styles.imgData}`} src="/assets/efficacy/img-graph-03-data.svg" alt="" />
              </div>
              <div className={styles.col2}>
                <img className={styles.imgDesktop} src="/assets/study-design/img-graph-03-percent.svg" alt="" />
                <p className={styles.imgDesktop}>(N=94; 95% CI: 5.7-7.4)</p>
                <ul>
                  <li>Median follow-up time was 11.9 months. Data cutoff: June 30, 2023</li>
                </ul>
                <p><b>Single-arm trials do not adequately characterize time-to-event endpoints such as PFS. Thus, these data from KRYSTAL-1 cannot be interpreted as having PFS benefit.</b></p>
              </div>
            </div>
            <div>
              <p><small>BICR=blinded independent central review; CI=confidence interval; CRC=colorectal cancer; DCR=disease control rate; DOR=duration of response; NCCN=National Comprehensive Cancer Network; ORR=objective response rate; OS=overall survival; PFS=progression-free survival.</small></p>
            </div>
          </ContentContainer>
        </div> */}
        <ContentContainer>
          <div>
            <p><small>BICR=blinded independent central review; CI=confidence interval; CRC=colorectal cancer; DOR=duration of response; NCCN=National Comprehensive Cancer Network; ORR=objective response rate.</small></p>
          </div>
          <br />
          <br />
        </ContentContainer>
      </MainContent >

      {/* Footer Callout */}
      < FooterCallout title="Review the safety and tolerability of KRAZATI + cetuximab" link="/c/safety" />
    </CRCLayout >
  )
}
