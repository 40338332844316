import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useForm } from "react-hook-form";
import { useSwiperSlide } from "swiper/react";
import {
  BaseLink,
  BaseText,
  Box,
  Container,
  Input,
  Label,
  StyledBothSideShape,
} from "../../_components/PageSwipper/utils/styles";
import useActiveLink from "../../_components/Header/hooks/useActiveLink";
import { device } from "../../_assets/styles/theme";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import ContainerScroll from "../../_components/PageSwipper/components/ContainerScroll";
import Cleave from "/node_modules/cleave.js/dist/cleave-esm.min.js";

import { ReactComponent as Check } from "../../_assets/img/icons/basic_done.svg";

const emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

const Slide2 = ({
  setShowHeaderSecondaryMenu,
  setCurrentSlide,
  isiconfig,
  setOverlay,
}) => {
  const swiperSlide = useSwiperSlide();
  const { isMobile } = useMobileDetect();
  const mobileBgWrapper = useRef();
  const [showPageContent, setShowPageContent] = useState(false);
  const mobileOverlay = "";
  const desktopOverlay = "";
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
  const [finishedSubmission, setFinishedSubmission] = useState(false);
  const [errorOnSubmission, setErrorOnSubmission] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm();
  useActiveLink(setShowHeaderSecondaryMenu, "contact-us", swiperSlide.isActive);

  useEffect(() => {
    const slide = document.querySelector(".mySwiper");

    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setShowPageContent(true);
      setCurrentSlide(2);
      setOverlay(slideOverlay);
      slide?.swiper?.draggable?.disable();
      return;
    }
    setShowPageContent(false);
    slide?.swiper?.draggable?.enable();
    // checks if specific inputs exist and applies cleave every 1 second
    // necessary for changes from dt to mobile
    setInterval(() => {
      checkForCleave();
    }, 1000);
  }, [swiperSlide.isActive]);

  const onSubmit = (data) => {
    const smsChecked = data["receive_message"];
    const reg = /\(|\)|\-|\s/gm;
    const phoneNum = data["mobile_phone"].replace(reg, "");
    const phoneInput = document.getElementById("mobile_phone");

    if (smsChecked && phoneNum.length === 10 || !smsChecked) {
      phoneInput.classList.remove("__has-error");
      sendFormData(data)
    } else {
      phoneInput.classList.add("__has-error");
      phoneInput.focus();
    }

  };

  function sendFormData(data) {
    // Push all form values into an array
    let urlEncodedArr = [];
    for (let key in data) {
      urlEncodedArr.push(key + "=" + encodeURIComponent(data[key]));
    }

    // Convert arr to string
    const urlEncodedStr = urlEncodedArr.join("&");

    // POST string to backend
    fetch("/api/api.php", {
      body: urlEncodedStr,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status !== "success") {
          // add error stuff here
          console.log(response.status);
          setFinishedSubmission(true);
          setErrorOnSubmission(true);
        } else {
          // Show thank you if no errors
          setFinishedSubmission(true);
        }
      });
  };

  function showPhone() {
    let checkboxes = document.querySelectorAll(".checkbox-container");

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];
      const container = checkbox.nextSibling;
      const phoneInput = container.querySelector("input");

      setTimeout(() => {
        if (checkbox.classList.contains("checked")) {
          // add phone input if checked
          phoneInput.classList.add("visible");
        } else {
          // remove phone input if not checked
          phoneInput.classList.remove("visible");
          phoneInput.value = "";
        }
      }, 100);
    }
  }

  function checkForCleave() {
    let zip = document.querySelector('.cleave-zip');
    let npi = document.querySelector('.cleave-npi');
    let phone = document.querySelector('.cleave-phone');

    if(zip) {
      let cleave1 = new Cleave(".cleave-zip", {
        numericOnly: true,
        blocks: [5],
      });
    }
    if(npi) {
      let cleave2 = new Cleave(".cleave-npi", {
        numericOnly: true,
        blocks: [20],
      });
    }
    if(phone) {
      let cleave3 = new Cleave(".cleave-phone", {
        blocks: [0, 3, 0, 3, 4],
        delimiters: ["(", ")", " ", "-"],
        numericOnly: true,
      });
    }
  }

  const onError = (data) => {};

  if (isMobile) {
    return (
      <CustomContainer
        className={showPageContent ? "visible" : "hidden"}
        ref={isMobile ? mobileBgWrapper : null}
        isiconfig={isiconfig}
      >
        <ContainerScroll>
          <FormWrapper padding="1.6rem" direction="column">
            <BaseText size={2}>Stay in the know about KRAZATI</BaseText>
            <BaseText as="p" margin=".5rem 0 0" size={1.2} weight="300">
             You are now registering for information that is intended for US
              healthcare professionals.
            </BaseText>

            {finishedSubmission ? (
              errorOnSubmission ? (
                <ErrorMessage>
                  Oops!
                  <br />
                  Please try submitting again.
                </ErrorMessage>
              ) : (
                <ThankYouMessage>Thank you for registering!</ThankYouMessage>
              )
            ) : (
              <>
                <BaseText
                  text-align="right"
                  margin=".5rem 0 0"
                  size={1}
                  weight="bold"
                >
                  *Required
                </BaseText>

                <Box
                  as="form"
                  direction="column"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="first_name">
                      First Name*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="first_name"
                      {...register("first_name", { required: true })}
                      className={errors?.first_name ? "__has-error" : ""}
                      onChange={(e) => {
                        clearErrors("first_name");
                      }}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="last_name">
                      Last Name*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="last_name"
                      {...register("last_name", { required: true })}
                      className={errors?.last_name ? "__has-error" : ""}
                      onChange={(e) => {
                        clearErrors("last_name");
                      }}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="email">
                      Email*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="email"
                      className={errors?.email ? "__has-error" : ""}
                      onChange={(e) => {
                        clearErrors("email");
                      }}
                      {...register("email", {
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="zipcode">
                      ZIP Code*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="zipcode"
                      className={`cleave-zip ${
                        errors?.zipcode ? "__has-error" : ""
                      }`}
                      onChange={(e) => {
                        clearErrors("zipcode");
                      }}
                      {...register("zipcode", { required: true })}
                    />
                  </Box>

                  <Box direction="column">
                    <Label weight="bold" size={1} htmlFor="provider_type">
                      Provider Type*
                    </Label>
                    <Input
                      margin="0.8rem 0 2rem"
                      id="provider_type"
                      className={errors?.provider_type ? "__has-error" : ""}
                      onChange={(e) => {
                        clearErrors("provider_type");
                      }}
                      {...register("provider_type", { required: true })}
                    />
                  </Box>
                  <Box
                    justify="flex-start"
                    align="center"
                    margin="0 0.8rem 2.4rem 0"
                  >
                    <Box
                      as="label"
                      htmlFor="connected"
                      position="relative"
                      border="0.1rem solid white"
                      margin="0 0.8rem 0 0"
                      width="2.4rem"
                      height="2.4rem"
                      background="#303345"
                      className={watch("be_connected") ? "checked" : null}
                    >
                      {watch("be_connected") ? (
                        <CheckWrapper>
                          <Check />
                        </CheckWrapper>
                      ) : null}
                      <Input
                        className="invisible"
                        id="connected"
                        {...register("be_connected")}
                        type="checkbox"
                      />
                    </Box>
                    <Label width="90%" htmlFor="connected">
                      I would like to be connected to a representative from
                      Mirati.
                    </Label>
                  </Box>

                  <Box
                    justify="flex-start"
                    align="center"
                    margin="0 0.8rem 2.4rem 0"
                  >
                    <Box
                      as="label"
                      htmlFor="receive_message"
                      position="relative"
                      border="0.1rem solid white"
                      margin="0 0.8rem 0 0"
                      width="2.4rem"
                      height="2.4rem"
                      background="#303345"
                      className={`checkbox-container ${
                        watch("receive_message") ? "checked" : null
                      }`}
                      onClick={() => {
                        showPhone();
                      }}
                    >
                      {watch("receive_message") ? (
                        <CheckWrapper>
                          <Check />
                        </CheckWrapper>
                      ) : null}
                      <Input
                        className="invisible"
                        id="receive_message"
                        width="2.4rem"
                        height="2.4rem"
                        {...register("receive_message")}
                        type="checkbox"
                      />
                    </Box>
                    <Box direction="column" align="flex-start" width="90%">
                      <Label
                        width="90%"
                        htmlFor="receive_message"
                        margin="0 0 0 .2rem"
                        onClick={(e) => {
                          showPhone(e);
                        }}
                      >
                        I would like to receive text messages from Mirati.
                        Message and data rates may apply.
                      </Label>
                      <BaseLink
                        margin="0 0  .2rem"
                        size={1.2}
                        weight="bold"
                        color="#17C94E"
                        decoration="underline"
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open("#sms", "_blank");
                        }}
                      >
                        SMS Terms and Conditions.
                      </BaseLink>
                      <Input
                        border="0.1rem solid white"
                        width="40%"
                        height="2.8rem"
                        background="#303345"
                        padding="0.4rem"
                        text-align="center"
                        size={1.2}
                        id="mobile_phone"
                        placeholder="(XXX) XXX-XXXX"
                        className={`cleave-phone ${
                          errors?.mobile_phone ? "__has-error" : ""
                        }`}
                        {...register("mobile_phone", { required: false })}
                      />
                    </Box>
                  </Box>

                  <Box
                    justify="flex-start"
                    align="center"
                    margin="0 0.8rem 0.5rem 0"
                  >
                    <Box
                      as="label"
                      htmlFor="privacy_notice"
                      position="relative"
                      border="0.1rem solid white"
                      margin="0 0.8rem 0 0"
                      width="2.4rem"
                      height="2.4rem"
                      background="#303345"
                      className={watch("privacy_notice") ? "checked" : null}
                    >
                      {watch("privacy_notice") ? (
                        <CheckWrapper>
                          <Check />
                        </CheckWrapper>
                      ) : null}
                      <Input
                        className="invisible"
                        id="privacy_notice"
                        width="2.4rem"
                        height="2.4rem"
                        {...register("privacy_notice", {
                          required: { message: "Required" },
                        })}
                        type="checkbox"
                      />
                    </Box>

                    <PrivacyRequiredInfo>*</PrivacyRequiredInfo>
                    <Label width="90%" htmlFor="privacy_notice">
                      I have read and agree to the Mirati
                      <BaseLink
                        margin="0 0 0 .2rem"
                        size={1.2}
                        weight="bold"
                        color="#17C94E"
                        decoration="underline"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            "https://mirati.com/privacy-policy/",
                            "_blank"
                          );
                        }}
                      >
                        Privacy Notice
                      </BaseLink>
                      .
                    </Label>
                  </Box>
                  <PrivacyErrorMessage>
                    {errors?.privacy_notice?.message}
                  </PrivacyErrorMessage>

                  <Button as="button" type="submit">
                    {isSubmitSuccessful ? "Sending..." : "Submit"}
                  </Button>
                </Box>
              </>
            )}
          </FormWrapper>
        </ContainerScroll>
      </CustomContainer>
    );
  }

  return (
    <CustomContainer
      className={showPageContent ? "visible" : "hidden"}
      isiconfig={isiconfig}
    >
      <ContainerScroll>
        <FormContainer
          background="#2E3344"
          border="white"
          borderSize={0.1}
          className="swiper-no-swiping swiper-pointer-events"
        >
          <FormWrapper padding="0" direction="column">
            <CustomTitle>1Stay in the know about KRAZATI</CustomTitle>
            <CustomSubTitle as="p" margin=".5rem 0 2rem" size={2} weight="300">
              You are now registering for information that is intended for US
              healthcare professionals.
            </CustomSubTitle>

            {finishedSubmission ? (
              errorOnSubmission ? (
                <ErrorMessage>
                  Oops!
                  <br />
                  Please try submitting again.
                </ErrorMessage>
              ) : (
                <ThankYouMessage>Thank you for registering!</ThankYouMessage>
              )
            ) : (
              <Box
                as="form"
                direction="column"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <Box>
                  <Box direction="column">
                    <Label weight="bold" size={1.3} htmlFor="first_name">
                      First Name*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="first_name"
                      {...register("first_name", { required: true })}
                      className={errors?.first_name ? "__has-error" : ""}
                      onChange={(e) => {
                        clearErrors("first_name");
                      }}
                    />
                  </Box>

                  <Box direction="column" margin="0 0 0 4rem">
                    <Label weight="bold" size={1.3} htmlFor="last_name">
                      Last Name*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="last_name"
                      className={errors?.last_name ? "__has-error" : ""}
                      {...register("last_name", { required: true })}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box direction="column">
                    <Label weight="bold" size={1.3} htmlFor="email">
                      Email*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="email"
                      className={errors?.email ? "__has-error" : ""}
                      {...register("email", {
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                  </Box>

                  <Box direction="column" margin="0 0 0 4rem">
                    <Label weight="bold" size={1.3} htmlFor="zipcode">
                      ZIP Code*
                    </Label>
                    <Input
                      margin="0.8rem 0 1.6rem"
                      id="zipcode"
                      className={`cleave-zip ${
                        errors?.zipcode ? "__has-error" : ""
                      }`}
                      {...register("zipcode", { required: true })}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box direction="column">
                    <Label weight="bold" size={1.3} htmlFor="provider_type">
                      Provider Type*
                    </Label>
                    <Input
                      margin="0.8rem 0 .5rem"
                      id="provider_type"
                      className={errors?.provider_type ? "__has-error" : ""}
                      {...register("provider_type", { required: true })}
                    />
                  </Box>
                  <Box direction="column" margin="0 0 0 4rem">
                    <Label weight="bold" size={1.3} htmlFor="npi_number">
                      NPI Number (This option saves you time!)
                    </Label>
                    <Input
                      margin="0.8rem 0 .5rem"
                      id="npi_number"
                      className="cleave-npi"
                      {...register("npi_number", { required: false })}
                    />
                  </Box>
                </Box>
                <BaseText
                  text-align="right"
                  margin="0 0 0"
                  size={1.3}
                  weight="bold"
                >
                  *Required
                </BaseText>

                <Box
                  justify="flex-start"
                  align="center"
                  margin="1.5rem 0.8rem 2.4rem 0"
                >
                  <Box
                    as="label"
                    style={{ cursor: "pointer" }}
                    htmlFor="connected"
                    position="relative"
                    border="0.1rem solid white"
                    margin="0.4rem 0.8rem 0 0"
                    width="2.4rem"
                    height="2.4rem"
                    background="#303345"
                    className={watch("be_connected") ? "checked" : null}
                  >
                    {watch("be_connected") ? (
                      <CheckWrapper>
                        <Check />
                      </CheckWrapper>
                    ) : null}
                    <Input
                      className="invisible"
                      id="connected"
                      {...register("be_connected")}
                      type="checkbox"
                    />
                  </Box>
                  <Label width="70%" htmlFor="connected">
                    I would like to be connected to a representative from
                    Mirati.
                  </Label>
                </Box>

                <Box
                  justify="flex-start"
                  align="center"
                  margin="0 0.8rem 2.4rem 0"
                >
                  <Box
                    as="label"
                    style={{ cursor: "pointer" }}
                    htmlFor="receive_message"
                    position="relative"
                    border="0.1rem solid white"
                    margin="0 0.8rem 0 0"
                    width="2.4rem"
                    height="2.4rem"
                    background="#303345"
                    className={`checkbox-container ${
                      watch("receive_message") ? "checked" : null
                    }`}
                    onClick={() => {
                      showPhone();
                    }}
                  >
                    {watch("receive_message") ? (
                      <CheckWrapper>
                        <Check />
                      </CheckWrapper>
                    ) : null}
                    <Input
                      className="invisible"
                      id="receive_message"
                      width="2.4rem"
                      height="2.4rem"
                      {...register("receive_message")}
                      type="checkbox"
                    />
                  </Box>
                  <Box justify="flex-start" align="center" width="90%">
                    <Label
                      width="70%"
                      htmlFor="receive_message"
                      margin="0 0 0 .2rem"
                    >
                      I would like to receive text messages from Mirati. Message
                      and data rates may apply.{" "}
                      <BaseLink
                        margin="0 0 .2rem"
                        size={1.6}
                        weight="bold"
                        color="#17C94E"
                        decoration="underline"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open("#sms", "_blank");
                        }}
                      >
                        SMS Terms and Conditions.
                      </BaseLink>
                    </Label>

                    <Input
                      border="0.1rem solid white"
                      width="16rem"
                      height="2.8rem"
                      background="#303345"
                      padding="0.4rem"
                      text-align="center"
                      size={2}
                      id="mobile_phone"
                      placeholder="(XXX) XXX-XXXX"
                      className={`cleave-phone ${
                        errors?.mobile_phone ? "__has-error" : ""
                      }`}
                      {...register("mobile_phone", { required: false })}
                    />
                  </Box>
                </Box>

                <Box justify="flex-start" align="center" margin="0 0.8rem 0 0">
                  <Box
                    as="label"
                    style={{ cursor: "pointer" }}
                    htmlFor="privacy_notice"
                    position="relative"
                    border="0.1rem solid white"
                    margin="0 0.8rem 0 0"
                    width="2.4rem"
                    height="2.4rem"
                    background="#303345"
                    className={watch("privacy_notice") ? "checked" : ""}
                  >
                    {watch("privacy_notice") ? (
                      <CheckWrapper>
                        <Check />
                      </CheckWrapper>
                    ) : null}
                    <Input
                      className="invisible"
                      id="privacy_notice"
                      width="2.4rem"
                      height="2.4rem"
                      {...register("privacy_notice", {
                        required: {
                          value: true,
                          message:
                            "You must accept the Privacy Notice to continue.",
                        },
                      })}
                      type="checkbox"
                    />
                  </Box>
                  <PrivacyRequiredInfo>*</PrivacyRequiredInfo>
                  <Label width="90%" htmlFor="privacy_notice">
                    I have read and agree to the Mirati
                    <BaseLink
                      margin="0 0 0 0.4rem"
                      size={1.6}
                      weight="bold"
                      color="#17C94E"
                      decoration="underline"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://mirati.com/privacy-policy/",
                          "_blank"
                        );
                      }}
                    >
                      Privacy Notice
                    </BaseLink>
                    .
                  </Label>
                </Box>
                <PrivacyErrorMessage>
                  {errors?.privacy_notice?.message}
                </PrivacyErrorMessage>

                <Button as="button" type="submit">
                  {isSubmitSuccessful ? "Sending..." : "Submit"}
                </Button>
              </Box>
            )}
          </FormWrapper>
        </FormContainer>
      </ContainerScroll>
    </CustomContainer>
  );
};

export default Slide2;

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  max-height: ${(props) => `calc(100% - ${props.isiconfig.mobile}rem)`};

  .checked {
  }

  .__has-error {
    border: 1px solid #f56f6f;
  }

  @media ${device.tablet} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig.tablet}rem)`};
  }

  @media ${device.desktop} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig.desktop}rem)`};
  }

  @media ${device.desktop880h} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig.thinkpad}rem)`};
  }
`;

const FormWrapper = styled(Box)`
  margin-bottom: 1rem;
`;

const FormContainer = styled(StyledBothSideShape)`
  height: 100%;

  @media ${device.desktop} {
    max-width: 89rem;
    margin: 3.2rem auto 0;
    padding: 3.2rem 6.4rem 4rem;
  }
`;
const Button = styled(BaseLink)`
  padding: 0.8rem 4rem;
  background: #3246ff;
  border: none;
  color: white;
  border-radius: 1.4rem;
  font-size: 1.1rem;
  width: fit-content;
  cursor: pointer;
  height: auto;
  border: 0.1rem solid #3246ff;
  transition: all 0.2s;
  margin-top: 2rem;
  &[disabled] {
    background: rgba(50, 70, 255, 0.5);
    border: 0.1rem solid #3246ff;
  }

  @media ${device.desktop} {
    text-align: center;
    width: 20rem;
    font-size: 1.6rem;
  }
`;

const CheckWrapper = styled.div`
  position: absolute;
`;

const CustomTitle = styled(BaseText)`
  font-size: 3.6rem;
`;

const CustomSubTitle = styled(BaseText)`
  font-size: 2rem;
`;

const PrivacyErrorMessage = styled.p`
  font-size: min(1.2rem);
  color: #f56f6f;

  @media ${device.desktop} {
  }
`;

const ThankYouMessage = styled.div`
  margin-top: 3rem;
  font-size: 3rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  font-family: "Barlow";
`;

const ErrorMessage = styled.div`
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 300;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  font-family: "Barlow";
`;

const PrivacyRequiredInfo = styled.div`
  color: #ffffff;
  font-family: "Barlow";
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-align: center;
  position: absolute;
  transform: translate(2.5rem, -1.2rem);
`;
