import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Title, TitleText, Scroll } from '../../_components/Tabs/Tabs'
import ReferenceWithLink from '../../_components/ReferenceWithLink'
import { device } from '../../_assets/styles/theme'
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll'
import useMobileDetect from '../../../_hooks/useMobileDetect'
import { FaArrowRight } from 'react-icons/fa'
import { BaseText, Box, CustomLink, FlexCentered } from '../../_components/PageSwipper/utils/styles'
import { Bullet, Container, CustomReference, InfoSubTitle, InfoTitle, ListContainer, ListTitle, TabShapeContainer, Wrapper } from './Tab1'
import TwoSides from '../../_components/Shapes/TwoSides'
import { ReactComponent as Chart } from '../img/dcr-chart.svg'
import { ReactComponent as MobileChart } from '../img/dcr-chart-mobile.svg'
import './css/style.scss';
import { useEffect } from 'react'

const ChatButton = ({ activeColor, percentage, element, text }) => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = (element) => {
    setIsActive(prev => !prev)
    const item = document.querySelectorAll(`.${element}`)
    item.forEach(bar => {
      bar.classList.toggle('active')
      bar.classList.toggle('inactive')
    })
  }

  return (
    <StyledButton
      className={isActive ? (percentage ? 'active animated' : 'active') : 'inactive'}
      activeColor={activeColor}
      onClick={() => handleClick(element)}>
      <span>{text}</span>

      {percentage && (
        <PercentageText
          className={isActive ? 'active' : 'inactive'}
          activeColor={activeColor}
        >
          <span>{percentage}</span>
          <span>%</span>
        </PercentageText>
      )}

    </StyledButton>
  )

}

const Tab2 = ({ isiconfig, handleShowModal, ...props }) => {
  const { isMobile } = useMobileDetect()

  const handleClick = (element) => {
    const item = document.querySelectorAll(`.${element}`)
    item.forEach(bar => {
      bar.classList.toggle('active')
      bar.classList.toggle('inactive')
    })
  }

  // Add function to text inside the svg
  useEffect(() => {
    const element = document.querySelector('#svg-sup-26')
    element?.addEventListener('click', () => {
      handleShowModal([25])
    })
  }, [])

  if (isMobile) {
    return (
      <CustomScroll {...props} isiconfig={isiconfig}>
        <ContainerScroll>
          <Container>
            <Wrapper>
              <CustomTitle>
                <p>REGISTRATIONAL PHASE 2 COHORT</p>
                <TitleText><span>DCR</span> in patients taking KRAZATI: 80%</TitleText>
              </CustomTitle>
              <TabShapeContainer className="tab-2">
                <TwoSides>
                  <InfoTitle><span className="green">80<sup>%</sup>DCR</span></InfoTitle>
                  <InfoSubTitle>(n=112; 95% CI: 70.8-86.5)<small><sup onClick={() => handleShowModal([31])}>31</sup>*</small></InfoSubTitle>
                </TwoSides>
              </TabShapeContainer>
              <CustomListContainer>
                <ListTitle> Depth of response: <small>80% of patients experienced tumor shrinkage of any magnitude.</small></ListTitle>
                <ListSubTitle><CustomBullet>•</CustomBullet>Median follow-up time was 12.9 months*</ListSubTitle>
                <CircledInfoContainer>
                  <CircledInfoItem>
                    <CircledInfoPercent border="#3246FF">37<sup>%</sup></CircledInfoPercent>
                    <CircledInfoText>Stable Disease</CircledInfoText>
                  </CircledInfoItem>

                  <CircledInfoItem>
                    <CircledInfoPercent border="#17C94E">42<sup>%</sup></CircledInfoPercent>
                    <CircledInfoText>Partial Response</CircledInfoText>
                  </CircledInfoItem>

                  <CircledInfoItem>
                    <CircledInfoPercent border="#B32EAA">1<sup>%</sup></CircledInfoPercent>
                    <CircledInfoText>Complete Response</CircledInfoText>
                  </CircledInfoItem>
                </CircledInfoContainer>
              </CustomListContainer>
            </Wrapper>

            <img src={require('../img/dcr-chart-mobile.svg').default} className="dcr-mobile-chart" alt="Bar Chart Of DCR In Patients" />

            <BannerContainer>
              <FlexCentered>
                <Banner>
                  <span>75% of responders</span> achieved a &gt;50% response<sup onClick={() => handleShowModal([33])}>33</sup>
                </Banner>
              </FlexCentered>
            </BannerContainer>

            <SubInfo>
              <BaseText>
                <p className="under-chart">Second line provides the first opportunity to target <em>KRAS G12C</em> with KRAZATI.</p>
              </BaseText>
            </SubInfo>
            <ReferenceWithLink url='/n/safety' linkText="Safety">
              <p>*Tumor response was assessed by BICR. Phase 2 data cutoff: October 15, 2021. Additional follow-up planned.</p>
              <p>BICR=blinded independent central review; CI=confidence interval; DCR=disease control rate.</p>
            </ReferenceWithLink>
          </Container>
        </ContainerScroll>
      </CustomScroll>
    )
  }

  return (
    <CustomScroll {...props} isiconfig={isiconfig}>
      <CustomContainer style={{ maxWidth: '100rem' }}>
        <Wrapper>
          <ChartContainer>
            <ChartWrapper>
              <Infos>
                <CustomTitle>
                  <p>REGISTRATIONAL PHASE 2 COHORT</p>
                  <TitleText><span>DCR</span> in patients taking KRAZATI: 80%<small>(n=112; 95% CI: 70.8-86.5)<sup onClick={() => handleShowModal([31])}>31</sup>*</small></TitleText>
                </CustomTitle>
                <SubInfo>
                  <BaseText>Depth of response: 80% of patients experienced tumor shrinkage of any magnitude.</BaseText>
                  <BaseText><CustomBullet>•</CustomBullet>Median follow-up time was 12.9 months*</BaseText>
                </SubInfo>
              </Infos>
              <div className='dcr-chart-wrap'>
                <Chart />
                <div className="chart-chevron">
                  <div className="inner">
                    <span>75% of responders</span> achieved a &gt;50% response<sup onClick={() => handleShowModal([33])}>33</sup>
                  </div>
                </div>
              </div>
              <SubInfo>
                <BaseText>
                  <p className="under-chart">Second line provides the first opportunity to target <em>KRAS G12C</em> with KRAZATI.</p>
                </BaseText>
              </SubInfo>
            </ChartWrapper>

            <ChartController>
              <OrientationText>Click a button to see the best tumor response</OrientationText>
              <OrientationIconWrapper><img src="/assets/icons/orientation-icon.svg" alt="Click to see the best tumor response" /></OrientationIconWrapper>
              <ChatButton element="stable-disease" text="Stable Disease" percentage="37" activeColor="#3246FF" />
              <ChatButton element="partial-response" text="Partial Response" percentage="42" activeColor="#17C94E" />
              <ChatButton element="complete-response" text="Complete Response" percentage="1" activeColor="#B32EAA" />
              <ChatButton element="progressive-disease" text="Progressive Disease" activeColor="#8550D9" />
            </ChartController>
          </ChartContainer>
        </Wrapper>


      </CustomContainer>
      <ReferenceWithLink url='/n/safety' linkText="Safety">
        <p>*Tumor response was assessed by BICR. Phase 2 data cutoff: October 15, 2021. Additional follow-up planned.</p>
        <p>BICR=blinded independent central review; CI=confidence interval; DCR=disease control rate.</p>
      </ReferenceWithLink>
    </CustomScroll>
  )
}

export default Tab2

export const CustomScroll = styled(Scroll)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: auto;

    @media ${device.desktop} {
      height: calc(100% - 10rem);
      padding-top: 4rem;
    }
`;

const CustomMobileChart = styled(MobileChart)`
  margin: 40px auto;
  width: 100%;
`

const CustomContainer = styled(Container)`
  height: 100%;
`

const CustomListContainer = styled(ListContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: .08rem;
  padding: 0 15px;
  h5 {
    text-transform: initial;
    max-width: 95%;
    font-family: "Barlow";
  }
  small {
    font-size: 1.2rem;
    font-weight: 500;
  }
`
export const ListSubTitle = styled.p`
  padding: 0 0.8rem;
  width: 100%;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1.8rem;
  .red {
    color: #FF005A;
  }
`

const CircledInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.4rem;
  margin-bottom: 2.1rem;
`

const CircledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(33% - 4.4rem);
  margin-right: 4.4rem;

  &:last-of-type {
    margin-right: 0;
  }
`

const CircledInfoPercent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background: #2E3244;
  border: .2rem solid ${props => props.border};
  font-family: 'Oswald';
  font-weight: 600;
  font-size: 2.2rem;
  color: white;
  padding-left: 10px;

  sup {
    font-size: 1.1rem;
    position: relative;
    top: -.5rem;
	margin: 0;
	padding: 0;
  }
`

const CircledInfoText = styled.p`
  font-family: 'Barlow Semi Condensed';
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
`

export const BannerContainer = styled.div`
  background: ${props => props.background || 'linear-gradient(#1F2435, #3A4259)'};
  box-shadow: 0px 0.1rem .2rem rgba(0,0,0,.14), 0px 0px 0.4rem rgba(0,0,0,.20), 0px 0.1rem 0.1rem rgba(0,0,0,.12);
  width: 100%;
  margin-bottom: 1rem;

`

export const Banner = styled.div`
  font-family: 'Oswald';
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  text-transform: uppercase;
  padding: 1.2rem 0;

  sup {
    font-size: 0.8rem;
  }
  span {
    color: #17C94E;
    font-weight: bold;
  }
`

const ChartContainer = styled.div`
  display: flex;
  position: relative;
  top: -4.5rem;
  pointer-events: all;
  .stable-disease {
    &.active {
      fill:#3144fc;
      transition: fill 1s;

    }
  }

  .partial-response {
    &.active {
      fill: #0ec843;
      transition: fill 1s;
    }
  }

  .complete-response {
    &.active {
      fill: #b22da8;
      transition: fill 1s;
    }
  }

  .progressive-disease {
    &.active {
      fill: #8550d9;
      transition: fill 1s;
    }
  }

  .inactive {
    fill: #b3b3b3;
    transition: fill 1s;
  }

`

const ChartController = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 3.6rem;
  padding-top: 8.8rem;

`

const OrientationText = styled.div`
  max-width: 15rem;
  color: #FFFFFF;
  font-family: 'Barlow';
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4rem;
  text-align: center;
  margin-bottom: 1.8rem;

`

const StyledButton = styled.button.attrs({
  className: 'swiper-no-swiping'
})`
  position: relative;
  color: white;
  width: 18.4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid #FFFFFF;
  border-radius: 2rem;
  font-family: "Barlow Semi Condensed";
  font-size: 1.4rem;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  margin-bottom: 2.4rem;

  > span {
    width: 100%;
    transition: transform 1s ease;
  }
  &.active {
    border: .2rem solid ${props => props.activeColor};
    background: #2E3244;

    &.animated {
      > span {
        transform: translateX(-2rem);
      }
    }
  }

`

const CustomTitle = styled(Title)`
  margin-bottom: 2.3rem;
  flex-direction: column;
  align-items: center;

  p {
    color: #FFFFFF;
    font-family: 'Oswald';
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.6rem;
  }

  @media ${device.desktop} {
    margin-bottom: 0;
    align-items: flex-start;
    p {
      color: #FFFFFF;
      font-family: 'Oswald';
      font-size: 1.7rem;
      letter-spacing: 0;
      line-height: 1.8rem;
    }

    h1 {
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 4rem;
    }

    small {
      font-size: 1.7rem;
      font-weight: 500;
      margin-left: .5rem;
      text-transform: none;

	  sup {
		top: unset;
      }
    }
  }

`

const PercentageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 100%;
  background: #2E3244;
  border: .2rem solid ${props => props.activeColor};
  position: absolute;
  right: -1rem;

  &.inactive {
    opacity: 0;
    transition: opacity .3s linear;

  }

  &.active {
    opacity: 1;
    transition: opacity .3s linear;
  }

  span {
    font-size: 2rem;
  }

`

const OrientationIconWrapper = styled.div`
  max-width: 3rem;
  margin-bottom: 2.2rem;
`

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    margin-top: 40px;

    svg {
      margin-left: -50px;
    }
  }

  @media ${device.desktop800h} {
    svg {
      max-width: 100rem;
      height: auto;
    }
  }
`
const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  left: 6rem;
  top: 3.8rem;

  @media ${device.desktop} {
    top: -1rem;
  }

`
const SubInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    font-size: 1.7rem;
    line-height: 2rem;

    &:first-of-type {
      margin-bottom: .5rem;
    }
  }
  .under-chart {
    margin: 2rem 0;
    text-align: center;
  }

`

const CustomBullet = styled(Bullet)`
  margin: 0 1rem;
`
