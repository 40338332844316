import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReasonBox, ReasonNumber, ReasonTag, Scroll, Title, TitleText } from '../../_components/Tabs/Tabs';
import { Link } from 'react-router-dom';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import { FaArrowRight } from 'react-icons/fa';
import ReferenceModal from '../../_components/Modal/ReferenceModal';
import { ReactComponent as Chart } from '../assets/img/chart_update_2024.svg';

const TitleComponent = ({ handleShowModal }) => {
    return (
        <CustomTitleText>
            <em>KRAS G12C</em> <strong>is a prevalent driver mutation with poor prognosis</strong>
            <sup style={{ cursor: 'pointer' }} onClick={() => handleShowModal([6, 7, 8, 9, 10, 11, 12, 13])}>
                6&#8209;13
            </sup>
        </CustomTitleText>
    );
};

const Tab1 = ({ ...props }) => {
    const { isMobile, isTabletAndLaptop } = useMobileDetect();
    const [referencesIndex, setReferencesIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (indexs) => {
        if (indexs) {
            setReferencesIndex(indexs);
        }
    };

    useEffect(() => {
        if (referencesIndex) {
            setShowModal(true);
        }
    }, [referencesIndex]);

    if (isMobile && !isTabletAndLaptop) {
        return (
            <Scroll {...props}>
                {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
                <ContainerScroll>
                    <Body>
                        <CustomTitle>
                            <TitleComponent handleShowModal={handleShowModal} />
                        </CustomTitle>

                        <Container>
                            <ChartWrapper>
                                <ChartContainer>
                                    {/* <Chart /> */}
                                    <img src='../assets/chart_update_2024.png' alt="Chart" />
                                    {/* <img src='/assets/chart.svg' alt="Chart" /> */}
                                    <svgText className="svg-text">
                                        G12V, G12D, G13C, G12A, G12S, G13D, other <sup style={{ fontSize: '80%' }} onClick={() => handleShowModal([8])}>8</sup>
                                    </svgText>
                                </ChartContainer>

                                <ChartTitle>
                                    Prevalence of specific driver mutations in lung adenocarcinoma<sup onClick={() => handleShowModal([6, 7])}>6, 7</sup>
                                </ChartTitle>
                            </ChartWrapper>

                            <Arrow>
                                <ArrowWrapper>
                                    <p>
                                        In NSCLC, the <em>KRAS G12C</em> mutation is <span>almost as prevalent</span> as <em>EGFR</em> mutations<sup onClick={() => handleShowModal([6, 7, 8])}>6&#8209;8</sup>
                                    </p>

                                    <div className="basic-text">
                                        <p>
                                            The presence of a <em>KRAS G12C</em> mutation in advanced NSCLC is correlated with <span className="green">poorer prognosis</span> in patients treated with chemotherapy when compared to patients with <em>KRAS</em> wild type.<sup onClick={() => handleShowModal([9, 10])}>9,10</sup>
                                        </p>
                                        <p>
                                            Second-line+ treatment with docetaxel in patients with <em>KRAS G12C</em>-mutated NSCLC is associated with a real-world PFS of less than 5 months.<sup onClick={() => handleShowModal([11, 12, 13])}>11-13</sup>
                                        </p>
                                    </div>
                                    <ArrowBtn as={Link} to='/n/MOA'>
                                        Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                                        <FaArrowRight />
                                    </ArrowBtn>
                                </ArrowWrapper>
                            </Arrow>

                            <RefMob linkText='Testing' url='/n/testing'>NSCLC=non-small cell lung cancer; PFS=progression-free survival.</RefMob>
                        </Container>
                    </Body>
                </ContainerScroll>
            </Scroll>
        );
    }

    if (isTabletAndLaptop) {
        return (
            <>
                <Scroll {...props}>
                    {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
                    <ContainerScroll>
                        <Tab>
                            <Body>
                                <CustomTitle>
                                    <ReasonBox>
                                        <ReasonTag>Reason</ReasonTag>
                                        <ReasonNumber>1</ReasonNumber>
                                    </ReasonBox>

                                    <TitleComponent handleShowModal={handleShowModal} />
                                </CustomTitle>

                                <Container>
                                    <Wrapper className='laptop-only'>
                                        <Arrow>
                                            <ArrowContainer>
                                                <ArrowWrapper>
                                                    <p>
                                                        In NSCLC, the <em>KRAS G12C</em> mutation is <span>almost as prevalent</span> as <em>EGFR</em> mutations<sup onClick={() => handleShowModal([6, 7, 8])}>6&#8209;8</sup>
                                                    </p>
                                                </ArrowWrapper>
                                            </ArrowContainer>
                                        </Arrow>
                                        <div className="basic-text">
                                            <p>
                                                The presence of a <em>KRAS G12C</em> mutation in advanced NSCLC is correlated with <span className="green">poorer prognosis</span> in patients treated with chemotherapy when compared to patients with <em>KRAS</em> wild type.<sup onClick={() => handleShowModal([9, 10])}>9,10</sup>
                                            </p>
                                            <p>
                                                Second-line+ treatment with docetaxel in patients with <em>KRAS G12C</em>-mutated NSCLC is associated with a real-world PFS of less than 5 months.<sup onClick={() => handleShowModal([11, 12, 13])}>11-13</sup>
                                            </p>
                                        </div>
                                        <ArrowBtn as={Link} to='/n/MOA'>
                                            Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                                            <FaArrowRight />
                                        </ArrowBtn>
                                    </Wrapper>

                                    <ChartWrapper>
                                        <ChartContainer>
                                            {/* <Chart /> */}
                                            <img src='../assets/chart_update_2024.png' alt="Chart" />
                                            {/* <img src='/assets/chart.svg' alt="Chart" /> */}
                                            <svgText className="svg-text">
                                                G12V, G12D, G13C, G12A, G12S, G13D, other <sup onClick={() => handleShowModal([8])}>8</sup>
                                            </svgText>
                                        </ChartContainer>

                                        <ChartTitle>
                                            Prevalence of specific driver <br className='laptop-only' /> mutations in lung adenocarcinoma
                                            <sup onClick={() => handleShowModal([6, 7])}>6,7</sup>
                                        </ChartTitle>
                                    </ChartWrapper>

                                    {isMobile && (
                                        <>
                                            <Arrow className='tablet-only'>
                                                <ArrowWrapper>
                                                    <p>
                                                        In NSCLC, the <em>KRAS G12C</em> mutation is <span>almost as prevalent</span> as <em>EGFR</em> mutations<sup onClick={() => handleShowModal([6, 7, 8])}>6&#8209;8</sup>
                                                    </p>
                                                    <div className="basic-text">
                                                        <p>
                                                            The presence of a <em>KRAS G12C</em> mutation in advanced NSCLC is correlated with <span className="green">poorer prognosis</span> in patients treated with chemotherapy when compared to patients with <em>KRAS</em> wild type.<sup onClick={() => handleShowModal([9, 10])}>9,10</sup>
                                                        </p>
                                                        <p>
                                                            Second-line+ treatment with docetaxel in patients with <em>KRAS G12C</em>-mutated NSCLC is associated with a real-world PFS of less than 5 months.<sup onClick={() => handleShowModal([11, 12, 13])}>11-13</sup>
                                                        </p>
                                                    </div>
                                                    <ArrowBtn as={Link} to='/n/MOA'>
                                                        Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                                                    </ArrowBtn>
                                                </ArrowWrapper>
                                            </Arrow>
                                        </>
                                    )}

                                    <div className={`${props.className} tablet-only`}>
                                        <ReferenceFooterWithLink linkText='Testing' url='/n/testing'>
                                            NSCLC=non-small cell lung cancer; PFS=progression-free survival.
                                        </ReferenceFooterWithLink>
                                    </div>
                                </Container>
                            </Body>
                        </Tab>
                    </ContainerScroll>
                </Scroll>
                <ReferecenceContainer className={`${props.className} laptop-only`}>
                    <ReferenceFooterWithLink linkText='Testing' url='/n/testing'>NSCLC=non-small cell lung cancer; PFS=progression-free survival.</ReferenceFooterWithLink>
                </ReferecenceContainer>
            </>
        );
    }

    return (
        <>
            <Tab {...props}>
                {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
                <Body>
                    <CustomTitle>
                        <ReasonBox>
                            <ReasonTag>Reason</ReasonTag>
                            <ReasonNumber>1</ReasonNumber>
                        </ReasonBox>

                        <TitleComponent handleShowModal={handleShowModal} />
                    </CustomTitle>

                    <Container>
                        <Wrapper>
                            <Arrow className='laptop-only'>
                                <ArrowContainer>
                                    <ArrowWrapper>
                                        <p>
                                            In NSCLC, <br />
                                            the <em>KRAS G12C</em> mutation <br />
                                            is <span>almost as prevalent</span> as <br />
                                            <em>EGFR</em> mutations<sup onClick={() => handleShowModal([6, 7, 8])}>6&#8209;8</sup>
                                        </p>
                                    </ArrowWrapper>
                                </ArrowContainer>
                            </Arrow>
                            <div className="basic-text">
                                <p>
                                    The presence of a <em>KRAS G12C</em> mutation in advanced NSCLC is correlated with <span className="green">poorer prognosis</span> in patients treated with chemotherapy when compared to patients with <em>KRAS</em> wild type.<sup onClick={() => handleShowModal([9, 10])}>9,10</sup>
                                </p>
                                <p>
                                    Second-line+ treatment with docetaxel in patients with <em>KRAS G12C</em>-mutated NSCLC is associated with a real-world PFS of less than 5 months.<sup onClick={() => handleShowModal([11, 12, 13])}>11-13</sup>
                                </p>
                            </div>
                            <ArrowBtn as={Link} to='/n/MOA'>
                                Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                                <FaArrowRight />
                            </ArrowBtn>
                        </Wrapper>

                        <ChartWrapper>
                            <ChartContainer>
                                {/* <Chart /> */}
                                <img src='../assets/chart_update_2024.png' alt="Chart" />
                                <svgText className="svg-text">
                                    G12V, G12D, G13C, G12A, G12S, G13D, other<sup onClick={() => handleShowModal([8])}>8</sup>
                                </svgText>
                            </ChartContainer>

                            <ChartTitle>
                                Prevalence of specific driver <br className='laptop-only' /> mutations in lung adenocarcinoma
                                <sup onClick={() => handleShowModal([6, 7])}>6,7</sup>
                            </ChartTitle>
                        </ChartWrapper>

                        {isMobile && (
                            <>
                                <Arrow className='tablet-only'>
                                    <ArrowWrapper>
                                        <p>
                                            In NSCLC, the <em>KRAS G12C</em> mutation is <span>almost as prevalent</span> as <em>EGFR</em> mutations<sup onClick={() => handleShowModal([6, 7, 8])}>6&#8209;8</sup>
                                        </p>
                                    </ArrowWrapper>
                                    <div className="basic-text">
                                        <p>
                                            The presence of a KRAS G12C mutation in advanced NSCLC is correlated with <span className="green">poorer prognosis</span> in patients treated with chemotherapy when compared to patients with <em>KRAS</em> wild type.<sup onClick={() => handleShowModal([9, 10])}>9,10</sup>
                                        </p>
                                        <p>
                                            Second-line+ treatment with docetaxel in patients with <em>KRAS G12C</em>-mutated NSCLC is associated with a real-world PFS of less than 5 months.<sup onClick={() => handleShowModal([11, 12, 13])}>11-13</sup>
                                        </p>
                                    </div>
                                    <ArrowBtn as={Link} to='/n/MOA'>
                                        Learn how KRAZATI challenges&nbsp;<em>KRAS G12C</em>
                                    </ArrowBtn>
                                </Arrow>
                            </>
                        )}
                    </Container>
                </Body>
            </Tab>
            {!isMobile && (
                <ReferenceWrapper {...props}>
                    <ReferenceFooterWithLink linkText='Testing' url='/n/testing'>
                        NSCLC=non-small cell lung cancer; PFS=progression-free survival.
                    </ReferenceFooterWithLink>
                </ReferenceWrapper>
            )}
        </>
    );
};

export default Tab1;

const Tab = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.desktop} {
        max-width: 108rem;
        margin: 0 auto;
        position: relative;
        justify-content: flex-start;
    }
`;

const Body = styled.div`
    max-width: 180rem;

    em {
      white-space: nowrap;
    }
    @media ${device.tablet} {
        padding-bottom: 0;
        margin-bottom: 0;
        margin: 0;
    }
    @media ${device.desktop} {
        margin-bottom: 0;
        margin: 0;
        > div {
            padding-left: 0;
            align-items: flex-start;
        }
    }

    .__is-reference {
        @media ${device.laptop} {
            position: absolute;
            bottom: 1rem;
            width: 100%;
        }

        @media ${device.desktop} {
            position: initial;
            bottom: initial;
            width: initial;
        }
    }
    .basic-text {
      position: relative;
      color: white;
      font-size: min(1.4rem, 1.8rem);
      font-family: 'Barlow Semi Condensed', sans-serif;
      font-weight: bold;
      p {
        text-transform: none !important;
        &:first-of-type {
        margin-bottom: 1rem;
        }
      }
      .green {
        color: #17C94E !important;
      }
      @media ${device.tablet} {
          font-size: min(1.8rem, 2rem);
          width: 100%;
          padding: 0;

          sup {
              line-height: 1;
          }
      }

      @media ${device.desktop} {
          max-width: 45rem;
          top: 10rem;
          font-size: 1.8rem;
          sup {
              font-size: 1rem;
          }
      }
    }
`;

const CustomTitle = styled(Title)`
    padding: 0 3.2rem;
    z-index: 10;

    @media ${device.tablet} {
        padding: 0 1.6rem;
        justify-content: flex-start;
    }

    @media ${device.laptop} {
        padding: 0 2rem;
    }

    @media ${device.desktop} {
        max-width: 70rem;
        padding: 0;
        margin-left: 0;
        margin-bottom: 0;
        align-items: center !important;
    }
`;

const Container = styled.div`
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media ${device.laptop} {
        flex-direction: row;
    }

    @media ${device.desktop800h} {
        margin-top: 1rem;
    }
    sup {
        cursor: pointer;
        pointer-events: all;
    }
`;

const Wrapper = styled.div`
    width: 100%;

    @media ${device.laptop} {
        padding: 0 2rem;
    }

    @media ${device.desktop} {
        padding: 0;
    }
`;
const Arrow = styled.div`
	position: relative;
	margin: 0 0 3.2rem;
	width: 100%;

	@media ${device.laptop} {
	  margin: 4.4rem 0 3.2rem;
		top: 7rem;
		margin: 0;
	}
`;
const ArrowContainer = styled.div`
filter: drop-shadow(5px 2px 4px rgba(0, 0, 0, 0.2));

@media ${device.tablet} {
	max-width: 40rem;
	margin-left: 0;
	height: 16rem;
  background: #17C94E;
  padding: 2px;
  min-height: auto;
	clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 0 50%, 0% 0%);
}
`;

const ArrowWrapper = styled.div`
    color: white;
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        font-size: min(1.3rem, 1.8rem);
        text-align: center;
        padding: 0 1.2rem;
        line-height: 1.2;
        margin-bottom: 1rem;
        span {
            color: #17c94e;
            font-weight: bold;
        }
    }

    @media ${device.tablet} {
        margin-bottom: 4rem;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        margin-bottom: 0;
        padding: 2.4rem;
        p {
            font-size: 2.4rem;
            text-align: initial;
            padding: 0;
            text-align: left;
            margin-bottom: 0;
            sup {
                font-size: 55%;
            }
        }
    }

		@media (min-width: 960px) {
			background: #1F2435;
			clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 0 50%, 0% 0%);
      position: relative;

		}
`;

const ArrowBtn = styled.button`
    font-family: 'Barlow', sans-serif;
    font-size: min(1.2rem, 1.7rem);
    padding: 0.6rem 2rem;
    max-height: calc(33 * 0.3125vmin);
    margin-top: 0.8rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    color: white;
    border: 2px solid #17C94E;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 600;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);

    &:hover {
        background-color: rgba(9, 11, 25, 0.5);
        border: 0.1rem solid #17c94e;
    }

    svg {
      margin-left: 1rem;
      fill: ${props => props.variant === 'black' ? '#3A3A3A' : 'white'};
      font-size: 1.2rem;
      margin-top: 2px;
    }

    @media ${device.tablet} {

      svg {
        font-size: 1.4rem;
        transition: transform .6s;
      }

      &:hover {
        svg {
          transform: translateX(0.3rem);
        }
      }
    }

    @media ${device.tablet} {
        font-size: 1.6rem;
        padding: 0.9rem 1.6rem;
        width: 36.8rem;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    @media ${device.laptop} {
        top: 11rem;
        margin-top: 0;
    }
`;

const ChartTitle = styled.div`
    color: white;
    font-size: min(1.4rem, 1.8rem);
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-align: center;
    padding: 0 4.4rem;
    font-weight: bold;

    @media ${device.tablet} {
        font-size: min(1.8rem, 2rem);
        width: 100%;
        padding: 0;

        sup {
            line-height: 1;
        }
    }

    @media ${device.desktop} {
        font-size: 1.8rem;
        sup {
            font-size: 1rem;
        }
    }
`;

const ChartContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 0.8rem;
    position: relative;
    svg {
        height: 100%;
        height: auto;
        width: 100%;
        position: relative;
        right: 0.5rem;
        #larger {
            transform: scale(0.828) translate(8.9%, 10.8%);
        }
        #break-off {
            transform: scale(1.035) translate(-1.5%, -6.25%);
        }
        #labels {
            opacity: 0;
        }

        @keyframes larger {
            from {
                transform: scale(0.828) translate(8.9%, 10.8%);
            }
            to {
                transform: none;
            }
        }

        @keyframes break {
            from {
                transform: scale(1.035) translate(-1.5%, -6.25%);
            }
            to {
                transform: none;
            }
        }

        @keyframes labels {
            from {
                opacity: 0;
            }
            to {
                opacity: 100%;
            }
        }

        @keyframes returnLarger {
            from {
                transform: none;
            }
            to {
                transform: scale(0.828) translate(8.9%, 10.8%);
            }
        }

        @keyframes returnBreak {
            from {
                transform: none;
            }
            to {
                transform: scale(1.045) translate(-2%, -6.25%);
            }
        }

        @keyframes returnLabels {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }

    .svg-text {
      color: transparent;
      position: absolute;
      font-family: 'Barlow';
      font-size: 15.539px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      width: 154px;
      top: 2px;
      right: 10px;
    }

    @media ${device.tablet} {
        max-width: 80%;
        margin: 0 auto;
        width: 100%;
        position: relative;
        right: 0;
        .svg-text {
          font-size: 22px;
          width: 220px;
          top: 11px;
          right: 10px;
        }
    }

    @media ${device.laptop} {
        max-width: 42rem;
        margin: 0 auto;
    }

    @media ${device.desktop} {
        padding: 0;
        width: 56rem;
        margin-bottom: 0;
        max-width: 100%;
        transform: translateX(5.5rem);

        svg {
            width: 56rem;
            height: 46rem;
        }
    }
`;

const ChartWrapper = styled(Wrapper)`
    width: initial;
    margin-bottom: 1rem;

    @media ${device.laptop} {
        width: 40rem;
        margin-bottom: 0;
        position: absolute;
        right: 2rem;
        top: -2rem;
        padding-left: 0;
    }

    @media ${device.desktop} {
        width: fit-content;

        margin-bottom: 0;
        position: absolute;
        right: 13rem;
    }
`;
const Box = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const CustomLink = styled(Link)`
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid white;
    font-family: 'Barlow', sans-serif;
    font-size: 1.3rem;
    color: white;
    padding-bottom: 0.3rem;
    transform: translateY(-1rem);
    margin: 3.3rem 0 3.5rem;

    svg {
        margin-left: 1rem;
        fill: white;
        font-size: 1.6rem;
    }

    @media ${device.tablet} {
        font-size: 1.3rem;

        svg {
            font-size: 1.6rem;
        }
    }

    @media ${device.desktop} {
        font-size: calc(13 * 0.11375vmin);

        svg {
            font-size: 1.6rem;
        }
    }
`;
const CustomTitleText = styled(TitleText)`
    @media ${device.tablet} {
        font-size: 3.2rem;
        line-height: 3.2rem;
        text-align: left;

        sup {
            font-size: 55%;
        }
    }

    @media ${device.laptop} {
        padding-right: 4rem;
    }

    @media ${device.desktop} {
        font-size: 3.2rem;
        line-height: 4rem;
        max-width: 100%;
        align-self: flex-start;
        margin-top: 8px;
    }
`;

const ReferecenceContainer = styled.div`
    @media ${device.tablet} {
        position: absolute !important;
        bottom: 1rem;
        width: 100%;
    }

    @media ${device.laptop} {
        position: absolute !important;
        bottom: 1rem;
        width: 100%;
    }

    @media ${device.desktop} {
        position: initial;
        bottom: initial;
        width: initial;
    }
`;
const ReferenceWrapper = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    @media ${device.desktop} {
      margin-top: 11rem;
    }
`;
const RefMob = styled(ReferenceFooterWithLink)`
	width: 100%;
	padding: 1rem 0.8rem 0;
  @media ${device.desktop} {
    padding-top: 2.2rem;
  }
`
