import InteriorBanner from "./components/InteriorBanner";
import CRCLayout from "./layouts/CRCLayout";
import JumpMenu from "./components/JumpMenu";
import MainContent from "../_common/components/Layout/MainContent"
import RefModal from "./components/RefModal"

import styles from './CRCAboutKRASG12C.module.scss';
import FooterCallout from "./components/FooterCallout";
import ContentContainer from "../_common/components/Layout/ContentContainer";
import JumpLink from "./components/JumpLink";
import MetaTags from "../_common/components/MetaTags";

export default function CRCAboutKRASG12C() {
  const links = [
    {
      url: '#poor-prognosis',
      text: 'Poor prognosis'
    },
    {
      url: '#unknown-status',
      text: 'Determining <i>KRAS G12C</i> status'
    },
    {
      url: '#continuous-inhibition',
      text: 'Continuous inhibition'
    },
    {
      url: '#krazati-properties',
      text: 'KRAZATI properties'
    }
  ]
  return (
    <CRCLayout>
      <MetaTags
        title='Understanding the Mutation'
        description='Acquire knowledge regarding the mutation and its significance in treatment decisions.'
        ogUrl='https://www.krazatihcp.com/c/challenging-mutation'
      />
      {/* Banner */}
      <InteriorBanner title="About <i>KRAS G12C</i>" imageUrl="/banners/banner-about-kras.jpg" desktopImageUrl="/banners/banner-about-kras-desktop.jpg" />
      {/* Jump Menu */}
      <JumpMenu title="What to know" links={links} />

      <MainContent>
        <div className={styles.krasG12c}>
          <ContentContainer>
            <h2><i>KRAS G12C:</i> why it's challenging</h2>
            <p className={styles.subHead}>For over 40 years, KRAS had been challenging to target due to the protein's smooth surface providing few binding pockets for small molecules, and high binding affinity for GTP.<RefModal references={[4, 5]} /></p>
            <br />
            <br />
            <h2>3 reasons <i>KRAS G12C</i> is a challenging mutation</h2>

            <div className={`${styles.step} ${styles.stepOne}`}>
              <JumpLink id="poor-prognosis" />
              <div className={styles.stepContent}>
                <div className={styles.stepNumber}>
                  <span className={styles.stepHeading}>1</span>
                </div>
                <div className={styles.stepCopy}>
                  <h2>A driver mutation with poor prognosis in CRC<RefModal references={[6, 7]} /></h2>
                  <p><strong>Worse prognosis</strong><br /><i>KRAS G12C</i> mutations in advanced CRC are often associated with worse prognosis than other KRAS mutations and KRAS wild type.<RefModal references={[6, 7]} /></p>
                  <p><strong>Worse OS</strong><br />The median OS for patients with a <i>KRAS G12C</i> mutation was 9.7 months vs 16.7 months for those with <i>RAS/BRAF</i> wild type, based on a study of patients with advanced CRC.<RefModal references={[7]} append="*" /></p>
                </div>
              </div>
              <div className={styles.stepImage}>
                <img className={styles.imgMobile} src="/assets/about/img-about-one.jpg" alt="Median OS In Patients" />
                <img className={styles.imgDesktop} src="/assets/about/img-about-one-desktop.jpg" alt="Median OS In Patients" />
              </div>
              <div className={styles.stepReferences}>
                <p><small>*Based on a retrospective study of 6477 patients from a US clinical genomic database diagnosed with metastatic CRC between January 1, 2011, and March 31, 2020. Median OS reported for 90 patients with the <i>KRAS G12C</i> mutation and 1007 patients with <i>RAS/BRAF</i> wild type who were treated in the second line.</small></p>
              </div>
            </div>

            <div className={`${styles.step} ${styles.stepTwo}`}>
              <JumpLink id="unknown-status" />
              <div className={styles.stepContent}>
                <div className={styles.stepNumber}>
                  <span className={styles.stepHeading}>2</span>
                </div>
                <div className={styles.stepCopy}>
                  <h2>The <i>KRAS G12C</i> status of your patients may not be known</h2>
                  <p>Identifying if your patients with CRC have a <i>KRAS</i> mutation is no longer enough. <b>Test for the <i>G12C</i> variant because those patients with it may be eligible for targeted therapy.</b></p>
                </div>
              </div>
              <div className={styles.stepImage}>
                <img src="/assets/about/img-about-two.jpg" alt="Man Standing Near Rocks" />
              </div>
            </div>

            <div className={`${styles.step} ${styles.stepThree}`}>
              <JumpLink id="continuous-inhibition" />
              <div className={styles.stepContent}>
                <div className={styles.stepNumber}>
                  <span className={styles.stepHeading}>3</span>
                </div>
                <div className={styles.stepCopy}>
                  <h2>Continuous regeneration may require continuous inhibition<RefModal references={[8, 10]} /></h2>
                  <ul>
                    <li><i>KRAS G12C</i> mutations produce abnormal KRAS proteins that lead to aberrant signaling and uncontrolled cellular proliferation<RefModal references={[11, 12, 13]} display="11-13" /></li>
                    <li>KRAS proteins, including G12C, regenerate every 24-48 <span className="no-wrap">hours<RefModal references={[8, 9, 10]} display="8-10" /></span></li>
                    <li>KRAS G12C may need to be continuously inhibited to suppress tumorigenesis<RefModal references={[8, 9, 10]} display="8-10" /></li>
                  </ul>
                </div>
              </div>
              <div className={styles.stepImage}>
                <img src="/assets/about/img-about-three.jpg" alt="Illustration of Advance Disease In Scan" />
              </div>
            </div>
          </ContentContainer>
        </div>

        <div className={styles.properties}>
          <JumpLink id="krazati-properties" />
          <ContentContainer>
            <div className={styles.propHeading}>
              <h2>KRAZATI is intentionally designed to meet the challenge of KRAS G12C</h2>
            </div>

            <div className={styles.propImage}>
              <img src="/assets/about/img-about-four.jpg" alt="Illustration of Krazati In Action" />
            </div>

            <div className={styles.propGrid}>
              <div>
                <p><strong>INCREASED ANTITUMOR ACTIVITY WHEN COMBINED WITH CETUXIMAB IN <span>m</span>CRC<RefModal references={[1, 22]} /></strong></p>
                <p>KRAZATI in combination with cetuximab had increased antitumor activity in some <i>KRAS G12C</i>-mutated CRC models compared to KRAZATI or cetuximab alone.</p>
              </div>
              <div>
                <p><strong>Longest half-life of any approved KRAS G12C inhibitor<RefModal references={[1]} /></strong></p>
                <p>An ~24-hour half-life allowed drug levels to be maintained above a target threshold.<sup>†‡</sup></p>
              </div>
              <div>
                <p><strong>Irreversible Inhibition<RefModal references={[1]} /></strong></p>
                <p>KRAZATI covalently binds to the mutant cysteine in <i>KRAS G12C</i> and locks the protein in its inactive state, preventing downstream signaling without affecting wild-type KRAS protein.</p>
              </div>
              <div>
                <p><span>Other Property:</span></p>
                <p><strong>CNS penetrant<RefModal references={[14, 15, 16]} display="14-16" /></strong></p>
                <p>KRAZATI CSF/free plasma concentration ratio was consistent with other agents with known CNS penetration.</p>
              </div>
            </div>

            <div className={styles.propRef}>
              <p><small><sup>†</sup>Clinical relevance of pharmacokinetic data is unknown. KRAZATI half-life is 23 hours.<RefModal references={[1]} /></small></p>
              <p><small><sup>‡</sup>This is a target threshold in preclinical models; target exceeded at KRAZATI 600 mg BID.<RefModal references={[17]} /></small></p>
              <p><small>BID=twice-daily; CNS=central nervous system; CRC=colorectal cancer; CSF=cerebrospinal fluid; EGF=epidermal growth factor; EGFR=epidermal growth factor receptor; GDP=guanosine diphosphate; GTP=guanosine triphosphate; mCRC=metastatic colorectal cancer; OS=overall survival.</small></p>
            </div>
          </ContentContainer>
        </div>
      </MainContent>

      {/* Footer Callout */}
      <FooterCallout title="Learn how testing can help identify patients" link="/c/testing" />
    </CRCLayout>
  )
}
