import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import {  useSwiperSlide } from 'swiper/react';
import { BaseText, BaseTitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';
import ReferenceModal from '../../_components/Modal/ReferenceModal'

const Slide1 = ({setPageBackground, setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig,setOverlay, setBackground}) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const mobileOverlay = 'linear-gradient(165deg, rgba(49, 78, 119, 0.25) 4.22%, rgba(22, 38, 66, 0.25) 90.76%);'
  const desktopOverlay = 'linear-gradient(165deg, rgba(49, 78, 119, 0.1) 4.22%, rgba(22, 38, 66, 0.1) 90.76%);'
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
  const mobileBackground = `url('/assets/testing-bg-woman-in-front-of-ocean.png') no-repeat fixed 40% -10rem / cover`
  const desktopBackground = `url('/assets/testing-bg-woman-in-front-of-ocean-desktop.png') no-repeat fixed center top / cover`
  const slideBackground =  isMobile ? mobileBackground : desktopBackground;

  useActiveLink(setShowHeaderSecondaryMenu, 'testing')

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setCurrentSlide(1)
      setShowPageContent(true)
      setBackground(slideBackground)
      setOverlay(slideOverlay)

      return

    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])



  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(()=> {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])


  return (
    <>
    {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={()=> setShowModal(false)} />}
    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <CustomWrapper>
          <Title>
              <Title1>Testing for <em>KRAS G12C</em></Title1>

              <Title2>
              NCCN Guidelines recommend biomarker testing for <em>KRAS</em>, including <em>G12C</em>, for all appropriate patients with advanced or metastatic nonsquamous NSCLC, and can be considered for those with squamous&nbsp;histology.<sup onClick={()=> handleShowModal([1])}>1</sup>
              </Title2>
          </Title>
        </CustomWrapper>
        <ScrollIcon marginBottom="2rem" marginTop="30px"/>
        <Reference>NCCN=National Comprehensive Cancer Network; NSCLC=non-small cell lung cancer.</Reference>
    </CustomContainer>
    </>
  )
}

export default Slide1

const CustomContainer = styled(Container)`
  display: flex;
  padding: 0;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};
  [class*="styles__Page-sc"] {
    background-position-y: 4.9rem;
  }
  @media ${device.desktop} {
    justify-content: space-between;
    height: 100%;
    max-height: ${props => `calc(98% - ${props.isiconfig})`};
  }
`

const CustomWrapper = styled(Wrapper)`
  margin-top: 11vmin;
  height: auto;
  @media ${device.desktop} {
    height: 100%;
    margin-top: 0;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  margin-bottom: 1.6rem;
  margin-top: 0;
  max-width: 102rem;
  margin: 7.5rem auto 0;

  @media ${device.desktop} {
    height: 100%;
    margin-bottom: 0;
    margin-top: 7.5rem;
    max-width: 120rem;
  }

`

const Title1 = styled(BaseTitle)`
  margin-bottom: 2rem;
  text-transform: uppercase;
  padding: 0 6rem;
  line-height: 4.8rem;
  font-weight: 400;

  @media ${device.desktop} {
    margin-bottom: 4rem;
  }
`
const Title2 = styled(BaseText)`
  text-align: center;
  font-size: 17px;
  margin-bottom: 1.6rem;
  max-width: 84%;
  font-weight: 500;
  sup {
    font-size: 55%;
  }

  span {
    color: #17C94E;
    font-weight: bold;
  }

  @media ${device.desktop} {
    font-size: 2.4rem;
    text-align: center;
    padding: 0;
    margin-bottom: 3rem;
  }

`

const SubTitle = styled(BaseText)`
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  max-width: 84%;


  @media ${device.tablet} {
    font-size: 1.2rem;
  }


  @media ${device.desktop} {
    max-width: 58.8rem;
    font-size: 1.4rem;
    margin-top: 10rem;
  }
`
