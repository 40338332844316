export const theme = {
    bgHome: "linear-gradient(#3246FF, #1022C3)",
    colors: {
        "blue": '#3246FF',
        "dark-blue": "#130A88",
        "green": '#2DC84D',
        "pink": "#A53CA5",
        "black": "#53565A",
        "dark-black": "#000000",
        "light-gray": "#F5F6FC",
        "gray": "#D9D9D6",
        "dark-gray": "#53565A",
        "yellow": "#EADA24",
        "red": "##EF3340",
        "white": "#FFFFFF"
    }
}

export const device = {
    mobile: `(min-width: 0px)`,
    tablet: `(min-width: 540px)`,
    laptop: `(min-width: 960px)`,
    desktop: `(min-width: 1100px)`,
};