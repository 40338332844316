import React from 'react'
import styled from 'styled-components/macro'

const TwoSides = ({children, background = '#2E3344', border = '#17C94E', size = .2, orientation = 'vertical', padding = '2rem', ...props}) => {
  return (
	<ShapeContainer border={border}>
		<Inner background={background} padding={padding}>
			<Content>{children}</Content>
		</Inner>
	</ShapeContainer>
  )
}

export default TwoSides

const ShapeContainer = styled.div`
	clip-path: polygon(25px 0, 100% 0, 100% calc(100% - 35px), calc(100% - 25px) 100%, 0 100%, 0 35px);
	background: ${props => props.border};
	padding: 2px;
	min-height: auto;
`
const Inner = styled.div`
	clip-path: polygon(24px 0, 100% 0, 100% calc(100% - 34px), calc(100% - 24px) 100%, 0 100%, 0 34px);
	background: ${props => props.background};
`

const Content = styled.div`
	padding: 25px 30px;
`