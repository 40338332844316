import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { FaChevronRight } from 'react-icons/fa';

import { BaseLink, BaseSubtitle, BaseText, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import { Link } from 'react-router-dom';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';

const Slide4 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setPageGradient, setBackground, setOverlay }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const desktopBackground = `url('/assets/home-hero-alternate-crop.png') no-repeat fixed 0 center / cover`;
    const mobileBackground = `url('/assets/home-video-bg-m.png') no-repeat center / cover`;
    const slideBackground = isMobile ? mobileBackground : desktopBackground;
    const mobileOverlay = `linear-gradient(#6f81a9, rgba(12, 22, 63, 0.6));`;
    const desktopOverlay = `linear-gradient( rgba(3, 15, 60, 0.3) 0%, rgba(1, 19, 101, 0.1) 100%)`;
    const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setPageGradient('');
            setCurrentSlide(4);
            setShowPageContent(true);
            setBackground(slideBackground);
            setOverlay(slideOverlay);
            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
            <Scroll>
                <ContainerScroll>
                    <Container className={showPageContent ? 'visible' : 'hidden'}>
                        <CustomWrapper isiconfig={isiconfig}>
                            <Title>DIVE DEEPER INTO KRAZATI</Title>
                            <CardContainer>
                                <Card className='home'>
                                    <CardTitle>
                                        <em>KRAS G12C</em> is challenging
                                    </CardTitle>
                                    <CardBtn as={Link} to='/n/about-kras-g12c'>
                                        <span>About <em>KRAS G12C</em></span> <FaChevronRight />
                                    </CardBtn>
                                </Card>

                                <Card className='home'>
                                    <CardTitle>How KRAZATI was intentionally designed to meet the challenge of KRAS G12C</CardTitle>
                                    <CardBtn as={Link} to='/n/MOA'>
                                        Interactive MOA <FaChevronRight />
                                    </CardBtn>
                                </Card>

                                <Card className='home'>
                                    <CardTitle>600 mg twice-daily oral dosing</CardTitle>
                                    <CardBtn as={Link} to='/n/dosing'>
                                        Dosing Details <FaChevronRight />
                                    </CardBtn>
                                </Card>
                            </CardContainer>
                        </CustomWrapper>
                    </Container>
                </ContainerScroll>
            </Scroll>
        </CustomContainer>
    );
};

export default Slide4;

const Scroll = styled.div`
    height: 100%;
`;

const CustomContainer = styled(Container)`
    display: flex;
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomWrapper = styled(Wrapper)`
    max-width: 100rem;
    margin: 2rem auto 0;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};

    @media (min-width: 374px) and (max-height: 800px) {
        margin: 2rem auto 0;
    }

    @media ${device.desktop} {
        // margin: 9.9rem auto 0;
    }
`;

const Title = styled(BaseSubtitle)`
    color: white;
    margin-bottom: 2rem;
    letter-spacing: 0.06rem;

    @media ${device.desktop} {
        font-size: 3.2rem;
        margin-bottom: 4rem;
        letter-spacing: 0.16rem;
        padding-left: 2rem;
    }

    @media ${device.desktop800h} {
        font-size: 3.6rem;
    }

    @media ${device.desktop600h} {
        font-size: 3.1rem;
    }
`;

export const CardContainer = styled.div`
    display: grid;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0 3rem;

    @media ${device.tablet} {
        max-width: 60%;
    }

    @media ${device.desktop} {
        font-size: 1.6rem;
        margin-bottom: 4rem;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 100%;
        justify-content: center;
    }
`;

export const Card = styled.div`
    background: ${(props) => props.background || 'linear-gradient(155deg, rgba(68, 81, 145, 0.5) -20%, rgba(0, 3, 21, 0.675) 100%)'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.padding || '2.7rem 3.6rem'};
    margin: ${(props) => props.margin || '0 0 2.4rem'};
    position: relative;

    &.home {
        &:nth-of-type(2) {
            padding: 1.8rem 3.8rem;
            > div {
                max-width: 75%;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: inherit;
        z-index: -1;
        box-shadow: inset 0.4rem 0.4rem 1rem 0.2rem #adc0d629;
    }

    &:last-child {
        margin-right: 0;
    }

    &:first-of-type {
        // clip-path: polygon(1.6rem 0, 100% 0, 100% 100%, 0 100%, 0 100%, 0 2.4rem);
    }

    &:last-of-type {
        // clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2.4rem), calc(100% - 1.6rem) 100%, 0 100%, 0 0);
    }

    &.rect {
        clip-path: none;
    }

    @media ${device.desktop} {
        align-items: center;
        padding: 4.4rem 4rem 4.2rem !important;
        margin-right: 4rem;
        margin-bottom: 0;
        min-height: 22.4rem;
        width: 29.2rem;
        background: ${(props) => props.background || 'linear-gradient(155deg, rgba(68, 81, 145, 0.5) -20%, rgba(20, 21, 27, 0.47) 100%)'};

        &.home {
            &:nth-of-type(2) {
                > div {
                    max-width: 100%;
                }
            }
        }
    }
`;
const CardTitle = styled(BaseText)`
    color: white;
    text-align: center;
    margin-bottom: 1.2rem;
    font-size: min(1.4rem, 1.8rem);

    @media ${device.tablet} {
        font-size: min(calc(1.8rem, 2.4rem));
    }

    @media ${device.desktop} {
        font-size: 2.4rem;
        margin-bottom: 2rem;
        min-height: 5.2rem;
        font-weight: 300;
    }
`;
const CardBtn = styled(BaseLink)`
    font-family: 'Barlow';
    font-size: min(1.225rem, 1.4rem);
    position: relative;
    background: #3246ff;
    color: white;
    padding: min(0.8rem, 1rem);
    border-radius: 4rem;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18.4rem;
    max-width: min(20.8rem, 28rem);
    max-height: 3.2rem;
    svg {
        right: 1.6rem;
        fill: white;
        position: absolute;
        transition: all 0.3s;
        font-size: min(1.2rem, 1.4rem);
    }

    &:hover {
        svg {
            right: 1rem;
        }
    }

    @media ${device.tablet} {
        max-width: min(20.8rem, 32rem);
        font-size: min(1.225rem, 1.4rem);
    }

    @media ${device.desktop} {
        max-width: min(20.8rem, 32rem);
        font-size: 1.4rem;
        width: 20.8rem;
        max-height: 3rem;

        svg {
            font-size: 1.6rem;
        }
    }

    @media ${device.desktop600h} {
        max-height: 3.4rem;
    }
`;
