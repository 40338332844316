export const theme = {
    bgHome: "linear-gradient(#3246FF, #1022C3)",
    colors: {
        "blue": '#3246FF',
        "dark-blue": "#130A88",
        "green": '#2DC84D',
        "pink": "#A53CA5",
        "black": "#53565A",
        "dark-black": "#000000",
        "light-gray": "#F5F6FC",
        "gray": "#D9D9D6",
        "dark-gray": "#53565A",
        "yellow": "#EADA24",
        "red": "##EF3340",
        "white": "#FFFFFF"
    }
}

export const device = {
    mobile: `(min-width: 0px)`,
    iphoneSe: `(min-width: 370px) and (max-width: 415px) and (min-height: 520px) and (max-height: 768px)`, 
    // Used to fixed in case of Safari browser navbar takes some of the browser height (it was breaking the site in mobile safari)
    iphoneSmallHeight: `(min-width: 410px) and (max-width: 415px) and (min-height: 700px) and (max-height: 768px)`,
    mobileBg: `(min-width: 460px)`,
    tablet: `(min-width: 600px)`,
    laptop: `(min-width: 960px)`,
    desktop: `(min-width: 1100px)`,
    desktop600h: `(min-width: 1100px) and (max-height: 600px)`,
    desktop800h: `(min-width: 1100px) and (max-height: 800px)`,
    thinkpad: `(max-height: 650px)`
};