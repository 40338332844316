import { useState } from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import useMobileDetect from '../../../_hooks/useMobileDetect'

export default function Indication() {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const { isMobile } = useMobileDetect()

  if (isMobile) {
    return null;
  }

  if (location.pathname !== '/n' && location.pathname !== '/n/') {
    return null;
  }

  if (!isOpen) {
    return null;
  }
  return (
    <IndicationBlock>
      <IndicationContainer>
        <IndicationWrapper>
          <Title>
            <p><b>INDICATION</b></p>
          </Title>
          <Content>
            <p>KRAZATI is indicated for the treatment of adult patients with <em>KRAS G12C</em>-mutated locally advanced or metastatic non-small cell lung cancer (NSCLC), as determined by an <span className='no-wrap'>FDA-approved</span> test, who have received at least one prior systemic therapy.</p>
            <p>This indication is approved under accelerated approval based on objective response rate (ORR) and duration of response (DOR). &nbsp;Continued approval for this indication may be contingent upon verification and description of a clinical benefit in a confirmatory trial(s).</p>
          </Content>
          <Close>
            <img src="/assets/icons/icon-indication-close.svg" alt="" onClick={() => {
              setIsOpen(false);
            }} />
          </Close>
        </IndicationWrapper>
      </IndicationContainer>
    </IndicationBlock>
  )
}

const IndicationBlock = styled.div`
  background: #fff;
`;

const IndicationContainer = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 20px;
`;

const IndicationWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 8px 0;
`;

const Title = styled.div`
  flex: 0 0 auto;
  font-family: "Barlow Condensed";
  font-size: 16px;
  color: #3246FF;
`;

const Content = styled.div`
  flex: 0 1 750px;
  font-family: "Barlow Condensed";
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;

  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Close = styled.div`
  flex: 1 0 auto;
  width: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  img {
   width: 18px;
  }
`;
