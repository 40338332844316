import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from '../_assets/styles/theme';
import Header from '../_components/Header';
import PageSwipper from '../_components/PageSwipper';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import MetaTags from '../_components/MetaTags/MetaTags';
import ISICollapsing from '../_components/ISI/Collapsing';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import Footer from '../_components/Footer';
import { Page } from '../_components/PageSwipper/utils/styles';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import StaticIsi from '../_components/ISI/StaticIsi';
import useMobileDetect from '../../_hooks/useMobileDetect';
import { isiConfig } from '../_components/ISI/config';
import ReferenceModal from '../_components/Modal/ReferenceModal';

const NCCN = ({ setPageBackground, page = 1 }) => {
  const history = useNavigate();
  const { isMobile } = useMobileDetect();
  const pageWrapper = useRef();
  const [headerRef, setHeaderRef] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [renderPage, setRenderPage] = useState(false);
  const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [pageGradient, setPageGradient] = useState(null);
  const [background, setBackground] = useState(
    `url(${isMobile ? '/assets/background-m.webp' : '/assets/background1.webp'}) no-repeat fixed 0 center / cover`
  );
  const [overlay, setOverlay] = useState(null);
  const overlayRef = useRef();
  const [referencesIndex, setReferencesIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs);
    }
  };

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true);
    }
  }, [referencesIndex]);

  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef?.current.clientHeight);
      setRenderPage(true);
      setPageBackground('');
    }
  }, [headerRef?.current]);

  // useEffect(() => {
  //   const nccnLink = document.querySelector('[href="/#nccn-recommendations"]');
  //   overlayRef.current.classList.remove('visible');
  //   overlayRef.current.classList.add('hidden');

  //   const time = setTimeout(() => {
  //     overlayRef.current.classList.add('visible');
  //     overlayRef.current.classList.remove('hidden');
  //   }, 100);

  //   currentSlide === 2 ? nccnLink.classList.add('link-active') : nccnLink.classList.remove('link-active');

  //   return () => {
  //     clearTimeout(time);
  //   };
  // }, [currentSlide]);

  return (
    <>
      <MetaTags
        title='Recommendations for Targeted Treatment'
        description='Learn of available targeted treatment guidelines.'
        ogUrl='https://www.krazatihcp.com/n/nccn-recommendations'
      />
      <Page background={background}>
        {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
        <PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
        <PageScroll>
          <Container ref={pageWrapper}>
            <Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
            <PageWrapper pageGradient={pageGradient}>
              {renderPage && (
                <PageSwipper nested={isMobile ? false : true} headerHeight={headerHeight}>
                  <Slide1
                    isiconfig={isiConfig.collapsed}
                    setCurrentSlide={setCurrentSlide}
                    setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                    setPageBackground={setPageBackground}
                    setPageGradient={setPageGradient}
                    setBackground={setBackground}
                    setOverlay={setOverlay}
                    handleShowModal={handleShowModal}
                  />
                  <Slide2
                    isiconfig={isiConfig.floating}
                    setCurrentSlide={setCurrentSlide}
                    setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                    setPageBackground={setPageBackground}
                    setPageGradient={setPageGradient}
                    setBackground={setBackground}
                    setOverlay={setOverlay}
                    handleShowModal={handleShowModal}
                  />
                </PageSwipper>
              )}
              {!isMobile && currentSlide === 1 && <ISICollapsing className="visible" />}
              {!isMobile && currentSlide === 2 && <ISICollapsing className="floatingWithIsi collapse" />}
              {isMobile && <FloatingIsi className={currentSlide === 2 ? 'collapse' : 'visible'} />}
            </PageWrapper>

            {!isMobile && currentSlide === 2 && (
              <StaticContentWrapper id='static-content important-safety-information'>
                <StaticIsi />
                <Footer translate={true}></Footer>
              </StaticContentWrapper>
            )}
          </Container>
        </PageScroll>
      </Page>
    </>
  );
};

export default NCCN;

const Container = styled.div`
  height: 100%;
  max-height: 100%;
`;
export const PageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: var(--screen-height);
  top: 0;
  left: 0;
  background: ${(props) => props.overlay};

  // &.visible {
  //   transition: opacity .3s linear;
  //   opacity: 1;
  // }

  // &.hidden {
  //   opacity: 0;
  // }
`;
const PageWrapper = styled.div`
  position: relative;

  @media ${device.desktop} {
    &:after {
      content: none;
    }
  }
`;
export const StaticContentWrapper = styled.div`
  height: auto;
  z-index: 999;

  @media ${device.desktop800h} {
  }
`;
