import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';

import { BaseSubtitle, BaseText, BaseTitle, Container, StyledRightSideShape, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect'
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import ReferenceModal from '../../_components/Modal/ReferenceModal';
import '../css/slide2.scss';


const Slide2 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)

  const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`

  useActiveLink(setShowHeaderSecondaryMenu, 'study')

  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(2)
      setOverlay(slideOverlay)

      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])

  if (isMobile) {
    return (
      <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <Scroll>
          {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
          <ContainerScroll>
            <CustomWrapper>
              <Title>
                <Title1>KRYSTAL-1 REGISTRATIONAL STUDY<sup onClick={() => handleShowModal([30])}>30</sup></Title1>
              </Title>

              <Characteristics>
                <CharacteristicsItems>

                  <CharacteristicsItem>
                    <CharacteristicImg>
                      <img src="/assets/dose.svg" alt="Dose - 600 Mg BID" />
                    </CharacteristicImg>
                    <CharacteristicText>
                      <p className="title">KRAZATI 600 mg BID</p>
                      <p>Until unacceptable toxicity or disease progression</p>
                    </CharacteristicText>
                  </CharacteristicsItem>

                  <CharacteristicsItem>

                    <CharacteristicImg>
                      <img src="/assets/target.svg" alt="Krystal-1 Registrational Study" />
                    </CharacteristicImg>

                    <CharacteristicText>
                      <div className="target-items-wrapper">
                        <div className="target-item-shadow">
                          <div className='target-item'>
                            <div className="target-item-inner">
                              <TargetHeader>PRIMARY <br /> ENDPOINT</TargetHeader>
                              <TargetBody>ORR</TargetBody>
                            </div>
                          </div>
                        </div>

                        <div className="target-item-shadow">
                          <div className='target-item'>
                            <div className="target-item-inner">
                              <TargetHeader>SELECT SECONDARY <br /> ENDPOINTS</TargetHeader>
                              <TargetBody>DOR<br /> and Safety</TargetBody>
                            </div>
                          </div>
                        </div>

                        {/* <div className="target-item-shadow">
                          <div className='target-item'>
                            <div className="target-item-inner">
                              <TargetHeader>POST HOC <br /> ANALYSES</TargetHeader>
                              <TargetBody>Intracranial ORR <br /> Intracranial DCR</TargetBody>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </CharacteristicText>
                  </CharacteristicsItem>

                  <CharacteristicsItem>
                    <CharacteristicImg>
                      <img src="/assets/folder.svg" alt="Key Exclusion Criteria" />
                    </CharacteristicImg>

                    <CharacteristicText>
                      <p>Key exclusion criteria included history of intestinal disease or major gastric surgery likely to alter absorption of medication or inability to swallow oral medications, active brain metastases, carcinomatous meningitis, other active cancer.<sup onClick={() => handleShowModal([31])}>31</sup></p>
                    </CharacteristicText>
                  </CharacteristicsItem>


                  {/* <AditionalText>DESCRIPTIVE ANALYSIS</AditionalText>
                  <CharacteristicsItem>
                    <CharacteristicImg>
                      <img src="/assets/mergingarrows.svg" alt="Krystal-1 Descriptive Analysis" />
                    </CharacteristicImg>
                    <CharacteristicText>
                      <p>A descriptive analysis of pooled data from the KRYSTAL-1 study evaluated prespecified endpoints. The analysis included the combined phase 1/1b and phase 2 cohorts of the KRYSTAL-1 NSCLC study population receiving 600 mg BID (N=132).<sup onClick={() => handleShowModal([32])}>32</sup></p>
                    </CharacteristicText>
                  </CharacteristicsItem> */}

                </CharacteristicsItems>

                <Criteria className="nbox" borderSize={.1} border="white" background="#272B39">
                  <CriteriaWrapper>

                    <CriteriaTitle>N=116</CriteriaTitle>
                    <CriteriaSubtitle>key inclusion criteria</CriteriaSubtitle>

                    <CriteriaList>
                      <CriteriaListItem>Locally advanced or metastatic NSCLC</CriteriaListItem>
                      <CriteriaListItem>Confirmed <em>KRAS G12C</em> mutation determined in tumor tissue</CriteriaListItem>
                      <CriteriaListItem>Previously treated with an IO-based regimen*</CriteriaListItem>
                      <CriteriaListItem>Stable brain metastases allowed<sup onClick={() => handleShowModal([31])}>31†</sup></CriteriaListItem>
                    </CriteriaList>

                  </CriteriaWrapper>
                </Criteria>
              </Characteristics>

            </CustomWrapper>
            <Reference>
              <p>*Platinum-based regimen and an immune checkpoint inhibitor.</p>
              <p style={{ margin: '8px 0' }}><sup>†</sup>Patients were eligible if brain metastases were adequately treated and patients were neurologically stable ≥2 weeks prior to enrollment without the use of corticosteroids or were on a stable or decreasing dose of ≤10 mg daily prednisone (or&nbsp;equivalent).<sup onClick={() => handleShowModal([31])}>31</sup></p>
              <p>BID=twice-daily; DOR=duration of response; IO=immunotherapy; NSCLC=non-small cell lung cancer; ORR=objective response rate.</p>
            </Reference>
          </ContainerScroll>
        </Scroll>
      </CustomContainer>
    )
  }

  return (
    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
      {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
      <CustomWrapper>
        <Title>
          <Title1>KRYSTAL-1 REGISTRATIONAL STUDY<sup onClick={() => handleShowModal([30])}>30</sup></Title1>
        </Title>

        <Characteristics>
          <Criteria borderSize={.1} border="white" background="#373B4E">
            <CriteriaWrapper>

              <CriteriaTitle>N=116</CriteriaTitle>
              <CriteriaSubtitle>key inclusion criteria</CriteriaSubtitle>

              <CriteriaList>
                <CriteriaListItem>Locally advanced or metastatic NSCLC</CriteriaListItem>
                <CriteriaListItem>Confirmed <em>KRAS G12C</em> mutation determined in tumor tissue</CriteriaListItem>
                <CriteriaListItem>Previously treated with an IO-based regimen*</CriteriaListItem>
                <CriteriaListItem>Stable brain metastases allowed<sup onClick={() => handleShowModal([31])}>31†</sup></CriteriaListItem>
              </CriteriaList>

            </CriteriaWrapper>
          </Criteria>

          <CharacteristicsItems>

            <CharacteristicsItem>
              <CharacteristicImg>
                <img src="/assets/dose.svg" alt="Dose - Krazati 600 mg BID" />
              </CharacteristicImg>
              <CharacteristicText>
                <p className="title">KRAZATI 600 mg BID</p>
                <p>Until unacceptable toxicity or disease progression</p>
              </CharacteristicText>
            </CharacteristicsItem>

            <CharacteristicsItem>

              <CharacteristicImg>
                <img src="/assets/target.svg" alt="Krystal-1 registrational study" />
              </CharacteristicImg>

              <CharacteristicText>
                <div className="target-items-wrapper">
                  <div className="target-item-shadow">
                    <div className='target-item'>
                      <div className="target-item-inner">
                        <TargetHeader>PRIMARY <br /> ENDPOINT</TargetHeader>
                        <TargetBody>ORR <br />&nbsp;</TargetBody>
                      </div>
                    </div>
                  </div>

                  <div className="target-item-shadow">
                    <div className='target-item'>
                      <div className="target-item-inner">
                        <TargetHeader>SELECT SECONDARY <br /> ENDPOINTS</TargetHeader>
                        <TargetBody>DOR <br /> and Safety</TargetBody>
                      </div>
                    </div>
                  </div>

                  {/* <div className="target-item-shadow">
                    <div className='target-item'>
                      <div className="target-item-inner">
                        <TargetHeader>POST HOC <br /> ANALYSES</TargetHeader>
                        <TargetBody>Intracranial ORR <br /> Intracranial DCR</TargetBody>
                      </div>
                    </div>
                  </div> */}
                </div>
              </CharacteristicText>
            </CharacteristicsItem>

            <CharacteristicsItem>
              <CharacteristicImg>
                <img src="/assets/folder.svg" alt="Krazati key exclusion criteria" />
              </CharacteristicImg>

              <CharacteristicText>
                <p>Key exclusion criteria included history of intestinal disease or major gastric surgery likely to alter absorption of medication or inability to swallow oral medications, active brain metastases, carcinomatous meningitis, other active cancer.<sup onClick={() => handleShowModal([31])}>31</sup></p>
              </CharacteristicText>
            </CharacteristicsItem>

            {/* <AditionalText>DESCRIPTIVE ANALYSIS</AditionalText>
            <CharacteristicsItem>
              <CharacteristicImg>
                <img src="/assets/mergingarrows.svg" alt="Krystal-1 descriptive analysis" />
              </CharacteristicImg>
              <CharacteristicText>
                <p>A descriptive analysis of pooled data from the KRYSTAL-1 study evaluated prespecified endpoints. The analysis included the combined phase 1/1b and phase 2 cohorts of the KRYSTAL-1 NSCLC study population receiving 600 mg BID (N=132).<sup onClick={() => handleShowModal([32])}>32</sup></p>
              </CharacteristicText>
            </CharacteristicsItem> */}

          </CharacteristicsItems>
        </Characteristics>
      </CustomWrapper>
      <Reference>
        <p>*Platinum-based regimen and an immune checkpoint inhibitor.</p>
        <p><sup>†</sup>Patients were eligible if brain metastases were adequately treated and patients were neurologically stable ≥2 weeks prior to enrollment without the use of corticosteroids or were on a stable or decreasing dose of ≤10 mg daily prednisone (or&nbsp;equivalent).<sup onClick={() => handleShowModal([31])}>31</sup></p>
        <p style={{ marginTop: 8 }}>BID=twice-daily; DOR=duration of response; IO=immunotherapy; NSCLC=non-small cell lung cancer; ORR=objective response rate.</p>
      </Reference>
    </CustomContainer>
  )
}

export default Slide2

const Scroll = styled.div`
  height: 100%
`

const CustomContainer = styled(Container)`
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0;
  height: ${props => `calc(100% - ${props.isiconfig})`};
`
const CustomWrapper = styled(Wrapper)`
  height: auto;
  margin-top: 1.6rem;

  @media ${device.desktop} {
    height: 100%;
    margin-top: 1.6rem;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  width: 100%;
  margin-bottom: 0;
  text-transform: uppercase;

  @media ${device.desktop} {
  }
`

const Title1 = styled(BaseSubtitle)`
  margin-bottom: 1.4rem;

  @media ${device.desktop} {
    font-size: 3.2rem;
    margin-bottom: 4.9rem;
    letter-spacing: 0.1rem;
  }

`

const Characteristics = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding: 0 0.8rem;

  @media ${device.tablet} {
    max-width: 80%;
  }

  @media ${device.desktop} {
    max-width: 100%;
    flex-direction: row;
    padding: 0;
    padding-left: 5.4rem;
  }
`

const Criteria = styled(StyledRightSideShape)`
  position: relative;
  padding: 1.6rem 1.2rem;
  margin-bottom: 3.4rem;

  &.nbox {
    margin-bottom: 5rem;
    margin-top: 5rem;

    h1 {
      margin-bottom: 1rem;
    }
  }

  @media ${device.desktop} {
    margin-right: 6rem;
    max-width: 27.6rem;
    margin-bottom: 0;
    padding: 2rem;
    padding-right: 3rem;
  }
`

const CriteriaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  color: white;
  height: 100%;
`

const CriteriaTitle = styled(BaseTitle)`
  color: #17C94E;
  font-weight: 500;

  @media ${device.desktop} {
    font-size: 5.3rem;
    line-height: 4.2rem;
    margin-bottom: 4rem;
  }
`

const CriteriaSubtitle = styled(BaseTitle)`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0.8rem;

  @media ${device.desktop} {
   margin-bottom: 2rem;
   font-size: 2rem;
  }

`

const CriteriaList = styled.ul`
  padding-left: 1.5rem;
  list-style: none;

  @media ${device.desktop} {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
  }
`

const CriteriaListItem = styled.li`
  font-family: 'Barlow', sans-serif;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    &.pink {
      color: #FF00FF;
      font-weight: bold;
    }
  }

  sup {
    font-size: 55%;
  }

  &:before {
    content: "\\2022";
    color: #17C94E;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  @media ${device.desktop} {
    font-size: 1.7rem;
    line-height: 2rem;
    margin-bottom: 2.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    sup {
      font-size: 55%;
    }
  }

`

const CharacteristicsItems = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 66rem;
  padding-right: 0.8rem;

  @media ${device.desktop} {
	padding-right: 0;
  }
`

const CharacteristicsItem = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr;
  align-items: flex-start;
  margin-bottom: 2.5rem;
  width: 100%;


  &:nth-of-type(2) {
    align-items: flex-start;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${device.desktop} {
    grid-template-columns: 6.4rem 1fr;
    margin-bottom: 3.2rem;
    align-items: center;

      &:nth-of-type(2) {
      align-items: center;
    }
  }

`

const CharacteristicText = styled(BaseText)`
  color: white;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-left: 1.2rem;

  .title {
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    font-family: 'Oswald';
  }

  sup {
    font-size: 0.9rem;
  }

  @media ${device.desktop} {
    font-size: 1.7rem;
    line-height: 1.8rem;
    margin-left: 2.4rem;

    .title {
      font-weight: bold;
      font-size: 2.4rem;
    }

    sup {
      font-size: 55%;
    }
  }
`

const CharacteristicImg = styled.div`
  img {
    width: 100%;
    text-align: center;
  }

`

const TargetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  max-width: 94%;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.desktop} {
    flex-direction: row;
    max-width: 100%;
  }

`

const TargetHeader = styled.div`
  font-family: 'Oswald', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  height: 5.6rem;
  width: 100%;
  padding:0 0.8rem;

  @media ${device.desktop} {
    padding: 0.8rem;
    font-size: 1.5rem;
  }

`

const TargetBody = styled.div`
  font-family: 'Barlow Condensed';
  font-size: 1.4rem;
  font-weight: 500;
  width: 100%;
  padding:0 0.8rem;

  @media ${device.desktop} {
    padding: 0.8rem;
    font-size: 1.7rem;
    line-height: 1.4;
  }

`

const AditionalText = styled(BaseText)`
  padding-left: 5.2rem;
  margin-bottom: .5rem;
  font-size: 1.7rem;
  font-weight: bold;
  font-family: 'Oswald';

  @media ${device.desktop} {
    padding-left: 8.8rem;
    font-size: 2.4rem;
  }

`
