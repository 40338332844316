import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { StyledBothSideShape } from '../PageSwipper/utils/styles'
import Modal from './Modal'
import { FaChevronRight } from 'react-icons/fa'
import { device } from '../../_assets/styles/theme'
import { baseHCPUrl } from '../../../config'

const RotateDevice = () => {
  const [open, setOpen] = useState(false);

  useState(() => {
    const cookies = document.cookie;
    const path = window.location.pathname;
    function handleResize() {
      const resizeCookie = document.cookie
      if (window.matchMedia("(orientation: portrait)").matches) {
        // you're in PORTRAIT mode
        setOpen(false);
      }
      if (
        window.matchMedia("(orientation: landscape)").matches &&
        !resizeCookie.includes('hideRotateDeviceModal=true') &&
        path != '/n/efficacy') {
        // you're in LANDSCAPE mode
        setOpen(true);
      }
    }
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    if (cookies.includes('hideRotateDeviceModal=true')) {
      setOpen(false);
      return;
    }
  }, [open]);

  const handleCloseRotateDeviceModal = () => {
    document.cookie = "hideRotateDeviceModal=true";
    setOpen(false);
  }

  return (
    <Modal isOpen={open}>
      <Container>
        <StyledBothSideShape background="#000" border="white" borderSize={0}>
          <Wrapper>
            <div className="rotate-device-content">
              <img src="/assets/rotate-device.svg" />
              <div>
                <span>ROTATE</span>
                <span>YOUR PHONE FOR</span>
                <span>THE BEST EXPERIENCE</span>
              </div>
            </div>
            <button onClick={handleCloseRotateDeviceModal}><img src="/assets/close-btn-green.svg" /></button>
          </Wrapper>
        </StyledBothSideShape>
      </Container>
    </Modal>
  )
}

export default RotateDevice

const Container = styled.div`
  width: 100%;
  max-width: 90%;
  font-family: 'Univers', sans-serif;
  position: relative;
  top: -50px;
  > div {
    max-width: 70%;
    margin: 0 auto;
    clip-path: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .rotate-device-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 20px 30px;
    img {
      max-width: 150px;
      height: auto;
    }
    span {
      color: #3246FF;
      font-size: 32px;
      display: block;
      &:first-child {
        font-size: 72px;
        line-height: 60px;
      }
    }
  }

  button {
    margin-bottom: 1rem;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
    border: none;
    background-color: transparent;
    font-size: 12.2.5rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.75rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: all .3s;
      position: absolute;
      right: 1.6rem;
      font-size: 1.2rem;
    }

    &:hover {
      svg {
        right: 1rem;
      }
    }
  }

  a {
    color: white;
    font-size: 1.3rem;
    line-height: 2.4rem;
    transition: all .3s;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 0.1rem;
      background: white;
      bottom: 0;
      left: 0;
      width: 100%;
    }

  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 2.8rem 3.8rem;

    p {
      font-size: 1.7rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.8rem;
      text-align: center;
      max-width: 100%;
    }

    button {
      height: 3.2rem;
      width: 16.8rem;
      border-radius: 1.6rem;

      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transition: all .3s;
        position: absolute;
        right: 1.6rem;
        font-size: 1.2rem;
      }

      &:hover {
        svg {
          right: 1rem;
        }
      }
    }

    a {
      color: #FFFFFF;
      font-size: 1.3rem;
      line-height: 2.4rem;
      transition: all .3s;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 0.1rem;
        background: white;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      &:hover {
        &:after {
          animation: change-width 1s alternate forwards;
        }
      }

      @keyframes change-width {
        0% {
          width: 100%;
        }

        50% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }

  }
`
