import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import Slide3 from './pages/Slide3';
import Slide4 from './pages/Slide4';
import ISICollapsing from '../_components/ISI/Collapsing';
import Slide5 from './pages/Slide5';
import useMobileDetect from '../../_hooks/useMobileDetect';
import { Page } from '../_components/PageSwipper/utils/styles';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import StaticIsi from '../_components/ISI/StaticIsi';
import Footer from '../_components/Footer';
import { isiConfig } from '../_components/ISI/config';
import { PageOverlay, StaticContentWrapper } from '../Home';
import MetaTags from '../_components/MetaTags/MetaTags';

const Testing = ({ setPageBackground }) => {
  const { isMobile } = useMobileDetect()
  const pageRef = useRef()
  const [headerRef, setHeaderRef] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [renderPage, setRenderPage] = useState(false)
  const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(1)
  const [background, setBackground] = useState(`url(${isMobile ? '' : '/assets/bg-about.webp'}) no-repeat fixed center top / cover`)
  const [overlay, setOverlay] = useState(null)
  const overlayRef = useRef()

  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef?.current.clientHeight)
      setRenderPage(true)
    }
  }, [headerRef?.current])

  useEffect(() => {
    overlayRef.current.classList.remove('visible')
    overlayRef.current.classList.add('hidden')

    const time = setTimeout(() => {
      overlayRef.current.classList.add('visible')
      overlayRef.current.classList.remove('hidden')
    }, 100)

    return () => {
      clearTimeout(time)
    }
  }, [currentSlide])

  return (
    <Page ref={pageRef} background={background}>
      <PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
      <PageScroll>
        <Container>
          <MetaTags
            title='KRAZATI® (adagrasib) | The Importance of Biomarker Testing'
            description='Discover the importance of biomarker testing. Explore how specific characteristics assist in identifying a subset of patients for targeted testing purposes.'
            ogUrl='https://www.krazatihcp.com/n/testing'
          />

          <Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
          <div style={{ position: 'relative' }}>
            {renderPage && (
              <PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
                <Slide1
                  isiconfig={isiConfig.collapsed}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                />
                <Slide2
                  isiconfig={isiConfig.floating}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                />
                <Slide3
                  isiconfig={isiConfig.floating}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                />
                {/* <Slide4
                  isiconfig={isiConfig.floating}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                /> */}
                <Slide5
                  isiconfig={isiConfig.floating}
                  setCurrentSlide={setCurrentSlide}
                  setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
                  setBackground={setBackground}
                  setOverlay={setOverlay}
                />
              </PageSwipper>
            )}

            {!isMobile && currentSlide !== 3 && <ISICollapsing className={currentSlide === 2 ? 'collapse' : 'visible'} />}
            {!isMobile && currentSlide === 3 && <ISICollapsing className="floatingWithIsi collapse" />}

            {isMobile && <FloatingIsi className={currentSlide === 2 || currentSlide === 3 ? 'collapse' : 'visible'} />}

          </div>

          {!isMobile && currentSlide === 3 &&
            <StaticContentWrapper>
              <StaticIsi />
              <Footer translate={true}></Footer>
            </StaticContentWrapper>
          }
        </Container>
      </PageScroll>
    </Page>
  )
}

export default Testing

const Container = styled.div`
  height: 100%;
`
