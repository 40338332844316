import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useSwiperSlide, useSwiper } from "swiper/react";
import { FaArrowRight } from "react-icons/fa";
import {
  BaseLink,
  BaseSubtitle,
  BaseText,
  Box,
  Container,
  Wrapper,
} from "../../_components/PageSwipper/utils/styles";
import useActiveLink from "../../_components/Header/hooks/useActiveLink";
import Reference from "../../_components/Reference";
import { device } from "../../_assets/styles/theme";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import ContainerScroll from "../../_components/PageSwipper/components/ContainerScroll";
import ResourcesTabs from "../components/Tabs/ResourcesTabs";
import ReferenceFooterWithLink from "../../_components/ReferenceWithLink";
import Leaving from "../../_components/Modal/Leaving";
import VideoModal from "../../_components/Modal/VideoModal";

// Videos
const Tab1 = ({ isiconfig }) => {
  const { isMobile } = useMobileDetect();

  if (isMobile) {
    return (
      <Scroll isiconfig={isiconfig}>
        <ContainerScroll>
          <Box
            height="auto"
            margin={isMobile ? "1.2rem 0 1.6rem" : "0 0 .6rem"}
            align="center"
            justify="center"
            direction="column"
          >
            <BaseSubtitle
              color="#fff"
              style={{ maxWidth: "85%", textAlign: "center" }}
            >
              PEER PERSPECTIVES ON KRAZATI FOR <em>KRAS G12C</em>-MUTATED
              ADVANCED NSCLC
            </BaseSubtitle>
            <BaseText
              style={{
                maxWidth: "85%",
                textAlign: "center",
                marginTop: "1.2rem",
              }}
              size={isMobile ? 1.6 : 2}
              color="#fff"
            >
              In the videos below, Mark A. Socinski, MD, Executive Medical
              Director of the AdventHealth Cancer Institute, shares what you
              should know about KRAZATI and KRAS G12C-mutated advanced NSCLC.
            </BaseText>
          </Box>
          <TabContainer>
            <Card direction="column" justify="center" align="center">
              <Box direction="column">
                <VideoModal src="https://player.vimeo.com/video/836335220?h=68b7d59851&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
                  <ImageBox>
                    <img src="/assets/thumb1alt.webp" alt="Video FPO" />
                  </ImageBox>
                </VideoModal>
                <BaseText
                  margin="1.6rem 0 0.9rem"
                  padding="0 0.8rem"
                  size={isMobile ? 1.7 : 2}
                  color="#fff"
                >
                  KRAZATI for <em>KRAS G12C</em>-Mutated Advanced NSCLC
                </BaseText>
              </Box>
            </Card>

            <Card direction="column" justify="center" align="center">
              <Box direction="column">
                <VideoModal src="https://player.vimeo.com/video/781866436?h=68b7d59851&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
                  <ImageBox>
                    <img src="/assets/thumb2alt.webp" alt="Video FPO" />
                  </ImageBox>
                </VideoModal>
                <BaseText
                  margin="1.6rem 0 0.9rem"
                  padding="0 0.8rem"
                  size={isMobile ? 1.7 : 2}
                  color="#fff"
                >
                  Identifying Patients With Possible <em>KRAS G12C</em>{" "}
                  Mutations
                </BaseText>
              </Box>
            </Card>
          </TabContainer>
          <Box height="auto">
            <CustomLink to="/n/support">
              <span>Mirati & Me</span>
              <FaArrowRight />
            </CustomLink>
          </Box>
          {isMobile && (
            <Reference style={{ color: "#fff" }}>
              NSCLC=non-small cell lung cancer.
            </Reference>
          )}
        </ContainerScroll>
      </Scroll>
    );
  }

  return (
    <Scroll isiconfig={isiconfig}>
      <Box
        height="auto"
        margin={isMobile ? "1.2rem 0 1.6rem" : "0 0 .6rem"}
        align="center"
        justify="center"
        direction="column"
      >
        <BaseSubtitle
          color="#fff"
          style={{ maxWidth: "55rem", textAlign: "center" }}
        >
          PEER PERSPECTIVES ON KRAZATI FOR <em>KRAS G12C</em>-MUTATED ADVANCED
          NSCLC
        </BaseSubtitle>
        <BaseText
          style={{ maxWidth: "50%", textAlign: "left", marginTop: "1.2rem" }}
          size={isMobile ? 1.6 : 2}
          color="#fff"
        >
          In the videos below, Mark A. Socinski, MD, Executive Medical Director
          of the AdventHealth Cancer Institute, shares what you should know
          about KRAZATI and KRAS G12C-mutated advanced NSCLC.
        </BaseText>
      </Box>
      <TabContainer>
        <Card direction="column" justify="center" align="center">
          <Box direction="column">
            <VideoModal src="https://player.vimeo.com/video/836335220?h=68b7d59851&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
              <ImageBox>
                <img src="/assets/thumb1alt.webp" alt="Video FPO" />
              </ImageBox>
            </VideoModal>
            <BaseText
              margin="1.6rem 0 0.9rem"
              padding="0 0.8rem"
              size={isMobile ? 1.7 : 2}
              color="#fff"
            >
              KRAZATI for <em>KRAS G12C</em>-Mutated Advanced NSCLC
            </BaseText>
          </Box>
        </Card>

        <Card direction="column" justify="center" align="center">
          <Box direction="column">
            <VideoModal src="https://player.vimeo.com/video/781866436?h=68b7d59851&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
              <ImageBox>
                <img src="/assets/thumb2alt.webp" alt="Video FPO" />
              </ImageBox>
            </VideoModal>
            <BaseText
              margin="1.6rem 0 0.9rem"
              padding="0 0.8rem"
              size={isMobile ? 1.7 : 2}
              color="#fff"
            >
              Identifying Patients With Possible <em>KRAS G12C</em> Mutations
            </BaseText>
          </Box>
        </Card>
      </TabContainer>
    </Scroll>
  );
};

// Downloads
const Tab2 = ({ isiconfig }) => {
  const { isMobile } = useMobileDetect();

  if (isMobile) {
    return (
      <Scroll isiconfig={isiconfig}>
        <ContainerScroll>
          <Box
            height="auto"
            margin={isMobile ? "1.2rem 0 0" : "0 0 0"}
            align="center"
            justify="center"
            direction="column"
          >
            <BaseSubtitle weight="bold" color="#fff">
              Resources
            </BaseSubtitle>
            <p className="subtitle">
              Easily access downloadable guides and important information to use
              during treatment with KRAZATI.
            </p>
          </Box>

          <DownloadContainer direction="column">
            <DownloadsCardMobile className="third">
              <DownloadsCardTitle
                weight="bold"
                color="#fff"
                size={isMobile ? 1.6 : 2}
                margin="0 0 1rem"
              >
                Healthcare Providers
              </DownloadsCardTitle>
              <DownloadWrapper>
                <DownloadTextWrapper>
                  <DownloadButton
                    target="_blank"
                    href="/files/therapy-management-guide.pdf"
                  >
                    Therapy Management Guide{" "}
                    <img src="/assets/download-icon.webp" alt="" />
                  </DownloadButton>
                  <p>
                    Digital resource designed to help guide your conversations
                    with patients while managing their treatment. Topics include
                    safety information, dosage modifications, counseling
                    patients on drug-drug interactions, and more.
                  </p>
                </DownloadTextWrapper>
                <DownloadTextWrapper>
                  <DownloadButton
                    target="_blank"
                    href="/files/dosing-flashcard.pdf"
                  >
                    Dosing Flashcard{" "}
                    <img src="/assets/download-icon.webp" alt="" />
                  </DownloadButton>
                  <p>Quick reference guide for dosing KRAZATI.</p>
                </DownloadTextWrapper>

                {/* <DownloadTextWrapper>
                  <DownloadButton>Coverage Support <img className="chevron" src='/assets/chevron-right.svg' alt="" /></DownloadButton>
                  <p>
                  Interactive map of payer coverage in your patient's local area.
                  </p>
                </DownloadTextWrapper> */}
              </DownloadWrapper>
            </DownloadsCardMobile>
          </DownloadContainer>
          <Box height="auto" justify="left" style={{ "margin-top": "10px" }}>
            {/* {isMobile && <Reference style={{ color: '#fff'}}>GI=gastrointestinal.</Reference>} */}
          </Box>
        </ContainerScroll>
      </Scroll>
    );
  }

  return (
    <Scroll isiconfig={isiconfig}>
      <Box
        height="auto"
        margin={isMobile ? "1.2rem 0 0" : "0 0 0"}
        align="center"
        justify="center"
        direction="column"
      >
        <BaseSubtitle weight="bold" color="#fff">
          Resources
        </BaseSubtitle>
        <p className="subtitle">
          Easily access downloadable guides and important information to use
          during treatment with KRAZATI.
        </p>
      </Box>

      <DownloadContainer direction="column">
        <DownloadsCardMobile>
          <DownloadsCardTitle
            weight="bold"
            color="#fff"
            size={isMobile ? 1.6 : 2}
            margin="0 0 1rem"
          >
            Healthcare Providers
          </DownloadsCardTitle>
          <DownloadWrapper className="shorten-before">
            <DownloadTextWrapper>
              <DownloadButton
                target="_blank"
                href="/files/therapy-management-guide.pdf"
              >
                Therapy Management Guide{" "}
                <img src="/assets/download-icon.webp" alt="" />
              </DownloadButton>
              <p>
                Digital resource designed to help guide your conversations with
                patients while managing their treatment. Topics include safety
                information, dosage modifications, counseling patients on
                drug-drug interactions, and more.
              </p>
            </DownloadTextWrapper>
            <DownloadTextWrapper>
              <DownloadButton
                target="_blank"
                href="/files/dosing-flashcard.pdf"
              >
                Dosing Flashcard <img src="/assets/download-icon.webp" alt="" />
              </DownloadButton>
              <p>Quick reference guide for dosing KRAZATI.</p>
            </DownloadTextWrapper>

            {/* <DownloadTextWrapper>
                <DownloadButton>Coverage Support <img className="chevron" src='/assets/chevron-right.svg' alt="" /></DownloadButton>
                <p>
                Interactive map of payer coverage in your patient's local area.
                </p>
              </DownloadTextWrapper> */}
          </DownloadWrapper>
        </DownloadsCardMobile>
      </DownloadContainer>
      {/* {isMobile && <CustomReference style={{ color: '#fff' }}>GI=gastrointestinal.</CustomReference>} */}
    </Scroll>
  );
};

const Slide1 = ({
  setShowHeaderSecondaryMenu,
  setCurrentSlide,
  isiconfig,
  setOverlay,
}) => {
  const history = useNavigate();
  const { hash } = useLocation();
  const swiperSlide = useSwiperSlide();
  const swiper = document.querySelector(".mySwiper");
  const { isMobile } = useMobileDetect();
  const [currentTab, setCurrentTab] = useState(0);
  const [showPageContent, setShowPageContent] = useState(false);

  const slideOverlay = `radial-gradient(circle, #EBE8E2 0%, #E7D7D4 27.15%, #D6C9D2 59.94%, #A09AAF 100%)`;

  useActiveLink(setShowHeaderSecondaryMenu, "resources");

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setShowPageContent(true);
      setCurrentSlide(1);
      swiper.swiper.disable();
    }
    setShowPageContent(false);
  }, [swiperSlide.isActive, currentTab]);

  const items = [
    {
      title: "VIDEOS",
      component: <Tab1 isiconfig={isiconfig} />,
    },
    {
      title: "DOWNLOADS & LINKS",
      component: <Tab2 isiconfig={isiconfig} />,
    },
  ];
  return (
    <CustomContainer
      className={showPageContent ? "visible" : "hidden"}
      isiconfig={isiconfig}
    >
      <Tab2 isiconfig={isiconfig} />
      {/*<ResourcesTabs setCurrentTab={setCurrentTab} currentTab={currentTab} items={items} />*/}
      {/* {!isMobile &&
                (currentTab === 0 ? (
                    <ReferenceFooterWithLink variant='white' linkText='Mirati & Me' url='/n/support'>
                        GI=gastrointestinal.
                    </ReferenceFooterWithLink>
                ) : (
                    <ReferenceFooterWithLink variant='white' linkText='Mirati & Me' url='/n/support'>
                        GI=gastrointestinal.
                    </ReferenceFooterWithLink>
                ))} */}
    </CustomContainer>
  );
};

export default Slide1;

const Scroll = styled.div`
  height: 100%;
  padding-bottom: 2rem;
  max-height: ${(props) => `calc(95% - ${props.isiconfig})`};
  h2 {
    text-transform: uppercase;
  }

  .subtitle {
    font-family: "Barlow", sans-serif;
    font-size: 1.5rem;
    color: white;
    font-weight: 700;
    margin-bottom: 3rem;
    margin-top: 1rem;
    text-align: center;
    padding: 0 3rem;
  }
  @media ${device.desktop} {
    max-height: 100%;
    height: ${(props) =>
      `calc(var(--screen-height) - 11.2rem - ${props.isiconfig})`};
    .subtitle {
      font-size: 2rem;
      margin-bottom: 4rem;
    }
  }

  @media ${device.desktop800h} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
  }
`;

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0;

  h2 {
    text-transform: uppercase;

    @media (min-width: 1100px) and (max-height: 800px) {
      padding-top: 30px;
    }
  }

  @media ${device.desktop} {
    max-height: 100%;
    height: ${(props) => `calc(99% - ${props.isiconfig})`};
    position: relative;
    padding-top: 3rem;
  }

  @media ${device.desktop800h} {
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
    height: ${(props) => `calc(100% - ${props.isiconfig})`};
  }
`;

const StyledIframe = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Button = styled(BaseLink)`
  background: #3246ff;
  color: white;
  border-radius: 1.4rem;
  font-size: 1.6rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14.4rem;
  min-height: 3.2rem;
  max-height: 3.2rem;
  cursor: pointer;
  img {
    max-width: 1.2rem;
    margin-left: 1rem;
  }
`;

const ImageBox = styled(Box)`
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);

  img {
  }

  @media ${device.desktop} {
    max-height: 20.2rem;
    img {
      max-height: 20.2rem;
    }
  }
`;

const TabContainer = styled.div`
  margin: 0 auto;
  max-width: 112.8rem;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    > div {
      max-width: 80%;
      margin: 3rem auto;
    }
  }
  @media ${device.desktop} {
    flex-direction: row;
    max-width: 74rem;
    gap: 3rem;
    > div {
      max-width: 95%;

      > div {
        justify-content: flex-start;
      }
    }
  }
`;

const Card = styled(Box)`
  margin-bottom: 3rem;

  @media ${device.desktop} {
    margin-right: 2.4rem;
    max-width: 36rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
const DownloadsCardMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  @media ${device.desktop} {
    font-size: 1.4rem;
  }
`;

const DownloadsCardTitle = styled.div`
  font-family: "Barlow Condensed", sans-serif;
  font-weight: bold;
  font-size: 1.3rem;
  color: #fff;
  text-transform: initial;
  margin: 0 0 4.2rem;
  padding: initial;
  text-align: initial;
  flex: 1;

  @media ${device.tablet} {
    font-size: 1.4rem;
  }

  @media ${device.desktop} {
    font-size: 2rem;
    margin-bottom: 0;
    max-width: 10.4rem;
  }
`;
const DownloadContainer = styled.div`
  max-width: 110rem;
  margin: 0 auto;
  justify-content: flex-start;
  height: auto;
  max-height: 100%;
`;
const DownloadWrapper = styled(Box)`
  width: 75%;
  padding: 0 0 0 20px;
  height: auto;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media ${device.tablet} {
    width: 63rem;
    align-items: flex-start;
  }

  @media ${device.laptop} {
    width: 85%;
  }

  @media ${device.desktop} {
    width: 90%;
  }

  &.shorten-before > div:before {
    height: calc(100% + 1rem);
  }
`;

const DownloadTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    transform: translate(-100%, 1.4rem);
    width: 15px;
    height: calc(100% + 2rem);
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;

    @media ${device.desktop} {
      transform: translate(-100%, calc(50% - 1rem));
    }
  }

  &:nth-last-of-type(2):before {
    border-bottom: 1px solid #fff;
  }

  &:last-of-type {
    margin-bottom: 0;

    &:before {
      display: none;
    }
  }

  .coming-soon-flag {
    height: 3rem;
  }

  p {
    color: #fff;
    font-family: "Barlow Condensed";
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.4rem;
    margin: 0.5rem 0;
  }

  @media ${device.tablet} {
    p {
      font-size: 1.4rem;
    }
  }

  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;

    p {
      flex: 1;
      max-width: 74%;
      font-size: 1.8rem;
      line-height: 2rem;
      margin: 0;
      margin-left: 1.6rem;
    }
  }
`;

const DownloadTextWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    // top: -83%;
    // left: -5px;
    top: -30px;
    transform: translate(-100%, 1.4rem);
    width: 15px;
    // height: calc(100% + 2rem);
    height: calc(50% + 5rem);
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;

    @media ${device.desktop} {
      top: -83%;
      transform: translate(-100%, calc(50% - 1rem));
      height: calc(100% + 2rem);
    }
  }

  &:nth-last-of-type(1):before {
    border-bottom: 1px solid #fff;
  }

  // &:last-of-type {
  // 	margin-bottom: 0;

  // 	&:before {
  // 		display: none;
  // 	}
  // }

  .coming-soon-flag {
    height: 3rem;
  }

  p {
    color: #fff;
    font-family: "Barlow Condensed";
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.4rem;
    margin: 0.5rem 0;
  }

  @media ${device.tablet} {
    p {
      font-size: 1.4rem;
    }
  }

  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;

    p {
      flex: 1;
      max-width: 74%;
      font-size: 1.8rem;
      line-height: 2rem;
      margin: 0;
      margin-left: 1.6rem;
    }
  }
`;

const DownloadButton = styled(BaseLink)`
  background: #3246ff;
  color: white;
  border-radius: 1.4rem;
  font-size: 1.138rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.8rem;
  padding: 0 1.4rem;
  position: relative;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.65;
  }

  img {
    max-width: 1.6rem;
    margin-left: 1rem;

    &.chevron {
      max-width: 0.8rem;
    }
  }

  @media ${device.tablet} {
    font-size: 1.3rem;
    height: 2.8rem;
    width: 23.2rem;
  }

  @media ${device.desktop} {
    height: 3.2rem;
    width: 23.2rem;

    img {
      &.chevron {
        max-width: 0.9rem;
      }
    }
  }
`;

const CustomReference = styled(Reference)`
  margin-bottom: 2rem;
`;

const CustomLink = styled(Link)`
  display: block;
  border-bottom: 0.1rem solid rgb(58, 58, 58);
  font: 1.3rem/1 "Barlow", sans-serif;
  color: rgb(58, 58, 58);
  padding-bottom: 0.3rem;
  margin: 1.3rem auto 3.3rem;

  svg {
    margin-left: 1rem;
    fill: rgb(58, 58, 58);
    font-size: 1.6rem;
  }
`;
