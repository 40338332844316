import { Helmet } from 'react-helmet-async';

export default function MetaTags({ title, ogTitle = title, description, ogDescription = description, ogUrl, canonical = ogUrl }) {
  return (
    <Helmet>
      {canonical && <link rel="canonical" href={canonical} />}
      <title>{title}</title>
      <meta name="description" content={description} data-rh="true" />

      {/* OG meta tags used by Social Media bots  */}
      <meta property="og:title" content={ogTitle} data-rh="true" />
      <meta property="og:description" content={ogDescription} data-rh="true" />
      {ogUrl && <meta property="og:url" content={ogUrl} data-rh="true" />}
    </Helmet>
  )
}
