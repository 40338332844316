import React, { useEffect, useState }  from 'react'
import {  useSwiperSlide } from 'swiper/react';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Tabs from '../../_components/Tabs/Tabs';
import Tab1 from '../components/Tab1';
import Tab2 from '../components/Tab2';
import Tab3 from '../components/Tab3';
import ReferenceFooterWithLink from '../../_components/ReferenceWithLink';
import { Container } from '../../_components/PageSwipper/utils/styles';
import styled from 'styled-components/macro';
import { device } from '../../_assets/styles/theme';

const Slide2 = ({setPageBackground, setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setOverlay}) => {
  const swiperSlide = useSwiperSlide()
  const [showPageContent, setShowPageContent] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;
  useActiveLink(setShowHeaderSecondaryMenu, 'about-kras-g12c')

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setOverlay(slideOverlay)
      setCurrentSlide(2)
      setCurrentTab(0)

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
      'event': '3 KRAS Reasons Tabs',
      '3_kras_reasons_tabs': 'REASON 1'
      });

      return
    }

    setShowPageContent(false)
  }, [swiperSlide.isActive])

  const items = [
    {
      title: 'Reason 1',
      component: <Tab1 />,
      reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing">
                    <p>GTP=guanosine triphosphate; PFS=progression-free survival.</p>
                  </ReferenceFooterWithLink>
    },
    {
      title: 'Reason 2',
      component: <Tab2 />,
      reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing">
                    <p>*Median OS of ~5 months in untreated brain metastases.</p>
                    <p>NSCLC-non-small cell lung cancer; OS=overall survival; QOL=quality of life.</p>
                  </ReferenceFooterWithLink>
    },
    {
      title: 'Reason 3',
      component: <Tab3 />,
      reference:  <ReferenceFooterWithLink style={{marginBottom: '0'}} url={'/n/testing'} linkText="Testing" />
    },

  ]

  return (
    <CustomContainer isiconfig={isiconfig} className={showPageContent ? 'visible' : 'hidden'} >
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        isiconfig={isiconfig}
        items={items}
        className='about-kras-g12c'
      />
    </CustomContainer>
  )
}

export default Slide2

const CustomContainer = styled(Container) `
  &.visible {
    #larger {
      animation: larger 1.5s ease 0.5s forwards;
    }
    #break-off {
      animation: break 1.5s ease 0.5s forwards;
    }
    #labels {
      animation: labels 1.5s ease 1.5s forwards;
    }
  }

  // Show the chart effect in the first tab after change the slide.
  &.hidden {
    #larger {
      animation: returnLarger 1.5s ease 0.5s forwards;
    }
    #break-off {
      animation: returnBreak 1.5s ease 0.5s forwards;
    }
    #labels {
      animation: returnLabels 1.5s ease 1.5s forwards;
    }
  }

  .slider-item {
    width: 100%;
    margin: auto;
    height: ${props => `calc(var(--screen-height) - ${props.isiconfig.mobile}rem)`};

    @media ${device.tablet} {
      height: ${props => `calc(var(--screen-height) - ${props.isiconfig.tablet}rem)`};
    }

    @media ${device.desktop} {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: ${props => `calc(var(--screen-height) - ${props.isiconfig.desktop}rem  - 11.2rem)`};
    }
  }

  .scroll-container {
    display: none;
  }
  .swiper-pagination {
    top: 1rem;
    p {
      width: 100%;
      padding: 0;
      border-radius: 0;
      border: 0.1rem solid #FFF;
      transform: skewX(-35deg);
      color: #fff;
      max-width: 30rem;
      height: 4rem;
      pointer-events: all;
      background: transparent;
      font-size: 1.6rem;

      span {
        width: 100%;
        height: 100%;
        transform: skewX(35deg);
        display: flex;
        align-items: center;
        justify-items: center;
        place-content: center;
      }
      &.swiper-pagination-bullet-active {
        background: linear-gradient(108.88deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.53) 100%);
      }
    }
  }
`
