import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useSwiperSlide } from "swiper/react";
import {
  BaseSubtitle,
  BaseText,
  Container,
  CustomLink,
  LinkBox,
  Wrapper,
} from "../../_components/PageSwipper/utils/styles";
import useActiveLink from "../../_components/Header/hooks/useActiveLink";
import { FaArrowRight } from "react-icons/fa";
import { device } from "../../_assets/styles/theme";
import ReferenceFooterWithLink from "../../_components/ReferenceWithLink";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import Reference from "../../_components/Reference";
import DoughnutChart from "../components/Chart";
import ReferenceModal from "../../_components/Modal/ReferenceModal";
import ContainerScroll from "../../_components/PageSwipper/components/ContainerScroll";

const TABLE_SCROLL_DELAY = 2000;
const DATA_COLLECTION = [
  { data: 64, text: <span>MEDIAN <br /> AGE</span>, background: 'transparent', rotation: 0 },
  { data: 97, text: <span>HISTOLOGY, Adenocarcinoma</span>, background: '#17C94E', rotation: 10 },
  { data: 96, text: <span>SMOKING <br />HISTORY</span>, spacing: 14, background: '#17C94E', rotation: 10 },
  { data: 11, text: <span>locally <br /> advanced <br /> disease</span>, spacing: 99, background: '#17C94E', rotation: -40 },
  { data: null, text: null, background: null, rotation: null },
  { data: null, text: null, background: null, rotation: null },
  { data: 83, text: <span>ECOG PS 1</span>, spacing: 99, background: '#17C94E', rotation: 60 },
  { data: 12, text: <span>4+ prior lines <br /> of therapy</span>, spacing: 99, background: '#17C94E', rotation: -50 },
  { data: null, text: null, background: null, rotation: null },
  { data: null, text: null, background: null, rotation: null },
  { data: 98, text: <span>Prior IO-based <br />regimen</span>, background: '#17C94E', rotation: 10 },
  { data: 38, text: <span>STUDIED IN THE <br />COMMUNITY <br />research <br />setting</span>, spacing: 110, background: '#17C94E', rotation: -130 },
  { data: null, text: null, background: null, rotation: null },
  { data: 42, text: <span> <small>SITES OF <br />EXTRA-THORACIC<br /> METASTASES</small><strong className='green'>Bone</strong></span>, background: '#17C94E', rotation: -140 },
  { data: 30, text: <span> <small>SITES OF <br />EXTRA-THORACIC <br /> METASTASES </small><strong className='green'>Brain</strong></span>, background: '#17C94E', rotation: -100 },
  { data: 21, text: <span> <small>SITES OF <br />EXTRA-THORACIC<br /> METASTASES</small><strong className='green'>Adrenals</strong></span>, background: '#17C94E', rotation: -75 },
  { data: 21, text: <span><small>SITES OF <br />EXTRA-THORACIC <br /> METASTASES</small><strong className='green'>Liver</strong></span>, background: '#17C94E', rotation: -75 },
];
const PageTitle = ({ handleShowModal }) => {
  return (
    <Title>
      <BaseSubtitle>
        Baseline Demographics
        <sup onClick={() => handleShowModal([30, 31])}>30,31</sup>
      </BaseSubtitle>
    </Title>
  );
};

const PageSubtitle = ({ handleShowModal }) => {
  return (
    <Subtitle>
      <BaseText>
        There is no recommendation for a washout period after an IO-based
        regimen in the KRAZATI Prescribing Information.
      </BaseText>
    </Subtitle>
  );
};

const TableBodyController = ({ children, setActualData }) => {
  const { isMobile } = useMobileDetect();
  const swiperSlide = useSwiperSlide();
  const [activeItem, setActiveItem] = useState(0);
  const [scrollActive, setScrollActive] = useState(false);
  const [scrollController, setScrollController] = useState();

  useEffect(() => {
    const perc = document.querySelector(
      "div.swiper-slide > div > div > div > div > div > p:nth-child(1) > sup",
    );
    if (activeItem === 0) {
      perc.style.display = "none";
    } else {
      perc.style.display = "initial";
    }

    if (!swiperSlide.isActive) return;

    if (scrollActive) {
      const scrollController = setTimeout(() => {
        if (activeItem + 1 === 4 || activeItem + 1 === 5) {
          setActiveItem(6);
          setActualData(DATA_COLLECTION[6]);
          return;
        }

        if (activeItem + 1 === 8 || activeItem + 1 === 9) {
          setActiveItem(10);
          setActualData(DATA_COLLECTION[10]);
          return;
        }

        if (activeItem + 1 === 12) {
          setActiveItem(13);
          setActualData(DATA_COLLECTION[13]);
          return;
        }

        if (activeItem >= children.length - 1) {
          setActualData(DATA_COLLECTION[0]);
          setActiveItem(0);
          return;
        }

        setActiveItem(activeItem + 1);
        setActualData(DATA_COLLECTION[activeItem + 1]);
      }, TABLE_SCROLL_DELAY);
      setScrollController(scrollController);
    }
  }, [swiperSlide.isActive, activeItem, children.length, scrollActive]);

  return (
    <>
      {children.map((child, index) => {
        if (index === activeItem) {
          if (index === 4 || index === 5 || index === 8)
            return (
              <React.Fragment key={index}>
                {React.cloneElement(child, {
                  className: `${child.props.className ? child.props.className : ""
                    }`,
                })}
              </React.Fragment>
            );

          return isMobile ? (
            React.cloneElement(child, {
              className: `active  ${child.props.className ? child.props.className : ""
                }`,
              key: index,
            })
          ) : (
            <ActiveItem key={index}>
              <ClickImage>
                <img src="/assets/icons/click-icon.svg" alt="" />
              </ClickImage>
              {React.cloneElement(child, {
                className: `active  ${child.props.className ? child.props.className : ""
                  }`,
              })}
            </ActiveItem>
          );
        }

        return (
          <TableBodyItemController
            setActualData={setActualData}
            key={index}
            scrollController={scrollController}
            setScrollActive={setScrollActive}
            itemIndex={index}
            setActiveItem={setActiveItem}
            activeItem={activeItem}
          >
            {child}
          </TableBodyItemController>
        );
      })}
    </>
  );
};

const TableBodyItemController = ({
  children,
  setActualData,
  itemIndex,
  setActiveItem,
  scrollController,
  setScrollActive,
}) => {
  const ref = useRef();
  const handleActive = () => {
    clearTimeout(scrollController);

    if (itemIndex === 4 || itemIndex === 5) {
      setActiveItem(6);
      setActualData(DATA_COLLECTION[6]);
      setScrollActive(false);
      return;
    }

    if (itemIndex === 8 || itemIndex === 9) {
      setActiveItem(7);
      setActualData(DATA_COLLECTION[7]);
      setScrollActive(false);
      return;
    }

    if (itemIndex === 12) {
      setActiveItem(13);
      setActualData(DATA_COLLECTION[13]);
      setScrollActive(false);
      return;
    }

    setActiveItem(itemIndex);
    setActualData(DATA_COLLECTION[itemIndex]);
    setScrollActive(false);
  };

  return React.cloneElement(children, { onClick: handleActive, onMouseOver: handleActive, ref });
};

const Table = ({ isMobile, setActualData, handleShowModal }) => {
  return (
    <TableContainer className={isMobile ? "" : "swiper-no-swiping"}>
      <TableItems>
        <TableHeader className="is_header">
          Key baseline demographics
        </TableHeader>
        <TableHeader className="is_header text-center">
          KRAZATI (N=116)
        </TableHeader>
      </TableItems>

      <TableBodyController setActualData={setActualData}>
        <TableItems schema="white">
          <TableItem>Median Age (Range)</TableItem>
          <TableItem>
            <span>64 (25-89)</span>
          </TableItem>
        </TableItems>

        <TableItems schema="gray">
          <TableItem>Histology, Adenocarcinoma (%)</TableItem>
          <TableItem>
            <span>97</span>
          </TableItem>
        </TableItems>

        <TableItems schema="white">
          <TableItem>Smoking History, Current & Former (%)</TableItem>
          <TableItem>
            <span>96</span>
          </TableItem>
        </TableItems>

        <TableItems schema="gray">
          <TableItem>Locally Advanced Disease (%)</TableItem>
          <TableItem>
            <span>11</span>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="white">
          <TableItem className="no-space">
            <p>ECOG PS (%)</p>
          </TableItem>
          <TableItem className="no-space">
            <p>&nbsp;</p>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="white">
          <TableItem className="no-space">
            <p className="extra">0</p>
          </TableItem>
          <TableItem className="no-space">
            <p>16</p>
          </TableItem>
        </TableItems>
        <TableItems schema="white">
          <TableItem className="no-space">
            <p className="extra">1</p>
          </TableItem>
          <TableItem className="no-space">
            <p>
              <span>83</span>
            </p>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="gray">
          <TableItem className="no-space">
            <p>Prior Lines of Therapy, 1/2/3/4+ (%)</p>
          </TableItem>
          <TableItem className="no-space">
            <p>
              <span>43 / 35 / 10 / 12</span>
            </p>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="gray">
          <TableItem className="no-space">
            <p>Range</p>
          </TableItem>
          <TableItem className="no-space">
            <p>1-7</p>
          </TableItem>
        </TableItems>

        <TableItems schema="gray">
          <TableItem className="no-space">
            <p>Prior Lines of Therapy, Median</p>
          </TableItem>
          <TableItem className="no-space">
            <p>2</p>
          </TableItem>
        </TableItems>

        <TableItems schema="white">
          <TableItem>Prior IO-Based Regimen (%)</TableItem>
          <TableItem>
            <span>98</span>
          </TableItem>
        </TableItems>

        <TableItems schema="gray">
          <TableItem>
            <span>
              Studied in the Community Research Setting (%)
              <sup
                style={{ marginLeft: "-18.5px" }}
                onClick={() => handleShowModal([27])}
              >
                27
              </sup>
            </span>
          </TableItem>
          <TableItem>
            <span>38</span>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="white">
          <TableItem className="no-space">
            <p>Sites of Extra-Thoracic Metastases (%)</p>
          </TableItem>
          <TableItem className="no-space">
            <p>&nbsp;</p>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="white">
          <TableItem className="no-space">
            <div className="extra">
              <p>Bone</p>
            </div>
          </TableItem>
          <TableItem className="no-space">
            <p>
              <span>42</span>
            </p>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="white">
          <TableItem className="no-space">
            <div className="extra">
              <p>Brain</p>
            </div>
          </TableItem>
          <TableItem className="no-space">
            <p>
              <span>30</span>
            </p>
          </TableItem>
        </TableItems>

        <TableItems className="no-space" schema="white">
          <TableItem className="no-space">
            <div className="extra">
              <p>Adrenals</p>
            </div>
          </TableItem>
          <TableItem className="no-space">
            <p>
              <span>21</span>
            </p>
          </TableItem>
        </TableItems>
        <TableItems schema="white">
          <TableItem className="no-space">
            <div className="extra">
              <p>Liver</p>
            </div>
          </TableItem>
          <TableItem className="no-space">
            <p>
              <span>21</span>
            </p>
          </TableItem>
        </TableItems>
      </TableBodyController>
    </TableContainer>
  );
};

const Slide3 = ({
  setPageBackground,
  setShowHeaderSecondaryMenu,
  setCurrentSlide,
  isiconfig,
  setBackground,
  setOverlay,
}) => {
  const { isMobile } = useMobileDetect();
  const swiperSlide = useSwiperSlide();
  const referenceText =
    "ECOG PS=Eastern Cooperative Oncology Group Performance Status; IO=immunotherapy.";
  const [actualData, setActualData] = useState(DATA_COLLECTION[0]);
  const [showPageContent, setShowPageContent] = useState(false);
  const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;
  const [referencesIndex, setReferencesIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs);
    }
  };

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true);
    }
  }, [referencesIndex]);

  useActiveLink(setShowHeaderSecondaryMenu, "study");

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setShowPageContent(true);
      setCurrentSlide(3);
      setOverlay(slideOverlay);
      setActualData(DATA_COLLECTION[0]);

      document.body.classList.add("study-3");
      return;
    }
    setShowPageContent(false);
    document.body.classList.remove("study-3");
  }, [swiperSlide.isActive]);

  if (!swiperSlide.isActive) return null;

  if (isMobile) {
    return (
      <CustomContainer
        className={showPageContent ? "visible" : "hidden"}
        isiconfig={isiconfig}
      >
        <Scroll>
          <ContainerScroll>
            <Container>
              {referencesIndex && (
                <ReferenceModal
                  indexs={referencesIndex}
                  isOpen={showModal}
                  close={() => setShowModal(false)}
                />
              )}
              <CustomWrapper>
                <PageTitle handleShowModal={handleShowModal} />
                <PageSubtitle handleShowModal={handleShowModal} />
                <Table
                  handleShowModal={handleShowModal}
                  setActualData={setActualData}
                  isMobile={isMobile}
                />
              </CustomWrapper>

              <CustomReference linkText="Efficacy" url="/n/efficacy">
                {referenceText}
              </CustomReference>
            </Container>
          </ContainerScroll>
        </Scroll>
      </CustomContainer>
    );
  }

  // Desktop component
  return (
    <>
      <CustomContainer
        className={showPageContent ? "visible" : "hidden"}
        isiconfig={isiconfig}
      >
        {referencesIndex && (
          <ReferenceModal
            indexs={referencesIndex}
            isOpen={showModal}
            close={() => setShowModal(false)}
          />
        )}
        <CustomWrapper>
          <PageTitle handleShowModal={handleShowModal} />
          <PageSubtitle handleShowModal={handleShowModal} />

          <ChartContainer>
            <Table
              handleShowModal={handleShowModal}
              setActualData={setActualData}
              isMobile={isMobile}
            />
            <DoughnutChart actualData={actualData} />
          </ChartContainer>
        </CustomWrapper>

        <CustomReference linkText="Efficacy" url="/n/efficacy">
          {referenceText}
        </CustomReference>
      </CustomContainer>
    </>
  );
};

export default Slide3;

const Scroll = styled.div`
  height: 100%;
`;

const CustomContainer = styled(Container)`
  display: flex;
  height: 100%;
  padding: 0;
  max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;
const CustomWrapper = styled(Wrapper)`
  margin-top: 4vmin;
  @media ${device.desktop} {
    margin-top: 2rem;
  }
`;
const Title = styled.div`
  display: flex;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0 3.5rem;
  margin-bottom: 1.5rem;

  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;

const CustomReference = styled(ReferenceFooterWithLink)``;
const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  color: white;

  > div {
    text-align: center;
  }

  sup {
    font-size: 55%;
  }

  span {
    color: #17c94e;
    font-weight: bold;
  }

  @media ${device.desktop} {
    font-size: 2rem;
    max-width: 72rem;
    > div {
      font-size: 2rem;
    }
    sup {
      font-size: 55%;
    }
  }
`;

const TableContainer = styled.div`
  width: 95%;
  margin: 1rem auto 1.5rem;
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  background-color: rgba(31,36,53,0.3);
  border: 0.1rem solid white;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Oswald';
  &.is_header {
    font-size: 1.25rem;
    font-weight: 500;
    &:first-of-type {
      padding: 0.8rem 1rem;
      letter-spacing: .05rem;
    }

    &:last-of-type {
      align-items: center;
      padding: 0.8rem 1rem;
      letter-spacing: 0;
    }

    &.text-center {
        text-align: center;
    }
  }

  &:first-of-type {
    padding: .5rem 1.6rem;
  }
  &:last-of-type {
    align-items: center;
    padding: 0.8rem;
  }

  @media ${device.desktop} {
    &:first-of-type {
      font-size: 2.1rem;
      padding: 0.8rem 1.6rem;
    }

    &:last-of-type {
      font-size: 1.7rem;
      font-weight: 600;
      align-items: center;
      padding: 0.8rem;
    }
  }
s
`;

const TableItem = styled(TableHeader)`
  background: white;
  border: none;
  color: #000000;
  text-transform: initial;
  cursor: pointer;

  .extra {
    padding-left: 1rem;
    justify-content: flex-end;
  }

  &:first-of-type {
    font-family: "Barlow Condensed";
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.4rem 1.6rem;

    sup {
      font-size: 55%;
    }
  }

  &:last-of-type {
    font-family: "Oswald";
    font-size: 1rem;
    font-weight: 500;
    padding: 0.4rem 0.8rem;
    padding-left: 10px !important;
    padding-right: 10px !important;

    sup {
      font-size: 55%;
    }
  }

  &.no-space {
    padding: 0.2rem 1.6rem;
  }

  @media ${device.desktop} {
    &:first-of-type {
      font-size: 1.6rem;

      sup {
        font-size: 55%;
      }
    }

    &:last-of-type {
      font-size: 1.3rem;

      sup {
        font-size: 55%;
      }
    }
  }
`;

const TableItems = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  color: white;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  &:first-of-type {
    margin-top: 0.5rem;
  }

  &.no-space {
    margin: 0;
    margin-bottom: -1px;
  }

  @media ${device.desktop} {
    &.active {
      > div {
        background: #17c94e;
      }
    }
  }

  ${TableItem} {
    pointer-events: all;
    background: ${(props) => {
    if (props.schema === "white") return "white";
    if (props.schema === "gray") return "#DADBDF";
  }};
  }
`;
const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5rem;
  height: 52rem;
  > div {
    &:first-of-type {
      width: 50%;
      margin: initial;
      margin-right: 5rem;
    }
  }

  @media ${device.desktop} {
    align-items: center;
    > div {
      &:first-of-type {
        width: 100%;
        max-width: 56rem;
      }
    }
  }
`;
const ActiveItem = styled.div`
  position: relative;

  .active {
    position: relative;
    > div {
      &:first-of-type {
        position: absolute;
        width: calc(100% + 1rem);
        position: relative;
        clip-path: polygon(
          calc(100% - 1rem) 0%,
          100% 50%,
          calc(100% - 1rem) 100%,
          0% 100%,
          1rem 50%,
          0% 0%
        );
      }

      &:last-of-type {
        position: absolute;
        width: calc(100% + 1rem);
        position: relative;
        clip-path: polygon(
          calc(100% - 1rem) 0%,
          100% 50%,
          calc(100% - 1rem) 100%,
          0% 100%,
          1rem 50%,
          0% 0%
        );

        span {
          margin-left: -1rem;
        }
      }
    }
  }
`;

const ClickImage = styled.div`
  position: absolute;
  left: -3rem;
  top: 50%;
  transform: translateY(-50%);
  img {
    max-width: 3rem;
  }
`;
