import { useEffect } from 'react'
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

/* 
This custom hook provides what is necessary to create animations on scroll, similar to AOS.
Framer motion docs: https://www.framer.com/docs/introduction/

Usage: 
1 - Import the hook: const [control, ref] = useAnimateOnScroll()
2 - Create a motion element to use the props: 
    2.1 - Styled components: const MyElement = styled(motion.div) or <CustomElement as={motion.div}>
    2.2 - Normal HTML/jsx: <motion.div></motion.div>
3 - Using the properties: <MyElement ref={ref} animate={control} variants={myAnimation} initial="start"> </MyElement>
4 - In the variants property you can create your own animation or use the customs on styles/animation.js 

Custom components: use const MyCustom = motion(styled.section``)
*/

const useAnimateOnScroll = () => {

    const control = useAnimation()
    const [ref, inView, root] = useInView()

    useEffect(() => {
        if (inView) {
            control.start("end");
        } 
    }, [control, inView]);

    return {control, ref, inView, root}
}

export default useAnimateOnScroll