import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, BaseText, Box, Container, FlexCentered, StyledLeftSideShape, Wrapper } from '../../_components/PageSwipper/utils/styles';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import style from '../css/slide-2.module.scss';

const Slide2 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = ` linear-gradient(173deg, #50527A -2.67%, #4A4E61 22.8%, #2F314A 96.29%)`;

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(2);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <Scroll isiconfig={isiconfig}>
            <ContainerScroll>
                <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
                    <CustomWrapper>
                        <div className={style.title}>
                            KRAZATI Recommended dosage: 600&nbsp;MG&nbsp;BID<sup onClick={() => handleShowModal([30])}>30</sup>
                        </div>
                        <div className={[style.title, style.subtitle].join(' ')}>3 tablets orally | 2x daily</div>
                        <div className={style['subtitle-text']}>Until disease progression or unacceptable toxicity</div>
                        <DosesContainer>
                            <StyledLeftSideShape className='dosing-2' background='#2F2F3C' border='white' borderSize={0.1}>
                                <div className={style.inner}>
                                    <div className={style['top-section']}>
                                        <div className={style['col-3']}>
                                            <img src='/assets/icn_2x.png' width='110' height='110' alt='Krazati - adagrasib Dosing Two Times Daily' />
                                        </div>
                                        <div className={style['col-9']}>
                                            <p>
                                                <span>AM</span> Take (3) 200-mg tablets by mouth in the morning
                                            </p>
                                            <p>
                                                <span>PM</span> Take (3) 200-mg tablets by mouth at night
                                            </p>
                                        </div>
                                    </div>
                                    <div className={style.flex}>
                                        <div className={[style.left, style['col-3']].join(' ')}>
                                            <span>How Large Is One Tablet of KRAZATI?</span>
                                            <div className={style['pill-comparisons-block']}>
                                                <div>
                                                    <img src='/assets/pea.webp' alt='Krazati - adagrasib Tablet Size Compared To A Pea' />
                                                    <em>Pea 1 cm</em>
                                                </div>
                                                <div>
                                                    <img src='/assets/capsule-size.webp' alt='Krazati - adagrasib Tablet Size Compared To A Capsule' />
                                                    <em>Actual size 1.5 cm</em>
                                                </div>
                                                <div>
                                                    <img src='/assets/peanut.webp' alt='Krazati - adagrasib Tablet Size Compared To A Peanut' />
                                                    <em>Peanut 2 cm</em>
                                                </div>
                                            </div>
                                        </div>
                                        {!isMobile && <div className={[style['icon-text-blocks'], style['col-9']].join(' ')}>
                                            <div>
                                                <div className={style.image}>
                                                    <img src='/assets/icn_immediate-release.svg' alt='Krazati - adagrasib Available As Ready Release Tablets' />
                                                </div>
                                                <span>Available as immediate release tablets</span>
                                            </div>

                                            <div>
                                                <div className={style.image}>
                                                    <img src='/assets/icn_schedule-same-time.svg' alt='Take Krazati - adagrasib The Same Time Daily' />
                                                </div>
                                                <span>Advise patients to take KRAZATI at the same time every day with or without food</span>
                                            </div>

                                            <div>
                                                <div className={style.image}>
                                                    <img className='cup' src='/assets/icn_swallowed-whole.svg' alt='Krazati - adagrasib Tablets Should Be Swallowed Whole' />
                                                </div>
                                                <span>KRAZATI tablets should be swallowed whole (not chewed, crushed, or split)</span>
                                            </div>

                                            <div>
                                                <div className={style.image}>
                                                    <img src='/assets/icn_more-than-4-hours.svg' alt='Missing Krazati - adagrasib Dose By 4 Hours' />
                                                </div>
                                                <span>
                                                    If a patient misses a dose by more than 4 hours or if vomiting occurs, they should resume dosing at the next scheduled time
                                                </span>
                                            </div>
                                            <div>
                                                <div className={style.image}>
                                                    <img src='/assets/icn_no-restrictions.svg' alt='No Restrictions For Use Of Ppis Or H2-Receptor Antagonists' />
                                                </div>
                                                <span>
                                                    There are no restrictions for use of PPIs or H<sub>2</sub>-receptor antagonists in the KRAZATI Prescribing Information
                                                </span>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </StyledLeftSideShape>
                            {isMobile &&
                                <div className={[style['icon-text-blocks'], style['mob']].join(' ')}>
                                    <div>
                                        <div className={style.image}>
                                            <img src='/assets/icn_immediate-release.svg' alt='Krazati - adagrasib available as ready release tablets' />
                                        </div>
                                        <span>Available as immediate release tablets</span>
                                    </div>

                                    <div>
                                        <div className={style.image}>
                                            <img src='/assets/icn_schedule-same-time.svg' alt='Take Krazati - adagrasib the same time daily' />
                                        </div>
                                        <span>Advise patients to take KRAZATI at the same time every day with or without food</span>
                                    </div>

                                    <div>
                                        <div className={style.image}>
                                            <img className='cup' src='/assets/icn_swallowed-whole.svg' alt='Krazati - adagrasib tablets should be swallowed whole' />
                                        </div>
                                        <span>KRAZATI tablets should be swallowed whole (not chewed, crushed, or split)</span>
                                    </div>

                                    <div>
                                        <div className={style.image}>
                                            <img src='/assets/icn_more-than-4-hours.svg' alt='Missing Krazati - adagrasib dose by 4 hours' />
                                        </div>
                                        <span>
                                            If a patient misses a dose by more than 4 hours or if vomiting occurs, they should resume dosing at the next scheduled time
                                        </span>
                                    </div>
                                    <div>
                                        <div className={style.image}>
                                            <img src='/assets/icn_no-restrictions.svg' alt='No restrictions for use of PPIs or H2-receptor antagonists' />
                                        </div>
                                        <span>
                                            There are no restrictions for use of PPIs or H<sub>2</sub>-receptor antagonists in the KRAZATI Prescribing Information
                                        </span>
                                    </div>
                                </div>
                            }
                        </DosesContainer>
                    </CustomWrapper>
                    <Reference>
                        <p>BID=twice daily; PPI=proton pump inhibitor.</p>
                    </Reference>
                </CustomContainer>
            </ContainerScroll>
        </Scroll>
    );
};

export default Slide2;

const Scroll = styled.div`
    height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomContainer = styled(Container)`
    justify-content: space-between;
    display: flex;
    height: 100%;
    padding: 0 0 1rem;

	@media ${device.desktop} {
		padding: 0;
		min-height: ${(props) => `calc(100vh - 11rem - ${props.isiconfig})`};
	}
`;
const CustomWrapper = styled(Wrapper)`
    padding-bottom: 1rem;
    justify-content: space-between;
    height: 100%;
    padding-top: 1.2rem;
	margin: 0;

    @media ${device.desktop} {
        padding-top: 3rem;
        height: auto;
        max-height: 100%;
    }
`;
export const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 0;
    text-transform: uppercase;
    padding: 0 5rem;
`;
export const Title1 = styled(BaseSubtitle)`
    margin-bottom: 0.2rem;

    @media ${device.desktop} {
        font-size: 3.2rem;
        margin-bottom: 0.5rem;
    }
`;
export const Title2 = styled(BaseSubtitle)`
margin-bottom: 0;

    @media ${device.desktop} {
        font-size: 2.2rem;
        margin-bottom: 0.3rem;
    }
`;
const SubTitle = styled(BaseText)`
    font-family: 'Barlow';
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0 1.6rem;
    margin-bottom: 1.2rem;

    @media ${device.tablet} {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 1.8rem;
    }
`;

const DosesContainer = styled(FlexCentered)`
    padding: 0 0.8rem;
    width: 100%;
    flex-direction: column;
    > div {
        margin-bottom: 0.8rem;
    }

    .shape-rect {
        background: linear-gradient(167deg, #5a566a -40%, #3e3e56);
        padding: 1.4rem 5.4rem 2rem 5.4rem;
        border: 0.1rem solid white;
        width: 100%;
    }

    @media ${device.tablet} {
			.dosing-2 {
					max-width: 80%;
			}
    }

    @media ${device.desktop} {
        max-width: 88rem;
        margin-bottom: 3rem;

        .dosing-2 {
            // max-width: 760px;
            max-width: 100%;
        }

        .wrapper-2 {
            display: flex;
            margin-top: 1.8rem;
            margin-bottom: 0;
        }
    }
`;
const CapsuleContainer = styled(FlexCentered)`
    flex-direction: column;
    // border-right: 0.1rem solid white;
    // padding-right: 11%;
    margin: auto;

    &:last-of-type {
        border-right: none;
        // padding-right: 0;
        // padding-left: 11%;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: calc(100% - 3.2rem);
            width: 0.1rem;
            background-color: white;
        }
    }
`;
const CapsuleWrapper = styled.div`
    display: flex;
    margin-bottom: 0.8rem;
    img {
        margin-right: 0.4rem;
    }
`;

const ImgWrapper = styled.div`
    display: flex;
    font-family: 'Oswald';
    span {
        font-size: 3.2rem;
        font-weight: 300;
        margin-left: 0.8rem;

        &.sun {
            color: #fff66d;
        }
        &.moon {
            color: #dddddd;
        }
    }
    img {
        width: 100%;
        position: relative;
        top: 0.1rem;
    }

    @media ${device.desktop} {
        span {
            font-size: 4rem;
            font-weight: 500;
        }
    }
`;

const CapsuleText = styled(BaseText)`
    font-size: 1.2rem;
    font-size: 1.2rem;
    font-weight: 400;
    @media ${device.desktop} {
        font-size: 2rem;
    }
`;

const ComparisonWrapper = styled(FlexCentered)`
    flex-direction: column;
    width: 100%;

    @media ${device.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const ComparisonTitle = styled(BaseText)`
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 2.1rem;

    @media ${device.desktop} {
        font-size: 1.6rem;
        margin-bottom: 0;
        max-width: 16.4rem;

        &:first-of-type {
            margin-right: 5.8rem;
        }
    }
`;

const Comparison = styled(FlexCentered)`
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @media ${device.desktop} {
        padding-right: 5rem;
    }
`;

const ComparisonItem = styled(FlexCentered)`
    flex-direction: column;
`;
const ComparisonItemText = styled(ComparisonTitle)`
    font-style: italic;
    margin-bottom: 0;
    font-weight: 400;
`;
const ComparisonItemImage = styled.div`
    margin-bottom: 0.4rem;
    img {
        width: 100%;
    }
`;

const IconsContainer = styled.div`
    font-family: 'Barlow';
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    width: 100%;
    padding: 0 1rem;
    margin-top: 2rem;
    > div {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
    }

    .image {
        width: 4.5rem;
        min-width: 4.5rem;
        margin-right: 1rem;

        display: flex;
        justify-content: center;
    }
    img {
        width: 4.5rem;

        &.cup {
            width: 3.6rem;
        }
    }
    span {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: left;
    }

    @media ${device.tablet} {
    }

    @media ${device.desktop} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 3rem;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        > div {
            margin-bottom: 2.8rem;
        }
        img {
            max-width: 5.5rem;
            margin-right: 2.7rem;
        }
        span {
            font-size: 1.7rem;
            font-weight: 500;
            text-align: left;
            width: 32rem;
        }
    }
`;

const ListContainer = styled.div`
    padding: 0 1rem;

    li {
        display: flex;
    }

    @media ${device.tablet} {
        ul {
            max-width: 80%;
        }
    }

    @media ${device.desktop} {
        ul {
            max-width: 100%;

            li {
                font-size: 1.7rem;
                margin-bottom: 0.5rem;
                line-height: 2.4rem;
            }
        }
    }
`;
