import styles from './ExternalLink.module.scss'
import * as Dialog from '@radix-ui/react-dialog'

export default function ExternalLink({ link }) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className={styles.link} href={link.url}>{link.text}</button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.dialogOverlay} />
        <Dialog.Content className={styles.dialogContent}>
          <Dialog.Close className={styles.dialogClose}>
            <img src="/assets/icons/icon-indication-close-button.svg" alt="Close references modal" />
          </Dialog.Close>
          <div className={styles.content}>
            <h2>You are leaving the Krazati® site</h2>
            <div>
              <a href={link.url} target="_blank" rel="noreferrer">Continue</a>
              <img src="/assets/icons/icon-arrow-right-blue.svg" alt="" />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
