import styles from './StyledHeading.module.scss'
export default function StyledHeading({ title, center = false }) {
  if (center) {
    center = { 'flex': '1 0 auto', 'textAlign': 'center' }
  } else {
    center = {}
  }
  return (
    <div className={styles.styledHeading}>
      <img src="/assets/icons/icon-styled-heading-arrow.svg" alt="" />
      <h3 style={center}>{title}</h3>
    </div>
  )
}
