import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useSwiperSlide } from "swiper/react";
import {
  BaseLink,
  BaseText,
  BaseTitle,
  Box,
  Container,
  Wrapper,
} from "../../_components/PageSwipper/utils/styles";
import useActiveLink from "../../_components/Header/hooks/useActiveLink";
import { device } from "../../_assets/styles/theme";
import useMobileDetect from "../../../_hooks/useMobileDetect";
import { FaChevronRight } from "react-icons/fa";
import { getToken, sendEmail } from "../../../api";
import { Card } from "../../Home/pages/Slide4";
import { Link } from "react-router-dom";

const Slide1 = ({
  setPageBackground,
  setShowHeaderSecondaryMenu,
  setCurrentSlide,
  isiconfig,
  setBackground,
  setOverlay,
}) => {
  const swiperSlide = useSwiperSlide();
  const { isMobile } = useMobileDetect();
  const mobileBgWrapper = useRef();
  const mobileOverlay =
    "linear-gradient( rgba(80, 82, 122, 0) -2.67%, rgba(74, 78, 97, 0.433) 22.8%, rgba(47, 49, 74, 1) 96.29%)";
  const desktopOverlay =
    "linear-gradient( rgba(80, 82, 122, 0.2154) -2.67%, rgba(74, 78, 97, 0.2154) 22.8%, rgba(47, 49, 74, 0.2154) 96.29%)";
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
  const [showPageContent, setShowPageContent] = useState(false);
  const slideBackground = `url(${
    isMobile
      ? "/assets/stay-connected-bg-m.png"
      : "/assets/stay-connected-bg.png"
  }) no-repeat fixed center top / cover`;
  const swiper = document.querySelector(".mySwiper");
  useActiveLink(setShowHeaderSecondaryMenu, "contact-us");

  // useEffect(()=> {
  // getToken()
  // sendEmail()
  // }, [])

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true);
      setCurrentSlide(1);
      setShowPageContent(true);
      setBackground(slideBackground);
      setOverlay(slideOverlay);
      swiper.swiper.disable();
      return;
    }
    setShowPageContent(false);
  }, [swiperSlide.isActive]);

  return (
    <CustomContainer
      className={showPageContent ? "visible" : "hidden"}
      ref={isMobile ? mobileBgWrapper : null}
      isiconfig={isiconfig}
    >
      <CustomWrapper>
        <Title>
          <BaseTitle padding="0 20px" size={isMobile ? 4 : 4.7}>
            How can we help you?
          </BaseTitle>
        </Title>

        <CardContainer direction="column" padding="0 0.8rem;">
          <CardWrapper
            background="rgba(34, 40, 62, .5)"
            margin="0 0 0.8rem"
            padding="1.8rem 2rem"
          >
            <Box className="card-wrapper" align="center">
              <img
                src="/assets/icn_medical-information.png"
                alt="Mirati Medical Information"
              />
              <Button
                target="_blank"
                href="https://www.miratimedinfo.com/us/s/"
              >
                Medical Information <FaChevronRight />{" "}
              </Button>
            </Box>
          </CardWrapper>
        </CardContainer>

        <Box
          justify="center"
          height="auto"
          align="center"
          padding="0 0.8rem"
          margin="1rem 0 2rem"
        >
          <BaseText text-align="center" size={isMobile ? 1.8 : 2.5}>
            To report an adverse event or product complaint,{" "}
            <BaseLink
              style={{ textDecoration: "underline" }}
              href="https://MiratiDrugSafety.com/HCPForm"
              target="_blank"
              size={isMobile ? 1.8 : 2.5}
              color="#17C94E"
              weight="bold"
            >
              click here
            </BaseLink>
            .
          </BaseText>
        </Box>
        <Box
          justify="center"
          height="auto"
          width={isMobile ? "32rem" : "70rem"}
          align="center"
          padding="0 0.8rem"
          margin="0 0 9.4rem"
        >
          <BaseText text-align="center" size={isMobile ? 1.8 : 2.5}>
            Alternatively, you may call{" "}
            <BaseLink
              href="tel:1.844.647.2841"
              size={isMobile ? 1.8 : 2.5}
              weight="bold"
            >
              {" "}
              1.844.647.2841
            </BaseLink>{" "}
            to speak with a Medical Information Specialist.
          </BaseText>
        </Box>
      </CustomWrapper>
    </CustomContainer>
  );
};

export default Slide1;

const CustomContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
  [class^="styles__BaseText"] {
    font-weight: 700;
  }
`;

const CustomWrapper = styled(Wrapper)`
  margin-top: 2rem;
  justify-content: center;

  img {
    width: fit-content;
    max-width: 4.4rem;
  }

  @media ${device.desktop} {
    margin-top: 4rem;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  margin: 3rem 5rem 1.6rem;
  padding: 20px 3rem 20px;

  @media ${device.desktop} {
    margin: 0 0 6rem;
  }
  @media (min-width: 1100px) and (max-height: 900px) {
    padding-top: 80px;
    margin-bottom: 3rem;
  } 
}
`;
const Button = styled(BaseLink)`
  padding: 0.8rem 1.6rem 0.8rem 1rem;
  background: #3246ff;
  color: white;
  border-radius: 2rem;
  font-size: 1.4rem;
  width: fit-content;
  height: auto;
  min-width: 18.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 1.5rem;
  svg {
    position: absolute;
    right: 1.6rem;
    transition: all 0.2s linear;
  }

  &:hover {
    svg {
      right: 1.2rem;
    }
  }

  @media ${device.desktop} {
    font-size: 1.1rem;
    margin-left: 0;
    min-width: 22rem;
    padding: 0.8rem 1.6rem;
  }
`;

const CardContainer = styled(Box)`
  font-size: 16px !important;
  @media ${device.tablet} {
    max-width: 60%;
  }

  @media ${device.laptop} {
    max-width: 50%;
  }

  @media ${device.desktop} {
    max-width: 94rem;
    margin-bottom: 7rem;
    flex-direction: row;
  }
`;

const CardWrapper = styled(Card)`
  height: 8rem;
  &:nth-of-type(1) {
    img {
      max-width: 6.5rem;
    }
  }

  &:nth-of-type(2) {
    img {
      max-width: 6.5rem;
    }
  }

  &:nth-of-type(3) {
    padding: 2.6rem 2rem 2.6rem 3.6rem;
    img {
      max-width: 3.2rem;
    }
  }
  @media ${device.desktop} {
    padding: 3.6rem;
    > div {
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      a {
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 1.6rem;
      }

      img {
        max-width: 8rem;
        width: 8rem;
      }
    }

    &:nth-of-type(1) {
      img {
        max-width: 8rem;
      }
    }

    &:nth-of-type(2) {
      img {
        max-width: 8rem;
      }
    }

    &:nth-of-type(3) {
      padding: 2.6rem 2rem 2.6rem 3.6rem;
      img {
        max-width: 6.4rem;
      }
    }
  }

  .card-wrapper {
    justify-content: center;

    img {
      max-width: 6.5rem;
      width: 6.5rem;

      @media ${device.desktop} {
        max-width: 8rem;
        width: fit-content;
      }
    }

    @media ${device.tablet} {
      justify-content: space-evenly;
    }

    @media ${device.desktop} {
      justify-content: space-between;
    }
  }
`;
