import React, { useEffect, useRef, useState }  from 'react'
import styled from 'styled-components/macro';
import {  useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import VideoContainerHome from '../components/VideoContainerHome';
import { device } from '../../_assets/styles/theme';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import Reference from '../../_components/Reference';

const VideoLegend = () => {
    return (
        <Reference>
            <p>NSCLC=non small cell lung cancer.</p>
        </Reference>
    );
};

const Slide3 = ({setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig,setPageGradient, setBackground, setOverlay}) => {
  const swiperSlide = useSwiperSlide();
  const containerRef = useRef()
  const { isMobile } = useMobileDetect()
  const [showPageContent, setShowPageContent] = useState(false);
  const desktopBackground = `url('/assets/home-hero-alternate-crop.png') no-repeat fixed 0 center / cover`;
  const mobileBackground = `url('/assets/home-video-bg-m.png') no-repeat center / cover`;
  const slideBackground = isMobile ? mobileBackground : desktopBackground;
  const mobileOverlay = `linear-gradient(#6f81a9, rgba(12, 22, 63, 0.6));`;
  const desktopOverlay = `linear-gradient(0deg, rgba(12, 22, 63, 0.272) 0%, rgba(12, 22, 63, 0.272)100%), linear-gradient(173deg, rgba(173, 192, 214, 0.272) 1.36%, rgba(148, 142, 160, 0.272) 41.06%, rgba(115, 115, 138, 0.272) 78.52%, rgba(102, 105, 134, 0.272) 113.27%);`;
  const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setPageGradient(null)
      setShowPageContent(true)
      setCurrentSlide(3)
      setBackground(slideBackground)
      setOverlay(slideOverlay)
      return
    }
    setShowPageContent(false)

  }, [swiperSlide.isActive])


  return (
	<Scroll isiconfig={isiconfig}>
		<ContainerScroll>
			<Container className={showPageContent ? 'visible' : 'hidden'} ref={containerRef}>
			<CustomWrapper isiconfig={isiconfig}>
				{!isMobile && <Title>PEER PERSPECTIVES ON KRAZATI FOR<br/> <em>KRAS G12C</em>-MUTATED ADVANCED NSCLC</Title>}
				<VideoContainerHome />
        <div style={{ width: '100%' }} className="reference-container">
          <VideoLegend />
        </div>
			</CustomWrapper>
			</Container>
		</ContainerScroll>
	</Scroll>
  )
}

export default Slide3

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;

const CustomWrapper = styled(Wrapper) `
  position: relative;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};
  @media ${device.desktop} {
    .reference-container {
      margin-top: 3rem;
    }
  }
`

export const Title = styled(BaseSubtitle)`
  color: white;
  font-size: min(1.6rem, 2.4rem);
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: 2.4rem;
    margin-bottom: 0;
  }

  @media ${device.desktop} {
    font-size: 3rem;
    margin-bottom: 2rem;
    margin-top: -4rem;
  }
`
