import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import ISICollapsing from '../_components/ISI/Collapsing';

import { isiConfig } from '../_components/ISI/config';
import { Page } from '../_components/PageSwipper/utils/styles';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import useMobileDetect from '../../_hooks/useMobileDetect';
import StaticIsi from '../_components/ISI/StaticIsi';
import Footer from '../_components/Footer';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import { PageOverlay, StaticContentWrapper } from '../Home';
import ReferenceModal from '../_components/Modal/ReferenceModal'
import MetaTags from '../_components/MetaTags/MetaTags'

const About = ({ setPageBackground, page = 1 }) => {
	const { isMobile } = useMobileDetect()
	const [headerRef, setHeaderRef] = useState(null)
	const [headerHeight, setHeaderHeight] = useState(0)
	const [renderPage, setRenderPage] = useState(false)
	const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
	const [background, setBackground] = useState(`url('/assets/bg-about.webp') no-repeat fixed center top / cover`)
	const [currentSlide, setCurrentSlide] = useState(1)
	const [overlay, setOverlay] = useState(null)
	const overlayRef = useRef()
	const [referencesIndex, setReferencesIndex] = useState(null)
	const [showModal, setShowModal] = useState(false)

	const handleShowModal = (indexs) => {
		if (indexs) {
			setReferencesIndex(indexs)
		}
	}

	useEffect(() => {
		if (referencesIndex) {
			setShowModal(true)
		}
	}, [referencesIndex])

	useEffect(() => {
		if (headerRef?.current) {
			setHeaderHeight(headerRef?.current.clientHeight)
			setRenderPage(true)
		}
	}, [headerRef?.current])

	useEffect(() => {
		overlayRef.current.classList.remove('visible')
		overlayRef.current.classList.add('hidden')

		const time = setTimeout(() => {
			overlayRef.current.classList.add('visible')
			overlayRef.current.classList.remove('hidden')
		}, 100)

		return () => {
			clearTimeout(time)
		}
	}, [currentSlide])

	return (
		<>
			<MetaTags
				title='KRAZATI® (adagrasib) | Common Driver Mutation'
				description='Learn about the common driver mutation and three reasons why advanced treatment may be appropriate.'
				ogUrl='https://www.krazatihcp.com/n/challenging-mutation'
			/>
			<Page background={background}>
				{referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
				<PageOverlay ref={overlayRef} className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
				<PageScroll>
					<Container>
						<Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
						<div>
							{renderPage && (
								<PageSwipper nested={isMobile ? false : true} setPageBackground={setPageBackground} headerHeight={headerHeight}>
									<Slide1
										isiconfig={isiConfig.collapsed}
										page={page} setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
										handleShowModal={handleShowModal}
									/>
									<Slide2
										isiconfig={isiConfig.floating}
										page={page} setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setBackground={setBackground}
										setOverlay={setOverlay}
									/>
								</PageSwipper>
							)}
							{!isMobile && currentSlide === 1 && <ISICollapsing className="visible" />}
							{!isMobile && currentSlide === 2 && <ISICollapsing className="floatingWithIsi collapse" />}
							{isMobile && <FloatingIsi className={currentSlide === 2 ? 'collapse' : 'visible'} />}
						</div>
					</Container>
					{!isMobile && currentSlide === 2 &&
						<StaticContentWrapper>
							<StaticIsi />
							<Footer translate={true}></Footer>
						</StaticContentWrapper>
					}
				</PageScroll>
			</Page>
		</>
	)
}

export default About

const Container = styled.div`
  height: auto;
  max-height: 100%;
  position: relative;
`
