import React from 'react';
import styled from 'styled-components/macro';
import { device } from '../../_assets/styles/theme';
import { BaseText, Box } from '../../_components/PageSwipper/utils/styles';

const ArrowCallout = ({ margin, children, className }) => {
    return (
        <Box className={className} width='auto' height='fit-content' margin={margin}>
            <div style={{ filter: 'drop-shadow(0 3px 3px rgba(0, 0, 0, 0.6))' }}>
                <ArrowShape>{children}</ArrowShape>
            </div>
        </Box>
    );
};

export default ArrowCallout;

const ArrowShape = styled.div`
    padding: 10px 16px;
    background: linear-gradient(90deg, rgba(31, 36, 53, 0.6), rgba(58, 66, 89, 0.7));

    @media ${device.desktop} {
        clip-path: polygon(0 0, calc(100% - 40px) 0, 100% 50%, calc(100% - 40px) 100%, 0 100%);
        width: 100%;
        padding: 18px 45px 20px 20px;
    }
`;
