import { createGlobalStyle } from "styled-components";

// QR Fonts
import GilroySemiboldWoff from "../fonts/Gilroy-SemiBold.woff";
import GilroySemiboldWoff2 from "../fonts/Gilroy-SemiBold.woff2";
import GilroyExtraboldWoff from "../fonts/Gilroy-ExtraBold.woff";
import GilroyExtraboldWoff2 from "../fonts/Gilroy-ExtraBold.woff2";
import BarlowWoff from "../fonts/BarlowCondensed-SemiBold.woff";
import BarlowWoff2 from "../fonts/BarlowCondensed-SemiBold.woff2";
import BonVivantSerifBoldWoff from "../fonts/BonVivantSerifBold.woff";
import BonVivantSerifBoldWoff2 from "../fonts/BonVivantSerifBold.woff2";
import ProximaNovaRgWoff from "../fonts/ProximaNova-Bold.woff";
import ProximaNovaRgWoff2 from "../fonts/ProximaNova-Bold.woff2";
import ProximaNovaMediumWoff from "../fonts/ProximaNova-Medium.woff";
import ProximaNovaMediumWoff2 from "../fonts/ProximaNova-Medium.woff2";
import ProximaNovaRegularIWoff from "../fonts/ProximaNova-RegularIt.woff";
import ProximaNovaRegularIWoff2 from "../fonts/ProximaNova-RegularIt.woff2";
import ProximaNovaRegularWoff from "../fonts/ProximaNova-Regular.woff";
import ProximaNovaRegularWoff2 from "../fonts/ProximaNova-Regular.woff2";
import { device } from "../../../HCP/_assets/styles/theme";

const getScreenheight = () => {
  return window.innerHeight - 1;
};

export default createGlobalStyle`
    @font-face {
        font-family: 'Avenir LT Std';
        src: url('/fonts-hcp/AvenirLTStd-Black.woff2') format('woff2'),
            url('/fonts-hcp/AvenirLTStd-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Avenir LT Std';
        src: url('/fonts-hcp/AvenirLTStd-Book.woff2') format('woff2'),
            url('/fonts-hcp/AvenirLTStd-Book.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-Regular.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-Italic.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-Italic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-Medium.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-MediumItalic.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-MediumItalic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-SemiBold.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-SemiBoldItalic.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-SemiBoldItalic.woff') format('woff');
        font-weight: 600;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-Bold.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow';
        src: url('/fonts-hcp/Barlow-BoldItalic.woff2') format('woff2'),
            url('/fonts-hcp/Barlow-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts-hcp/BarlowCondensed-Medium.woff2') format('woff2'),
            url('/fonts-hcp/BarlowCondensed-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url(${BarlowWoff2}) format('woff2'),
        url(${BarlowWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts-hcp/BarlowCondensed-SemiBold.woff2') format('woff2'),
            url('/fonts-hcp/BarlowCondensed-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts-hcp/BarlowCondensed-Bold.woff2') format('woff2'),
            url('/fonts-hcp/BarlowCondensed-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow Semi Condensed';
        src: url('/fonts-hcp/BarlowSemiCondensed-SemiBold.woff2') format('woff2'),
            url('/fonts-hcp/BarlowSemiCondensed-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Barlow Semi Condensed';
        src: url('/fonts-hcp/BarlowSemiCondensed-Bold.woff2') format('woff2'),
            url('/fonts-hcp/BarlowSemiCondensed-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Bon Vivant';
        src: url(${BonVivantSerifBoldWoff2}) format('woff2'),
        url(${BonVivantSerifBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Gilroy';
        src: url(${GilroySemiboldWoff}) format('woff2'),
        url(${GilroySemiboldWoff2}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Gilroy';
        src: url(${GilroyExtraboldWoff2}) format('woff2'),
        url(${GilroyExtraboldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Gilroy';
        src: url('/fonts-hcp/Gilroy-Bold.woff2') format('woff2'),
            url('/fonts-hcp/Gilroy-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Oswald';
        src: url('/fonts-hcp/Oswald-ExtraLight.woff') format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Oswald';
        src: url('/fonts-hcp/Oswald-Regular.woff2') format('woff2'),
            url('/fonts-hcp/Oswald-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Oswald';
        src: url('/fonts-hcp/Oswald-RegularItalic.woff2') format('woff2'),
            url('/fonts-hcp/Oswald-RegularItalic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Oswald';
        src: url('/fonts-hcp/Oswald-DemiBold.woff2') format('woff2'),
            url('/fonts-hcp/Oswald-DemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Oswald';
        src: url('/fonts-hcp/Oswald-Demi-BoldItalic.woff2') format('woff2'),
            url('/fonts-hcp/Oswald-Demi-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaRegularIWoff2}) format('woff2'),
        url(${ProximaNovaRegularIWoff}) format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: block;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaMediumWoff2}) format('woff2'),
        url(${ProximaNovaMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Proxima Nova';
        src: url(${ProximaNovaRgWoff2}) format('woff2'),
        url(${ProximaNovaRgWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Proxima Nova Rg';
        src: url(${ProximaNovaRegularWoff2}) format('woff2'),
        url(${ProximaNovaRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'Univers';
        src: url('/fonts-hcp/Univers-UltraCondensed.woff2') format('woff2'),
            url('/fonts-hcp/Univers-UltraCondensed.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    :root {
        --screen-height: ${getScreenheight()}px;
        --collapsed-isi: 8.2rem;
        --floating-isi: 4rem;
		    position: relative;

        @media ${device.tablet} {
            --collapsed-isi: 11.89rem;
            --floating-isi: 65px;
        }

        @media ${device.desktop} {
            --collapsed-isi: 13.6rem;
            --floating-isi: 4.7rem;
        }

    }

    html {
        overflow: hidden;
        font-size: calc(10 * 0.3125vmin);

        @media (max-height: 650px) and (max-width: 374px) {
            font-size: calc(10 * 0.26vmin);
        }

        @media  (max-width: 374px) and (min-height: 650px) and (max-height: 750px) {
            font-size: calc(10 * 0.30vmin);
        }

        @media ${device.iphoneSmallHeight} {
            font-size: calc(10 * 0.25vmin);
        }

        @media ${device.iphoneSe} {
            font-size: calc(10 * 0.255vmin);
        }

        @media ${device.mobileBg} {
            font-size: calc(10 * 0.24vmin);
        }

        @media ${device.mobileBg} {
            font-size: calc(10 * 0.221875vmin);
        }

        @media ${device.tablet} {
            font-size: calc(10 * 0.13125vmin);
        }

        @media ${device.laptop} {
            font-size: calc(10 * 0.1225vmin);
        }

        @media ${device.desktop} {
            font-size: calc(10 * 0.11375vmin);
        }
    }

	body:not(.isi-expanded) #isi-truncate-line {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	body.isi-expanded #isi-truncate-line {
		margin: 4px 0 10px
	}

  body.indication-open {
    [class^="FloatingIsi__Content"] {
      max-height: 0;
    }
  }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

    }

    a {
        text-decoration: none;
    }

    img {
        width: 100%;
    }

    .custom-icon {
        width:20px;
        height:20px;
    }

    .custom-icon.down {
        transform: rotate(180deg);
    }

    .study-3 {
        #static-content {
            @media ${device.thinkpad} {
                margin-top: 20px;
                box-shadow: 0px 3px 3px 3px #000;
            }
        }
    }

    sup {
        cursor: pointer;
        pointer-events: all;
        line-height: 0;
        padding: 20px;
        margin: -20px;
        font-size: 75%;
        //vertical-align: super;
        // position: relative;
        // bottom: -0.05em;

        &.no-cursor {
            cursor: initial;
        }
        span.dagger {
          font-size: 80%;
          position: relative;
          top: -0.2em;
        }
    }

    #svg-sup-27 {
        cursor: pointer;
        pointer-events: all;
    }

    .tablet-only {
        display: none;
        width: 100%;
        @media ${device.tablet} {
        display: initial;
        }
        @media ${device.laptop} {
        display: none;
        }
    }

    .laptop-only {
        display: none;
        width: 100%;
        @media ${device.laptop} {
            display: initial;
        }
    }

    .text-initial {
        text-transform: initial;
    }
`;
