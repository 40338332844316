import React, { useEffect } from 'react'

const useActiveLink = (setShowHeaderSecondaryMenu, currentpage, isActive = true) => {
  useEffect(()=> {
    const page = `[data-link*="${currentpage}"]`
    const element = document.querySelector(page)

    if(isActive) {
      element?.classList.add('link-active')
      setShowHeaderSecondaryMenu(true)

      return
    }

    element?.classList.remove('link-active')
  
    // document.querySelector('./about')?.classList.remove('active')
  }, [])

 
}

export default useActiveLink