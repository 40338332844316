import { Link } from "react-router-dom"
import CRCLayout from "./layouts/CRCLayout"
import Container from "../_common/components/Layout/Container"
import MainContent from "../_common/components/Layout/MainContent"

import styles from './CRCHome.module.scss'
import StyledHeading from "./components/StyledHeading"
import CardLink from "./components/CardLink"
import ContentContainer from "../_common/components/Layout/ContentContainer"
import RefModal from "./components/RefModal"

import MetaTags from "../_common/components/MetaTags"

export default function CRCHome() {
  return (
    <CRCLayout>

      <MetaTags
        title='Information for Healthcare Providers'
        description='Welcome to our information center for healthcare providers. Gain understanding regarding treatment options and support resources.'
        ogUrl='https://www.krazatihcp.com/c'
      />

      {/* Banner */}
      <div className={styles.homebanner}>
        <span className={styles.homebannerApproved}>NOW APPROVED FOR ADVANCED CRC</span>
        <Container>
          <h1>Built for the <br className={styles.homebannerBreak} />challenge of KRAS G12C</h1>
          <div className={styles.homebannerCallout}>
            <img src="/assets/icons/icon-banner-arrow.svg" alt="" aria-hidden="true" />
            <span>NOW APPROVED FOR ADVANCED CRC</span>
          </div>
        </Container>
      </div>

      {/* What brings you here */}
      <div className={styles.bringsYouHere}>
        <ContentContainer>
          <div className={styles.bringsYouHereHeading}>
            <span>What brings you here today?</span>
            <img src="/assets/icons/icon-banner-arrow.svg" alt="" aria-hidden="true" />
          </div>
          <div className={styles.bringsYouHereLinks}>
            <div className={styles.bringsYouHereLinksMobile}>
              <Link to="/c/challenging-mutation">More about <i>KRAS G12C</i></Link>
              <Link to="/c/dosage">Prescribing KRAZATI</Link>
              {/* <a href="/c/efficacy#os">Updated KRAZATI OS data</a> */}
            </div>
            <div className={styles.bringsYouHereLinksDesktop}>
              <Link to="/c/challenging-mutation">I want to learn about <i>KRAS G12C</i></Link>
              <Link to="/c/dosage">I'm currently prescribing KRAZATI</Link>
              {/* <a href="/c/efficacy#os">I'm looking for KRAZATI OS data</a> */}
            </div>
          </div>
        </ContentContainer>
      </div>

      <MainContent>
        {/* Targeted theraphy */}
        <div className={styles.targetedTheraphy}>
          {/* <Container> */}
          <ContentContainer>
            <h2>KRAZATI offers a chance for response in previously treated advanced CRC<RefModal references={[1]} /></h2>

            <div className={styles.styledHeadingMobile}>
              <Link to="/c/efficacy">See clinical trial efficacy<img className={styles.iconArrowBlue} src="/assets/icons/icon-arrow-right-blue.svg" alt="" /></Link>
              <StyledHeading title="Category 2A NCCN recommended" />
              <p><strong>Advanced CRC</strong></p>
              <p>Adagrasib (KRAZATI) is NCCN Category 2A recommended in the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®): colon and rectal cancers.<RefModal references={[2, 3]} /></p>
            </div>
            <div className={styles.styledHeadingDesktop}>
              <div className={styles.styledHeadingDesktopWrapper}>
                <div>
                  <img src="/assets/icons/icon-styled-heading-arrow.svg" alt="" />
                  <h3>Category 2A NCCN recommended</h3>
                </div>
                <div>
                  <p><strong>Advanced CRC</strong></p>
                  <p>Adagrasib (KRAZATI) is an NCCN Category 2A recommendation in the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®): colon and rectal cancers.<RefModal references={[2, 3]} /></p>
                </div>
              </div>
              <a href="/c/efficacy">See clinical trial efficacy<img className={styles.iconArrowBlue} src="/assets/icons/icon-arrow-right-blue.svg" alt="" /></a>
            </div>
          </ContentContainer>
          {/* </Container> */}
        </div>

        {/* Dive deeper */}
        <div className={styles.diveDeeper}>
          <ContentContainer>
            <h2>Dive deeper into KRAZATI</h2>
            <CardLink title={["The challenge<br /> of <i>KRAS G12C</i>"]} image="/assets/cards/card-link-about-kras.jpg" desktopImage="/assets/cards/card-link-about-kras-desktop.jpg" text="About <i>KRAS G12C</i>" link="/c/challenging-mutation" />
            <CardLink title="Intentionally designed to meet the challenge of KRAS G12C" image="/assets/cards/card-link-kras-prop.jpg" desktopImage="/assets/cards/card-link-kras-prop-desktop.jpg" text="KRAZATI properties" link="/c/challenging-mutation#krazati-properties" />
            <CardLink title="600-mg <br /> twice-daily <br />oral dosing" image="/assets/cards/card-link-dosing.jpg" desktopImage="/assets/cards/card-link-dosing-desktop.jpg" text="Get dosing details" link="/c/dosage" />
            <p><small>CRC=colorectal cancer; NCCN=National Comprehensive Cancer Network; OS=overall survival.</small></p>
          </ContentContainer>
        </div>
      </MainContent>

    </CRCLayout>
  )
}
