import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import Header from '../_components/Header';
import { Helmet } from 'react-helmet-async';
import PageSwipper from '../_components/PageSwipper';
import { useState } from 'react';
import Slide1 from './pages/Slide1';
import Slide2 from './pages/Slide2';
import ISICollapsing from '../_components/ISI/Collapsing';
import useMobileDetect from '../../_hooks/useMobileDetect';
import FloatingIsi from '../_components/ISI/components/FloatingIsi';
import { isiConfig } from '../_components/ISI/config';
import { Page } from '../_components/PageSwipper/utils/styles';
import PageScroll from '../_components/PageSwipper/components/PageScroll';
import { PageOverlay } from '../Home';
import StaticIsi from '../_components/ISI/StaticIsi';
import Footer from '../_components/Footer';
import MetaTags from '../_components/MetaTags/MetaTags';

const MOA = ({ setPageBackground }) => {
	const { isMobile } = useMobileDetect()
	const [headerRef, setHeaderRef] = useState(null)
	const [headerHeight, setHeaderHeight] = useState(0)
	const [renderPage, setRenderPage] = useState(false)
	const [showHeaderSecondaryMenu, setShowHeaderSecondaryMenu] = useState(true)
	const [currentSlide, setCurrentSlide] = useState(1)
	const [background, setBackground] = useState(`url(${isMobile ? '#FFF' : '/assets/bg-about.webp'}) no-repeat fixed center top / cover`)
	const [overlay, setOverlay] = useState(null)

	useEffect(() => {
		if (headerRef?.current) {
			setHeaderHeight(headerRef?.current.clientHeight)
			setRenderPage(true)
		}
	}, [headerRef?.current])

	return (
		<>
			<MetaTags
				title='KRAZATI® (adagrasib) | Meet the Challenge'
				description='KRAZATI® (adagrasib) is intentionally designed to challenge a common driver mutation. Explore its mechanism of action with an interactive model.'
				ogUrl='https://www.krazatihcp.com/n/MOA'
			/>

			<Page background={background} style={{ transition: 'background .5s' }}>
				<PageOverlay className={overlay ? 'visible' : 'hidden'} overlay={overlay} />
				<PageScroll>
					<Container>

						<Header showHeaderSecondaryMenu={showHeaderSecondaryMenu} setHeaderRef={setHeaderRef} currentSlide={currentSlide} />
						<div>

							{renderPage && (
								<PageSwipper setPageBackground={setPageBackground} headerHeight={headerHeight}>
									<Slide1
										setBackground={setBackground}
										isiconfig={isiConfig.collapsed}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setOverlay={setOverlay}
									/>
									<Slide2
										setBackground={setBackground}
										isiconfig={isiConfig.floating}
										setCurrentSlide={setCurrentSlide}
										setShowHeaderSecondaryMenu={setShowHeaderSecondaryMenu}
										setOverlay={setOverlay}
									/>
								</PageSwipper>
							)}

							{!isMobile && currentSlide === 1 && <ISICollapsing className="visible" />}
							{!isMobile && currentSlide === 2 && <ISICollapsing className="floatingWithIsi collapse" />}

							{isMobile && <FloatingIsi className={currentSlide === 2 ? 'collapse' : 'visible'} />}
						</div>
					</Container>

					{!isMobile && currentSlide === 2 &&
						<StaticContentWrapper>
							<StaticIsi />
							<Footer translate={true}></Footer>
						</StaticContentWrapper>
					}
				</PageScroll>
			</Page>
		</>
	)
}

export default MOA

const Container = styled.div`
  max-height: var(--screen-height);
  position: relative;
`
const StaticContentWrapper = styled.div`
  position: relative;
  height: auto;
  z-index: 999;
`
