import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import ReferenceModal from '../../_components/Modal/ReferenceModal'
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';

const Slide4 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const [referencesIndex, setReferencesIndex] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const slideOverlay = `linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`;

  const handleShowModal = (indexs) => {
    if (indexs) {
      setReferencesIndex(indexs)
    }
  }

  useEffect(() => {
    if (referencesIndex) {
      setShowModal(true)
    }
  }, [referencesIndex])

  useActiveLink(setShowHeaderSecondaryMenu, 'testing')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(2)
      setOverlay(slideOverlay)
      setBackground(`linear-gradient(173deg, rgba(80, 82, 122, 0.7452) -2.67%, rgba(74, 78, 97, 07452) 22.8%, rgba(47, 49, 74, 0.7452) 96.29%);`);

      return
    }
    setShowPageContent(false)

  }, [swiperSlide.isActive])


  return (
    <Scroll isiconfig={isiconfig}>
      <ContainerScroll>
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'}>
          {referencesIndex && <ReferenceModal indexs={referencesIndex} isOpen={showModal} close={() => setShowModal(false)} />}
          <CustomWrapper>
            <Title>
              <BaseSubtitle>impact of biomarker testing on patient outcomes<sup onClick={() => handleShowModal([29])}>29</sup></BaseSubtitle>
              <div className="sub-title">Overall survival by availability of molecular genotyping results prior to 1L therapy<sup onClick={() => handleShowModal([29])}>29*</sup></div>
            </Title>

            <Characteristics>
              <Wrap>
                <img src='/assets/biomarker_testing_chart.png' alt="Impact of Biomarker Testing On Patient Outcomes" />
              </Wrap>
            </Characteristics>

            <BlueBox>
              Comprehensive molecular testing, prior to 1L therapy, was associated with an improvement in overall survival, mediated by the delivery of targeted therapy<sup onClick={() => handleShowModal([29])}>29</sup>
            </BlueBox>
          </CustomWrapper>
          <CustomReference>
            <p>
              *Based on availability of molecular genotyping results prior to 1L therapy in a retrospective cohort of patients with newly diagnosed stage IV nonsquamous NSCLC (N=335). Categories were defined as comprehensive testing panel (which included <em>EGFR, ALK, BRAF, ROS1, MET, RET</em>, and <em>NTRK</em>) and Incomplete (2-6 genes tested).
            </p>
            <p>
              1L=first-line; OS=overall survival.
            </p>
          </CustomReference>
        </CustomContainer>
      </ContainerScroll>
    </Scroll>
  )
}

export default Slide4;

const CustomWrapper = styled(Wrapper)`
	@media ${device.desktop} {
    @media (max-height: 920px) {
      margin-top: 4.5rem;
    }
  }
`
const Footnote = styled(Reference)`
	margin: 4rem 0 10px;

	@media ${device.desktop} {
		margin: 0;
		transform: translateY(calc(-100% - 5px));
	}
`

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
`;
const CustomContainer = styled(Container)`
  display: flex;
  height:  100%;
  padding: 0;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0 1.2rem;

  .sub-title {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-size: 1rem;
    max-width: 25rem;
    margin: 0 auto;
    text-transform: none;
  }

  @media ${device.desktop} {
    max-width: 90rem;
    margin-bottom: 0;
    .sub-title {
      font-size: 2rem;
      max-width: 45rem;
    }
  }
`

const BlueBox = styled.div`
  font-family: 'Barlow Semi Condensed', sans-serif;
  padding: 1rem 1.5rem;
  background: #0F1282;
  color: white;
  font-size: 1.75rem;
  max-width: 65rem;
  text-align: center;
  margin: 0 1rem 1rem;
  @media ${device.desktop} {
    margin-bottom: 2rem;
  }
`
const Characteristics = styled.div`
  padding: 0 2.4rem;

  @media ${device.desktop} {
    margin-top: 1.5rem;
    padding: 0;
  }
`
const Wrap = styled.div`
  margin: 2rem auto;
  width: 100%;
  img {
    max-width: 95%;
    @media (min-aspect-ratio: 9/5) {
      max-height: 40vh;
    }
  }
`
const CharacteristicsItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  width: 100%;
  color: white;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 2.5rem;

  span {
    font-size: 1.5rem;
    letter-spacing: .06rem;
  }
  .percent-wrap {
    font-size: 1rem;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    .percent {
      font-size: 2rem;
      margin-left: 1rem;
    }
    .percent-text {
      max-width: 12rem;
      margin-left: 1rem;
    }
  }

  @media ${device.desktop} {
    display: flex;
    gap: 4rem;

    span {
      font-size: 2.9rem;
      text-align: left;
    }

    &:first-of-type {
      img {
        max-width: 16rem;
      }
    }
    .percent-wrap {
      font-size: 1.75rem;
      .percent {
        font-family: 'Oswald', sans-serif;
        font-size: 5rem;
      }
      .percent-text {
        max-width: 21rem;
      }
    }
  }
`

const CharacteristicImg = styled.div`
  text-align: center;
  img {
    width: 6.25rem;
    text-align: center;
    margin-bottom: .5rem;
  }

  @media ${device.desktop} {
    img {
      width: initial;
      text-align: center;
    }
  }

  @media ${device.desktop800h} {
    img {
      max-width: 95%;
    }
  }
`
export const CustomReference = styled(Reference)`
    margin-bottom: 6rem;
    padding-right: 5.6rem;
    @media ${device.desktop} {
      margin-top: 2rem;
    }
`;
