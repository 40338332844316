export const fadeIn = {
    start: {opacity: 0, },
    end: {opacity: 1, transition: {duration: .5, delay: .1}}
}

export const fadeLeft = {
    start: {opacity: 0, x: 100},
    end: {opacity: 1, x: 0, transition: {duration: .5, delay: .1}} 
}

export const fadeRight = {
    start: {opacity: 0, x: -100},
    end: {opacity: 1, x: 0, transition: {duration: .5, delay: .1 }} 
}

export const fadeTop = {
    start: {opacity: 0, y: -50},
    end: {opacity: 1, y: 0, transition: {duration: .5, delay: .1}} 
}

export const fadeBottom = {
    start: {opacity: 0, y: 50},
    end: {opacity: 1, y: 0, transition: {duration: .5, delay: .1}} 
}

export const rotate = {
    start: { rotate: 0, transition: {duration: .5,} },
    end: {rotate: 180, transition: {duration: .5}},
}