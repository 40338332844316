import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSwiperSlide, useSwiper } from 'swiper/react';
import { BaseSubtitle, BaseTitle, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';
import useMobileDetect from '../../../_hooks/useMobileDetect';

const Slide1 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const swiper = useSwiper();
    const { hash } = useLocation();
    const mobileOverlay = '';
    const [showPageContent, setShowPageContent] = useState(false);

    const desktopOverlay = 'linear-gradient(165deg, rgba(49, 78, 119, 0.2854) 4.22%, rgba(22, 38, 66, 0.2854) 90.76%)'
    const slideOverlay = desktopOverlay
    const slideBackground = isMobile ? `url('/assets/study-design-bg.png') no-repeat fixed 15% center / cover` : `url('/assets/study-design-bg.png') no-repeat fixed 20% top / cover`

    useActiveLink(setShowHeaderSecondaryMenu, 'safety');

    useEffect(() => {
        if (hash === '#concomitant-medications') {
            swiper.slideTo(3);
            return;
        }
    }, [hash]);

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setCurrentSlide(1);
            setShowPageContent(true);
            setBackground(slideBackground);
            setOverlay(slideOverlay);
            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
            <CustomWrapper>
                <Title>
                    <Title1>Safety and tolerability of KRAZATI</Title1>
                </Title>

                <SubTitle>
                    <p>
                        Scroll to learn about the safety evaluation of&nbsp;KRAZATI in 366 adult patients, including 116 with <em>KRAS G12C</em>-mutated advanced NSCLC.
                        <sup className="custom-sup" onClick={() => handleShowModal([30])}>30</sup>
                    </p>
                </SubTitle>
            </CustomWrapper>
            <ScrollIcon marginBottom='2rem' />
            <Reference>NSCLC=non-small cell lung cancer.</Reference>
        </CustomContainer>
    );
};

export default Slide1;

const CustomContainer = styled(Container)`
 	height: 100%;
	max-height: ${(props) => `calc(100% - ${props.isiconfig} - 7rem)`};

	@media ${device.tablet} {
        max-height: ${(props) => `calc(97% - ${props.isiconfig})`};
	      justify-content: space-between;
    }

	@media (min-width: 680px) {
		max-height: ${(props) => `calc(96.5% - ${props.isiconfig})`};
	}

	@media (min-width: 780px) {
		max-height: ${(props) => `calc(96% - ${props.isiconfig})`};
	}

	@media ${device.desktop} {
        max-height: ${(props) => `calc(96% - ${props.isiconfig})`};
    }

	@media (min-width: 1763px) {
		max-height: ${(props) => `calc(97.5% - ${props.isiconfig})`};
	}

	.swiper-wrapper {
		height: 100%!important;

		&>div,
		&>div>div {
			height: 100%!important;
			justify-content: space-between;
		}
	}
`;

const CustomWrapper = styled(Wrapper)`
    margin-top: 14vh;

    @media ${device.desktop} {
        margin-top: 0;
        justify-content: center;
        align-items: center;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    color: white;
    margin-bottom: 1.6rem;
    padding: 0 3rem;

    @media ${device.desktop} {
        margin-bottom: 1rem;
        max-width: 80rem;
    }
`;
const Title1 = styled(BaseTitle)`
    text-transform: uppercase;
    padding: 0 6rem;

    @media ${device.desktop} {
        margin-top: 3rem;
        padding: 0 5rem;
        margin-bottom: 1.1rem;
    }
    @media ${device.desktop800h} {
    }
`;
const SubTitle = styled(BaseSubtitle)`
    font-family: 'Barlow';
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 0px 2.5rem;

    span {
        color: #17c94e;
        font-weight: bold;
    }

    p {
        font-size: 2.1rem;
        margin-bottom: 1rem;
    }

    @media ${device.desktop} {
        max-width: 76rem;
        font-size: 2.4rem;

        &:first-of-type {
            margin-bottom: 2.4rem;
        }

        sup.custom-sup {
          font-size: calc(1.3rem);
        }
    }

    @media ${device.desktop600h} {
        font-size: 2rem;
    }
`;

const CustomSup = styled.sup`
    @media ${device.desktop600h} {
        font-size: calc(1.5rem);
    }
`;
