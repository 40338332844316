import React, { useMemo, useState } from 'react'
import { useRef } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BaseLink, Box } from '../../PageSwipper/utils/styles'

const SubNavMobile = ({ item, closeNav }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [showingSubMenuController, setShowingSubMenuController] = useState('hide')

  const menu = useRef()

  const handleMenu = () => {
    setShowMenu(!showMenu);
    classController(!showMenu);
  }

  const classController = (isActive) => {
    if (isActive) setShowingSubMenuController('show')
    if (!isActive) {
      setTimeout(()=> {
        setShowingSubMenuController('hide')
      }, 400)
      setShowingSubMenuController('hidding')

      return
    }
  }

  return (
    <SubNavContainer direction="column">
      <NavLink justify="flex-start" align="center" border="0.1rem solid white" onClick={handleMenu}>
        <BaseLink
          as={Link}
          width="100%"
          color="white"
          // target={item.url === "#" ? null : "_blank"}
          rel="noreferrer"
          to={item.url}
          padding="1.2rem 1.6rem"
          onClick={item.url === "" && handleMenu}
        >
          {item.label}
        </BaseLink>

        <MenuMobileChevron className={showMenu ? 'active' : null }>
          <FaChevronDown />
        </MenuMobileChevron>
      </NavLink>

      {item.submenu.map((item, index) => (
        <SubNavChild className={showingSubMenuController} key={item.label + index}>
          <BaseLink
            as={Link}
            width="100%"
            color="white"
            // target={item.url === "#" ? null : "_blank"}
            rel="noreferrer"
            to={item.url}
            padding="1.2rem 3.2rem"
			onClick={closeNav}
          >
            {item.label}
          </BaseLink>
        </SubNavChild>
      )) }
    </SubNavContainer>
  )
}

export default SubNavMobile

const SubNavContainer = styled(Box)`
 overflow: hidden;
`

const NavLink = styled(Box)`
  border: none;
  border-bottom: 0.1rem solid white;
`

const SubNavChild = styled(Box)`
  border: none;
  border-bottom: 0.1rem solid white;

  &:nth-of-type(1 + n) {
    border-bottom: none;
  }

  &.hide {
    display: none;
    transform: translateY(-3rem);
    transition: all .5s;
  }

  &.hidding {
    transform: translateY(-3rem);
    animation: hidding .3s ease-in-out forwards;
  }

  &.show {
    display: flex;
    animation: show .5s ease-in-out forwards;
  }

  @keyframes show {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes hidding {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(-3rem);
      opacity: 0;
    }
  }
`
const MenuMobileChevron = styled(Box)`
  width: fit-content;
  padding: 1.2rem 1.6rem;
  z-index: 2;
  animation: rotateBack .5s ease-in-out forwards;

  svg {
    fill: #17C94E;
  }

  &.active {
    animation: rotate .5s ease-in-out forwards;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(180deg);
    }
  }

  @keyframes rotateBack {
    from {
      transform: rotate(180deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`