import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import { useSwiperSlide, useSwiper } from 'swiper/react';
import { BaseText, Container, FlexCentered } from '../../_components/PageSwipper/utils/styles';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';
import Reference from '../../_components/Reference';
import '../css/style.scss';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';

const HomeLegends = () => {
    return (
        <Reference>
            <p>CNS=central nervous system; NSCLC=non-small cell lung cancer.</p>
        </Reference>
    );
};

const Slide1 = ({ handleShowModal, setPageBackground, setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay }) => {
    const swiperSlide = useSwiperSlide();
    const swiper = useSwiper();
    const { hash } = useLocation();
    const { isMobile } = useMobileDetect();
    const mobileBgWrapper = useRef();
    const [showPageContent, setShowPageContent] = useState(false);
    const mobileOverlay = 'linear-gradient(180deg, rgba(49, 78, 119, .5317) 4.22%, rgba(22, 38, 66, .5317) 90.76%)';
    const desktopOverlay = 'linear-gradient(180deg, rgba(49, 78, 119, .2382) 4.22%, rgba(22, 38, 66, .2382) 90.76%)';
    const slideOverlay = isMobile ? mobileOverlay : desktopOverlay;
    const slideBackground = `url(${isMobile ? '/assets/nccn-bg-ocean-m.png' : '/assets/nccn-bg-ocean.png'}) no-repeat fixed 40% center / cover`;

    useActiveLink(setShowHeaderSecondaryMenu, 'nccn-recommendations');

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setCurrentSlide(1);
            setShowPageContent(true);
            setBackground(slideBackground);
            setOverlay(slideOverlay);
            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

	if(isMobile) {
		return (
			<MobileContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
				<ContainerScroll>
          <Title>
            <Title1>NCCN CLINICAL PRACTICE GUIDELINES IN ONCOLOGY (NCCN GUIDELINES<sup className="r-ball">®</sup>) RECOMMENDS adagrasib (KRAZATI) FOR THE TREATMENT OF KRAS G12C</Title1>
            <Subtitle>Adagrasib (KRAZATI) is the first and only KRAS inhibitor recommended by the National Comprehensive Cancer Network<sup>®</sup> (NCCN<sup>®</sup>) in both NSCLC and CNS guidelines.<sup onClick={()=> handleShowModal([1, 2])}>1,2</sup></Subtitle>
            <Subtitle><strong>Scroll to learn more about the category 2A recommendations.</strong></Subtitle>
          </Title>
					<div style={{ width: '100%' }}>
						<HomeLegends />
					</div>
				</ContainerScroll>
			</MobileContainer>
		)
	}

    return (
		<DesktopContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
			<Title>
        <Title1>NCCN CLINICAL PRACTICE GUIDELINES IN ONCOLOGY (NCCN GUIDELINES<sup className="r-ball">®</sup>) RECOMMENDS adagrasib (KRAZATI) FOR THE TREATMENT OF KRAS G12C</Title1>
        <Subtitle>Adagrasib (KRAZATI) is the first and only KRAS inhibitor recommended by the National Comprehensive Cancer Network<sup>®</sup> (NCCN<sup>®</sup>) in both NSCLC and CNS guidelines.<sup onClick={()=> handleShowModal([1, 2])}>1,2</sup></Subtitle>
        <Subtitle><strong>Scroll to learn more about the category 2A recommendations.</strong></Subtitle>
			</Title>
			<HomeLegends />
		</DesktopContainer>
    );
};

export default Slide1;

const MobileContainer = styled(Container)`
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0;
  height: ${props => `calc(100% - ${props.isiconfig})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .swiper-wrapper {
	height: 100%!important;
	justify-content: center;
  }

  .swiper-slide {
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`

const DesktopContainer = styled(Container)`
	height: 100%;
	max-height: ${(props) => `calc(100% - ${props.isiconfig})`};
	justify-content: space-between;

	@media ${device.tablet} {
        max-height: ${(props) => `calc(97% - ${props.isiconfig})`};
    }

	@media (min-width: 680px) {
		max-height: ${(props) => `calc(96.5% - ${props.isiconfig})`};
	}

	@media (min-width: 780px) {
		max-height: ${(props) => `calc(96% - ${props.isiconfig})`};
	}

	@media ${device.desktop} {
        max-height: ${(props) => `calc(96% - ${props.isiconfig})`};
    }

	@media (min-width: 1763px) {
		max-height: ${(props) => `calc(97.5% - ${props.isiconfig})`};
	}

	.swiper-wrapper {
		height: 100%!important;

		&>div,
		&>div>div {
			height: 100%!important;
			justify-content: space-between;
		}
	}
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: normal;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5rem;
  padding: 0 3.5rem;

  .r-ball {
    cursor: auto;
    font-size: 50%;
    top: -0.5rem;
    position: relative;
  }

  @media ${device.tablet} {
    .r-ball {
      top: -1.5rem;
    }
  }

  @media ${device.desktop} {
    margin-bottom: 5.6rem;
    padding: 0 5rem;
    .r-ball {
      top: -1rem;
    }
  }
`;
const Title1 = styled.p`
  font-family: 'Oswald', sans-serif;
  font-size: 2.5rem;
  letter-spacing: 0;
  text-shadow: 0 .2rem 1.7rem rgba(0, 0, 0, 0.48);

  @media ${device.tablet} {
    font-size: 8rem;
    // letter-spacing: 2.8rem;
    // padding-left: 2rem;
    text-shadow: 0 0.2rem 1.7rem rgba(0, 0, 0, 0.48);
  }

  @media ${device.desktop} {
    font-size: 4.7rem;
    line-height: 1.15;
	  margin-bottom: 2.5rem;
    max-width: 98rem;
  }
`;
const Subtitle = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: min(1.7rem, 2rem);
  line-height: 2rem;
  span {
    color: #17C94E;
    font-weight: bold;
  }

  sup {
    font-size: calc(min(1.7rem, 2rem) * .65);
  }

  @media ${device.tablet} {
    font-size: 1.7rem;
    line-height: min(2rem, 2.8rem);

    sup {
      font-size: 55%;
    }
  }

  @media ${device.desktop} {
    font-size: 2rem;
    line-height: 2.8rem;
    max-width: 87rem;

    sup {
      font-size: 55%;
    }
  }
`;
