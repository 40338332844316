import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { BaseSubtitle, Box, Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference';
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import { Title1 } from './Slide2';
import { ReactComponent as CalendarImage } from '../img/calendar.svg';

const Slide4 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
    const { isMobile } = useMobileDetect();
    const swiperSlide = useSwiperSlide();
    const [showPageContent, setShowPageContent] = useState(false);
    const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`;

    useActiveLink(setShowHeaderSecondaryMenu, 'safety');

    useEffect(() => {
        if (swiperSlide.isActive) {
            setShowHeaderSecondaryMenu(true);
            setShowPageContent(true);
            setCurrentSlide(4);
            setOverlay(slideOverlay);

            return;
        }
        setShowPageContent(false);
    }, [swiperSlide.isActive]);

    if (isMobile) {
        return (
            <Scroll isiconfig={isiconfig}>
                <ContainerScroll>
                    <Container className={showPageContent ? 'visible' : 'hidden'}>
                        <CustomWrapper>
                            <Title>
                                <Title1>
                                    TIME TO ONSET AND RESOLUTION OF SELECT ADVERSE REACTIONS (ARs)<sup onClick={() => handleShowModal([31])}>31</sup>
                                </Title1>
                            </Title>

                            <SubTitle>
                                <p>Overall, 92% of patients with GI ARs (diarrhea, nausea, and vomiting) reported them within the first 6 weeks.</p>
                            </SubTitle>

                            <MobileTable>
                                {/* <CalendarImage /> */}
                                <img src={require('../img/calendar.svg').default} alt='GI ARs Reported Within First 6 Weeks' />
                            </MobileTable>

                            <ColoredBox borderColor='#3246FF'>
                                <p>
                                    The median time to resolution after an initial occurrence was <span>1.7 weeks</span> for increased ALT and AST levels.*
                                </p>
                            </ColoredBox>

                            <ColoredBox borderColor='#17C94E'>
                                <p>
                                    The median time to resolution after an initial occurrence was <span>2.1 weeks</span> for GI ARs.<sup className='no-cursor'>†</sup>
                                </p>
                            </ColoredBox>
                            <BannerMobile>
                                <p>
                                    MONITORING AND SUPPORT FOR PATIENTS IS IMPORTANT IN <span style={{ color: '#17c94e' }}>THE FIRST 6 WEEKS </span>AND THROUGHOUT TREATMENT.
                                </p>
                            </BannerMobile>
                        </CustomWrapper>

                        <Reference>
                            <p>*The range for the time to resolution after an initial occurrence was 0.3 to 15.1 weeks.</p>
                            <p><sup>†</sup>The range for the time to resolution after an initial occurrence was 0.1 to 50.1 weeks.</p>
                            <p>ALT=alanine aminotransferase; AST=aspartate aminotransferase; GI=gastrointestinal.</p>
                        </Reference>
                    </Container>
                </ContainerScroll>
            </Scroll>
        );
    }

    return (
        <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
            <ContainerScroll>
                <CustomWrapper>
                    <Title>
                        <Title1>
                            TIME TO ONSET AND RESOLUTION OF SELECT ADVERSE REACTIONS (ARs)<sup onClick={() => handleShowModal([31])}>31</sup>
                        </Title1>
                    </Title>

                    <SubTitle>
                        <p>Overall, 92% of patients with GI ARs (diarrhea, nausea, and vomiting) reported the initial event within the first 6 weeks.</p>
                    </SubTitle>

                    <DesktopTable>
                        <img src={require('../img/calendar.svg').default} alt='GI ARs reported within first 6 weeks' />
                        <div>
                            <ColoredBox borderColor='#3246FF'>
                                <p>
                                    The median <span>time to resolution</span> after an initial occurrence was <span>1.7 weeks</span> for increased ALT and AST levels.*
                                </p>
                            </ColoredBox>

                            <ColoredBox borderColor='#17C94E'>
                                <p>
                                    The median <span>time to resolution</span> after an initial occurrence was <span>2.1 weeks</span> for GI ARs.
                                    <sup className='no-cursor'>†</sup>
                                </p>
                            </ColoredBox>

                            <Arrow>
                                <ArrowContainerShadow></ArrowContainerShadow>
                                <ArrowContainer>
                                    <ArrowWrapper>
                                        <p>
                                            Monitoring and support for patients is important in <span style={{ color: '#17c94e' }}>the first 6 weeks</span> and throughout
                                            treatment.
                                        </p>
                                    </ArrowWrapper>
                                </ArrowContainer>
                            </Arrow>
                        </div>
                    </DesktopTable>
                </CustomWrapper>
            </ContainerScroll>
            <Reference>
                <p>*The range for the time to resolution after an initial occurrence was 0.3 to 15.1 weeks.</p>
                <p><sup>†</sup>The range for the time to resolution after an initial occurrence was 0.1 to 50.1 weeks.</p>
                <p>ALT=alanine aminotransferase; AST=aspartate aminotransferase; GI=gastrointestinal.</p>
            </Reference>
        </CustomContainer>
    );
};

export default Slide4;

const Scroll = styled.div`
    height: 100%;
    max-height: ${(props) => `calc(100% - ${props.isiconfig} - 1rem)`};
`;

const CustomContainer = styled(Container)`
    display: flex;
    height: ${(props) => `calc(100% - ${props.isiconfig})`};
    padding: 0;
    justify-content: space-between;
`;
const CustomWrapper = styled(Wrapper)`
    height: auto;
    margin-bottom: 3rem;
    margin-top: 2rem;

    .mobile-banner {
        width: 100%;
    }
    @media ${device.desktop} {
        margin-top: 1.6rem;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    width: 100%;
    margin-bottom: 2.9rem;
    text-transform: uppercase;
    padding: 0 2.6rem;

    @media ${device.desktop} {
        margin-bottom: 1.6rem;
    }
`;
const MobileTable = styled(Box)`
    height: auto;
    img {
        width: 28rem;
    }
`;

const DesktopTable = styled(Box)`
    display: flex;
    align-items: center;
    max-width: 960px;
	gap: 4rem;

	img {
		max-width: 500px;
	}

    > div {
		flex: 1;
        width: 40rem;
        padding-top: 2.5rem;
    }
`;

const SubTitle = styled(BaseSubtitle)`
    font-family: 'Barlow';
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0px 2.5rem;

    sup {
        font-size: 0.8rem;
    }

    span {
        color: #17c94e;
        font-weight: bold;
    }

    p {
        margin-bottom: 1rem;
    }

    @media ${device.desktop} {
        max-width: 68rem;
        font-size: 2rem;
        margin-bottom: 1rem;
        sup {
            font-size: 1.1rem;
        }
    }
`;

const ColoredBox = styled.div`
    padding: 2rem 1.6rem;
    color: #ffffff;
    font-family: Barlow;
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2rem;
    border: 0.3rem solid ${(props) => props.borderColor};
    background-color: #111830;
    box-shadow: 0 0.7rem 0.8rem -0.4rem rgba(0, 0, 0, 0.2), 0 0.5rem 2.2rem 0.4rem rgba(0, 0, 0, 0.12), 0 1.2rem 1.7rem 0.2rem rgba(0, 0, 0, 0.14);

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.5rem 0.8rem 0;
    &:last-of-type {
        margin: 2.5rem 0.8rem;
    }

    span {
        color: ${(props) => props.borderColor};
        font-weight: bold;
    }

    sup {
        font-size: 1.1rem;
        transform: translateY(-0.2rem);
    }

    @media ${device.tablet} {
        max-width: 70%;
    }

    @media ${device.desktop} {
        margin: 0.5rem 0.8rem 0;
        max-width: 100%;
        width: 37rem;
        margin-bottom: 3.2rem;
        &:last-of-type {
            margin: 2.5rem 0.8rem;
        }
    }
`;

const BannerMobile = styled.div`
    margin-top: 2rem;
    padding: 2.4rem 2.8rem;
    width: 100%;
    background: linear-gradient(108.19deg, rgba(31, 36, 53, 0.8) 0%, rgba(47, 54, 75, 0.7) 100%, rgba(66, 74, 101, 0.7) 100%);
    box-shadow: 0 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.3rem 0.3rem -0.2rem rgba(0, 0, 0, 0.12), 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.14);

    p {
        color: white;
        font-family: 'Oswald';
        font-size: 1.8rem;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 2.4rem;
        text-transform: uppercase;
    }

    @media ${device.tablet} {
        text-align: center;
    }
`;

const Arrow = styled.div`
    position: relative;
    margin: 4.4rem 0 3.2rem;

    @media ${device.laptop} {
        margin: 0;
    }
`;
const ArrowContainer = styled.div`
    background: linear-gradient(108.19deg, rgba(31, 36, 53, 0.8) 0%, rgba(47, 54, 75, 0.7) 100%, rgba(66, 74, 101, 0.7) 100%);
    box-shadow: 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12), 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14);
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 0 50%, 0% 0%);

    @media ${device.laptop} {
        max-width: 42rem;
        height: 9.6rem;
    }
`;
const ArrowContainerShadow = styled.div`
    position: absolute;
    z-index: -1;
    content: '';
    background: rgba(0, 0, 0, 0.1);
    width: calc(100% + 0.4rem);
    height: calc(100% + 0.4rem);
    left: 0px;
    top: 0px;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 0 50%, 0% 0%);

    @media ${device.laptop} {
        max-width: 42rem;
    }
`;
const ArrowWrapper = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    max-width: 42rem;
    height: 9.6rem;
    p {
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        font-size: min(1.3rem, 1.8rem);
        padding: 0 2rem 0 1.6rem;
        span {
            color: #17c94e;
            font-weight: bold;
        }
    }

    @media ${device.desktop} {
        align-items: center;
        p {
            font-size: 1.8rem;

            small {
                font-size: 1.2rem;
                sup {
                    font-size: 1rem;
                }
            }
        }
    }
`;
