import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import {  useSwiperSlide } from 'swiper/react';
import { Container, Wrapper } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect';
import ScrollIcon from '../../_components/PageSwipper/utils/ScrollIcon';

const Slide1 = ({setPageBackground, setShowHeaderSecondaryMenu, handleShowModal, setCurrentSlide, isiconfig, setOverlay, setBackground}) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide()
  const [showPageContent, setShowPageContent] = useState(false)
  const mobileBackground = `url('/assets/about-bg-ocean.png') no-repeat fixed center top / cover`;
  const desktopBackground = `url('/assets/img_bkg-about-kras.png') no-repeat fixed center top / cover`;
  const slideBackground = isMobile ? mobileBackground : desktopBackground;
  const mobileOverlay = 'linear-gradient(173deg, rgba(49, 78, 119, 0.454) -4.22%, rgba(22, 38, 66, 0.454) 90.76%);'
  const slideOverlay = isMobile ? mobileOverlay : '';

  useActiveLink(setShowHeaderSecondaryMenu, 'about-kras-g12c')

  useEffect(()=> {
    if(swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setOverlay(slideOverlay)
      setCurrentSlide(1)

      return
    }
    setBackground(slideBackground);
    setShowPageContent(false)
  }, [swiperSlide.isActive])


  return (
    <>
    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <CustomWrapper>
          <Title>
            <Title1><em>KRAS G12C</em> is challenging</Title1>
            <Subtitle><span>For over 40 years</span>, KRAS had been challenging to target due to the protein's smooth surface providing few binding pockets for small molecules, and high binding affinity for GTP.<sup onClick={()=> handleShowModal([4,5])}>4,5</sup></Subtitle>
            <Title2>Scroll to see <span>3 reasons why</span> <em>KRAS G12C</em> may require a treatment that can challenge its relentlessness in advanced NSCLC.</Title2>
          </Title>
        </CustomWrapper>
        <ScrollIcon marginBottom={isMobile ? "2.6rem" : "1.4rem"} />
        <Reference>GTP=guanosine triphosphate; NSCLC=non-small cell lung cancer.</Reference>
    </CustomContainer>
    </>
  )
}

export default Slide1

const CustomContainer = styled(Container)`
  display: flex;
  max-height: ${props => `calc(100% - ${props.isiconfig})`};

  @media ${device.desktop} {
    justify-content: space-between;
    max-height: ${props => `calc(98% - ${props.isiconfig})`};
  }
`
const CustomWrapper = styled(Wrapper)`
  padding: 0 2.4rem;
  margin-top: 0;
  height: auto;
  @media ${device.desktop} {
    height: 100%;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1;
  width: 100%;
  margin-bottom: 3.5rem;
  margin-top: 5rem;
  @media ${device.desktop} {
    margin-bottom: 5.6rem;
    margin-top: 0;
    height: 100%;
  }
`
const Title1 = styled.p`
  font-family: "Oswald", sans-serif;
  margin-bottom: 1.6rem;
  font-size: min(4rem, 5.8rem);
  line-height: 4.8rem;
  text-transform: uppercase;

  @media ${device.tablet} {
    font-size: min(4rem, 6.4rem);
    line-height: min(4.8rem, 7.6rem);
  }

  @media ${device.desktop} {
    font-size: 6.4rem;
    line-height: 7.6rem;
    margin-bottom: 1.2rem;
  }
`

const Subtitle = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: min(1.7rem, 2rem);
  line-height: 2rem;
  margin-bottom: 2rem;
  margin-top: 0;
  span {
    color: #17C94E;
    font-weight: bold;
  }

  sup {
    font-size: calc(min(1.7rem, 2rem) * .65);
  }

  @media ${device.tablet} {
    font-size: 1.7rem;
    line-height: min(2rem, 2.8rem);

    sup {
      font-size: 55%;
    }
  }


  @media ${device.desktop} {
    font-size: 2.4rem;
    line-height: 2.8rem;
    max-width: 101rem;

    sup {
      font-size: 55%;
    }
  }
`

const Title2 = styled.p`
  font-family: "Barlow", sans-serif;
  font-size: min(1.7rem, 2rem);
  line-height: 2rem;

  span {
    color: #17C94E;
    font-weight: bold;
  }

  @media ${device.tablet} {
    font-size: 1.7rem;
    line-height: min(2rem, 2.8rem);
  }


  @media ${device.desktop} {
    font-size: 2.4rem;
    line-height: 2.8rem;
    max-width: 73rem;
  }

`
