import ContentContainer from '../../_common/components/Layout/ContentContainer'
import styles from './Footer.module.scss'

export default function Footer({ id, links = {} }) {
  return (
    <div className={styles.footer}>
      <ContentContainer>
        <div className={styles.footerWrapper}>
          <div className={styles.logo}>
            <a href="https://www.bms.com" target="_blank" rel="noreferrer"><img src="/assets/bms-logo.svg" alt="Bristol Myers Squibb Company Logo" /></a>
          </div>
          <div className={styles.footerContent}>
            <div className={`${styles.content} roboto-medium`}>
              <p style={{ marginBottom: 15 }}>© 2024 Mirati Therapeutics, Inc.,<br className={styles.mobileBreak} /> a Bristol Myers Squibb company.</p>
              <p style={{ marginBottom: 15 }}>KRAZATI<sup>®</sup> and the related logo are registered<br className={styles.mobileBreak} /> trademarks of Mirati Therapeutics, Inc.</p>
              <p>{id && id}</p>
            </div>
            <div className={styles.footerNav}>
              <div className={styles.links}>
                <ul>
                  {links && links.map((link) => (
                    <li key={link.key}>
                      <a href={link.url} target="_blank" rel="noreferrer">{link.text}</a>
                    </li>
                  ))}
                  <li><a style={{ 'textTransform': 'uppercase' }} href="#" onClick={() => {
                    window.OneTrust.ToggleInfoDisplay()
                  }}>Your Privacy Choices<img className={styles.privacyIcon} src="/assets/icons/privacyoptions.svg" alt="" /></a></li>
                </ul>
              </div>
              <div className={styles.contact}>
                <p><i>This website is intended for U.S. residents 18 years of age or older.</i></p>
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}
