import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Link, useLocation } from "react-router-dom"
import { useInView } from 'react-intersection-observer';

import './common/global.scss'
import styles from './index.module.scss';
import Indication from "./components/Indication"
import Navigation from "./components/Navigation"
import Container from "../_common/components/Layout/Container"
import ISI from "./components/ISI"
import StickyISI from "./components/StickyISI"
import Footer from './components/Footer'

import MetaTags from '../_common/components/MetaTags'

export default function LandingPage() {
  const location = useLocation()
  const [activeIsi, setActiveIsi] = useState(false)

  const { ref, inView } = useInView({
    rootMargin: "-48px",
  })
  const isiOpacityVariants = {
    initial: {
      opacity: 1
    },
    visible: {
      opacity: 1,
      transition: { ease: 'easeOut' },
    },
    hidden: {
      opacity: 0,
      display: 'none',
      transition: { ease: 'easeOut' },
    }
  }
  const links = [
    {
      key: 0,
      url: 'https://www.bms.com/legal-notice.html',
      text: 'LEGAL NOTICE',
      type: 'external'
    },
    {
      key: 1,
      url: 'https://www.bms.com/privacy-policy.html',
      text: 'PRIVACY POLICY',
      type: 'external'
    },
  ]

  useEffect(() => {
    if (location.hash === '#important-safety-information') {
      setActiveIsi(true)
    } else {
      setActiveIsi(false)
    }
  }, [location, inView, activeIsi])

  return (
    <div className={`${styles.landingPage} landing-page`}>
      <MetaTags
        title='KRAZATI® (adagrasib) | Information for Healthcare Providers'
        description='KRAZATI® (adagrasib) offers an oral treatment that can be taken at home. Safety, testing, and support information for healthcare providers.'
        ogUrl='https://www.krazatihcp.com'
      />

      <Indication />
      <Navigation />

      <div className="landing-page--heading">
        <Container>
          <div className={styles.landingPageHeading}>
            <h1>KRAZATI helps meet the challenge of <span className="no-wrap">KRAS G12C</span> across multiple tumor types</h1>
            <p>Click below to continue to indication-specific site.</p>
          </div>
        </Container>
      </div>

      <div className={styles.landingPageLinks}>
        <Container>
          <div className={styles.flex}>

            <Link to='/n' className={styles.cardLink}>
              <div className={`${styles.landingPageCard} ${styles.nsclc}`}>
                <div className={styles.linkLogo}>
                  <img src="/assets/logo/IMG_NSCLC_Logo.svg" alt="Disease Indication Flag" />
                </div>
                <div className={`${styles.linkBar} ${styles.linkBarNSCLC}`}>
                  NSCLC Home
                  <img className={styles.arrowLink} src="/assets/icons/icon-arrow-right.svg" alt="" />
                </div>
              </div>
            </Link>

            <Link to='/c' className={styles.cardLink}>
              <div className={`${styles.landingPageCard} ${styles.crc}`}>
                <div className={styles.linkLogo}>
                  <img src="/assets/logo/IMG_CRC_Logo.svg" alt="Disease Indication Flag" />
                </div>
                <div className={`${styles.linkBar} ${styles.linkBarCRC}`}>
                  CRC Home
                  <img className={styles.arrowLink} src="/assets/icons/icon-arrow-right.svg" alt="" />
                </div>
              </div>
            </Link>

          </div>
        </Container>
      </div>

      <div ref={ref} className="footer">
        <ISI />
        <Footer id="US-KRA-24-00064 v1 6/24" links={links} />
      </div>

      {/* {!inView && <StickyISI />} */}
      <motion.div variants={isiOpacityVariants} initial="initial" animate={!inView ? 'visible' : `${activeIsi ? 'visible' : 'hidden'}`}>
        <StickyISI />
      </motion.div>
    </div >
  )
}
