import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro';
import { useSwiperSlide } from 'swiper/react';
import { Container, Wrapper, BaseText } from '../../_components/PageSwipper/utils/styles';
import useActiveLink from '../../_components/Header/hooks/useActiveLink';
import Reference from '../../_components/Reference'
import { device } from '../../_assets/styles/theme';
import useMobileDetect from '../../../_hooks/useMobileDetect'
import ContainerScroll from '../../_components/PageSwipper/components/ContainerScroll';
import { Banner, BannerContainer } from '../../Efficacy/components/Tab2';
import { Table, TableBody, TableBodyLeft, TableBodyValues, TableContainer, TableHeaderInfo, TableHeaderTitle, TableItemHeader, TableItemLeft, TableSectionDescription, TableSectionTitle, TableText, TableValues, Title1 } from './Slide2';
import { Bullet, ListContainer, ListItem, ListText } from '../../Efficacy/components/Tab1';

const TableSection = ({ title, values, handleShowModal }) => {
  return (
    <TableBodyWrapper>
      <TableBodyLeft>
        <TableSectionDescription>{title}</TableSectionDescription>
      </TableBodyLeft>

      <TableGrid>
        <TableBodyValues>{values[0]}</TableBodyValues>
        <TableBodyValues>{values[1]}</TableBodyValues>
      </TableGrid>
    </TableBodyWrapper>
  )
}


const Slide3 = ({ setShowHeaderSecondaryMenu, setCurrentSlide, isiconfig, setBackground, setOverlay, handleShowModal }) => {
  const { isMobile } = useMobileDetect()
  const swiperSlide = useSwiperSlide();
  const [showPageContent, setShowPageContent] = useState(false)
  const slideOverlay = `linear-gradient(172.87deg, #50527A 0%, #4A4E61 25.73%, #2F314A 100%)`

  useActiveLink(setShowHeaderSecondaryMenu, 'safety')

  useEffect(() => {
    if (swiperSlide.isActive) {
      setShowHeaderSecondaryMenu(true)
      setShowPageContent(true)
      setCurrentSlide(3)
      setOverlay(slideOverlay)

      return
    }
    setShowPageContent(false)
  }, [swiperSlide.isActive])

  if (isMobile) {
    return (
      <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
        <Scroll>
          <ContainerScroll>
            <Container>
              <CustomWrapper>
                <Title>
                  <Title1>laboratory abnormalities<sup onClick={() => handleShowModal([30])}>30</sup></Title1>
                </Title>

                <TableContainer>
                  <div className="content">
                    <div className="text">
                      <TableText>
                        Select Laboratory Abnormalities Occurring (≥25%) That Worsened From Baseline in Patients With <em>KRAS G12C</em>-Mutated NSCLC Who Received KRAZATI in KRYSTAL-1
                      </TableText>
                      <BaseText>
                        <p className="uppercase">
                          <span className="green">None of the patients</span> who received prior IO &lt;30 days before KRAZATI treatment experienced Grade ≥3 treatment-related hepatotoxicity.<sup onClick={() => handleShowModal([34])}>34<span className="dagger">†</span></sup>
                        </p>
                        <p>&nbsp;</p>
                        <ul>
                          <li>There is no recommendation for a washout period after an IO-based regimen in the KRAZATI Prescribing Information.</li>
                        </ul>
                      </BaseText>
                    </div>
                  </div>
                  {/* <Table>
                      <TableHeader>
                        <TableItemLeft>
                          <TableHeaderTitle>Laboratory Abnormality</TableHeaderTitle>
                        </TableItemLeft>

                      <TableItemHeader>
                        <TableHeaderInfo>
                          <TableValues>KRAZATI (N=116)</TableValues>
                        </TableHeaderInfo>

                        <TableGrid>
                          <TableGrade>
                            <TableValues>All Grades (%)</TableValues>
                          </TableGrade>
                          <TableGrade>
                            <TableValues>Grade 3 or 4 (%)</TableValues>
                          </TableGrade>
                        </TableGrid>
                      </TableItemHeader>
                      </TableHeader>

                      <TableBody>
                        <TableSectionTitle>Hematology</TableSectionTitle>
                        <TableSection title="Decreased hemoglobin" values={[64, 25]} />
                      </TableBody>

                      <TableBody>
                        <TableSectionTitle>Chemistry</TableSectionTitle>
                        <TableSection title="Increased aspartate aminotransferase" values={[52, 6]} />
                        <TableSection title="Increased alanine aminotransferase" values={[46, 5]} />
                        <TableSection title="Increased lipase" values={[35,1.8]} />
                        <TableSection title="Decreased magnesium" values={[26,0]} />
                        <TableSection title="Decreased potassium" values={[26,3.5]} />
                        <TableSection title="Decreased sodium" values={[52,8]} />
                        <TableSection title="Increased creatinine" values={[50,0]} />
                        <TableSection title="Decreased albumin" values={[50,0.9]} />
                      </TableBody>
                    </Table> */}


                </TableContainer>
                <MobileTable>
                  <img src='/assets/safety-lab-anomolies-m.png' alt="Laboratory abnormalities" />
                </MobileTable>
              </CustomWrapper>

              <Reference>
                <p>*Denominator used to calculate the rate varied from 106 to 113 based on the number of patients with a baseline value and at least one post-treatment value.</p>
                <p><sup>†</sup>Hepatotoxicity included increased ALT/AST, increased liver function test, and mixed liver injury. In patients who received IO &gt;30 days before KRAZATI, 7 patients experienced grade 3 treatment-related hepatotoxicity, and 1 patient  experienced grade 4 treatment-related hepatotoxicity (ALT increase).</p>
                <p>ALT=alanine aminotransferase; AST=aspartate aminotransferase; IO=immunotherapy; NSCLC=non-small cell lung cancer.</p>
              </Reference>
            </Container>
          </ContainerScroll>
        </Scroll>
      </CustomContainer>
    )
  }

  return (
    <CustomContainer className={showPageContent ? 'visible' : 'hidden'} isiconfig={isiconfig}>
      <CustomWrapper>
        <Title>
          <Title1>Laboratory abnormalities<sup onClick={() => handleShowModal([30])}>30</sup></Title1>
        </Title>
        <CustomTableText>
          Select Laboratory Abnormalities Occurring (≥25%) That Worsened From Baseline in Patients With <em>KRAS G12C</em>-Mutated NSCLC Who Received KRAZATI in KRYSTAL-1
        </CustomTableText>
        <div className="content">
          <div className="table">
            <DesktopTable>
              <img src='/assets/slide3-table.webp' alt="Laboratory Abnormalities" />
            </DesktopTable>
          </div>
          <div className="text">
            <BaseText>
              <p className="uppercase">
                <span className="green">None of the patients</span> who received prior IO &lt;30 days before KRAZATI treatment experienced Grade ≥3 treatment-related hepatotoxicity.<sup onClick={() => handleShowModal([34])}>34<span className="dagger">†</span></sup>
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>There is no recommendation for a washout period after an IO-based regimen in the KRAZATI Prescribing Information.</li>
              </ul>
            </BaseText>
          </div>
        </div>
      </CustomWrapper>


      <CustomReference>
        <p>*Denominator used to calculate the rate varied from 106 to 113 based on the number of patients with a baseline value and at least one post-treatment value.</p>
        <p><sup>†</sup>Hepatotoxicity included increased ALT/AST, increased liver function test, and mixed liver injury. In patients who received IO &gt;30 days before KRAZATI, 7 patients experienced grade 3 treatment-related hepatotoxicity, and 1 patien  experienced grade 4 treatment-related hepatotoxicity (ALT increase).</p>
        <p>ALT=alanine aminotransferase; AST=aspartate aminotransferase; IO=immunotherapy; NSCLC=non-small cell lung cancer.</p>
      </CustomReference>
    </CustomContainer>
  )

}

export default Slide3

const Scroll = styled.div`
  height: 100%;
`

const CustomContainer = styled(Container)`
  display: flex;
  height: 100%;
  padding: 0;
  height: ${props => `calc(100% - ${props.isiconfig} - 1rem)`};
  justify-content: space-between;
  .content {
    .table {
      background: transparent;
    }
    .text {
      text-align: center;
      .uppercase {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
      }
      .green {
        color: #17C94E;
      }
    }
  }
  ul {
    margin-left: 1em;
    li::marker {
      margin-right: 10px;
    }
  }
  @media ${device.desktop} {
    .content {
      .text {
        text-align: left;
        .uppercase {
          text-align: left;
        }
      }
      justify-content: space-around;
      .table {
        width: auto;
        max-width: inherit;
      }
      .text {
        max-width: 500px;
      }
    }
  }
`
const CustomWrapper = styled(Wrapper)`
  height: auto;
  margin-bottom: 3rem;
  margin-top: 2rem;
  @media ${device.desktop} {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  width: 100%;
  text-transform: uppercase;

  h2 {
    text-transform: uppercase;
  }

  @media ${device.desktop} {
    margin-bottom: 2rem;
    padding: 0 2.6rem;


  }
`

const CustomTableText = styled(TableText)`
  font-family: 'Barlow';
  max-width: 75rem;
  color: #FFFFFF;
  font-family: Barlow;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.4rem;
  text-align: center;
  text-transform: initial;
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 58% 1fr;
  margin-bottom: .5rem;
`
const TableBodyWrapper = styled(TableHeader)`
  margin-bottom: 0.4rem;
`

const TableGrade = styled.div`
  border-right: 0.1rem solid white;
  padding: 0 0.4rem;
  line-height: 1;
  text-align: center;
  span {
    font-size: 0.8rem;
  }
  &:last-of-type {
    border-right: none;
  }
`

const CustomBannerContainer = styled(BannerContainer)`
  padding: 0 2.6rem;
  > div {
    text-align: left;
  }
`

const CustomBanner = styled(Banner)`
  padding: 0.8rem 0 .2rem;
`

const CustomListText = styled(ListText)`
  font-size: 1.3rem;
font-size: 1.3rem;
  margin-bottom: 0.4rem;

  &:last-of-type {
    margin-bottom: 2.4rem;
  }

  sup {
    font-size: 0.9rem;
  }

  @media ${device.desktop} {
    font-size: 1.7rem;

    sup {
      font-size: 1.1rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }
`
const CustomListContainer = styled(ListContainer)`
  padding: 0 4.4rem 0 2.6rem;

  @media ${device.desktop} {
    padding: 0;
    width: 100%;
  }
`

const CustomListItem = styled(ListItem)`
  color: #17C94E;
  a {
    text-decoration: underline;
    color: #17C94E;
    font-size: 1.3rem;
font-size: 1.3rem;
    font-family: 'Barlow';
    font-weight: bold;
  }

  @media ${device.desktop} {
    margin: 0;
    margin-bottom: .5rem;

    a {
      font-size: 1.7rem;
    }
  }
`
const CustomBullet = styled(Bullet)`
  color: #17C94E;
  margin: 0 .5rem;
`
const Information = styled.p`
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 1.3rem;
font-size: 1.3rem;
  color: white;
  margin-bottom: 2.4rem;
  sup {
    font-size: 0.9rem;
  }

  span {
    color: #17C94E;
    font-weight: bold;
  }

  @media ${device.desktop} {
    font-size: 1.7rem;
    margin-bottom: 0;
    text-align: left;
    sup {
      font-size: 1.1rem;
    }
  }
`

const DesktopTable = styled.div`
  max-width: 52rem;
  width: 100%;
  > div {
    padding: 3.2rem;
  }

`

const MobileTable = styled.div`
  img {
	width: 100%;
    max-width: 36rem;
	display: block;
	margin: 0 auto;
  }
`

const CustomReference = styled(Reference)`

`
